import React, { useRef, useState } from "react"
import { Link } from "react-router-dom"
import { Button, CardTitle, Col, Row, Spinner } from "reactstrap"

const CustomHeaderTitle = ({
  loading,
  title,
  rowCount,
  onChangeRowNumber,
  orderingList,
  orderingOptions = [],
  onChangeOrdering,
  searchBox,
  onSearchHandler,
  rightBtn,
  rightBtnRoute,
  rightBtnTitle,
  lgColumn,
  deleteBtn,
  onAllDeleteHandler,
  deleteBtnTitle,
  onCancelDeleteHandler,
  cancelBtn,
  onCancelLoading,
  onAdvancedSearch,
  advnacedSearchBtn,
  advnacedSearchBtnTitle,
  pageRowNumberOptions,
  onExportBtnClick,
  enableUploadBtn,
  onUploadBtnhandler,
  uploadBtnLoading,
  exportBtn,
  exportBtnLoading,
}) => {
  const [advnacedSearchClick, setAdvancedSearchClick] = useState(false)
  const [file, setFile] = useState(null)
  const fileRef = useRef()

  const onAdvancedSearchClicked = () => {
    setAdvancedSearchClick(!advnacedSearchClick)
    onAdvancedSearch(!advnacedSearchClick)
  }

  const onClickFileHandler = () => {
    fileRef.current.click()
  }

  const onGetFile = e => {
    const file = e.target.files[0]
    onUploadBtnhandler(file)
  }

  return (
    <CardTitle className="">
      <input
        type="file"
        style={{ display: "none" }}
        onChange={onGetFile}
        className="upload-file"
        id="upload-file"
        ref={fileRef}
      />
      <Row>
        <Col lg={6}>
          <Row>
            <Col
              lg={lgColumn ? lgColumn : 1}
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span style={{ fontSize: 20 }}>{title && title}</span>
              &nbsp;
              {loading && (
                <Spinner
                  className="ms-1"
                  style={{ padding: 7, marginTop: 5 }}
                  size="sm"
                  color="primary"
                />
              )}
            </Col>
            {rowCount && (
              <Col lg={2}>
                <select
                  id="formrow-InputState"
                  className="form-control rowSelectBox"
                  onChange={value => {
                    onChangeRowNumber(value.target.value)
                  }}
                >
                  {pageRowNumberOptions ? (
                    pageRowNumberOptions.map((row, key) => [
                      <option value={row} key={key}>
                        {row}
                      </option>,
                    ])
                  ) : (
                    <React.Fragment>
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={500}>500</option>
                    </React.Fragment>
                  )}
                </select>
              </Col>
            )}

            {orderingList && (
              <Col lg={3}>
                <div className="row acsbox">
                  <div className="col-6 leftRow">
                    <select
                      className="form-control "
                      onChange={value => {
                        onChangeOrdering({ name: value.target.value })
                      }}
                    >
                      {orderingOptions.length > 0 ? (
                        orderingOptions.map((option, key) => (
                          <option key={key} value={option.value}>
                            {option.name}
                          </option>
                        ))
                      ) : (
                        <option value={"id"}>ID</option>
                      )}
                    </select>
                  </div>
                  <div className="col-6 rightRow">
                    <select
                      className="form-control "
                      onChange={value => {
                        onChangeOrdering({ order: value.target.value })
                      }}
                    >
                      <option value={"desc"}>Desc</option>
                      <option value={"asc"}>Asc</option>
                    </select>
                  </div>
                </div>
              </Col>
            )}
            {searchBox && (
              <Col lg={4}>
                <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                  <div className="position-relative">
                    <label htmlFor="search-bar-0" className="search-label">
                      <span id="search-bar-0-label" className="sr-only">
                        Search this table
                      </span>
                      <input
                        id="search-bar-0"
                        type="text"
                        placeholder="Serach"
                        className="form-control"
                        onKeyUp={value => {
                          onSearchHandler(value.target.value)
                        }}
                      />
                    </label>
                    <i className="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </Col>

        <Col lg={6} style={{ textAlign: "right" }}>
          {advnacedSearchBtn && (
            <Link
              onClick={onAdvancedSearchClicked}
              className="btn btn-primary btn-sm m-1"
            >
              {advnacedSearchBtnTitle ? advnacedSearchBtnTitle : "Filter"}
            </Link>
          )}
          {cancelBtn && (
            <Button
              onClick={onCancelDeleteHandler}
              className="btn btn-info btn-sm m-1"
              disabled={onCancelLoading}
            >
              {onCancelLoading ? "Please Wail..." : "Cancel"}
            </Button>
          )}
          {exportBtn && (
            <Button
              onClick={onExportBtnClick}
              className="btn btn-info btn-sm m-1"
              disabled={exportBtnLoading}
            >
              {exportBtnLoading ? "Please Wail..." : "Export"}
            </Button>
          )}

          {enableUploadBtn && (
            <React.Fragment>
              <Button
                onClick={onClickFileHandler}
                className="btn btn-info btn-sm m-1"
                disabled={uploadBtnLoading}
              >
                {uploadBtnLoading ? "Please Wail..." : "Upload File"}
              </Button>
            </React.Fragment>
          )}

          {deleteBtn && (
            <Link
              onClick={onAllDeleteHandler}
              className="btn btn-danger btn-sm m-1"
            >
              {deleteBtnTitle ? deleteBtnTitle : "Delete"}
            </Link>
          )}
          {rightBtn && (
            <Link
              to={rightBtnRoute ? rightBtnRoute : "/"}
              className="btn btn-primary btn-sm"
            >
              {rightBtnTitle ? rightBtnTitle : "Create New"}
            </Link>
          )}
        </Col>
      </Row>
      <div className="row " style={{ justifyContent: "space-between" }}>
        <div
          className="col-8"
          style={{ alignItems: "center", display: "flex" }}
        >
          &nbsp;
        </div>
        <div className="col-2 " style={{ textAlign: "right" }}></div>
      </div>
    </CardTitle>
  )
}

export default CustomHeaderTitle
