import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Table,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

//redux
import { useSelector, useDispatch } from "react-redux"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import avatar from "../../assets/images/users/avatar-1.jpg"
import {
  changeUserPassword,
  changeUserProfile,
  getLoginHistories,
} from "store/auth/auth-actions"
import { authAction } from "store/auth/auth-slice"
import PasswordInput from "components/Common/PasswordInput"
import {
  getDiffDateFromNow,
  getTimestamp,
  getTimestampFormated,
} from "utils/utils"
// actions
const Profile = () => {
  //meta title
  document.title = "Profile"

  const user = useSelector(state => state.auth.user)
  const loading = useSelector(state => state.auth.loading)
  const loginHistory = useSelector(state => state.auth.loginHistories)

  const dispatch = useDispatch()

  const changePassword = useSelector(state => state.auth.changePassword)

  const nameValidate = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: user ? user?.name : "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your UserName"),
    }),
    onSubmit: values => {
      dispatch(changeUserProfile(values))
    },
  })

  console.log(loginHistory)

  useEffect(() => {
    dispatch(getLoginHistories())
    return () => {
      dispatch(authAction.resetChangePassword())
    }
  }, [])

  const changePasswordValidate = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      current_password: "",
      password: "",
      confirm: "",
    },
    validationSchema: Yup.object({
      current_password: Yup.string().required("current password is required"),
      password: Yup.string()
        .required(" password is required")
        .min(8, "Must be 8 characters or more")
        .matches(/[a-z]+/, "One lowercase character")
        .matches(/[A-Z]+/, "One uppercase character")
        .matches(/[@$!%*#?&]+/, "One special character")
        .matches(/\d+/, "One number"),
      confirm: Yup.string()
        .required("confirm password is required")
        .oneOf([Yup.ref("password")], "Password not match"),
    }),
    onSubmit: values => {
      console.log()
      dispatch(changeUserPassword(values))
    },
  })

  return (
    <React.Fragment>
      {/* Render Breadcrumb */}
      <Breadcrumb title={user?.name} breadcrumbItem="Profile" />

      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <div className="d-flex">
                <div className="flex-grow-1 align-self-center">
                  <div className="text-muted">
                    <h5>{user?.name}</h5>
                    <p className="mb-1">{user?.email}</p>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <h4 className="card-title mb-4">Profile</h4>

      <Card>
        <CardBody>
          <Form
            className="form-horizontal"
            onSubmit={e => {
              e.preventDefault()
              nameValidate.handleSubmit()
              return false
            }}
          >
            <div className="form-group">
              <Label className="form-label">Name</Label>
              <Input
                name="name"
                className="form-control"
                placeholder="Enter Name"
                type="text"
                onChange={nameValidate.handleChange}
                onBlur={nameValidate.handleBlur}
                value={nameValidate.values.name || ""}
                invalid={
                  nameValidate.touched.name && nameValidate.errors.name
                    ? true
                    : false
                }
              />
              {nameValidate.touched.username && nameValidate.errors.username ? (
                <FormFeedback type="invalid">
                  {nameValidate.errors.username}
                </FormFeedback>
              ) : null}
            </div>
            <div className="text-center mt-4">
              <Button type="submit" color="danger" disabled={loading}>
                {loading ? "Please Wail..." : "Update"}
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>

      <h4 className="card-title mb-4">Change Password</h4>
      <Card>
        <CardBody>
          <Form
            className="form-horizontal"
            onSubmit={e => {
              e.preventDefault()
              changePasswordValidate.handleSubmit()
              return false
            }}
          >
            <Row>
              <Col>
                <div className="form-group mb-4">
                  <Label className="form-label">Current Password</Label>
                  <PasswordInput
                    name="current_password"
                    // value={name}
                    className="form-control"
                    placeholder="Enter Current Password"
                    type="password"
                    onChange={changePasswordValidate.handleChange}
                    onBlur={changePasswordValidate.handleBlur}
                    value={changePasswordValidate.values.current_password || ""}
                    invalid={
                      (changePasswordValidate.touched.current_password &&
                        changePasswordValidate.errors.current_password) ||
                      changePassword?.error?.current_password?.[0]
                        ? true
                        : false
                    }
                  />
                  {(changePasswordValidate.touched.current_password &&
                    changePasswordValidate.errors.current_password) ||
                  changePassword?.error?.current_password ? (
                    <FormFeedback type="invalid">
                      {changePasswordValidate.errors.current_password ||
                        changePassword?.error?.current_password?.[0]}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form-group mb-4">
                  <Label className="form-label">New Password</Label>
                  <PasswordInput
                    name="password"
                    // value={name}
                    className="form-control"
                    placeholder="Enter New Password"
                    type="password"
                    onChange={changePasswordValidate.handleChange}
                    onBlur={changePasswordValidate.handleBlur}
                    value={changePasswordValidate.values.password || ""}
                    invalid={
                      (changePasswordValidate.touched.password &&
                        changePasswordValidate.errors.password) ||
                      changePassword?.error?.password?.[0]
                        ? true
                        : false
                    }
                  />
                  {(changePasswordValidate.touched.password &&
                    changePasswordValidate.errors.password) ||
                  changePassword?.error?.password?.[0] ? (
                    <FormFeedback type="invalid">
                      {changePasswordValidate.errors.password ||
                        changePassword?.error?.password?.[0]}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form-group mb-4">
                  <Label className="form-label">Re-type Password</Label>
                  <PasswordInput
                    name="confirm"
                    // value={name}
                    className="form-control"
                    placeholder="Enter New Password"
                    type="password"
                    onChange={changePasswordValidate.handleChange}
                    onBlur={changePasswordValidate.handleBlur}
                    value={changePasswordValidate.values.confirm || ""}
                    invalid={
                      (changePasswordValidate.touched.confirm &&
                        changePasswordValidate.errors.confirm) ||
                      changePassword?.error?.confirm?.[0]
                        ? true
                        : false
                    }
                  />
                  {(changePasswordValidate.touched.confirm &&
                    changePasswordValidate.errors.confirm) ||
                  changePassword?.error?.confirm?.[0] ? (
                    <FormFeedback type="invalid">
                      {changePasswordValidate.errors.confirm ||
                        changePassword?.error?.confirm?.[0]}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <div className="text-center mt-4">
              <Button
                type="submit"
                color="danger"
                disabled={changePassword?.loading}
              >
                {changePassword?.loading ? "Please Wait... " : "Change"}
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>

      <h4 className="card-title mb-4">Login History</h4>

      <Card>
        <CardBody>
          <Table>
            <thead>
              <tr>
                <th>SN</th>
                <th>IP</th>
                <th>Browser</th>
                <th>Device Platform</th>
                <th>Login Date</th>
              </tr>
            </thead>
            <tbody>
              {loginHistory?.data?.map((val, key) => (
                <tr key={key}>
                  <td>{key + 1}</td>
                  <td>{val?.ip}</td>
                  <td>{val?.browser}</td>
                  <td>{val?.device}</td>
                  <td>{getDiffDateFromNow(val?.created_at)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default Profile
