import React, { useEffect, useState } from "react"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import {
  exportMonitorQuantificationReport,
  getMonitorQuantificationReport,
} from "store/monitor-reports/monitor-report-action"
import {
  getMonitorIps,
  getMonitorItemCategories,
  getMonitorProjects,
} from "store/monitor-dashboard/monitor-dashboard-action"
import MonitorQuantificationReportListComponent from "components/MonitorReports/Quantification"

const MonitorQuantificationReport = () => {
  //meta title
  document.title = "Quantification Report"

  const [params, setParams] = useState({})
  const { data, error, loading, exportStatus } = useSelector(state => ({
    data: state.monitorReports.monitorQuantificationReport.data,
    error: state.monitorReports.monitorQuantificationReport.error,
    loading: state.monitorReports.monitorQuantificationReport.loading,
    exportStatus: state.monitorReports.monitorQuantificationExport,
  }))

  const dispatch = useDispatch()

  const onExport = () => {
    dispatch(exportMonitorQuantificationReport(params))
  }

  useEffect(() => {
    dispatch(getMonitorQuantificationReport())
    dispatch(getMonitorProjects())
    dispatch(getMonitorItemCategories())
    dispatch(getMonitorIps())
  }, [])

  const onSearchHandler = filter => {
    setParams(filter)
    dispatch(getMonitorQuantificationReport(filter))
  }

  return (
    <React.Fragment>
      <Breadcrumbs title={"Monitor"} breadcrumbItem={"Quantification Report"} />
      <MonitorQuantificationReportListComponent
        data={data}
        error={error}
        loading={loading}
        onSearchHandler={onSearchHandler}
        onExportBtnClick={onExport}
        exportStatus={exportStatus}
      />
    </React.Fragment>
  )
}

export default MonitorQuantificationReport
