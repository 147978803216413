import { useFormik } from "formik"

import * as Yup from "yup"
import React from "react"
import {
  Card,
  CardBody,
  Label,
  Button,
  Form,
  Input,
  FormFeedback,
} from "reactstrap"
import CustomHeaderTitle from "components/Common/CustomHeaderTitle"
import SingleSelect from "components/Common/SelectBoxes/SingleSelect"

const ItemForm = ({
  onSubmit,
  loading,
  error,
  formMode,
  item,
  itemCategories,
  itemCategoryLoading,
  itemCategoryError,
}) => {
  const validationType = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      item_category_id: item ? item?.item_category_id : "",
      item_name: item ? item?.item_name : "",
      unfp_item_name: item ? item?.unfp_item_name : "",
      ip_item_description: item ? item?.ip_item_description : "",
      uom: item ? item?.uom : "",
      unfpa_uom_quantity: item ? item?.unfpa_uom_quantity : "",
      ip_uom_quantity: item ? item?.ip_uom_quantity : "",
      ip_unit_umo: item ? item?.ip_unit_umo : "",
    },
    validationSchema: Yup.object().shape({
      item_category_id: Yup.string().required("This item category is required"),
      item_name: Yup.string().required("This item name is required").min(3),
      unfp_item_name: Yup.string()
        .required("The UNFPA item name is required")
        .min(2),
      ip_item_description: Yup.string()
        .required("The IP Item Description is required")
        .min(2),
      uom: Yup.string().required("The Unit of measure  is required"),
      unfpa_uom_quantity: Yup.string().required(
        "The UNFPA Unit of measure is required"
      ),
      ip_uom_quantity: Yup.string().required(
        "The IP Unit of measure quantity  is required"
      ),
      ip_unit_umo: Yup.string().required("This Ip unit of measure is required"),
    }),

    onSubmit: values => {
      onSubmit(values)
    },
  })

  return (
    <Card>
      <CardBody>
        <CustomHeaderTitle
          loading={loading}
          title={formMode === "Edit" ? "Edit Item" : "Add Item"}
          lgColumn={3}
          rowCount={false}
          orderingList={false}
          searchBox={false}
          rightBtn={true}
          rightBtnTitle={"Back"}
          rightBtnRoute={"/items"}
        />

        <Form
          onSubmit={e => {
            e.preventDefault()
            validationType.handleSubmit()
            return false
          }}
        >
          <div className="mb-3">
            <Label className="form-label" htmlFor="provinceSelect">
              Item Category
            </Label>

            {itemCategories.length > 0 && (
              <SingleSelect
                loading={itemCategoryLoading}
                defaultValue={item?.item_category_id}
                name="item_category_id"
                options={itemCategories}
                formik={validationType}
                formMode={formMode}
              />
            )}

            {validationType.errors.item_category_id ||
            error?.item_category_id?.[0] ? (
              <div
                type="invalid"
                style={{
                  color: "#f46a6a",
                  fontSize: "80%",
                }}
              >
                {validationType.errors.item_category_id ||
                  error?.item_category_id?.[0]}
              </div>
            ) : null}
          </div>

          <div className="mb-3">
            <Label className="form-label">Item Name</Label>
            <Input
              name="item_name"
              placeholder="Item Name"
              type="text"
              onChange={validationType.handleChange}
              onBlur={validationType.handleBlur}
              value={validationType.values.item_name || ""}
              invalid={
                (validationType.touched.item_name &&
                  validationType.errors.item_name) ||
                error?.item_name?.[0]
                  ? true
                  : false
              }
            />
            {(validationType.touched.item_name &&
              validationType.errors.item_name) ||
            error?.item_name?.[0] ? (
              <FormFeedback type="invalid">
                {validationType.errors.item_name || error?.item_name?.[0]}
              </FormFeedback>
            ) : null}
          </div>

          <div className="mb-3">
            <Label className="form-label">UNFPA Item ID</Label>
            <Input
              name="unfp_item_name"
              placeholder="UNFPA Item ID"
              type="text"
              onChange={validationType.handleChange}
              onBlur={validationType.handleBlur}
              value={validationType.values.unfp_item_name || ""}
              invalid={
                (validationType.touched.unfp_item_name &&
                  validationType.errors.unfp_item_name) ||
                error?.unfp_item_name?.[0]
                  ? true
                  : false
              }
            />
            {(validationType.touched.unfp_item_name &&
              validationType.errors.unfp_item_name) ||
            error?.unfp_item_name?.[0] ? (
              <FormFeedback type="invalid">
                {validationType.errors.unfp_item_name ||
                  error?.unfp_item_name?.[0]}
              </FormFeedback>
            ) : null}
          </div>

          <div className="mb-3">
            <Label className="form-label">IP-Item description</Label>
            <Input
              name="ip_item_description"
              placeholder="IP-Item description"
              type="text"
              onChange={validationType.handleChange}
              onBlur={validationType.handleBlur}
              value={validationType.values.ip_item_description || ""}
              invalid={
                (validationType.touched.ip_item_description &&
                  validationType.errors.ip_item_description) ||
                error?.ip_item_description?.[0]
                  ? true
                  : false
              }
            />
            {(validationType.touched.ip_item_description &&
              validationType.errors.ip_item_description) ||
            error?.ip_item_description?.[0] ? (
              <FormFeedback type="invalid">
                {validationType.errors.ip_item_description ||
                  error?.ip_item_description?.[0]}
              </FormFeedback>
            ) : null}
          </div>

          <div className="mb-3">
            <Label className="form-label">UNFPA Unit of measure (UOM)</Label>
            <Input
              name="uom"
              placeholder="UNFPA Unit
              of measure (UOM)"
              type="text"
              onChange={validationType.handleChange}
              onBlur={validationType.handleBlur}
              value={validationType.values.uom || ""}
              invalid={
                (validationType.touched.uom && validationType.errors.uom) ||
                error?.uom?.[0]
                  ? true
                  : false
              }
            />
            {(validationType.touched.uom && validationType.errors.uom) ||
            error?.uom?.[0] ? (
              <FormFeedback type="invalid">
                {validationType.errors.uom || error?.uom?.[0]}
              </FormFeedback>
            ) : null}
          </div>

          <div className="mb-3">
            <Label className="form-label">IP Unit UOM</Label>
            <Input
              name="ip_unit_umo"
              placeholder="IP Unit UOM"
              type="text"
              onChange={validationType.handleChange}
              onBlur={validationType.handleBlur}
              value={validationType.values.ip_unit_umo || ""}
              invalid={
                (validationType.touched.ip_unit_umo &&
                  validationType.errors.ip_unit_umo) ||
                error?.ip_unit_umo?.[0]
                  ? true
                  : false
              }
            />
            {(validationType.touched.ip_unit_umo &&
              validationType.errors.ip_unit_umo) ||
            error?.ip_unit_umo?.[0] ? (
              <FormFeedback type="invalid">
                {validationType.errors.ip_unit_umo || error?.ip_unit_umo?.[0]}
              </FormFeedback>
            ) : null}
          </div>

          <div className="mb-3">
            <Label className="form-label">
              UNFPA Unit of measure (UOM) Quantity
            </Label>
            <Input
              name="unfpa_uom_quantity"
              placeholder="UNFPA Unit of measure (UOM)
              Quantity"
              type="text"
              onChange={validationType.handleChange}
              onBlur={validationType.handleBlur}
              value={validationType.values.unfpa_uom_quantity || ""}
              invalid={
                (validationType.touched.unfpa_uom_quantity &&
                  validationType.errors.unfpa_uom_quantity) ||
                error?.unfpa_uom_quantity?.[0]
                  ? true
                  : false
              }
            />
            {(validationType.touched.unfpa_uom_quantity &&
              validationType.errors.unfpa_uom_quantity) ||
            error?.unfpa_uom_quantity?.[0] ? (
              <FormFeedback type="invalid">
                {validationType.errors.unfpa_uom_quantity ||
                  error?.unfpa_uom_quantity?.[0]}
              </FormFeedback>
            ) : null}
          </div>

          <div className="mb-3">
            <Label className="form-label">IP Unit UOM Quantity</Label>
            <Input
              name="ip_uom_quantity"
              placeholder="IP
              Unit UOM Quantity"
              type="text"
              onChange={validationType.handleChange}
              onBlur={validationType.handleBlur}
              value={validationType.values.ip_uom_quantity || ""}
              invalid={
                (validationType.touched.ip_uom_quantity &&
                  validationType.errors.ip_uom_quantity) ||
                error?.ip_uom_quantity?.[0]
                  ? true
                  : false
              }
            />
            {(validationType.touched.ip_uom_quantity &&
              validationType.errors.ip_uom_quantity) ||
            error?.ip_uom_quantity?.[0] ? (
              <FormFeedback type="invalid">
                {validationType.errors.ip_uom_quantity ||
                  error?.ip_uom_quantity?.[0]}
              </FormFeedback>
            ) : null}
          </div>

          <div className="d-flex flex-wrap gap-2">
            <Button type="submit" color="primary" disabled={loading}>
              {loading
                ? "Please Wait..."
                : formMode === "Add"
                ? "Submit"
                : "Update"}
            </Button>
          </div>
        </Form>
      </CardBody>
    </Card>
  )
}

export default ItemForm
