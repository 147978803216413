import FacilityForm from "components/Facility/form"
import ItemForm from "components/Item/form"
import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { storeFacility } from "store/facility/facility-action"
import {
  getAllItemCategories,
  getAllProvinces,
} from "store/general-renders/general-action"
import { generalAction } from "store/general-renders/general-slice"
import { storeItem } from "store/item/item-action"

const AddItem = () => {
  document.title = "Add Item"

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { loading, error, data } = useSelector(state => state.item)
  const { itemCategories, itemCategoryLoading, itemCategoryError } =
    useSelector(state => ({
      itemCategories: state.generalRenders.itemCategories,
      itemCategoryLoading: state.generalRenders.itemCategoryLoading,
      itemCategoryError: state.generalRenders.itemCategoryError,
    }))

  const handleSubmit = values => {
    dispatch(storeItem(values, navigate))
  }

  useEffect(() => {
    dispatch(getAllItemCategories())
  }, [])

  return (
    <ItemForm
      onSubmit={handleSubmit}
      loading={loading}
      error={error}
      formMode={"Add"}
      itemCategories={itemCategories}
      itemCategoryLoading={itemCategoryLoading}
      itemCategoryError={itemCategoryError}
    />
  )
}

export default AddItem
