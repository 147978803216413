import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import DeleteModal from "components/Common/DeleteModal"
import FacilityListComponent from "components/Facility/list"
import {
  deleteFacility,
  exportFacilities,
  getFacilities,
} from "store/facility/facility-action"

const FacilityList = () => {
  const dispatch = useDispatch()
  const { data, error, loading, exportStatus } = useSelector(
    state => state.facility
  )
  const [deleteModal, setDeleteModal] = useState(false)
  const [facility, setFacility] = useState(null)
  const [search, setSearch] = useState(null)
  const [pageNumber, setPageNumber] = useState(10)
  const [according, setAccording] = useState({ order: "desc", name: "id" })

  const handleDeleteFunc = data => {
    if (facility) {
      dispatch(deleteFacility(facility.id))
    }
    setDeleteModal(false)
  }

  const onClickDelete = data => {
    setFacility(data)
    setDeleteModal(true)
  }

  const onPageClick = link => {
    dispatch(getFacilities(link.url, pageNumber, according, search))
  }

  const onSearchHandler = value => {
    setSearch(value)
  }

  const onChangeRowNumber = value => {
    setPageNumber(value)
  }

  const onChangeOrdering = value => {
    if (value.order) {
      setAccording(prev => {
        return { ...prev, order: value.order }
      })
    }
    if (value.name) {
      setAccording(prev => {
        return { ...prev, name: value.name }
      })
    }
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(getFacilities(undefined, undefined, undefined, search))
    }, 1500)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  useEffect(() => {
    dispatch(getFacilities(undefined, pageNumber, according, search))
  }, [pageNumber, according])

  const onExportBtnClick = () => {
    dispatch(exportFacilities(search))
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteFunc}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Breadcrumbs title={"Admin"} breadcrumbItem={"Facilities"} />
      <FacilityListComponent
        data={data}
        error={error}
        loading={loading}
        onClickDelete={onClickDelete}
        handleDeleteFunc={handleDeleteFunc}
        onPageClick={onPageClick}
        onSearch={onSearchHandler}
        onChangeRowNumber={onChangeRowNumber}
        onChangeOrdering={onChangeOrdering}
        onExportBtnClick={onExportBtnClick}
        exportStatus={exportStatus}
      />
    </React.Fragment>
  )
}

export default FacilityList
