import React, { useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Col, Form, Label, Row } from "reactstrap"
import Select from "react-select"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import moment from "moment"
import { isObject } from "lodash"
import {
  getMonitorFacilities,
  getMonitorItems,
  getMonitorProviderUsers,
} from "store/monitor-dashboard/monitor-dashboard-action"
import { monitorDashboardAction } from "store/monitor-dashboard/monitor-dashboard-slice"
import { getMonitorNonIps } from "store/monitor-distributions/monitor-distribution-action"

const MonitorDistributionFilter = ({ onAdvancedSearchSubmit, loading }) => {
  const distributionFlatpickerfrom = useRef(null)
  const distributionFlatpickerto = useRef(null)
  const [category, setCategory] = useState(null)
  const [province, setProvince] = useState(null)
  const [facility, setFacility] = useState(null)
  const [project, setProject] = useState(null)
  const [item, setItem] = useState(null)
  const [ip, setIP] = useState(null)
  const [provinceIP, setProvinceIP] = useState(null)
  const [NonIp, setNonIp] = useState(null)
  const [providerUser, setProviderUser] = useState(null)
  const [expireDate, setExpireDate] = useState({
    from: null,
    to: null,
  })
  const [distributionDate, setDistributionDate] = useState({
    from: null,
    to: null,
  })
  const items = useSelector(state => state.monitorDashboard.MonitorItems)
  const categories = useSelector(
    state => state.monitorDashboard.MonitorItemCategoires
  )

  const provider_users = useSelector(
    state => state.monitorDashboard.monitorProviderUsers
  )

  const provinces = useSelector(
    state => state.monitorDashboard.monitorProvinces
  )

  const ips = useSelector(state => state.monitorDashboard.MonitorIps)

  const nonIps = useSelector(state => state.monitorDistributions.NonIps)

  const projects = useSelector(state => state.monitorDashboard.monitorProjects)

  const facilities = useSelector(
    state => state.monitorDashboard.monitorFacilities
  )

  const filteredItems = useMemo(
    () =>
      items?.data.map(item => ({
        value: item.id,
        label: `${item.unfp_item_name} ( ${item.item_name} )`,
      })),
    [items]
  )

  const dispatch = useDispatch()

  const handleSubmit = () => {
    let data = {}
    if (item) {
      data.item = item.map(ite => (ite.value ? ite.value : ite))
    }

    if (NonIp) {
      data.nonIps = NonIp.map(non => (non.value ? non.value : non))
    }
    data.provinceIP = isObject(provinceIP) ? provinceIP.value : null
    data.project = isObject(project) ? project.value : null
    data.category = isObject(category) ? category.value : null
    data.providerIP = isObject(ip) ? ip.value : null
    data.providerUser = isObject(providerUser) ? providerUser.value : null
    data.province = isObject(province) ? province.value : null
    data.facility = isObject(facility) ? facility.value : null
    data.distributionDate = {
      from: distributionDate.from ? distributionDate.from : null,
      to: distributionDate.to ? distributionDate.to : null,
    }
    onAdvancedSearchSubmit(data)
  }

  const onCategoryChangeHandler = cate => {
    setCategory(cate)
    dispatch(getMonitorItems(cate.value))
  }

  const onItemChangeHandler = item => {
    const check = item.some(ite => ite.value === "all")
    if (check) {
      setItem(filteredItems)
    } else {
      setItem(item)
    }
  }

  const onProvinceChangeHandler = value => {
    setProvince(value)
    dispatch(getMonitorFacilities(value.value))
  }

  const onFacilityChangeHandler = value => {
    setFacility(value)
  }

  const onProjectChangeHandler = value => {
    setProject(value)
  }

  const onIPChangeHandler = value => {
    dispatch(getMonitorProviderUsers(value.value))
    setIP(value)
  }

  const onProvinceIPChangeHandler = value => {
    dispatch(getMonitorNonIps(value.value))
    setProvinceIP(value)
  }

  const onNonIpChangeHandler = value => {
    const check = value.some(ite => ite.value === "all")
    if (check) {
      setNonIp(nonIps?.data)
    } else {
      setNonIp(value)
    }
  }

  const onProviderUserChangeHandler = value => {
    setProviderUser(value)
  }

  const onClearHandler = () => {
    distributionFlatpickerfrom.current.flatpickr.clear()
    distributionFlatpickerto.current.flatpickr.clear()
    dispatch(monitorDashboardAction.resetMonitorProviderUsers())
    setCategory(null)
    setItem(null)
    setProvince(null)
    setFacility(null)
    setIP(null)
    setProject(null)
    setProviderUser(null)
    setDistributionDate({ from: null, to: null })
    onAdvancedSearchSubmit({})
    setProvinceIP(null)
    setNonIp(null)
    dispatch(monitorDashboardAction.resetMonitorItems())
    dispatch(monitorDashboardAction.resetMonitorFacilities())
  }

  return (
    <Form
      onSubmit={e => {
        e.preventDefault()
        handleSubmit()
        return false
      }}
    >
      <div className="mb-3">
        <Row>
          <Col>
            <Label className="form-label">Provider IPs</Label>
            {ips?.data && (
              <Select
                options={ips?.data}
                value={ip}
                onChange={onIPChangeHandler}
              />
            )}
          </Col>

          <Col>
            <Label className="form-label">Provider Users</Label>
            {provider_users?.data && (
              <Select
                options={[
                  { value: "all", label: "All" },
                  ...provider_users?.data,
                ]}
                value={providerUser}
                onChange={onProviderUserChangeHandler}
              />
            )}
          </Col>
        </Row>
      </div>

      <div className="mb-3">
        <Row>
          <Col>
            <Label className="form-label">IPs</Label>
            {ips?.data && (
              <Select
                options={ips?.data}
                value={provinceIP}
                onChange={onProvinceIPChangeHandler}
              />
            )}
          </Col>
          <Col>
            <Label className="form-label">Non IPs</Label>
            {nonIps?.data && (
              <Select
                isMulti={true}
                options={[{ value: "all", label: "All" }, ...nonIps?.data]}
                value={NonIp}
                onChange={onNonIpChangeHandler}
              />
            )}
          </Col>
        </Row>
      </div>

      <div className="mb-3">
        <Row>
          <Col>
            <Label className="form-label">Projects</Label>
            {projects?.data && (
              <Select
                options={projects?.data}
                value={project}
                onChange={onProjectChangeHandler}
              />
            )}
          </Col>
        </Row>
      </div>

      <div className="mb-3">
        <Row>
          <Col>
            <Label className="form-label">Province</Label>
            {provinces?.data && (
              <Select
                options={provinces?.data}
                value={province}
                onChange={onProvinceChangeHandler}
              />
            )}
          </Col>
          <Col>
            <Label className="form-label">Facilities</Label>
            {facilities?.data && (
              <Select
                options={[
                  { value: null, label: "Select" },
                  ...facilities?.data,
                ]}
                value={facility}
                onChange={onFacilityChangeHandler}
              />
            )}
          </Col>
        </Row>
      </div>

      <div className="mb-3">
        <Row>
          <Col>
            <Label className="form-label">Item Categories</Label>
            {categories?.data && (
              <Select
                options={categories?.data}
                value={category || categories?.data[0]}
                onChange={onCategoryChangeHandler}
              />
            )}
          </Col>
          <Col>
            <Label className="form-label">Items</Label>
            {filteredItems && (
              <Select
                isMulti={true}
                options={[{ value: "all", label: "All" }, ...filteredItems]}
                value={item}
                onChange={onItemChangeHandler}
              />
            )}
          </Col>
        </Row>
      </div>

      <div className="mb-3 ">
        <Row>
          <Col>
            <Label className="form-label">Distribution From Date</Label>
            <Flatpickr
              ref={distributionFlatpickerfrom}
              className="form-control d-block"
              placeholder="dd M,yyyy"
              options={{
                altInput: true,
                altFormat: "Y-m-d",
                dateFormat: "Y-m-d",
              }}
              onChange={value => {
                setDistributionDate(prev => {
                  return {
                    ...prev,
                    from: moment(value[0]).format("YYYY-MM-DD"),
                  }
                })
              }}
            />
          </Col>
          <Col>
            <Label className="form-label">Distribution To Date</Label>
            <Flatpickr
              ref={distributionFlatpickerto}
              className="form-control d-block"
              placeholder="dd M,yyyy"
              options={{
                altInput: true,
                altFormat: "Y-m-d",
                dateFormat: "Y-m-d",
              }}
              onChange={value => {
                setDistributionDate(prev => {
                  return { ...prev, to: moment(value[0]).format("YYYY-MM-DD") }
                })
              }}
            />
          </Col>
        </Row>
      </div>

      <div className="d-flex flex-wrap gap-2 mb-4">
        <Button
          type="submit"
          className="btn btn-sm btn-primary"
          color="primary"
          disabled={loading}
        >
          {loading ? "Please Wait..." : "Search"}
        </Button>
        <Button
          onClick={onClearHandler}
          className="btn btn-sm btn-danger"
          color="primary"
        >
          Clear
        </Button>
      </div>
    </Form>
  )
}

export default MonitorDistributionFilter
