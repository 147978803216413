import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: [],
  loading: false,
  error: null,
  exportExpireReceipt: { loading: false, error: null },
  count: 0,
}

const providerExpireReceiptsSlice = createSlice({
  name: "providerExpireReceipts",
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    setError(state, action) {
      state.error = action.payload
    },
    setProject(state, action) {
      state.project = action.payload
    },
    setProviderExpireExport(state, action) {
      state.exportExpireReceipt = {
        loading: action.payload.loading,
        error: action.payload.error,
      }
    },
    setCount(state, action) {
      state.count = action.payload
    },
    resetState(state) {
      state.data = []
      state.loading = false
      state.error = null
      state.project = null
      state.exportExpireReceipt = { loading: false, error: null }
    },
  },
})

export const providerExpireReceiptsAction = providerExpireReceiptsSlice.actions

export default providerExpireReceiptsSlice
