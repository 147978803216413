import { createSlice } from "@reduxjs/toolkit"

const initialState = { data: [], error: null, loading: false }

const activityLogsSlice = createSlice({
  name: "activityLogs",
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload
    },
    setError(state, action) {
      state.error = action.payload
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    resetState(state) {
      state.data = []
      state.error = null
      state.loading = false
    },
  },
})

export const activityLogsAction = activityLogsSlice.actions

export default activityLogsSlice
