import WarehouseForm from "components/Warehouse/form"
import React, { useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import {
  getByIdWarehouse,
  updateWarehouse,
} from "store/warehouse/warehouse-action"
import Preloader from "components/Common/Loader"
import { warehouseAction } from "store/warehouse/warehouse-slice"

const EditWarehouse = () => {
  document.title = "Edit Warehouse"

  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { loading, warehouse, error } = useSelector(state => state.warehouse)

  const onSubmit = values => {
    const form = new FormData()
    form.append("name", values.name)
    form.append("id", id)
    dispatch(updateWarehouse(id, form, navigate))
  }

  useEffect(() => {
    dispatch(getByIdWarehouse(id))

    return () => {
      dispatch(warehouseAction.resetState())
    }
  }, [])

  return (
    <React.Fragment>
      {warehouse ? (
        <WarehouseForm
          loading={loading}
          warehouse={warehouse?.data}
          error={error}
          onSubmit={onSubmit}
          formMode={"Edit"}
        />
      ) : (
        <Preloader loading={true} />
      )}
    </React.Fragment>
  )
}

export default EditWarehouse
