import React from "react"
import { Card, CardBody } from "reactstrap"
import { useNavigate } from "react-router-dom"
import CustomHeaderTitle from "components/Common/CustomHeaderTitle"
import GeneralTables from "components/Common/GeneralTable"
import ActionColumns from "components/Common/GeneralTable/ActionColumns"

const IPListComponent = ({
  data,
  error,
  loading,
  onClickDelete,
  onPageClick,
  onSearch,
  onChangeRowNumber,
  onChangeOrdering,
  onExportBtnClick,
  exportStatus,
}) => {
  const navigate = useNavigate()

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CustomHeaderTitle
            loading={loading}
            title={"List"}
            rowCount={true}
            onChangeRowNumber={onChangeRowNumber}
            orderingList={true}
            onChangeOrdering={onChangeOrdering}
            searchBox={true}
            onSearchHandler={onSearch}
            exportBtn={true}
            onExportBtnClick={onExportBtnClick}
            exportBtnLoading={exportStatus?.loading}
            rightBtn={true}
            rightBtnTitle={"Add IP"}
            rightBtnRoute={"/add-ip"}
            orderingOptions={[
              { value: "id", name: "ID" },
              { value: "name", name: "Name" },
            ]}
          />
          <GeneralTables
            data={data?.data}
            loading={loading}
            headers={["#", "Name", "Code", "Action"]}
            pagination={{ meta: data?.meta, onPageClick: onPageClick }}
            renderItem={(ip, index) => (
              <tr key={index}>
                <th scope="row">{data?.meta?.from + index}</th>
                <td>{ip.name}</td>
                <td>{ip.code}</td>
                <td>
                  <ActionColumns
                    data={ip}
                    enableEdit={true}
                    enableDelete={true}
                    editRoute={`/edit-ip/${ip.id}`}
                    onClickDelete={onClickDelete}
                  />
                </td>
              </tr>
            )}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default IPListComponent
