import React, { useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"

import ProvinceForm from "components/Province/form"
import { getByIdProvince, updateProvince } from "store/province/province-action"
import { provinceAction } from "store/province/province-slice"
import Preloader from "components/Common/Loader"

const EditProvince = () => {
  document.title = "Edit Province"

  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { loading, province, error } = useSelector(state => state.province)

  const onSubmit = values => {
    const form = new FormData()
    form.append("province", values.province)
    form.append("region", values.region)
    form.append("id", id)
    dispatch(updateProvince(id, form, navigate))
  }

  useEffect(() => {
    dispatch(getByIdProvince(id))

    return () => {
      dispatch(provinceAction.resetState())
    }
  }, [])

  return (
    <React.Fragment>
      {province ? (
        <ProvinceForm
          loading={loading}
          province={province?.data}
          error={error}
          onSubmit={onSubmit}
          formMode={"Edit"}
        />
      ) : (
        <Preloader loading={true} />
      )}
    </React.Fragment>
  )
}

export default EditProvince
