import { useFormik } from "formik"

import * as Yup from "yup"
import React from "react"
import {
  Card,
  CardBody,
  Label,
  Button,
  Form,
  Input,
  FormFeedback,
} from "reactstrap"
import CustomHeaderTitle from "components/Common/CustomHeaderTitle"
import SingleSelect from "components/Common/SelectBoxes/SingleSelect"

const FacilityForm = ({
  onSubmit,
  loading,
  error,
  formMode,
  facility,
  provinces,
  provinceLoading,
  provinceError,
}) => {
  const validationType = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      province_id: facility ? facility?.province_id : "",
      facility_name: facility ? facility?.facility_name : "",
      facility_type: facility ? facility?.facility_type : "",
      facility_code: facility ? facility?.facility_code : "",
    },
    validationSchema: Yup.object().shape({
      province_id: Yup.string().required("This province is required"),
      facility_name: Yup.string()
        .required("This facility name is required")
        .min(3),
      facility_type: Yup.string()
        .required("This facility type is required")
        .min(3),
      facility_code: Yup.string()
        .required("This facility code is required")
        .min(1),
    }),

    onSubmit: values => {
      onSubmit(values)
    },
  })

  return (
    <Card>
      <CardBody>
        <CustomHeaderTitle
          loading={loading}
          title={formMode === "Edit" ? "Edit Facility" : "Add Facility"}
          lgColumn={3}
          rowCount={false}
          orderingList={false}
          searchBox={false}
          rightBtn={true}
          rightBtnTitle={"Back"}
          rightBtnRoute={"/facilities"}
        />

        <Form
          onSubmit={e => {
            e.preventDefault()
            validationType.handleSubmit()
            return false
          }}
        >
          <div className="mb-3">
            <Label className="form-label" htmlFor="provinceSelect">
              Province
            </Label>

            {provinces.length > 0 && (
              <SingleSelect
                loading={provinceLoading}
                defaultValue={facility?.province_id}
                name="province_id"
                options={provinces}
                formik={validationType}
                formMode={formMode}
              />
            )}

            {validationType.errors.province_id || error?.province_id?.[0] ? (
              <div
                type="invalid"
                style={{
                  color: "#f46a6a",
                  fontSize: "80%",
                }}
              >
                {validationType.errors.province_id || error?.province_id?.[0]}
              </div>
            ) : null}
          </div>

          <div className="mb-3">
            <Label className="form-label">Facility Name</Label>
            <Input
              name="facility_name"
              placeholder="Facility Name"
              type="text"
              onChange={validationType.handleChange}
              onBlur={validationType.handleBlur}
              value={validationType.values.facility_name || ""}
              invalid={
                (validationType.touched.facility_name &&
                  validationType.errors.facility_name) ||
                error?.facility_name?.[0]
                  ? true
                  : false
              }
            />
            {(validationType.touched.facility_name &&
              validationType.errors.facility_name) ||
            error?.facility_name?.[0] ? (
              <FormFeedback type="invalid">
                {validationType.errors.facility_name ||
                  error?.facility_name?.[0]}
              </FormFeedback>
            ) : null}
          </div>

          <div className="mb-3">
            <Label className="form-label">Facility Type</Label>
            <Input
              name="facility_type"
              placeholder="Facility Type"
              type="text"
              onChange={validationType.handleChange}
              onBlur={validationType.handleBlur}
              value={validationType.values.facility_type || ""}
              invalid={
                (validationType.touched.facility_type &&
                  validationType.errors.facility_type) ||
                error?.facility_type?.[0]
                  ? true
                  : false
              }
            />
            {(validationType.touched.facility_type &&
              validationType.errors.facility_type) ||
            error?.facility_type?.[0] ? (
              <FormFeedback type="invalid">
                {validationType.errors.facility_type ||
                  error?.facility_type?.[0]}
              </FormFeedback>
            ) : null}
          </div>

          <div className="mb-3">
            <Label className="form-label">Facility Code</Label>
            <Input
              name="facility_code"
              placeholder="Facility Type"
              type="text"
              onChange={validationType.handleChange}
              onBlur={validationType.handleBlur}
              value={validationType.values.facility_code || ""}
              invalid={
                (validationType.touched.facility_code &&
                  validationType.errors.facility_code) ||
                error?.facility_code?.[0]
                  ? true
                  : false
              }
            />
            {(validationType.touched.facility_code &&
              validationType.errors.facility_code) ||
            error?.facility_code?.[0] ? (
              <FormFeedback type="invalid">
                {validationType.errors.facility_code ||
                  error?.facility_code?.[0]}
              </FormFeedback>
            ) : null}
          </div>

          <div className="d-flex flex-wrap gap-2">
            <Button type="submit" color="primary" disabled={loading}>
              {loading
                ? "Please Wait..."
                : formMode === "Add"
                ? "Submit"
                : "Update"}
            </Button>
          </div>
        </Form>
      </CardBody>
    </Card>
  )
}

export default FacilityForm
