import Logout from "pages/Authentication/Logout"
import Dashboard from "pages/Dashboard"
import UnAuthorized from "pages/errors/UnAuthorized"
import React from "react"
import WarehouseList from "pages/Admin/Warehouse"
import AddWarehouse from "pages/Admin/Warehouse/add"
import ProviderList from "pages/Admin/Providers"
import AddProvider from "pages/Admin/Providers/add"
import EditWarehouse from "pages/Admin/Warehouse/update"
import EditProvider from "pages/Admin/Providers/update"
import ProvinceList from "pages/Admin/Province"
import EditProvince from "pages/Admin/Province/update"
import FacilityList from "pages/Admin/Facility"
import AddFacility from "pages/Admin/Facility/add"
import EditFacility from "pages/Admin/Facility/update"
import IPList from "pages/Admin/IP"
import AddIP from "pages/Admin/IP/add"
import EditIP from "pages/Admin/IP/update"
import ItemCategoryList from "pages/Admin/ItemCategory"
import AddItemCategory from "pages/Admin/ItemCategory/add"
import EditItemCategory from "pages/Admin/ItemCategory/update"
import ItemList from "pages/Admin/Item"
import AddItem from "pages/Admin/Item/add"
import EditItem from "pages/Admin/Item/update"
import UserList from "pages/Admin/User"
import AddUser from "pages/Admin/User/add"
import EditUser from "pages/Admin/User/update"
import RoleList from "pages/Admin/Role"
import EditRole from "pages/Admin/Role/update"
import ProjectList from "pages/Admin/Project"
import AddProject from "pages/Admin/Project/add"
import EditProject from "pages/Admin/Project/update"
import Profile from "pages/Profile"
import ProviderChartInItems from "pages/Provider/Dashboard/ProviderChartInItems"
import ProviderChartOutItems from "pages/Provider/Dashboard/ProviderChartOutItems"
import ProviderIPsChartInItems from "pages/Provider/Dashboard/ProviderIPsChartInItems"
import ProviderIPsChartOutItems from "pages/Provider/Dashboard/ProviderIPsChartOutItems"
import ProviderInList from "pages/Provider/ProviderIn"
import AddProviderIn from "pages/Provider/ProviderIn/add"
import EditProviderIn from "pages/Provider/ProviderIn/update"
import ProviderOutList from "pages/Provider/ProviderOut"
import AddProviderOut from "pages/Provider/ProviderOut/add"
import EditProviderOut from "pages/Provider/ProviderOut/update"
import ProviderExpireReceiptList from "pages/Provider/ExpireReceipts"
import ProviderConsumptionList from "pages/Provider/Consumptions"
import ProviderTransactionList from "pages/Provider/Reports/transaction"
import ProviderReceiptReportList from "pages/Provider/Reports/ReceiptReport"
import ProviderDistributionReportList from "pages/Provider/Reports/DistributionReport"
import ProviderProvinceDistributionReportList from "pages/Provider/Reports/IPDistributionReport"
import ProviderInventoryList from "pages/Provider/Reports/Inventory"
import ProvinceChartInItems from "pages/Province/Dashboard/ProvinceChartInItems"
import ProvinceChartOutItems from "pages/Province/Dashboard/ProvinceChartOutItems"
import ProvinceInList from "pages/Province/ProvinceIn"
import ProvinceOutList from "pages/Province/ProvinceOut"
import AddProvinceOut from "pages/Province/ProvinceOut/add"
import EditProvinceOut from "pages/Province/ProvinceOut/update"
import ProvinceExpireReceiptList from "pages/Province/ExpireReceipts"
import ProvinceConsumptionList from "pages/Province/Consumptions"
import ProvinceTransactionList from "pages/Province/Reports/transaction"
import MonitorChartInItems from "pages/Monitor/Dashboard/MonitorChartInItems"
import MonitorChartOutItems from "pages/Monitor/Dashboard/MonitorChartOutItems"
import MonitorIPsChartInItems from "pages/Monitor/Dashboard/MonitorIPsChartInItems"
import MonitorIPsChartOutItems from "pages/Monitor/Dashboard/MonitorIPsChartOutItems"
import MonitorReceipts from "pages/Monitor/Receipts"
import MonitorDistributions from "pages/Monitor/Distributions"
import MonitorExpireReceiptList from "pages/Monitor/ExpireReceipts"
import MonitorTransactionList from "pages/Monitor/Reports/transaction"
import MonitorReceiptReportList from "pages/Monitor/Reports/ReceiptReport"
import MonitorDistributionReportList from "pages/Monitor/Reports/DistributionReport"
import MonitorProvinceDistributionReportList from "pages/Monitor/Reports/IPDistributionReport"
import MonitorInventoryList from "pages/Monitor/Reports/Inventory"
import ActivityLogsList from "pages/Admin/ActivityLogs"
import ProviderReceiptActionRequestList from "pages/Admin/ProviderActionRequests/receipts"
import ProviderDistributionActionRequestList from "pages/Admin/ProviderActionRequests/distributions"
import ProviderConsumptionReportList from "pages/Provider/Reports/ConsumptionReport"
import ProviderTransactionByProjectList from "pages/Provider/Reports/transactionByProject"
import MonitorConsumptionReportList from "pages/Monitor/Reports/ConsumptionReport"
import ProviderReceiptHealthReportList from "pages/Provider/Reports/receiptHealthReport"
import ProviderExpireReceiptListBased12Month from "pages/Provider/ExpireReceipts/ExpireListBased12Month"
import ProviderStockOut from "pages/Provider/StockOut"
import ProviderQuantificationReport from "pages/Provider/Reports/QuantificationReport"
import ProviderQuantificationOrganizationReport from "pages/Provider/Reports/QuantificationOrganization"
import ProviderConsumptionReportList2 from "pages/Provider/Reports/ConsumptionReport2"
import ProvinceConsumptionReportList from "pages/Province/Reports/ConsumptionReport"
import ProvinceDistributionActionRequestList from "pages/Provider/ProvinceActionRequest/distributions"
import MonitorExpireReceiptListBased12Month from "pages/Monitor/ExpireReceipts/ExpireListBased12Month"
import MonitorStockOut from "pages/Monitor/StockOut"
import MonitorConsumptionReportList2 from "pages/Monitor/Reports/ConsumptionReport2"
import MonitorReceiptHealthReportList from "pages/Monitor/Reports/receiptHealthReport"
import MonitorQuantificationReport from "pages/Monitor/Reports/QuantificationReport"
import MonitorQuantificationOrganizationReport from "pages/Monitor/Reports/QuantificationOrganization"

export const childrenRoutes = [
  {
    path: "dashboard",
    element: <Dashboard />,
    allowRoles: [],
  },
  {
    path: "warehouse",
    element: <WarehouseList />,
    allowRoles: ["Admin"],
  },
  {
    path: "add-warehouse",
    element: <AddWarehouse />,
    allowRoles: ["Admin"],
  },
  {
    path: "edit-warehouse/:id",
    element: <EditWarehouse />,
    allowRoles: ["Admin"],
  },
  {
    path: "providers",
    element: <ProviderList />,
    allowRoles: ["Admin"],
  },
  {
    path: "add-provider",
    element: <AddProvider />,
    allowRoles: ["Admin"],
  },
  {
    path: "edit-provider/:id",
    element: <EditProvider />,
    allowRoles: ["Admin"],
  },
  {
    path: "provinces",
    element: <ProvinceList />,
    allowRoles: ["Admin"],
  },
  {
    path: "edit-province/:id",
    element: <EditProvince />,
    allowRoles: ["Admin"],
  },
  {
    path: "facilities",
    element: <FacilityList />,
    allowRoles: ["Admin"],
  },
  {
    path: "add-facility",
    element: <AddFacility />,
    allowRoles: ["Admin"],
  },
  {
    path: "edit-facility/:id",
    element: <EditFacility />,
    allowRoles: ["Admin"],
  },
  {
    path: "ips",
    element: <IPList />,
    allowRoles: ["Admin"],
  },
  {
    path: "add-ip",
    element: <AddIP />,
    allowRoles: ["Admin"],
  },
  {
    path: "edit-ip/:id",
    element: <EditIP />,
    allowRoles: ["Admin"],
  },
  {
    path: "item-categories",
    element: <ItemCategoryList />,
    allowRoles: ["Admin"],
  },
  {
    path: "add-item-category",
    element: <AddItemCategory />,
    allowRoles: ["Admin"],
  },
  {
    path: "edit-item-category/:id",
    element: <EditItemCategory />,
    allowRoles: ["Admin"],
  },
  {
    path: "items",
    element: <ItemList />,
    allowRoles: ["Admin"],
  },
  {
    path: "add-item",
    element: <AddItem />,
    allowRoles: ["Admin"],
  },
  {
    path: "edit-item/:id",
    element: <EditItem />,
    allowRoles: ["Admin"],
  },
  {
    path: "/users",
    element: <UserList />,
    allowRoles: ["Admin"],
  },
  {
    path: "/add-user",
    element: <AddUser />,
    allowRoles: ["Admin"],
  },
  {
    path: "/edit-user/:id",
    element: <EditUser />,
    allowRoles: ["Admin"],
  },
  {
    path: "/roles",
    element: <RoleList />,
    allowRoles: ["Admin"],
  },
  {
    path: "/edit-role/:id",
    element: <EditRole />,
    allowRoles: ["Admin"],
  },
  {
    path: "/projects",
    element: <ProjectList />,
    allowRoles: ["Admin"],
  },
  {
    path: "/add-project",
    element: <AddProject />,
    allowRoles: ["Admin"],
  },
  {
    path: "/edit-project/:id",
    element: <EditProject />,
    allowRoles: ["Admin"],
  },
  {
    path: "/profile",
    element: <Profile />,
    allowRoles: [],
  },
  {
    path: "/dashboard/provider-item-ins",
    element: <ProviderChartInItems />,
    allowRoles: ["Provider"],
  },
  {
    path: "/dashboard/provider-item-outs",
    element: <ProviderChartOutItems />,
    allowRoles: ["Provider"],
  },
  {
    path: "/dashboard/provider-ips-item-ins",
    element: <ProviderIPsChartInItems />,
    allowRoles: ["Provider"],
  },
  {
    path: "/dashboard/provider-ips-item-outs",
    element: <ProviderIPsChartOutItems />,
    allowRoles: ["Provider"],
  },
  {
    path: "/provider-receipts",
    element: <ProviderInList />,
    allowRoles: ["Provider"],
    allowPermission: "show-provider-in",
  },
  {
    path: "/add-provider-receipts",
    element: <AddProviderIn />,
    allowRoles: ["Provider"],
    allowPermission: "create-provider-in",
  },
  {
    path: "/edit-provider-receipts/:id",
    element: <EditProviderIn />,
    allowRoles: ["Provider"],
    allowPermission: "create-provider-in",
  },
  {
    path: "/provider-distributions",
    element: <ProviderOutList />,
    allowRoles: ["Provider"],
    allowPermission: "show-provider-out",
  },
  {
    path: "/add-provider-distributions",
    element: <AddProviderOut />,
    allowRoles: ["Provider"],
    allowPermission: "create-provider-out",
  },
  {
    path: "/edit-provider-distributions/:id",
    element: <EditProviderOut />,
    allowRoles: ["Provider"],
    allowPermission: "create-provider-out",
  },
  {
    path: "/provider-expire-receipts",
    element: <ProviderExpireReceiptList />,
    allowRoles: ["Provider"],
  },
  {
    path: "/provider-expire-receipts-base-12-month",
    element: <ProviderExpireReceiptListBased12Month />,
    allowRoles: ["Provider"],
  },
  {
    path: "/provider-consumptions",
    element: <ProviderConsumptionReportList />,
    allowRoles: ["Provider"],
  },
  {
    path: "/provider-stock-out",
    element: <ProviderStockOut />,
    allowRoles: ["Provider"],
  },
  {
    path: "/provider-transactions",
    element: <ProviderTransactionList />,
    allowRoles: ["Provider"],
  },
  {
    path: "/provider-transactions-by-project",
    element: <ProviderTransactionByProjectList />,
    allowRoles: ["Provider"],
  },
  {
    path: "/provider-receipt-reports",
    element: <ProviderReceiptReportList />,
    allowRoles: ["Provider"],
  },
  {
    path: "/provider-distribution-reports",
    element: <ProviderDistributionReportList />,
    allowRoles: ["Provider"],
  },
  {
    path: "/provider-ip-distribution-reports",
    element: <ProviderProvinceDistributionReportList />,
    allowRoles: ["Provider"],
  },
  {
    path: "/provider-inventory-reports",
    element: <ProviderInventoryList />,
    allowRoles: ["Provider"],
  },
  {
    path: "/provider-consumptions-reports",
    element: <ProviderConsumptionReportList />,
    allowRoles: ["Provider"],
  },
  {
    path: "/provider-consumptions-reports-second",
    element: <ProviderConsumptionReportList2 />,
    allowRoles: ["Provider"],
  },
  {
    path: "/provider-quantification-report",
    element: <ProviderQuantificationReport />,
    allowRoles: ["Provider"],
  },
  {
    path: "/provider-quantification-based-organization-report",
    element: <ProviderQuantificationOrganizationReport />,
    allowRoles: ["Provider"],
  },
  {
    path: "/dashboard/province-chart-receipts",
    element: <ProvinceChartInItems />,
    allowRoles: ["Province"],
  },
  {
    path: "/dashboard/province-chart-distributions",
    element: <ProvinceChartOutItems />,
    allowRoles: ["Province"],
  },
  {
    path: "/province-receipts",
    element: <ProvinceInList />,
    allowRoles: ["Province"],
    allowPermission: "show-province-in",
  },
  {
    path: "/province-distributions",
    element: <ProvinceOutList />,
    allowRoles: ["Province"],
    allowPermission: "show-province-out",
  },
  {
    path: "/add-province-distributions",
    element: <AddProvinceOut />,
    allowRoles: ["Province"],
    allowPermission: "create-province-out",
  },
  {
    path: "/edit-province-distributions/:id",
    element: <EditProvinceOut />,
    allowRoles: ["Province"],
    allowPermission: "update-province-out",
  },
  {
    path: "/province-expire-receipts",
    element: <ProvinceExpireReceiptList />,
    allowRoles: ["Province"],
  },
  {
    path: "/province-consumptions",
    element: <ProvinceConsumptionList />,
    allowRoles: ["Province"],
  },
  {
    path: "/province-transactions",
    element: <ProvinceTransactionList />,
    allowRoles: ["Province"],
  },
  {
    path: "/province-consumptions-reports",
    element: <ProvinceConsumptionReportList />,
    allowRoles: ["Province"],
  },
  {
    path: "/provider-province-action-request",
    element: <ProvinceDistributionActionRequestList />,
    allowRoles: ["Provider"],
  },

  {
    path: "/dashboard/monitor-item-ins",
    element: <MonitorChartInItems />,
    allowRoles: ["Monitor"],
  },
  {
    path: "/dashboard/monitor-item-outs",
    element: <MonitorChartOutItems />,
    allowRoles: ["Monitor"],
  },
  {
    path: "/dashboard/monitor-ips-item-ins",
    element: <MonitorIPsChartInItems />,
    allowRoles: ["Monitor"],
  },
  {
    path: "/dashboard/monitor-ips-item-outs",
    element: <MonitorIPsChartOutItems />,
    allowRoles: ["Monitor"],
  },
  {
    path: "/monitor-receipts",
    element: <MonitorReceipts />,
    allowRoles: ["Monitor"],
  },
  {
    path: "/monitor-distributions",
    element: <MonitorDistributions />,
    allowRoles: ["Monitor"],
  },
  {
    path: "/monitor-exipre-receipts",
    element: <MonitorExpireReceiptList />,
    allowRoles: ["Monitor"],
  },
  {
    path: "/monitor-exipre-receipts-based-12",
    element: <MonitorExpireReceiptListBased12Month />,
    allowRoles: ["Monitor"],
  },
  {
    path: "/monitor-stock-out",
    element: <MonitorStockOut />,
    allowRoles: ["Monitor"],
  },
  // {
  //   path: "/monitor-consumptions",
  //   element: <MonitorConsumptionReportList />,
  //   allowRoles: ["Monitor"],
  // },

  {
    path: "/monitor-transactions",
    element: <MonitorTransactionList />,
    allowRoles: ["Monitor"],
  },
  {
    path: "/monitor-receipt-reports",
    element: <MonitorReceiptReportList />,
    allowRoles: ["Monitor"],
  },
  {
    path: "/monitor-distribution-reports",
    element: <MonitorDistributionReportList />,
    allowRoles: ["Monitor"],
  },
  {
    path: "/monitor-ip-distribution-reports",
    element: <MonitorProvinceDistributionReportList />,
    allowRoles: ["Monitor"],
  },
  {
    path: "/monitor-inventory-reports",
    element: <MonitorInventoryList />,
    allowRoles: ["Monitor"],
  },
  {
    path: "/monitor-consumptions-reports",
    element: <MonitorConsumptionReportList />,
    allowRoles: ["Monitor"],
  },
  {
    path: "/monitor-consumptions-reports-two",
    element: <MonitorConsumptionReportList2 />,
    allowRoles: ["Monitor"],
  },
  {
    path: "/monitor-receipt-health-reports",
    element: <MonitorReceiptHealthReportList />,
    allowRoles: ["Monitor"],
  },
  {
    path: "/monitor-quantification-report",
    element: <MonitorQuantificationReport />,
    allowRoles: ["Monitor"],
  },
  {
    path: "/monitor-quantification-report-based-organization",
    element: <MonitorQuantificationOrganizationReport />,
    allowRoles: ["Monitor"],
  },
  {
    path: "/activity-logs",
    element: <ActivityLogsList />,
    allowRoles: ["Admin"],
  },
  {
    path: "/provider-receipt-action-requests",
    element: <ProviderReceiptActionRequestList />,
    allowRoles: ["Admin"],
  },
  {
    path: "/provider-distribution-action-requests",
    element: <ProviderDistributionActionRequestList />,
    allowRoles: ["Admin"],
  },
  {
    path: "/provider-receipt-health-reports",
    element: <ProviderReceiptHealthReportList />,
    allowRoles: ["Provider"],
  },
  {
    path: "/logout",
    element: <Logout />,
    allowRoles: [],
  },
  {
    path: "/unauthorized",
    element: <UnAuthorized />,
    allowRoles: [],
  },
]
