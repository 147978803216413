import React from "react"
import { Button, Col, Row } from "reactstrap"
import GeneralTables from "../GeneralTable"

const SubmitedBoxComponent2 = ({ submited, RemoveHandler, columns }) => {
  return (
    <React.Fragment>
      <Row style={{ marginTop: 20, justifyContent: "center" }}>
        <GeneralTables
          data={submited}
          parentCheckbox={false}
          headers={columns}
          renderItem={(item, index) => {
            return (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{item?.item1}</td>
                <td>{item?.item2}</td>
                <td>{item?.item3}</td>
                <td>{item?.item4}</td>
                <td>{item?.item5}</td>
                <td>
                  <Button
                    className="btn btn-danger btn-sm"
                    style={{
                      textAlign: "left !important",
                      position: "absolute",
                      zIndex: 1000,
                    }}
                    onClick={RemoveHandler.bind(this, index)}
                  >
                    x
                  </Button>
                </td>
              </tr>
            )
          }}
        />
      </Row>
    </React.Fragment>
  )
}

export default SubmitedBoxComponent2
