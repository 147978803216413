import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: [],
  error: null,
  loading: false,
  ip: null,
  NonIps: { error: null, loading: false, data: [] },
}

const monitorDistributionSlice = createSlice({
  name: "monitorDistribution",
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload
    },
    setError(state, action) {
      state.error = action.payload
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    setIP(state, action) {
      state.ip = action.payload
    },
    setNoneIps(state, action) {
      state.NonIps = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    resetState(state) {
      state.data = []
      state.error = null
      state.loading = false
      state.ip = null
    },
    resetNoneIps(state) {
      state.NonIps = { error: null, loading: false, data: [] }
    },
  },
})

export const monitorDistributionAction = monitorDistributionSlice.actions

export default monitorDistributionSlice
