import axiosInstance from "api/axiosInstance"
import { provinceDashboardAction } from "./province-dashboard-slice"
import exportAxiosInstance from "api/exportAxiosInstance"
import FileSaver from "file-saver"

export const getProvinceStock = params => dispatch => {
  dispatch(
    provinceDashboardAction.setProvinceStock({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-province-stock`, { params: params ? params : {} })
    .then(respnose => {
      dispatch(
        provinceDashboardAction.setProvinceStock({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        provinceDashboardAction.setProvinceStock({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getProvinceIps = () => dispatch => {
  dispatch(
    provinceDashboardAction.setProvinceIps({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-province-ips`)
    .then(respnose => {
      dispatch(
        provinceDashboardAction.setProvinceIps({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        provinceDashboardAction.setProvinceIps({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getProvinceItemCategories = () => dispatch => {
  dispatch(
    provinceDashboardAction.setProvinceItemCategories({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-province-itemCategories`)
    .then(respnose => {
      dispatch(
        provinceDashboardAction.setProvinceItemCategories({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        provinceDashboardAction.setProvinceItemCategories({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getProvinceItems = id => dispatch => {
  dispatch(
    provinceDashboardAction.setProvinceItems({
      loading: true,
      error: null,
      data: [],
    })
  )
  axiosInstance
    .get(`/v1/get-province-items/${id}`)
    .then(respnose => {
      dispatch(
        provinceDashboardAction.setProvinceItems({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        provinceDashboardAction.setProvinceItems({
          loading: false,
          error: error?.response,
          data: [],
        })
      )
      console.log(error)
    })
}

export const getProvinceInItems = params => dispatch => {
  dispatch(
    provinceDashboardAction.setProvinceInItems({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-province-stock-ins`, { params: params ? params : {} })
    .then(respnose => {
      dispatch(
        provinceDashboardAction.setProvinceInItems({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        provinceDashboardAction.setProvinceInItems({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getProvinceOutItems = params => dispatch => {
  dispatch(
    provinceDashboardAction.setProvinceOutItems({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-province-stock-outs`, { params: params ? params : {} })
    .then(respnose => {
      dispatch(
        provinceDashboardAction.setProvinceOutItems({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        provinceDashboardAction.setProvinceOutItems({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getProvinceWarehouses = () => dispatch => {
  dispatch(
    provinceDashboardAction.setProvinceWarehouses({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-province-warehouses`)
    .then(respnose => {
      dispatch(
        provinceDashboardAction.setProvinceWarehouses({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        provinceDashboardAction.setProvinceWarehouses({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getProvinceProvinces = () => dispatch => {
  dispatch(
    provinceDashboardAction.setProvinceProvinces({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-province-provinces`)
    .then(respnose => {
      dispatch(
        provinceDashboardAction.setProvinceProvinces({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        provinceDashboardAction.setProvinceProvinces({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getProvinceProvider = () => dispatch => {
  dispatch(
    provinceDashboardAction.setProvinceProvider({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-province-providers`)
    .then(respnose => {
      dispatch(
        provinceDashboardAction.setProvinceProvider({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        provinceDashboardAction.setProvinceProvider({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getProvinceFacilities = id => dispatch => {
  dispatch(
    provinceDashboardAction.setProvinceFacilities({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-province-facilities/${id}`)
    .then(respnose => {
      dispatch(
        provinceDashboardAction.setProvinceFacilities({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        provinceDashboardAction.setProvinceFacilities({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getProvinceProjects = () => dispatch => {
  dispatch(
    provinceDashboardAction.setProvinceProjects({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-province-projects`)
    .then(respnose => {
      dispatch(
        provinceDashboardAction.setProvinceProjects({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        provinceDashboardAction.setProvinceProjects({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const exportProvinceStock =
  (params = {}) =>
  dispatch => {
    dispatch(
      provinceDashboardAction.setExportStatus({
        loading: true,
        error: null,
      })
    )

    exportAxiosInstance
      .get("/v1/export-province-stock", {
        params: params,
      })
      .then(response => {
        if (response) {
          const dataBlob = new Blob([response?.data], {
            type: "application/pdf,",
          })
          FileSaver.saveAs(
            dataBlob,
            `Province-In-${Math.random().toString()}.xlsx`
          )
          dispatch(
            provinceDashboardAction.setExportStatus({
              loading: false,
              error: null,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          provinceDashboardAction.setExportStatus({
            loading: false,
            error: error?.response?.errors,
          })
        )
        console.log(error)
      })
  }
