import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import SAlert from "components/Alert"
import { getProviderOuts } from "store/provider-out/provider-out-action"
import useSearch from "hooks/useSearch"
import ProviderOutDetailModal from "components/ProviderOut/details"
import ProviderDistributionActionRequestListComponent from "components/ProviderActionRequests/Distributions/list"
import {
  getProviderDistributionActionRequests,
  sendProviderDistributionActionRequests,
} from "store/provider-action-requests/provider-action-request-action"
import {
  getIps,
  getItemCategories,
  getProjects,
  getProvinces,
} from "store/admin/admin-dashboard-action"

const ProviderDistributionActionRequestList = () => {
  document.title = "Distributions"

  const dispatch = useDispatch()
  const { data, error, loading } = useSelector(
    state => state.provActionRequests
  )
  const [deleteModal, setDeleteModal] = useState(false)
  const [proOut, setProOut] = useState(null)
  const [search, setSearch] = useState(null)
  const [pageNumber, setPageNumber] = useState(10)
  const [according, setAccording] = useState({ order: "desc", name: "id" })
  const [advnacedSearch, setAdvancedSearch] = useState({})
  const [detailModal, setDetailModal] = useState(false)

  const onAllDeleteClicked = ids => {
    if (ids.length > 0) {
      dispatch(sendProviderDistributionActionRequests(ids, "Delete"))
    } else {
      SAlert({ text: "no receipt selected", status: "info" })
    }
  }
  const onCancelDeleteClicked = ids => {
    if (ids.length > 0) {
      dispatch(sendProviderDistributionActionRequests(ids, "Cancel"))
    } else {
      SAlert({ text: "no receipt selected", status: "info" })
    }
  }

  const onPageClick = link => {
    dispatch(
      getProviderDistributionActionRequests(
        link.url,
        pageNumber,
        according,
        search,
        advnacedSearch
      )
    )
  }

  const onSearchHandler = value => {
    setSearch(value)
  }

  const onChangeRowNumber = value => {
    setPageNumber(value)
  }

  const onChangeOrdering = value => {
    if (value.order) {
      setAccording(prev => {
        return { ...prev, order: value.order }
      })
    }
    if (value.name) {
      setAccording(prev => {
        return { ...prev, name: value.name }
      })
    }
  }

  useSearch(
    getProviderDistributionActionRequests(
      undefined,
      undefined,
      undefined,
      search,
      advnacedSearch
    ),
    search
  )

  useEffect(() => {
    dispatch(
      getProviderDistributionActionRequests(
        undefined,
        pageNumber,
        according,
        search,
        advnacedSearch
      )
    )
  }, [pageNumber, according])

  useEffect(() => {
    dispatch(getItemCategories())
    dispatch(getProvinces())
    dispatch(getIps())
    dispatch(getProjects())
  }, [])

  const onAdvancedSearchSubmit = data => {
    setAdvancedSearch(data)
    dispatch(getProviderOuts(undefined, undefined, undefined, search, data))
  }

  const onClickDetail = data => {
    setProOut(data)
    setDetailModal(true)
  }

  return (
    <React.Fragment>
      <ProviderOutDetailModal
        open={detailModal}
        setOpen={setDetailModal}
        data={proOut}
      />
      <Breadcrumbs
        title={"Admin"}
        breadcrumbItem={"Distributions  Delete Requets"}
      />
      <ProviderDistributionActionRequestListComponent
        data={data}
        error={error}
        loading={loading}
        onPageClick={onPageClick}
        onSearch={onSearchHandler}
        onChangeRowNumber={onChangeRowNumber}
        onChangeOrdering={onChangeOrdering}
        onAllDeleteClicked={onAllDeleteClicked}
        onCancelDeleteClicked={onCancelDeleteClicked}
        onAdvancedSearchSubmit={onAdvancedSearchSubmit}
        onClickDetail={onClickDetail}
      />
    </React.Fragment>
  )
}

export default ProviderDistributionActionRequestList
