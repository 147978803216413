import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Outlet, Navigate, useLocation } from "react-router-dom"

const AuthroizedRoute = ({ children, allowRoles, allowPermission }) => {
  const { role, permissions } = useSelector(state => state.auth)
  const location = useLocation()
  return (
    <React.Fragment>
      {allowRoles.includes(role) ? (
        <React.Fragment>
          {allowPermission ? (
            <React.Fragment>
              {permissions.includes(allowPermission) ? (
                children
              ) : (
                <Navigate
                  to={"/unauthorized"}
                  state={{ from: location }}
                  replace
                />
              )}
            </React.Fragment>
          ) : (
            children
          )}
        </React.Fragment>
      ) : (
        <Navigate to={"/unauthorized"} state={{ from: location }} replace />
      )}
    </React.Fragment>
  )
}

export default AuthroizedRoute
