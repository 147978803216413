import TableContainer from "components/Common/TableContainer"
import React, { useMemo, useRef, useState } from "react"
import { Button, Input } from "reactstrap"
import { isNumber } from "lodash"
import { getQuantityFromSubmited } from "utils/providerOutUtils"
import { checkForMinusNumber, numberIsSame } from "utils/utils"

const ReceiptsList = ({ data, onSubmitAddHandler, submited }) => {
  const [outQuantity, setOutQuantity] = useState([])
  const inputRef = useRef()

  const handleChangeQuantity = (e, receipt) => {
    if (checkForMinusNumber(e)) {
      e.target.value = 0
      return false
    }
    // if (e.target.value < 0 && !isNumber(e.target.value)) {
    //   return false
    // }
    inputRef.current = receipt.unique_id
    const newItem = { id: receipt.unique_id, quantity: e.target.value }
    setOutQuantity(prev =>
      prev.some(p => p.id === receipt.unique_id)
        ? prev.map(qty =>
            qty.id === newItem.id ? { ...qty, quantity: e.target.value } : qty
          )
        : [...prev, newItem]
    )
  }

  function showTotal(quantity, id) {
    const value = outQuantity.find(qty => qty.id === id)
    if (value) {
      return (
        parseInt(quantity) -
        getQuantityFromSubmited(submited, id) -
        parseInt(Math.abs(value.quantity))
      )
    } else {
      return quantity - getQuantityFromSubmited(submited, id)
    }
  }

  const onAddHandler = (outQuantityValue, receipt) => {
    const quantity = parseInt(outQuantityValue.quantity)
    const unfpa_quantity_receipt = parseInt(
      receipt?.unit_uom_quantity_per_unfpa
    )
    let unfpa_quantity
    const ip_quantity = parseInt(receipt?.unit_umo_quantity_per_ip)
    if (!numberIsSame(unfpa_quantity_receipt, ip_quantity)) {
      unfpa_quantity = quantity / unfpa_quantity_receipt
    } else {
      unfpa_quantity = quantity
    }
    const total_value =
      unfpa_quantity.toFixed(2) * receipt?.unit_price_per_unfpa

    const result = {
      unfpa_quantity: unfpa_quantity.toFixed(2),
      total_value: total_value.toFixed(2),
      out_quantity: quantity,
      provider_in_id: outQuantityValue.id,
      batch_number: receipt?.batch_number,
      project_id: receipt?.project_id,
    }

    onSubmitAddHandler(result)
    onResetReceiptList()
  }

  const onResetReceiptList = () => {
    setOutQuantity([])
    inputRef.current.value = ""
  }

  function checkForAdd(receipt) {
    const outQuantityValue = outQuantity.find(
      qty => qty.id === receipt?.unique_id
    )

    if (outQuantityValue) {
      const total = showTotal(receipt?.in_qty_per_ip, receipt?.unique_id)
      if (total >= 0 && total !== NaN && total <= receipt?.in_qty_per_ip) {
        if (
          total !== receipt?.in_qty_per_ip &&
          getQuantityFromSubmited(submited, receipt?.unique_id) !==
            receipt?.in_qty_per_ip
        ) {
          return (
            <Button
              className="btn btn-sm btn-info"
              onClick={() => onAddHandler(outQuantityValue, receipt)}
              type="button"
            >
              Add
            </Button>
          )
        } else {
          return false
        }
      } else {
        return <span className="text-danger">Out of Quantity</span>
      }
    } else {
      return
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "UNFPA ITEM ID",
        accessor: "item.unfp_item_name",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return cellProps?.value
        },
      },
      {
        Header: "IP- ITEM DESCRIPTION",
        accessor: "item.ip_item_description",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return cellProps?.value
        },
      },
      {
        Header: "UNFPA UOM",
        accessor: "item",
        id: "unpfa_item",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return `${cellProps?.value?.uom} ${
            cellProps?.value?.unfpa_uom_quantity > 1 &&
            cellProps?.value?.unfpa_uom_quantity
          }`
        },
      },
      {
        Header: "IP UOM",
        accessor: "item",
        id: "ip_item",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return `${cellProps?.value?.ip_unit_umo} ${
            cellProps?.value?.ip_uom_quantity > 1 &&
            cellProps?.value?.ip_uom_quantity
          }`
        },
      },
      {
        Header: "BATCH NUMBER",
        accessor: "batch_number",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return cellProps?.value
        },
      },
      {
        Header: "EXPIRE DATE",
        accessor: "expire_date",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return cellProps?.value
        },
      },
      {
        Header: "Quantity",
        accessor: "in_qty_per_ip",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return cellProps?.value
        },
      },
      {
        Header: "Out",
        filterable: false,
        disableFilters: true,
        Cell: ({ row }) => {
          return (
            <Input
              key={row?.original?.unique_id}
              type="number"
              name={`quantity-${row?.original.unique_id}`}
              onWheel={e => e.target.blur()}
              ref={inputRef}
              min={0}
              value={
                outQuantity?.find(qty => qty.id === row?.original?.unique_id)
                  ?.quantity
              }
              onChange={e => handleChangeQuantity(e, row?.original)}
              autoFocus={row?.original?.unique_id === inputRef.current}
            />
          )
        },
      },
      {
        Header: "Balance",
        filterable: false,
        disableFilters: true,
        Cell: ({ row }) => {
          return showTotal(
            row?.original?.in_qty_per_ip,
            row?.original?.unique_id
          )
        },
      },

      {
        Header: "",
        id: "addDistribute",
        filterable: false,
        disableFilters: true,
        Cell: ({ row }) => {
          return checkForAdd(row?.original)
        },
      },
    ],
    [handleChangeQuantity, checkForAdd]
  )

  return (
    <React.Fragment>
      <div className="mb-4 h4 card-title">Items</div>
      <TableContainer
        columns={columns}
        data={data}
        isGlobalFilter={false}
        isAddOptions={false}
        customPageSize={6}
      />
    </React.Fragment>
  )
}

export default ReceiptsList
