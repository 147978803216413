import { isObject } from "lodash"

export const getSelectValueWithDefault = (validationValue, array) => {
  if (isObject(validationValue)) {
    return validationValue.value
  } else {
    return array[0].value
  }
}

export const getQuantityFromSubmited = (submitedArray, id) => {
  if (submitedArray.length > 0) {
    const check = submitedArray.some(subvalue => subvalue.provider_in_id === id)
    if (check) {
      const total = submitedArray.reduce(
        (acc, { provider_in_id, out_quantity }) => ({
          ...acc,
          [provider_in_id]: {
            qty: acc[provider_in_id]
              ? acc[provider_in_id].qty + out_quantity
              : out_quantity,
          },
        }),
        {}
      )
      return total[id].qty
    } else {
      return 0
    }
  } else {
    return 0
  }
}
