import PropTypes from "prop-types"
import React, { useEffect } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { useDispatch, useSelector } from "react-redux"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// import images
import logo from "assets/images/logo.svg"
import logoLight from "../../assets/images/logos/log_bg_less.png"
import { forgotPassword, login } from "store/auth/auth-actions"
import Preloader from "components/Common/Loader"
import { getAuthToken } from "utils/authUtil"
import PasswordInput from "components/Common/PasswordInput"
import { authAction } from "store/auth/auth-slice"

const ForgotPage = props => {
  //meta title
  document.title = "eLMIS Forgot Password"
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const { error, data, loading } = useSelector(
    state => state.auth.forgotPassword
  )

  const token = getAuthToken()

  if (token) {
    navigate(-1)
  }

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Please Enter Your Email")
        .email("Input it shoud be email"),
    }),
    onSubmit: values => {
      dispatch(forgotPassword(values))
    },
  })

  useEffect(() => {
    return () => {
      dispatch(authAction.resetForgotPassword())
    }
  }, [])

  return (
    <React.Fragment>
      {!token ? (
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col xs={7}>
                        <div className="text-primary p-4">
                          <h5 className="text-primary">eLMIS !</h5>
                          <p>Forgot Password.</p>
                        </div>
                      </Col>
                      <Col
                        className="col-5 align-self-center"
                        style={{ textAlign: "right" }}
                      >
                        <img
                          src={logoLight}
                          alt=""
                          style={{ width: 100, marginRight: 10 }}
                          className="img-fluid"
                        />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/" className="logo-light-element">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      <Form
                        className="form-horizontal"
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >
                        {data ? <Alert color="success">{data}</Alert> : null}

                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                          {error ? (
                            <p className="text-danger p-2">{error}</p>
                          ) : null}
                        </div>

                        <Row>
                          <Col>
                            <Link to={"/login"}>Return to login </Link>
                          </Col>
                        </Row>

                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                            disabled={loading}
                          >
                            {loading ? "Please wait..." : "Log In"}
                          </button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    © {new Date().getFullYear()} eLMIS by{" "}
                    <a href="https://www.aryanict.com/">Aryan ICT Solutions</a>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <Preloader loading={true} />
      )}
    </React.Fragment>
  )
}

export default withRouter(ForgotPage)

ForgotPage.propTypes = {
  history: PropTypes.object,
}
