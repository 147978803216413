import React from "react"
import ProvinceStockComponents from "./ProvinceStock"

const ProvinceDashboardComponent = ({
  stocks,
  onAdvanceSearch,
  onCategoryChange,
  categories,
  onExportClicked,
  onAdvancedSearchHandle,
  allData,
}) => {
  return (
    <React.Fragment>
      {categories?.data && (
        <ProvinceStockComponents
          stocks={stocks}
          allData={allData}
          onCategoryChange={onCategoryChange}
          onAdvanceSearch={onAdvanceSearch}
          categories={categories}
          onExportClicked={onExportClicked}
          onAdvancedSearchHandle={onAdvancedSearchHandle}
        />
      )}
    </React.Fragment>
  )
}

export default ProvinceDashboardComponent
