import React from "react"
import { Card, CardBody } from "reactstrap"
import { useNavigate } from "react-router-dom"
import CustomHeaderTitle from "components/Common/CustomHeaderTitle"
import GeneralTables from "components/Common/GeneralTable"
import ActionColumns from "components/Common/GeneralTable/ActionColumns"

const RoleListComponent = ({ data, error, loading }) => {
  const navigate = useNavigate()

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CustomHeaderTitle
            loading={loading}
            title={"List"}
            rowCount={false}
            orderingList={false}
            searchBox={false}
            rightBtn={false}
            orderingOptions={[{ value: "id", name: "ID" }]}
          />

          <GeneralTables
            data={data}
            loading={loading}
            headers={["#", "Role", "Action"]}
            renderItem={(role, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{role.name}</td>
                <td>
                  <ActionColumns
                    data={role}
                    enableEdit={true}
                    enableDelete={false}
                    editRoute={`/edit-role/${role.id}`}
                  />
                </td>
              </tr>
            )}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default RoleListComponent
