import FacilityForm from "components/Facility/form"
import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { storeFacility } from "store/facility/facility-action"
import { getAllProvinces } from "store/general-renders/general-action"
import { generalAction } from "store/general-renders/general-slice"

const AddFacility = () => {
  document.title = "Add Facility"

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { loading, error, data } = useSelector(state => state.facility)
  const { provinces, provinceLoading, provinceError } = useSelector(
    state => state.generalRenders
  )

  const handleSubmit = values => {
    dispatch(storeFacility(values, navigate))
  }

  useEffect(() => {
    dispatch(getAllProvinces())
  }, [])

  return (
    <FacilityForm
      onSubmit={handleSubmit}
      loading={loading}
      error={error}
      formMode={"Add"}
      provinces={provinces}
      provinceLoading={provinceLoading}
      provinceError={provinceError}
    />
  )
}

export default AddFacility
