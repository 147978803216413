import React, { useEffect, useState } from "react"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import {
  getProviderIps,
  getProviderItemCategories,
  getProviderProjects,
  getProviderProvinces,
} from "store/provider-dashboard/provider-dashboard-action"
import {
  exportProviderQuantificationReport,
  exportProviderQuantificationReportBasedOrganization,
  getProviderQuantificationReportBasedOrganization,
} from "store/provider-reports/p-report-action"
import ProviderQuantificationOrganizationReportListComponent from "components/ProviderReports/QuantificationBasedOrganization"
import { getProviderConsumptionsProjects } from "store/provider-consumption/province-consumption-action"

const ProviderQuantificationOrganizationReport = () => {
  //meta title
  document.title = "Quantification Based Organization Report"

  const [params, setParams] = useState({})
  const { data, error, loading, exportStatus } = useSelector(state => ({
    data: state.providerReports.providerQuantificationReport.data,
    error: state.providerReports.providerQuantificationReport.error,
    loading: state.providerReports.providerQuantificationReport.loading,
    exportStatus: state.providerReports.providerQuantificationExport,
  }))

  const dispatch = useDispatch()

  const onPageClick = link => {
    dispatch(getProviderQuantificationReportBasedOrganization(link.url, params))
  }

  const onChangeRowNumber = value => {
    setParams(prev => {
      return { ...prev, pageNumber: value }
    })
    dispatch(
      getProviderQuantificationReportBasedOrganization(undefined, {
        ...params,
        pageNumber: value,
      })
    )
  }

  const onExport = () => {
    dispatch(exportProviderQuantificationReportBasedOrganization(params))
  }

  useEffect(() => {
    dispatch(getProviderQuantificationReportBasedOrganization())
    dispatch(getProviderConsumptionsProjects())
    dispatch(getProviderIps())
    dispatch(getProviderProvinces())
  }, [])

  const onSearchHandler = filter => {
    setParams(filter)
    dispatch(
      getProviderQuantificationReportBasedOrganization(undefined, filter)
    )
  }

  return (
    <React.Fragment>
      <Breadcrumbs
        title={"Provider"}
        breadcrumbItem={"Quantification Based Organization Report"}
      />
      <ProviderQuantificationOrganizationReportListComponent
        data={data}
        error={error}
        loading={loading}
        onSearchHandler={onSearchHandler}
        onExportBtnClick={onExport}
        exportStatus={exportStatus}
        onPageClick={onPageClick}
        onChangeRowNumber={onChangeRowNumber}
      />
    </React.Fragment>
  )
}

export default ProviderQuantificationOrganizationReport
