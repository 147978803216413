import SubmitedBoxComponent from "components/Common/SubmitedBox"
import SubmitedBoxComponent2 from "components/Common/SubmitedBox/index2"
import React from "react"
import { Button, Col, Row } from "reactstrap"

const SubmitedList = ({ submited, setSubmited }) => {
  const RemoveItemHandler = itemKey => {
    setSubmited(prev => {
      const updateItems = prev.filter((ite, key) => key !== itemKey)
      return [...updateItems]
    })
  }

  return (
    <SubmitedBoxComponent2
      submited={submited}
      columns={[
        "SN",
        "PROVINCE",
        "FACILITY",
        "ITEM",
        "BATCH NUMBER",
        "QTY",
        "Action",
      ]}
      RemoveHandler={RemoveItemHandler}
    />
  )
}

export default SubmitedList
