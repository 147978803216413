import axiosInstance from "api/axiosInstance"
import { roleAction } from "./role-slice"
import SAlert from "components/Alert"

export const getRoles = () => dispatch => {
  dispatch(roleAction.setLoading(true))
  axiosInstance
    .get("/v1/roles")
    .then(response => {
      if (response) {
        dispatch(roleAction.setError(null))
        dispatch(roleAction.setData(response?.data))
        dispatch(roleAction.setLoading(false))
      }
    })
    .catch(error => {
      dispatch(roleAction.setLoading(false))
      console.log(error)
    })
}

export const getByIdRole = id => dispatch => {
  dispatch(roleAction.setLoading(true))
  axiosInstance
    .get(`/v1/role/${id}`)
    .then(response => {
      if (response) {
        dispatch(roleAction.setError(null))
        dispatch(roleAction.setRole(response?.data))
        dispatch(roleAction.setLoading(false))
      }
    })
    .catch(error => {
      dispatch(roleAction.setLoading(false))
      console.log(error)
    })
}

export const updateRolePermission = (id, permission, status) => dispatch => {
  dispatch(
    roleAction.setPermissionChange({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .post(`/v1/role/${id}/update-permission`, { permission, status })
    .then(respnose => {
      SAlert({
        title: "Success",
        text: "Role Permission successfully updated",
      })
      dispatch(
        roleAction.setPermissionChange({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        roleAction.setPermissionChange({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}
