import React from "react"

const Preloader = ({ loading }) => {
  return (
    <React.Fragment>
      {loading && (
        <div id="preloader block" style={{ marginTop: 300 }}>
          <div id="status block">
            <div className="spinner-chase">
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default Preloader
