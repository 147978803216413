import Login from "pages/Authentication/Login"
import ForgotPage from "pages/Authentication/Forgot"
import ResetPasswordPage from "pages/Authentication/resetPassword"
import Logout from "pages/Authentication/Logout"
import Dashboard from "pages/Dashboard"
import RootLayout from "pages/Layout/rootLayout"
import UnAuthorized from "pages/errors/UnAuthorized"
import React, { useMemo } from "react"
import { RouterProvider, createBrowserRouter } from "react-router-dom"
import AuthroizedRoute from "./AuthroizedRoute"
import WarehouseList from "pages/Admin/Warehouse"
import AddWarehouse from "pages/Admin/Warehouse/add"
import { childrenRoutes } from "./childrenRoutes"

const RoutePage = () => {
  const childRoute = useMemo(
    () =>
      childrenRoutes.map(route => ({
        path: route.path,
        element:
          route.allowRoles.length > 0 || route.allowPermission ? (
            <AuthroizedRoute
              allowRoles={route.allowRoles}
              allowPermission={route.allowPermission}
            >
              {route.element}
            </AuthroizedRoute>
          ) : (
            route.element
          ),
      })),
    [childrenRoutes]
  )

  const router = createBrowserRouter([
    {
      path: "/",
      element: <RootLayout />,
      id: "root",
      children: childRoute,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/forgot-password",
      element: <ForgotPage />,
    },
    {
      path: "/reset-password/:token",
      element: <ResetPasswordPage />,
    },
  ])

  return <RouterProvider router={router} />
}

export default RoutePage
