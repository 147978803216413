import React, { useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import IPForm from "components/IP/form"
import { getByIdIP, updateIP } from "store/ips/ips-action"
import { ipsAction } from "store/ips/ips-slice"
import Preloader from "components/Common/Loader"

const EditIP = () => {
  document.title = "Edit IP"

  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { loading, ip, error } = useSelector(state => state.ips)

  const onSubmit = values => {
    values.id = id
    dispatch(updateIP(id, values, navigate))
  }

  useEffect(() => {
    dispatch(getByIdIP(id))

    return () => {
      dispatch(ipsAction.resetState())
    }
  }, [])

  return (
    <React.Fragment>
      {ip ? (
        <IPForm
          loading={loading}
          ip={ip?.data}
          error={error}
          onSubmit={onSubmit}
          formMode={"Edit"}
        />
      ) : (
        <Preloader loading={true} />
      )}
    </React.Fragment>
  )
}

export default EditIP
