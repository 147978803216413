import React, { useState } from "react"
import { Card, CardBody, Collapse, Table } from "reactstrap"
import { useNavigate } from "react-router-dom"
import CustomHeaderTitle from "components/Common/CustomHeaderTitle"
import GeneralTables from "components/Common/GeneralTable"
import ProviderQuantificationOrganizationFilter from "./filter"
import MonitorQuantificationOrganizationFilter from "./filter"

const MonitorQuantificationOrganizationReportListComponent = ({
  data,
  error,
  loading,
  onExportBtnClick,
  onSearchHandler,
  exportStatus,
  onPageClick,
  onChangeRowNumber,
}) => {
  const navigate = useNavigate()
  const [filterOpen, setFilterOpen] = useState(false)
  const [filter, setFilter] = useState({})

  const onAdvancedSearchHandler = result => {
    setFilterOpen(result)
  }

  const onAdvancedSearchSubmit = data => {
    setFilter(data)
    onSearchHandler(data)
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CustomHeaderTitle
            loading={loading}
            title={"Quantification Based Organization Report"}
            lgColumn={8}
            exportBtn={true}
            exportBtnLoading={exportStatus?.loading}
            onExportBtnClick={onExportBtnClick}
            rowCount={true}
            // pageRowNumberOptions={[20, 100, 150]}
            onChangeRowNumber={onChangeRowNumber}
            searchBox={false}
            rightBtn={false}
            advnacedSearchBtn={true}
            advnacedSearchBtnTitle={"Filter"}
            onAdvancedSearch={onAdvancedSearchHandler}
          />
          <Collapse isOpen={filterOpen}>
            <MonitorQuantificationOrganizationFilter
              loading={loading}
              error={error}
              onAdvancedSearchSubmit={onAdvancedSearchSubmit}
            />
          </Collapse>

          <GeneralTables
            data={data?.data}
            loading={loading}
            headers={[
              "Organization Reciept the FP Commodites during the year",
              "Province",
              "No of Facilities",
              "Submission Date",
            ]}
            pagination={{ meta: data?.meta, onPageClick: onPageClick }}
            renderItem={(data, index) => (
              <tr key={index}>
                <td>
                  {data?.distributor ? data?.distributor : data?.ip?.name}
                </td>

                <td>{data?.province?.province}</td>
                <td>{data?.totalFacility}</td>
                <td>{data?.date}</td>
              </tr>
            )}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default MonitorQuantificationOrganizationReportListComponent
