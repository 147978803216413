import { useFormik } from "formik"
import * as Yup from "yup"
import React from "react"
import {
  Card,
  CardBody,
  Label,
  Button,
  Form,
  Input,
  FormFeedback,
} from "reactstrap"
import CustomHeaderTitle from "components/Common/CustomHeaderTitle"

const ItemCategoryForm = ({
  onSubmit,
  loading,
  error,
  formMode,
  itemCategory,
}) => {
  const validationType = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: itemCategory ? itemCategory?.name : "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("This name is required").min(4),
    }),
    onSubmit: values => {
      onSubmit(values)
    },
  })

  return (
    <Card>
      <CardBody>
        <CustomHeaderTitle
          loading={loading}
          title={formMode === "Edit" ? "Edit Category" : "Add Category"}
          lgColumn={3}
          rowCount={false}
          orderingList={false}
          searchBox={false}
          rightBtn={true}
          rightBtnTitle={"Back"}
          rightBtnRoute={"/item-categories"}
        />

        <Form
          onSubmit={e => {
            e.preventDefault()
            validationType.handleSubmit()
            return false
          }}
        >
          <div className="mb-3">
            <Label className="form-label">Name</Label>
            <Input
              name="name"
              placeholder="Name"
              type="text"
              onChange={validationType.handleChange}
              onBlur={validationType.handleBlur}
              defaultValue={itemCategory?.name}
              invalid={
                (validationType.touched.name && validationType.errors.name) ||
                error?.name?.[0]
                  ? true
                  : false
              }
            />
            {(validationType.touched.name && validationType.errors.name) ||
            error?.name?.[0] ? (
              <FormFeedback type="invalid">
                {validationType.errors.name || error?.name?.[0]}
              </FormFeedback>
            ) : null}
          </div>

          <div className="d-flex flex-wrap gap-2">
            <Button type="submit" color="primary" disabled={loading}>
              {loading
                ? "Please Wait..."
                : formMode === "Add"
                ? "Submit"
                : "Update"}
            </Button>
          </div>
        </Form>
      </CardBody>
    </Card>
  )
}

export default ItemCategoryForm
