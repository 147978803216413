import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import DeleteModal from "components/Common/DeleteModal"
import {
  deleteItemCategory,
  exportItemCategories,
  getItemCategories,
} from "store/item-category/item-category-action"
import ItemCategoryListComponent from "components/ItemCategory/list"

const ItemCategoryList = () => {
  document.title = "Item Categories"

  const dispatch = useDispatch()
  const { data, error, loading, exportStatus } = useSelector(
    state => state.itemCategory
  )
  const [deleteModal, setDeleteModal] = useState(false)
  const [itemCategory, setItemCategory] = useState(null)
  const [search, setSearch] = useState(null)
  const [pageNumber, setPageNumber] = useState(10)
  const [according, setAccording] = useState({ order: "desc", name: "id" })

  const handleDeleteFunc = data => {
    if (itemCategory) {
      dispatch(deleteItemCategory(itemCategory.id))
    }
    setDeleteModal(false)
  }

  const onClickDelete = data => {
    setItemCategory(data)
    setDeleteModal(true)
  }

  const onPageClick = link => {
    dispatch(getItemCategories(link.url, pageNumber, according, search))
  }

  const onSearchHandler = value => {
    setSearch(value)
  }

  const onChangeRowNumber = value => {
    setPageNumber(value)
  }

  const onChangeOrdering = value => {
    if (value.order) {
      setAccording(prev => {
        return { ...prev, order: value.order }
      })
    }
    if (value.name) {
      setAccording(prev => {
        return { ...prev, name: value.name }
      })
    }
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(getItemCategories(undefined, undefined, undefined, search))
    }, 1500)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  useEffect(() => {
    dispatch(getItemCategories(undefined, pageNumber, according, search))
  }, [pageNumber, according])

  const onExportBtnClick = () => {
    dispatch(exportItemCategories(search))
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteFunc}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Breadcrumbs title={"Admin"} breadcrumbItem={"Item Category"} />
      <ItemCategoryListComponent
        data={data}
        error={error}
        loading={loading}
        onClickDelete={onClickDelete}
        handleDeleteFunc={handleDeleteFunc}
        onPageClick={onPageClick}
        onSearch={onSearchHandler}
        onChangeRowNumber={onChangeRowNumber}
        onChangeOrdering={onChangeOrdering}
        onExportBtnClick={onExportBtnClick}
        exportStatus={exportStatus}
      />
    </React.Fragment>
  )
}

export default ItemCategoryList
