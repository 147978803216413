import React, { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { getAllItemCategories } from "store/general-renders/general-action"
import ItemForm from "components/Item/form"
import { getByIdItem, updateItem } from "store/item/item-action"
import { itemAction } from "store/item/item-slice"
import { generalAction } from "store/general-renders/general-slice"
import { ItemCategorySelector } from "store/selectors"
import Preloader from "components/Common/Loader"

const EditItem = () => {
  document.title = "Edit Item"

  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { loading, item, error } = useSelector(state => state.item)
  const { itemCategories, itemCategoryLoading, itemCategoryError } =
    useSelector(ItemCategorySelector)

  const onSubmit = values => {
    values.id = id
    dispatch(updateItem(id, values, navigate))
  }

  useEffect(() => {
    dispatch(getByIdItem(id))
    dispatch(getAllItemCategories())

    return () => {
      dispatch(itemAction.resetState())
      dispatch(generalAction.resetItemCategory())
    }
  }, [])

  return (
    <React.Fragment>
      {item && itemCategories.length > 0 ? (
        <ItemForm
          loading={loading}
          item={item?.data}
          error={error}
          onSubmit={onSubmit}
          itemCategories={itemCategories}
          itemCategoryLoading={itemCategoryLoading}
          itemCategoryError={itemCategoryError}
          formMode={"Edit"}
        />
      ) : (
        <Preloader loading={true} />
      )}
    </React.Fragment>
  )
}

export default EditItem
