import React, { useEffect, useState } from "react"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import {
  exportMonitorIpsStock,
  getMonitorIps,
  getMonitorIpsStock,
  getMonitorItemCategories,
  getMonitorItems,
  getMonitorStock,
} from "store/monitor-dashboard/monitor-dashboard-action"
import MonitorDashboardComponent from "components/MonitorDashboard/MonitorDashboardComponent"
import { exportMonitorInventoryReport } from "store/monitor-reports/monitor-report-action"
import WelcomeComp from "pages/Dashboard/WelcomeComp"

const MonitorDashboard = props => {
  //meta title
  document.title = "Dashboard"

  const [filters, setFilters] = useState(null)
  const [filtersIP, setFiltersIP] = useState(null)

  const monitorStock = useSelector(state => state.monitorDashboard.monitorStock)

  const monitorIpsStock = useSelector(
    state => state.monitorDashboard.monitorIpsStock
  )

  const onAdvancedSearchHandler = values => {
    setFilters(values)
  }
  const onIPsAdvanceSearchHandler = values => {
    setFiltersIP(values)
    dispatch(getMonitorIpsStock(values))
  }

  const onProviderStockExport = () => {
    dispatch(exportMonitorInventoryReport(filters))
  }

  const onIPStockExport = () => {
    dispatch(exportMonitorIpsStock(filtersIP))
  }

  const dispatch = useDispatch()

  const onSubmit = values => {
    dispatch(getMonitorStock(values))
  }

  const onCategoryChange = category => {
    dispatch(getMonitorItems(category))
  }

  useEffect(() => {
    dispatch(getMonitorStock())
    dispatch(getMonitorIpsStock())
    dispatch(getMonitorIps())
    dispatch(getMonitorItemCategories())
  }, [])

  return (
    <React.Fragment>
      <Breadcrumbs title={"Monitor"} breadcrumbItem={"Dashboard"} />
      <WelcomeComp />
      <MonitorDashboardComponent
        provStock={monitorStock?.data?.data?.monitorReports}
        provIpsStock={monitorIpsStock?.data?.data}
        provData={monitorStock?.data?.data}
        onSubmit={onSubmit}
        onCategoryChange={onCategoryChange}
        onAdvancedSearchHandler={onAdvancedSearchHandler}
        onProviderStockExport={onProviderStockExport}
        onIPsAdvanceSearchHandler={onIPsAdvanceSearchHandler}
        onIPStockExport={onIPStockExport}
      />
    </React.Fragment>
  )
}

export default MonitorDashboard
