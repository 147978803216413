import React, { useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Col, Form, Label, Row, Input } from "reactstrap"
import Select from "react-select"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import moment from "moment"
import { getProviderItems } from "store/provider-dashboard/provider-dashboard-action"
import { providerDashboardAction } from "store/provider-dashboard/provider-dashboard-slice"
import { isObject } from "lodash"
import { monitorDashboardAction } from "store/monitor-dashboard/monitor-dashboard-slice"
import {
  getMonitorItems,
  getMonitorProviderUsers,
} from "store/monitor-dashboard/monitor-dashboard-action"

const MonitorReceiptFilter = ({ onAdvancedSearchSubmit, loading }) => {
  const expireFlatpickerfrom = useRef(null)
  const expireFlatpickerto = useRef(null)
  const receiptFlatpickerfrom = useRef(null)
  const receiptFlatpickerto = useRef(null)
  const [category, setCategory] = useState(null)
  const [item, setItem] = useState(null)
  const [ip, setIP] = useState(null)
  const [project, setProject] = useState(null)
  const [batchNumber, setBatchNumber] = useState("")
  const [providerUser, setProviderUser] = useState(null)
  const [provider, setProvider] = useState(null)
  const [expireDate, setExpireDate] = useState({
    from: null,
    to: null,
  })
  const [receiptDate, setReceiptDate] = useState({
    from: null,
    to: null,
  })
  const items = useSelector(state => state.monitorDashboard.MonitorItems)
  const projects = useSelector(state => state.monitorDashboard.monitorProjects)
  const provider_users = useSelector(
    state => state.monitorDashboard.monitorProviderUsers
  )
  const providers = useSelector(
    state => state.monitorDashboard.monitorProviders
  )
  const ips = useSelector(state => state.monitorDashboard.MonitorIps)
  const categories = useSelector(
    state => state.monitorDashboard.MonitorItemCategoires
  )

  const filteredItems = useMemo(
    () =>
      items?.data.map(item => ({
        value: item.id,
        label: `${item.unfp_item_name} ( ${item.item_name} )`,
      })),
    [items]
  )

  const dispatch = useDispatch()

  const handleSubmit = () => {
    let data = {}
    if (item) {
      data.item = item.map(ite => (ite.value ? ite.value : ite))
    }
    data.project = isObject(project) ? project.value : null
    data.ip_id = isObject(ip) ? ip.value : null
    data.provider = isObject(provider) ? provider.value : null
    data.providerUser = isObject(providerUser) ? providerUser.value : null
    data.category = isObject(category) ? category.value : null
    data.batchNumber = batchNumber
    data.expireDate = {
      from: expireDate.from ? expireDate.from : null,
      to: expireDate.to ? expireDate.to : null,
    }
    data.receiptDate = {
      from: receiptDate.from ? receiptDate.from : null,
      to: receiptDate.to ? receiptDate.to : null,
    }
    onAdvancedSearchSubmit(data)
  }

  const onCategoryChangeHandler = cate => {
    setCategory(cate)
    dispatch(getMonitorItems(cate.value))
  }

  const onItemChangeHandler = item => {
    const check = item.some(ite => ite.value === "all")
    if (check) {
      setItem(filteredItems)
    } else {
      setItem(item)
    }
  }
  const onIPChangeHandler = value => {
    dispatch(getMonitorProviderUsers(value.value))
    setIP(value)
  }

  const onProviderUserChangeHandler = value => {
    setProviderUser(value)
  }

  const onProjectChangeHandler = value => {
    setProject(value)
  }
  const onProviderChangeHandler = value => {
    setProvider(value)
  }

  const onClearHandler = () => {
    expireFlatpickerfrom.current.flatpickr.clear()
    expireFlatpickerto.current.flatpickr.clear()
    receiptFlatpickerfrom.current.flatpickr.clear()
    receiptFlatpickerto.current.flatpickr.clear()
    dispatch(monitorDashboardAction.resetMonitorProviderUsers())
    setCategory(null)
    setItem(null)
    setIP(null)
    setProviderUser(null)
    setProject(null)
    setExpireDate({ from: null, to: null })
    setReceiptDate({ from: null, to: null })
    setProvider(null)
    setBatchNumber("")
    onAdvancedSearchSubmit({})
    dispatch(monitorDashboardAction.resetMonitorItems())
  }

  return (
    <Form
      onSubmit={e => {
        e.preventDefault()
        handleSubmit()
        return false
      }}
    >
      <div className="mb-3">
        <Row>
          <Col>
            <Label className="form-label">Provider IPs</Label>
            {ips?.data && (
              <Select
                options={ips?.data}
                value={ip}
                onChange={onIPChangeHandler}
              />
            )}
          </Col>

          <Col>
            <Label className="form-label">Provider Users</Label>
            {provider_users?.data && (
              <Select
                options={[
                  { value: "all", label: "All" },
                  ...provider_users?.data,
                ]}
                value={providerUser}
                onChange={onProviderUserChangeHandler}
              />
            )}
          </Col>
        </Row>
      </div>

      <div className="mb-3">
        <Row>
          <Col>
            <Label className="form-label">Projects</Label>
            {projects?.data && (
              <Select
                options={projects?.data}
                value={project}
                onChange={onProjectChangeHandler}
              />
            )}
          </Col>
        </Row>
      </div>

      <div className="mb-3">
        <Row>
          <Col>
            <Label className="form-label">Providers</Label>
            {providers?.data && (
              <Select
                options={[{ value: "all", label: "All" }, ...providers?.data]}
                value={provider}
                onChange={onProviderChangeHandler}
              />
            )}
          </Col>

          <Col>
            <Label className="form-label">Batch Number</Label>
            <Input
              name="batchNumber"
              value={batchNumber}
              onChange={value => {
                setBatchNumber(value.target.value)
              }}
              placeholder="Batch Number"
              type="text"
            />
          </Col>
        </Row>
      </div>

      <div className="mb-3">
        <Row>
          <Col>
            <Label className="form-label">Item Categories</Label>
            {categories?.data && (
              <Select
                options={categories?.data}
                value={category || categories?.data[0]}
                onChange={onCategoryChangeHandler}
              />
            )}
          </Col>
          <Col>
            <Label className="form-label">Items</Label>
            {filteredItems && (
              <Select
                isMulti={true}
                options={[{ value: "all", label: "All" }, ...filteredItems]}
                value={item}
                onChange={onItemChangeHandler}
              />
            )}
          </Col>
        </Row>
      </div>

      <div className="mb-3 ">
        <Row>
          <Col>
            <Label className="form-label">Expire From Date</Label>
            <Flatpickr
              ref={expireFlatpickerfrom}
              className="form-control d-block"
              placeholder="dd M,yyyy"
              options={{
                altInput: true,
                altFormat: "Y-m-d",
                dateFormat: "Y-m-d",
              }}
              onChange={value => {
                setExpireDate(prev => {
                  return {
                    ...prev,
                    from: moment(value[0]).format("YYYY-MM-DD"),
                  }
                })
              }}
            />
          </Col>
          <Col>
            <Label className="form-label">Expire To Date</Label>
            <Flatpickr
              ref={expireFlatpickerto}
              className="form-control d-block"
              placeholder="dd M,yyyy"
              options={{
                altInput: true,
                altFormat: "Y-m-d",
                dateFormat: "Y-m-d",
              }}
              onChange={value => {
                setExpireDate(prev => {
                  return { ...prev, to: moment(value[0]).format("YYYY-MM-DD") }
                })
              }}
            />
          </Col>
        </Row>
      </div>

      <div className="mb-3 ">
        <Row>
          <Col>
            <Label className="form-label">Receipt From Date</Label>
            <Flatpickr
              ref={receiptFlatpickerfrom}
              className="form-control d-block"
              placeholder="dd M,yyyy"
              options={{
                altInput: true,
                altFormat: "Y-m-d",
                dateFormat: "Y-m-d",
              }}
              onChange={value => {
                setReceiptDate(prev => {
                  return {
                    ...prev,
                    from: moment(value[0]).format("YYYY-MM-DD"),
                  }
                })
              }}
            />
          </Col>
          <Col>
            <Label className="form-label">Receipt To Date</Label>
            <Flatpickr
              ref={receiptFlatpickerto}
              className="form-control d-block"
              placeholder="dd M,yyyy"
              options={{
                altInput: true,
                altFormat: "Y-m-d",
                dateFormat: "Y-m-d",
              }}
              onChange={value => {
                setReceiptDate(prev => {
                  return { ...prev, to: moment(value[0]).format("YYYY-MM-DD") }
                })
              }}
            />
          </Col>
        </Row>
      </div>

      <div className="d-flex flex-wrap gap-2 mb-4">
        <Button
          type="submit"
          className="btn btn-sm btn-primary"
          color="primary"
          disabled={loading}
        >
          {loading ? "Please Wait..." : "Search"}
        </Button>
        <Button
          onClick={onClearHandler}
          className="btn btn-sm btn-danger"
          color="primary"
        >
          Clear
        </Button>
      </div>
    </Form>
  )
}

export default MonitorReceiptFilter
