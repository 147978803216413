import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"

import MonitorExpireListComponent from "components/MonitorExpireReceipts copy/list"
import {
  exportMonitorExpireReceipts,
  getMonitorExpireReceipts,
} from "store/monitor-expire-receipts/monitor-expire-action"

const MonitorExpireReceiptList = () => {
  document.title = "Expire Receipts"

  const dispatch = useDispatch()
  const {
    data,
    error,
    loading,
    exportExpireReceipt: exportStatus,
  } = useSelector(state => ({
    data: state.monitorExpireReceipts.data,
    error: state.monitorExpireReceipts.error,
    loading: state.monitorExpireReceipts.loading,
    exportExpireReceipt: state.monitorExpireReceipts.exportExpireReceipt,
  }))
  const [search, setSearch] = useState(null)
  const [pageNumber, setPageNumber] = useState(10)
  const [according, setAccording] = useState({ order: "desc", name: "id" })
  const [advnacedSearch, setAdvancedSearch] = useState({})

  const onPageClick = link => {
    dispatch(
      getMonitorExpireReceipts(
        link.url,
        pageNumber,
        according,
        search,
        advnacedSearch,
        6
      )
    )
  }

  const onSearchHandler = value => {
    setSearch(value)
  }

  const onChangeRowNumber = value => {
    setPageNumber(value)
  }

  const onChangeOrdering = value => {
    if (value.order) {
      setAccording(prev => {
        return { ...prev, order: value.order }
      })
    }
    if (value.name) {
      setAccording(prev => {
        return { ...prev, name: value.name }
      })
    }
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(
        getMonitorExpireReceipts(
          undefined,
          undefined,
          undefined,
          search,
          advnacedSearch,
          6
        )
      )
    }, 1500)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  useEffect(() => {
    dispatch(
      getMonitorExpireReceipts(
        undefined,
        pageNumber,
        according,
        search,
        advnacedSearch,
        6
      )
    )
  }, [pageNumber, according])

  const onAdvancedSearchSubmit = data => {
    setAdvancedSearch(data)
    dispatch(
      getMonitorExpireReceipts(undefined, undefined, undefined, search, data, 6)
    )
  }

  const onExportBtnClick = () => {
    dispatch(
      exportMonitorExpireReceipts(undefined, undefined, search, advnacedSearch)
    )
  }

  return (
    <React.Fragment>
      <Breadcrumbs title={"Monitor"} breadcrumbItem={"Expire Receipts"} />
      <MonitorExpireListComponent
        data={data}
        error={error}
        loading={loading}
        onPageClick={onPageClick}
        onSearch={onSearchHandler}
        onChangeRowNumber={onChangeRowNumber}
        onChangeOrdering={onChangeOrdering}
        onAdvancedSearchSubmit={onAdvancedSearchSubmit}
        onExportBtnClick={onExportBtnClick}
        exportStatus={exportStatus}
      />
    </React.Fragment>
  )
}

export default MonitorExpireReceiptList
