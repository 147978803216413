import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import DeleteModal from "components/Common/DeleteModal"
import {
  deleteManyProviderIn,
  deleteProviderIn,
  getProviderIns,
} from "store/provider-in/provider-in-action"
import SAlert from "components/Alert"
import {
  getProviderItemCategories,
  getProviderProjects,
} from "store/provider-dashboard/provider-dashboard-action"
import useSearch from "hooks/useSearch"
import ProviderInDetailModal from "components/ProviderIn/details"
import ProviderReceiptsActionRequestListComponents from "components/ProviderActionRequests/Receipts/list"
import {
  getItemCategories,
  getProjects,
  getUsers,
} from "store/admin/admin-dashboard-action"
import {
  getProviderReceiptActionRequests,
  sendProviderReceiptActionRequests,
} from "store/provider-action-requests/provider-action-request-action"

const ProviderReceiptActionRequestList = () => {
  document.title = "Receipts Action Request"

  const dispatch = useDispatch()
  const { data, error, loading } = useSelector(
    state => state.provActionRequests
  )
  const [deleteModal, setDeleteModal] = useState(false)
  const [search, setSearch] = useState(null)
  const [pageNumber, setPageNumber] = useState(10)
  const [according, setAccording] = useState({ order: "desc", name: "id" })
  const [proIn, setProIn] = useState(null)
  const [advnacedSearch, setAdvancedSearch] = useState({})
  const [detailModal, setDetailModal] = useState(false)

  const onAllDeleteClicked = ids => {
    if (ids.length > 0) {
      dispatch(sendProviderReceiptActionRequests(ids, "Delete"))
    } else {
      SAlert({ text: "no receipt selected", status: "info" })
    }
  }
  const onCancelDeleteClicked = ids => {
    if (ids.length > 0) {
      dispatch(sendProviderReceiptActionRequests(ids, "Cancel"))
    } else {
      SAlert({ text: "no receipt selected", status: "info" })
    }
  }

  const onPageClick = link => {
    dispatch(
      getProviderReceiptActionRequests(
        link.url,
        pageNumber,
        according,
        search,
        advnacedSearch
      )
    )
  }

  const onSearchHandler = value => {
    setSearch(value)
  }

  const onChangeRowNumber = value => {
    setPageNumber(value)
  }

  const onChangeOrdering = value => {
    if (value.order) {
      setAccording(prev => {
        return { ...prev, order: value.order }
      })
    }
    if (value.name) {
      setAccording(prev => {
        return { ...prev, name: value.name }
      })
    }
  }

  useSearch(
    getProviderReceiptActionRequests(
      undefined,
      undefined,
      undefined,
      search,
      advnacedSearch
    ),
    search
  )

  useEffect(() => {
    dispatch(
      getProviderReceiptActionRequests(
        undefined,
        pageNumber,
        according,
        search,
        advnacedSearch
      )
    )
  }, [pageNumber, according])

  useEffect(() => {
    dispatch(getItemCategories())
    dispatch(getProjects())
    dispatch(getUsers(2))
  }, [])

  const onAdvancedSearchSubmit = data => {
    setAdvancedSearch(data)
    dispatch(
      getProviderReceiptActionRequests(
        undefined,
        undefined,
        undefined,
        search,
        data
      )
    )
  }

  const onClickDetail = data => {
    setProIn(data)
    setDetailModal(true)
  }

  return (
    <React.Fragment>
      <ProviderInDetailModal
        data={proIn}
        open={detailModal}
        setOpen={setDetailModal}
      />
      <Breadcrumbs
        title={"Admin"}
        breadcrumbItem={"Receipts Delete Requests"}
      />
      <ProviderReceiptsActionRequestListComponents
        data={data}
        error={error}
        loading={loading}
        onPageClick={onPageClick}
        onSearch={onSearchHandler}
        onChangeRowNumber={onChangeRowNumber}
        onChangeOrdering={onChangeOrdering}
        onAllDeleteClicked={onAllDeleteClicked}
        onAdvancedSearchSubmit={onAdvancedSearchSubmit}
        onClickDetail={onClickDetail}
        onCancelDeleteClicked={onCancelDeleteClicked}
      />
    </React.Fragment>
  )
}

export default ProviderReceiptActionRequestList
