import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getMonitorIPsOutItems } from "store/monitor-dashboard/monitor-dashboard-action"
import { useSearchParams } from "react-router-dom"
import { monitorDashboardAction } from "store/monitor-dashboard/monitor-dashboard-slice"
import MonitorIPsChartOutItemsList from "components/MonitorDashboard/MonitorIPsItems/MonitorIPsChartOutList"

const MonitorIPsChartOutItems = () => {
  const dispatch = useDispatch()

  const [searchParams] = useSearchParams()
  const getParams = {
    itemId: searchParams.get("itemId"),
    from: searchParams.get("from") !== "null" ? searchParams.get("from") : null,
    to: searchParams.get("to") !== "null" ? searchParams.get("to") : null,
    ip_id: searchParams.get("ip_id"),
  }
  const MonitorOutItems = useSelector(
    state => state.monitorDashboard.MonitorOutItems
  )

  useEffect(() => {
    dispatch(getMonitorIPsOutItems(getParams))

    return () => {
      dispatch(monitorDashboardAction.resetMonitorOutItems())
    }
  }, [])

  return (
    <MonitorIPsChartOutItemsList
      data={MonitorOutItems?.data?.data}
      loading={MonitorOutItems?.loading}
    />
  )
}

export default MonitorIPsChartOutItems
