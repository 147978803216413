import SingleSelect from "components/Common/SelectBoxes/SingleSelect"
import React, { useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Col, Form, Input, Label, Row } from "reactstrap"
import Select from "react-select"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import moment from "moment"
import { getProviderItems } from "store/provider-dashboard/provider-dashboard-action"
import { providerDashboardAction } from "store/provider-dashboard/provider-dashboard-slice"

const ProviderQuantificationOrganizationFilter = ({
  onAdvancedSearchSubmit,
  loading,
}) => {
  const ips = useSelector(state => state.providerDashboard.ProviderIps)
  const provinces = useSelector(
    state => state.providerDashboard.providerProvinces
  )
  const projects = useSelector(
    state => state.providerConsumptions.providerConsumpProjects
  )
  const dispatch = useDispatch()

  const fromdateFlatpicker = useRef(null)
  const todateFlatpicker = useRef(null)
  const [Ips, setIps] = useState(null)
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [project, setProject] = useState(null)
  const [province, setProvince] = useState(null)

  const handleSubmit = () => {
    let searchs = {}
    if (project) {
      searchs.project = project.map(proj => (proj.value ? proj.value : proj))
    }
    if (Ips) {
      searchs.ips = Ips.map(ip => (ip.value ? ip.value : ip))
    }
    if (province) {
      searchs.province = province.map(prov => (prov.value ? prov.value : prov))
    }
    searchs.from = fromDate
    searchs.to = toDate
    onAdvancedSearchSubmit(searchs)
  }

  const onIpChangeHandler = ip => {
    const check = ip.some(ite => ite.value === "all")
    if (check) {
      setIps(ips?.data)
    } else {
      setIps(ip)
    }
  }
  const onProvinceChangehandler = prov => {
    const check = prov.some(prov => prov.value === "all")
    if (check) {
      setProvince(provinces?.data)
    } else {
      setProvince(prov)
    }
  }

  const onProjectChangeHandler = proj => {
    const check = proj.some(ite => ite.value === "all")
    if (check) {
      setProject(projects?.data)
    } else {
      setProject(proj)
    }
  }

  const onClearHandler = () => {
    fromdateFlatpicker.current.flatpickr.clear()
    todateFlatpicker.current.flatpickr.clear()
    setIps(null)
    setProject(null)
    setProvince(null)
    setFromDate(null)
    setToDate(null)
    onAdvancedSearchSubmit({})
  }

  return (
    <Form
      onSubmit={e => {
        e.preventDefault()
        handleSubmit()
        return false
      }}
    >
      <div className="mb-3">
        <Row>
          <Col>
            <Label className="form-label">Projects</Label>
            {projects?.data && (
              <Select
                options={[{ value: "all", label: "All" }, ...projects?.data]}
                value={project}
                isMulti={true}
                onChange={onProjectChangeHandler}
              />
            )}
          </Col>
          <Col>
            <Label className="form-label">IPs</Label>
            {ips?.data && (
              <Select
                options={[{ value: "all", label: "All" }, ...ips?.data]}
                value={Ips}
                isMulti={true}
                onChange={onIpChangeHandler}
              />
            )}
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row>
          <Col>
            <Label className="form-label">Provinces</Label>
            {provinces?.data && (
              <Select
                options={[{ value: "all", label: "All" }, ...provinces?.data]}
                value={province}
                isMulti={true}
                onChange={onProvinceChangehandler}
              />
            )}
          </Col>
        </Row>
      </div>

      <div className="mb-3 ">
        <Row>
          <Col>
            <Label className="form-label">From</Label>
            <Flatpickr
              ref={fromdateFlatpicker}
              className="form-control d-block"
              placeholder="dd M,yyyy"
              options={{
                altInput: true,
                altFormat: "Y-m-d",
                dateFormat: "Y-m-d",
              }}
              onChange={value => {
                setFromDate(moment(value[0]).format("YYYY-MM-DD"))
              }}
            />
          </Col>
          <Col>
            <Label className="form-label">To</Label>
            <Flatpickr
              ref={todateFlatpicker}
              className="form-control d-block"
              placeholder="dd M,yyyy"
              options={{
                altInput: true,
                altFormat: "Y-m-d",
                dateFormat: "Y-m-d",
              }}
              onChange={value => {
                setToDate(moment(value[0]).format("YYYY-MM-DD"))
              }}
            />
          </Col>
        </Row>
      </div>

      <div className="d-flex flex-wrap gap-2">
        <Button
          type="submit"
          className="btn btn-sm btn-primary"
          color="primary"
          disabled={loading}
        >
          {loading ? "Please Wait..." : "Search"}
        </Button>
        <Button
          onClick={onClearHandler}
          className="btn btn-sm btn-danger"
          color="primary"
        >
          Clear
        </Button>
      </div>
    </Form>
  )
}

export default ProviderQuantificationOrganizationFilter
