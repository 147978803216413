import React, { useState } from "react"
import { Button, Card, CardBody, Collapse } from "reactstrap"
import { useNavigate } from "react-router-dom"
import CustomHeaderTitle from "components/Common/CustomHeaderTitle"
import GeneralTables from "components/Common/GeneralTable"
import ProviderStockOutFilter from "./filter"

const ProviderStockOutComponent = ({
  data,
  error,
  loading,
  onPageClick,
  onSearch,
  onChangeRowNumber,
  onChangeOrdering,
  onExportBtnClick,
  onAdvancedSearch,
  exportStatus,
}) => {
  const navigate = useNavigate()
  const [filterOpen, setFilterOpen] = useState(false)

  const onAdvancedSearchHandler = result => {
    setFilterOpen(result)
  }

  const onAdvancedSearchSubmit = filters => {
    onAdvancedSearch(filters)
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CustomHeaderTitle
            loading={loading}
            title={"Stock Out "}
            lgColumn={4}
            rowCount={false}
            onChangeRowNumber={onChangeRowNumber}
            orderingList={false}
            onChangeOrdering={onChangeOrdering}
            searchBox={false}
            onSearchHandler={onSearch}
            rightBtn={false}
            exportBtn={true}
            onExportBtnClick={onExportBtnClick}
            exportBtnLoading={exportStatus?.loading}
            advnacedSearchBtn={false}
            advnacedSearchBtnTitle={"Filter"}
            onAdvancedSearch={onAdvancedSearchHandler}
            orderingOptions={[
              { value: "id", name: "ID" },
              { value: "expire_date", name: "Expire Date" },
              { value: "date", name: "Submission Date" },
            ]}
          />
          <Collapse isOpen={filterOpen}>
            <ProviderStockOutFilter
              loading={loading}
              onAdvancedSearchSubmit={onAdvancedSearchSubmit}
            />
          </Collapse>
          <p
            style={{
              textAlign: "center",
              fontSize: 15,
              fontWeight: 600,
              color: "red",
            }}
          >
            Note: Stock out quantity for 6 months
          </p>
          <GeneralTables
            data={data}
            loading={loading}
            headers={[
              "SN",
              "UNFPA ITEM ID",
              "IP- ITEM DESCRIPTION",
              "AMC ( Last Year )",
              "Current Stock out Qty",
              "Current Balance",
              "Stock out Period ( Per Month )",
              "Estimated ( Stock Out Date )",
            ]}
            renderItem={(data, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{data?.unfpa_item}</td>
                <td>{data?.ip_item}</td>
                <td>{data?.prevTotal}</td>
                <td>{data?.stockOut}</td>
                <td>{data?.balance}</td>
                <td>{data?.priod_per_month}</td>
                <td>{data?.stockDate}</td>
              </tr>
            )}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default ProviderStockOutComponent
