import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"

import {
  exportProvinceConsumptionReports,
  getProvinceConsumptionReports,
} from "store/province-reports/province-report-action"
import { getProvinceItemCategories } from "store/province-dashboard/province-dashboard-action"
import { getProvinceConsumptionsProviderAccount } from "store/province-consumptions/province-consumption-action"
import ProvinceConsumptionReportListComponent from "components/ProvinceReports/Consumption"

const ProvinceConsumptionReportList = ({ from }) => {
  document.title = "Consumption Reports"

  const dispatch = useDispatch()
  const consumptions = useSelector(
    state => state.provinceReports.provinceConsumptionReport
  )
  const exportStatus = useSelector(
    state => state.provinceReports.provinceConsumptionExport
  )

  const [according, setAccording] = useState({ order: "desc", name: "date" })
  const [advnacedSearch, setAdvancedSearch] = useState({})
  const [pageNumber, setPageNumber] = useState(20)

  const onChangeOrdering = value => {
    if (value.order) {
      setAccording(prev => {
        return { ...prev, order: value.order }
      })
    }
    if (value.name) {
      setAccording(prev => {
        return { ...prev, name: value.name }
      })
    }
  }

  const onPageClick = link => {
    dispatch(
      getProvinceConsumptionReports(
        according,
        advnacedSearch,
        pageNumber,
        link.url
      )
    )
  }

  const onChangeRowNumber = number => {
    setPageNumber(number)
  }

  useEffect(() => {
    dispatch(
      getProvinceConsumptionReports(
        according,
        advnacedSearch,
        pageNumber,
        undefined
      )
    )
  }, [according, pageNumber])

  useEffect(() => {
    dispatch(getProvinceItemCategories())
    dispatch(getProvinceConsumptionsProviderAccount())
  }, [])

  const onSearchHandler = data => {
    setAdvancedSearch(data)
    dispatch(
      getProvinceConsumptionReports(undefined, data, pageNumber, undefined)
    )
  }

  const onExportBtnClick = () => {
    dispatch(exportProvinceConsumptionReports(according, advnacedSearch))
  }

  return (
    <React.Fragment>
      <Breadcrumbs title={"Province"} breadcrumbItem={"Consumption Reports"} />
      <ProvinceConsumptionReportListComponent
        data={consumptions?.data}
        error={consumptions?.error}
        loading={consumptions?.loading}
        onChangeOrdering={onChangeOrdering}
        onSearchHandler={onSearchHandler}
        onExportBtnClick={onExportBtnClick}
        exportStatus={exportStatus}
        onChangeRowNumber={onChangeRowNumber}
        onPageClick={onPageClick}
      />
    </React.Fragment>
  )
}

export default ProvinceConsumptionReportList
