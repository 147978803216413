import React, { useEffect, useState } from "react"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import ProvinceDashboardComponent from "components/ProvinceDashboard/ProvinceDashbaord"
import {
  exportProvinceStock,
  getProvinceIps,
  getProvinceItemCategories,
  getProvinceItems,
  getProvinceStock,
} from "store/province-dashboard/province-dashboard-action"
import WelcomeComp from "pages/Dashboard/WelcomeComp"

const ProviderDashboard = props => {
  //meta title
  document.title = "Dashboard"

  const [advancedSearch, setAdvancedSearch] = useState({})

  const provinceStock = useSelector(
    state => state.provinceDashboard.provinceStock
  )

  const categories = useSelector(
    state => state.provinceDashboard.ProvinceItemCategoires
  )

  const dispatch = useDispatch()

  const onAdvanceSearch = values => {
    setAdvancedSearch(values)
    console.log(values)
    dispatch(getProvinceStock(values))
  }

  const onCategoryChange = category => {
    dispatch(getProvinceItems(category))
  }

  useEffect(() => {
    dispatch(getProvinceStock())
    dispatch(getProvinceIps())
    dispatch(getProvinceItemCategories())
  }, [])

  const onAdvancedSearchHandle = values => {
    setAdvancedSearch(values)
    dispatch(getProvinceStock(values))
  }

  const onExportClicked = () => {
    dispatch(exportProvinceStock(advancedSearch))
  }

  return (
    <React.Fragment>
      <Breadcrumbs title={"Province"} breadcrumbItem={"Dashboard"} />
      <WelcomeComp />
      <ProvinceDashboardComponent
        stocks={provinceStock?.data?.data?.provinceReports}
        allData={provinceStock?.data?.data}
        onAdvanceSearch={onAdvanceSearch}
        onCategoryChange={onCategoryChange}
        categories={categories}
        onExportClicked={onExportClicked}
        onAdvancedSearchHandle={onAdvancedSearchHandle}
      />
    </React.Fragment>
  )
}

export default ProviderDashboard
