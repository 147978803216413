import React from "react"
import { Col, Row, Table } from "reactstrap"

const ProviderInTotalBox = ({ totals }) => {
  return (
    <Row
      style={{
        marginBottom: 40,
        justifyItems: "center",
        justifyContent: "center",
      }}
    >
      <Col lg={9}>
        <Table
          borderless={false}
          bordered={true}
          style={{ overflow: "hidden" }}
        >
          <tbody>
            <tr>
              <th style={{ color: "#19ada1", fontSize: 13 }}>
                Total Receipts ( Per IP )
              </th>
              <td
                style={{
                  color: "#d08658",
                  fontSize: 13,
                  fontFamily: "monospace",
                }}
              >
                {totals?.receipts}
              </td>
              <th style={{ color: "#19ada1", fontSize: 13 }}>
                Total Available Receipts ( Per IP )
              </th>
              <td
                style={{
                  color: "#d08658",
                  fontSize: 13,
                  fontFamily: "monospace",
                }}
              >
                {totals?.available_receipts}
              </td>
            </tr>
            <tr>
              <th style={{ color: "#19ada1", fontSize: 13 }}>
                Total Receipts ( Per UNFPA )
              </th>
              <td
                style={{
                  color: "#d08658",
                  fontSize: 13,
                  fontFamily: "monospace",
                }}
              >
                {totals?.unfpa_receipts?.toFixed(2)}
              </td>
              <th style={{ color: "#19ada1", fontSize: 13 }}>
                Total Available Receipts ( Per UNFPA )
              </th>
              <td
                style={{
                  color: "#d08658",
                  fontSize: 13,
                  fontFamily: "monospace",
                }}
              >
                {" "}
                {totals?.available_unfpa_receipts?.toFixed(2)}
              </td>
            </tr>
            {totals?.pending_provider_to_provider_status ? (
              <tr>
                <th style={{ color: "#19ada1", fontSize: 13 }} colSpan={2}>
                  Total Pending of Provider to Provider Receipt{" "}
                </th>

                <td
                  style={{
                    color: "#d08658",
                    fontSize: 15,
                    fontFamily: "monospace",
                    textAlign: "center",
                  }}
                  colSpan={2}
                >
                  {totals?.pending_provider_to_provider_status}
                </td>
              </tr>
            ) : (
              <tr></tr>
            )}
          </tbody>
        </Table>
      </Col>
    </Row>
  )
}

export default ProviderInTotalBox
