import { redirect } from "react-router-dom"

export async function setAuthToken(response) {
  const token = localStorage.setItem("token", JSON.stringify(response))
  return token
}

export function getAuthToken() {
  const token = JSON.parse(localStorage.getItem("token"))
  return token
}

export function tokenLoader() {
  return getAuthToken()
}

export function checkAuthLoader() {
  const token = getAuthToken()
  if (!token) {
    return redirect("/login")
  }
  return token
}

export function removeAuthToken() {
  localStorage.removeItem("token")
}

export function checkNotLogin() {
  const token = getAuthToken()
  if (token) {
    return redirect("/dashboard")
  }
  return true
}

export function authorize(role, allowRoles) {
  const check = allowRoles.includes(role)
  if (!check) {
    return redirect("/unauthorized")
  }
}
