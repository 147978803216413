import Preloader from "components/Common/Loader"
import PermissionsComponents from "components/Permission"
import UserForm from "components/User/form"
import React, { useEffect, useLayoutEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { getAllUserRegistrationData } from "store/general-renders/general-action"
import { generalAction } from "store/general-renders/general-slice"
import { regDataSelector } from "store/selectors"
import {
  getByIdUser,
  updateDirectPermission,
  updateUser,
} from "store/user/user-action"
import { userAction } from "store/user/user-slice"
import { isObject } from "lodash"

const EditUser = () => {
  document.title = "Edit User"
  const { id } = useParams()
  const navigate = useNavigate()
  const [loaded, setLoaded] = useState(false)
  const { regData, regLoading, regError } = useSelector(regDataSelector)

  const { loading, error, user } = useSelector(state => state.user)
  const permissionResponse = useSelector(state => state.user.permissionChange)

  const dispatch = useDispatch()

  const handleSubmit = values => {
    values.providers = values.providers.map(pr => (pr.value ? pr.value : pr))
    values.warehouses = values.warehouses.map(wr => (wr.value ? wr.value : wr))
    values.projects = values.projects.map(pr => (pr.value ? pr.value : pr))
    values.provinces = values.provinces.map(province =>
      isObject(province) ? province.value : province
    )
    values.ips = values.ips.map(ip => (ip.value ? ip.value : ip))
    values.item_categories = values.item_categories.map(category =>
      category.value ? category.value : category
    )
    values.id = id
    values.old_password = user?.old_password
    dispatch(updateUser(id, values, navigate))
  }

  const onChangePermission = (permission, status) => {
    dispatch(updateDirectPermission(id, permission, status))
  }

  //   useLayoutEffect(() => {
  //     dispatch(userAction.resetState())
  //     dispatch(generalAction.resetUserRegData())
  //   }, [])

  useEffect(() => {
    dispatch(getAllUserRegistrationData())
    dispatch(getByIdUser(id))
    setLoaded(true)

    return () => {
      dispatch(userAction.resetState())
      dispatch(generalAction.resetUserRegData())
    }
  }, [dispatch])

  return (
    <React.Fragment>
      {regData?.data && user ? (
        <>
          <UserForm
            onSubmit={handleSubmit}
            loading={loading}
            error={error}
            user={user}
            formMode={"Edit"}
            regData={regData}
            regLoading={regLoading}
            regError={regError}
            permissions={regData?.data?.permissions}
            userDirectPermissions={user?.directPermissions}
            onChangePermission={onChangePermission}
            permissionResponse={permissionResponse}
            activePermissions={user?.activePermissions}
          />
        </>
      ) : (
        <Preloader loading={true} />
      )}
    </React.Fragment>
  )
}

export default EditUser
