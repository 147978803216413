import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import {
  getProviderIps,
  getProviderItemCategories,
  getProviderItems,
  getProviderProviders,
  getProviderProvinces,
  getProviderWarehouses,
} from "store/provider-dashboard/provider-dashboard-action"

import { isEmpty, isObject } from "lodash"
import Preloader from "components/Common/Loader"
import {
  getByIdProviderOut,
  getProviderOutProviderAccounts,
  updateProviderOut,
} from "store/provider-out/provider-out-action"
import ProviderOutEditForm from "components/ProviderOut/EditForm"
import { providerOutAction } from "store/provider-out/provider-out-slice"

const EditProviderOut = () => {
  document.title = "Edit Distribution"

  const params = useParams()
  const { id } = params

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { loading, error } = useSelector(state => state.providerOut)
  const distribution = useSelector(state => state.providerOut.distribution)

  const warehosues = useSelector(
    state => state.providerDashboard.providerWarehouses
  )
  const providers = useSelector(
    state => state.providerDashboard.providerProviders
  )

  const categories = useSelector(
    state => state.providerDashboard.ProviderItemCategoires
  )
  const ips = useSelector(state => state.providerDashboard.ProviderIps)
  const providerAccounts = useSelector(
    state => state.providerOut.providerAccounts
  )
  const items = useSelector(state => state.providerDashboard.ProviderItems)
  const provinces = useSelector(
    state => state.providerDashboard.providerProvinces
  )
  const facilities = useSelector(
    state => state.providerDashboard.providerFacilities
  )

  const handleSubmit = (values, file) => {
    const forms = new FormData()

    forms.append("_method", "put")
    forms.append("document", file)
    forms.append("old_document", distribution?.data?.document)
    forms.append("provider_in_id", distribution?.data?.provider_in_id)
    forms.append("id", distribution?.data?.id)
    forms.append(
      "distribution_reason",
      isObject(values.distribution_reason)
        ? values.distribution_reason.value
        : values.distribution_reason
    )
    forms.append(
      "item_category_id",
      isObject(values.item_category_id)
        ? values.item_category_id.value
        : values.item_category_id
    )
    forms.append(
      "item_id",
      isObject(values.item_id) ? values.item_id.value : values.item_id
    )

    forms.append(
      "provider_id",
      isObject(values.provider_id)
        ? values.provider_id.value
        : values.provider_id
    )
    forms.append(
      "province_id",
      isObject(values.province_id)
        ? values.province_id.value
        : values.province_id
    )

    const warehouse_disribute = isObject(values.warehouse_disribute)
      ? values.warehouse_disribute.value
      : values.warehouse_disribute

    forms.append("warehouse_disribute", warehouse_disribute)

    if (warehouse_disribute === "HFs") {
      forms.append(
        "facility_id",
        isObject(values.facility_id)
          ? values.facility_id.value
          : values.facility_id
      )
    } else {
      forms.append("facility_id", "")
    }

    forms.append(
      "ip_id",
      isObject(values.ip_id) ? values.ip_id.value : values.ip_id
    )
    forms.append(
      "warehouse_id",
      isObject(values.warehouse_id)
        ? values.warehouse_id.value
        : values.warehouse_id
    )
    forms.append("distributor", values.distributor)
    forms.append("out_quantity", values.out_quantity)
    forms.append("date", values.date)

    forms.append("distributed_type", values.distributed_type)
    forms.append("other_hfs", values.other_hfs)

    dispatch(updateProviderOut(id, forms, navigate))
  }

  useEffect(() => {
    dispatch(getProviderWarehouses())
    dispatch(getProviderProviders())
    dispatch(getProviderItemCategories())
    dispatch(getProviderProvinces())
    dispatch(getProviderIps())
    dispatch(getProviderOutProviderAccounts())
    dispatch(getByIdProviderOut(id))

    return () => {
      dispatch(providerOutAction.resetState())
      dispatch(providerOutAction.resetProviderAccount())
    }
  }, [])

  // useEffect(() => {
  //   dispatch(getProviderItems(distribution?.data?.item_category_id))
  // }, [distribution])

  return (
    <React.Fragment>
      {!isEmpty(distribution?.data) ? (
        <ProviderOutEditForm
          onSubmit={handleSubmit}
          loading={loading}
          error={error}
          formMode={"Edit"}
          warehosues={warehosues?.data}
          providers={providers?.data}
          categories={categories?.data}
          items={items?.data}
          ips={ips?.data}
          provinces={provinces?.data}
          facilities={facilities?.data}
          distribution={distribution?.data}
          providerAccounts={providerAccounts?.data}
        />
      ) : (
        <Preloader loading={true} />
      )}
    </React.Fragment>
  )
}

export default EditProviderOut
