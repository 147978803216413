import React, { useMemo, useRef, useState } from "react"
import { Button, Col, Form, Label, Row } from "reactstrap"

import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import moment from "moment"
import { useSelector, useDispatch } from "react-redux"
import { isObject } from "lodash"
import Select from "react-select"
import {
  getProviderFacilities,
  getProviderItems,
} from "store/provider-dashboard/provider-dashboard-action"
import { providerDashboardAction } from "store/provider-dashboard/provider-dashboard-slice"
import { DISTRIBUTEDDETAIL } from "constants/data"

const ProviderDistributionReportFilter = ({
  onAdvancedSearchSubmit,
  loading,
}) => {
  const dateFlatpickerfrom = useRef(null)
  const dateFlatpickerto = useRef(null)
  const [category, setCategory] = useState(null)
  const [item, setItem] = useState(null)
  const [warehouse, setWarehouse] = useState(null)
  const [geoRegion, setGeoRegion] = useState(null)
  const [trType, setTrType] = useState(null)
  const [province, setProvince] = useState(null)
  const [facility, setFacility] = useState(null)
  const [ip, setIP] = useState(null)
  const [project, setProject] = useState(null)
  const [warehouse_disribute, setWarehouseDistribute] = useState(null)
  const [expireDate, setExpireDate] = useState({
    from: null,
    to: null,
  })

  const dispatch = useDispatch()

  const [date, setDate] = useState({
    from: null,
    to: null,
  })

  const categories = useSelector(
    state => state.providerDashboard.ProviderItemCategoires
  )

  const items = useSelector(state => state.providerDashboard.ProviderItems)

  const provinces = useSelector(
    state => state.providerDashboard.providerProvinces
  )

  const projects = useSelector(
    state => state.providerDashboard.providerProjects
  )

  const ips = useSelector(state => state.providerDashboard.ProviderIps)

  const facilities = useSelector(
    state => state.providerDashboard.providerFacilities
  )

  const warehouses = useSelector(
    state => state.providerDashboard.providerWarehouses
  )

  const filteredItems = useMemo(
    () =>
      items?.data.map(item => ({
        value: item.id,
        label: `${item.unfp_item_name} ( ${item.item_name} )`,
      })),
    [items]
  )

  const handleSubmit = () => {
    let data = {}

    data.date = {
      from: date.from ? date.from : null,
      to: date.to ? date.to : null,
    }
    if (item) {
      data.item = item.map(ite => (ite.value ? ite.value : ite))
    }

    data.category = isObject(category) ? category.value : null
    data.warehouse = isObject(warehouse) ? warehouse.value : null
    data.geoRegion = isObject(geoRegion) ? geoRegion.value : null
    data.trType = isObject(trType) ? trType.value : null
    data.project = isObject(project) ? project.value : null
    data.ip = isObject(ip) ? ip.value : null
    data.province = isObject(province) ? province.value : null
    data.facility = isObject(facility) ? facility.value : null
    data.warehouse_disribute = isObject(warehouse_disribute)
      ? warehouse_disribute.value
      : null

    onAdvancedSearchSubmit(data)
  }

  const onClearHandler = () => {
    dateFlatpickerfrom.current.flatpickr.clear()
    dateFlatpickerto.current.flatpickr.clear()
    setItem(null)
    setCategory(null)
    setGeoRegion(null)
    setTrType(null)
    setWarehouse(null)
    setDate({ from: null, to: null })
    setProvince(null)
    setFacility(null)
    setIP(null)
    setProject(null)
    onAdvancedSearchSubmit({})
    setWarehouseDistribute(null)
    dispatch(providerDashboardAction.resetProviderItems())
    dispatch(providerDashboardAction.resetProviderFacilities())

    onAdvancedSearchSubmit({})
  }

  const onCategoryChangeHandler = cate => {
    setCategory(cate)
    dispatch(getProviderItems(cate.value))
  }

  const onItemChangeHandler = item => {
    const check = item.some(ite => ite.value === "all")
    if (check) {
      setItem(filteredItems)
    } else {
      setItem(item)
    }
  }

  const onWarehouseChange = warehouse => {
    setWarehouse(warehouse)
  }
  const onGeoRegionChangeHandler = region => {
    setGeoRegion(region)
  }
  const onTransactionTypeChange = type => {
    setTrType(type)
  }

  const onProvinceChangeHandler = value => {
    setProvince(value)
    setFacility("")
    dispatch(getProviderFacilities(value.value))
  }

  const onFacilityChangeHandler = value => {
    setFacility(value)
  }

  const onIPChangeHandler = value => {
    setIP(value)
  }

  const onProjectChangeHandler = value => {
    setProject(value)
  }

  const onWarehouseDistributeChangeHandler = value => {
    setWarehouseDistribute(value)
  }

  return (
    <Form
      onSubmit={e => {
        e.preventDefault()
        handleSubmit()
        return false
      }}
    >
      <div className="mb-3">
        <Row>
          <Col>
            <Label className="form-label">IPs</Label>
            {ips?.data && (
              <Select
                options={ips?.data}
                value={ip}
                onChange={onIPChangeHandler}
              />
            )}
          </Col>
          <Col>
            <Label className="form-label">Projects</Label>
            {projects?.data && (
              <Select
                options={projects?.data}
                value={project}
                onChange={onProjectChangeHandler}
              />
            )}
          </Col>
        </Row>
      </div>

      <div className="mb-3">
        <Row>
          <Col>
            <Label className="form-label">Province</Label>
            {provinces?.data && (
              <Select
                options={provinces?.data}
                value={province}
                onChange={onProvinceChangeHandler}
              />
            )}
          </Col>
          <Col>
            <Label className="form-label">Facilities</Label>
            {facilities?.data && (
              <Select
                options={facilities?.data}
                value={facility}
                onChange={onFacilityChangeHandler}
              />
            )}
          </Col>
        </Row>
      </div>

      <div className="mb-3">
        <Row>
          <Col>
            <Label className="form-label">Item Categories</Label>
            {categories?.data && (
              <Select
                options={categories?.data}
                value={category}
                onChange={onCategoryChangeHandler}
              />
            )}
          </Col>
          <Col>
            <Label className="form-label">Items</Label>
            {filteredItems && (
              <Select
                isMulti={true}
                options={[{ value: "all", label: "All" }, ...filteredItems]}
                value={item}
                onChange={onItemChangeHandler}
              />
            )}
          </Col>
        </Row>
      </div>

      <div className="mb-3">
        <Row>
          <Col>
            <Label className="form-label">Warehouse</Label>
            {warehouses?.data && (
              <Select
                options={warehouses?.data}
                value={warehouse}
                onChange={onWarehouseChange}
              />
            )}
          </Col>
          <Col>
            <Label className="form-label">GEOGRAPHIC REGION</Label>
            {filteredItems && (
              <Select
                options={[
                  { value: "Central", label: "Central" },
                  { value: "North", label: "North" },
                  { value: "South", label: "South" },
                  { value: "East", label: "East" },
                  { value: "West", label: "West" },
                ]}
                value={geoRegion}
                onChange={onGeoRegionChangeHandler}
              />
            )}
          </Col>
        </Row>
      </div>

      <div className="mb-3">
        <Row>
          {/* <Col>
            <Label className="form-label">Transaction Type</Label>
            {filteredItems && (
              <Select
                options={[
                  { value: "Receipts", label: "Receipts" },
                  { value: "Distribution", label: "Distribution" },
                ]}
                value={trType}
                onChange={onTransactionTypeChange}
              />
            )}
          </Col> */}
          <Col>
            <Label className="form-label">Warehouse Distribute</Label>
            <Select
              options={[{ value: "all", label: "All" }, ...DISTRIBUTEDDETAIL]}
              value={warehouse_disribute}
              onChange={onWarehouseDistributeChangeHandler}
            />
          </Col>
        </Row>
      </div>

      <div className="mb-3 ">
        <Row>
          <Col>
            <Label className="form-label">Distribution From Date</Label>
            <Flatpickr
              ref={dateFlatpickerfrom}
              className="form-control d-block"
              placeholder="dd M,yyyy"
              options={{
                altInput: true,
                altFormat: "Y-m-d",
                dateFormat: "Y-m-d",
              }}
              onChange={value => {
                setDate(prev => {
                  return {
                    ...prev,
                    from: moment(value[0]).format("YYYY-MM-DD"),
                  }
                })
              }}
            />
          </Col>
          <Col>
            <Label className="form-label">Distribution To Date</Label>
            <Flatpickr
              ref={dateFlatpickerto}
              className="form-control d-block"
              placeholder="dd M,yyyy"
              options={{
                altInput: true,
                altFormat: "Y-m-d",
                dateFormat: "Y-m-d",
              }}
              onChange={value => {
                setDate(prev => {
                  return { ...prev, to: moment(value[0]).format("YYYY-MM-DD") }
                })
              }}
            />
          </Col>
        </Row>
      </div>

      <div className="d-flex flex-wrap gap-2 mb-4">
        <Button
          type="submit"
          className="btn btn-sm btn-primary"
          color="primary"
          disabled={loading}
        >
          {loading ? "Please Wait..." : "Search"}
        </Button>
        <Button
          onClick={onClearHandler}
          className="btn btn-sm btn-danger"
          color="primary"
        >
          Clear
        </Button>
      </div>
    </Form>
  )
}

export default ProviderDistributionReportFilter
