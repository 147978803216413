import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getProviderIPsOutItems } from "store/provider-dashboard/provider-dashboard-action"
import { useSearchParams } from "react-router-dom"
import { providerDashboardAction } from "store/provider-dashboard/provider-dashboard-slice"
import ProviderIPsChartOutItemsList from "components/ProviderDashboard/ProviderIPsItems/ProviderIPsChartOutList"

const ProviderIPsChartOutItems = () => {
  const dispatch = useDispatch()

  const [searchParams] = useSearchParams()
  const getParams = {
    itemId: searchParams.get("itemId"),
    from: searchParams.get("from") !== "null" ? searchParams.get("from") : null,
    to: searchParams.get("to") !== "null" ? searchParams.get("to") : null,
    ip_id: searchParams.get("ip_id"),
  }
  const ProviderOutItems = useSelector(
    state => state.providerDashboard.ProviderOutItems
  )

  useEffect(() => {
    dispatch(getProviderIPsOutItems(getParams))

    return () => {
      dispatch(providerDashboardAction.resetProviderOutItems())
    }
  }, [])

  return (
    <ProviderIPsChartOutItemsList
      data={ProviderOutItems?.data?.data}
      loading={ProviderOutItems?.loading}
    />
  )
}

export default ProviderIPsChartOutItems
