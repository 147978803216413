import ProvinceConsumptionListComponent from "components/ProvinceConsumption/list"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  exportProvinceConsumptions,
  getProvinceConsumptions,
  getProvinceConsumptionsProviderAccount,
  getProvinceConsumptionsProvinces,
  storeProvinceConsumptions,
  updateProvinceConsumptions,
} from "store/province-consumptions/province-consumption-action"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { provinceConsumptionAction } from "store/province-consumptions/province-consumption-slice"

const ProvinceConsumptionList = () => {
  document.title = "Consumptions"

  const [filters, setfilter] = useState({})
  const dispatch = useDispatch()
  const {
    data,
    error,
    loading,
    export: exportStatus,
  } = useSelector(state => ({
    data: state.provinceConsumptions.data,
    error: state.provinceConsumptions.error,
    loading: state.provinceConsumptions.loading,
    export: state.provinceConsumptions.export,
  }))

  const storeStatus = useSelector(state => state.provinceConsumptions.store)

  const onAdvancedSearchSubmit = values => {
    setfilter(values)
    dispatch(getProvinceConsumptions(values))
  }

  const onExport = () => {
    dispatch(exportProvinceConsumptions(filters))
  }

  useEffect(() => {
    dispatch(getProvinceConsumptions())
    dispatch(getProvinceConsumptionsProviderAccount())

    return () => {
      dispatch(provinceConsumptionAction.resetState())
    }
  }, [])

  const onSubmit = value => {
    value.year = filters?.year ? filters.year : new Date().getFullYear()
    value.month = filters?.month
      ? filters.month
      : ("0" + (new Date().getMonth() + 1)).slice(-2)

    value.consume = value.quantity
    dispatch(storeProvinceConsumptions(value))
  }

  const onUpdateFunc = value => {
    dispatch(updateProvinceConsumptions(value))
  }

  return (
    <React.Fragment>
      <Breadcrumbs title={"Province"} breadcrumbItem={"Consumptions"} />
      <ProvinceConsumptionListComponent
        data={data?.data}
        loading={loading}
        error={error}
        onAdvancedSearchSubmit={onAdvancedSearchSubmit}
        onUpdateFunc={onUpdateFunc}
        onSubmit={onSubmit}
        filters={filters}
        storeStatus={storeStatus}
        onExport={onExport}
        exportLoading={exportStatus?.loading}
      />
    </React.Fragment>
  )
}

export default ProvinceConsumptionList
