import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: [],
  error: null,
  loading: false,
  province: null,
  store: { loading: false, error: null },
  export: { loading: false, error: null },
  providerConsumpProvinces: { data: [], error: null, loading: false },
  providerConsumpProjects: { data: [], error: null, loading: false },
  providerConsumpIPs: { data: [], error: null, loading: false },
  providerConsumpFacilities: { data: [], error: null, loading: false },
}

const providerConsumptionSlice = createSlice({
  name: "providerConsumptions",
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload
    },
    setError(state, action) {
      state.error = action.payload
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    setProvince(state, action) {
      state.province = action.payload
    },
    setStore(state, action) {
      state.store = {
        loading: action.payload.loading,
        error: action.payload.error,
      }
    },
    setExport(state, action) {
      state.export = {
        loading: action.payload.loading,
        error: action.payload.error,
      }
    },
    setProviderConsumptionProvince(state, action) {
      state.providerConsumpProvinces = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setProviderConsumptionFacilities(state, action) {
      state.providerConsumpFacilities = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setProviderConsumptionProjects(state, action) {
      state.providerConsumpProjects = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setProviderConsumptionIPs(state, action) {
      state.providerConsumpIPs = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    resetState(state) {
      state.data = []
      state.error = null
      state.loading = false
      state.province = null
      state.store = { loading: false, error: null }
      state.export = { loading: false, error: null }
      state.providerConsumpProvinces = { loading: false, error: null, data: [] }
      state.providerConsumpFacilities = {
        loading: false,
        error: null,
        data: [],
      }
      state.providerConsumpIPs = { loading: false, error: null, data: [] }
    },
  },
})

export const providerConsumptionAction = providerConsumptionSlice.actions

export default providerConsumptionSlice
