import { useFormik } from "formik"

import * as Yup from "yup"
import React, { useEffect, useMemo, useState } from "react"
import {
  Card,
  CardBody,
  Label,
  Button,
  Form,
  Input,
  FormFeedback,
  Row,
  Col,
} from "reactstrap"
import CustomHeaderTitle from "components/Common/CustomHeaderTitle"
import SingleSelect from "components/Common/SelectBoxes/SingleSelect"
import PermissionsComponents from "components/Permission"
import MultipleSelect from "components/Common/SelectBoxes/MultipleSelect"
import { isArray, isObject } from "lodash"
import PasswordInput from "components/Common/PasswordInput"
import {
  ProviderValidation,
  AdminValidation,
  ProvinceValidation,
  MonitorValidation,
  checkValidationRole,
  AllowInput,
} from "./ValidatedUser"
import { ROLES } from "constants/data"
import CustomSelect from "components/Common/SelectBoxes/CustomSelect"

const UserForm = ({
  onSubmit,
  loading,
  error,
  formMode,
  user,
  regData,
  regLoading,
  onChangePermission,
  permissionResponse,
  activePermissions,
}) => {
  const provinces = regData?.data?.provinces
  const categories = regData?.data?.categories
  const roles = regData?.data?.roles
  const providers = regData?.data?.providers
  const warehouses = regData?.data?.warehouses
  const ips = regData?.data?.ips
  const projects = regData?.data?.projects

  const [validateUser, setValidateUser] = useState(null)
  const [UserRole, setUserRole] = useState(
    user?.user?.role_id ? ROLES[user?.user?.role_id - 1]?.label : "Provider"
  )
  const validationType = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: user ? user?.user?.name : "",
      email: user ? user?.user?.email : "",
      password: "",
      confirm: "",
      provinces: user
        ? isArray(user?.user?.provinces)
          ? user?.user?.provinces
          : []
        : [],
      role_id: user ? user?.user?.role_id : "",
      ip_id: user ? user?.user?.ip_id : "",
      item_categories: user
        ? isArray(user?.user?.item_categories)
          ? user?.user?.item_categories
          : []
        : [],
      providers: user
        ? isArray(user?.user?.providers)
          ? user?.user?.providers
          : []
        : [],
      warehouses: user
        ? isArray(user?.user?.warehouses)
          ? user?.user?.warehouses
          : []
        : [],
      projects: user
        ? isArray(user?.user?.projects)
          ? user?.user?.projects
          : []
        : [],
      ips: user ? (isArray(user?.user?.ips) ? user?.user?.ips : []) : [],
    },
    validationSchema: validateUser,

    onSubmit: values => {
      onSubmit(values)
    },
  })

  const onProvinceChangehandler = province => {
    const check = province.some(prov => prov.value === "select-all")

    if (check) {
      validationType.setFieldValue("provinces", provinces)
    } else {
      validationType.setFieldValue("provinces", province)
    }
  }

  const onItemCategoryChange = category => {
    const check = category.some(cat => cat.value === "select-all")
    if (check) {
      validationType.setFieldValue("item_categories", categories)
    } else {
      validationType.setFieldValue("item_categories", category)
    }
  }

  const onIpChangeHandler = ip => {
    const check = ip.some(ip => ip.value === "select-all")
    if (check) {
      validationType.setFieldValue("ips", ips)
    } else {
      validationType.setFieldValue("ips", ip)
    }
  }

  const onProviderChangeHandler = provider => {
    const check = provider.some(provider => provider.value === "select-all")
    if (check) {
      validationType.setFieldValue("providers", providers)
    } else {
      validationType.setFieldValue("providers", provider)
    }
  }

  const onWarehouseChangeHandler = warehouse => {
    const check = warehouse.some(ware => ware.value === "select-all")
    if (check) {
      validationType.setFieldValue("warehouses", warehouses)
    } else {
      validationType.setFieldValue("warehouses", warehouse)
    }
  }

  const onProjectChangeHandler = project => {
    const check = project.some(proj => proj.value === "select-all")
    if (check) {
      validationType.setFieldValue("projects", projects)
    } else {
      validationType.setFieldValue("projects", project)
    }
  }

  const onRoleChangeHandler = value => {
    setValidateUser(checkValidationRole(value.value))
    setUserRole(value.label)
    validationType.setFieldValue("role_id", value.value)
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CustomHeaderTitle
            loading={loading || regLoading}
            title={formMode === "Edit" ? "Edit User" : "Add User"}
            lgColumn={3}
            rowCount={false}
            orderingList={false}
            searchBox={false}
            rightBtn={true}
            rightBtnTitle={"Back"}
            rightBtnRoute={"/users"}
          />

          <Form
            onSubmit={e => {
              e.preventDefault()
              validationType.handleSubmit()
              return false
            }}
          >
            <Row>
              <Col>
                <div className="mb-3">
                  <Label className="form-label">Roles</Label>
                  {!regLoading && roles && (
                    <CustomSelect
                      isLoading={loading}
                      name={"role_id"}
                      onChange={onRoleChangeHandler}
                      isDisabled={formMode === "Edit"}
                      options={roles}
                      value={
                        isObject(validationType.values.role_id)
                          ? validationType.values.role_id
                          : roles.find(
                              ware =>
                                validationType.values.role_id === ware.value
                            )
                      }
                    />
                  )}

                  {validationType.errors.role_id || error?.role_id?.[0] ? (
                    <div
                      type="invalid"
                      style={{
                        color: "#f46a6a",
                        fontSize: "80%",
                      }}
                    >
                      {validationType.errors.role_id || error?.role_id?.[0]}
                    </div>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <Label className="form-label">Full Name</Label>
                  <Input
                    name="name"
                    placeholder="Full Name"
                    type="text"
                    onChange={validationType.handleChange}
                    onBlur={validationType.handleBlur}
                    value={validationType.values.name || ""}
                    invalid={
                      (validationType.touched.name &&
                        validationType.errors.name) ||
                      error?.name?.[0]
                        ? true
                        : false
                    }
                  />
                  {(validationType.touched.name &&
                    validationType.errors.name) ||
                  error?.name?.[0] ? (
                    <FormFeedback type="invalid">
                      {validationType.errors.name || error?.name?.[0]}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label className="form-label">Email</Label>
                  <Input
                    name="email"
                    placeholder="Email"
                    type="text"
                    onChange={validationType.handleChange}
                    onBlur={validationType.handleBlur}
                    value={validationType.values.email || ""}
                    invalid={
                      (validationType.touched.email &&
                        validationType.errors.email) ||
                      error?.email?.[0]
                        ? true
                        : false
                    }
                  />
                  {(validationType.touched.email &&
                    validationType.errors.email) ||
                  error?.email?.[0] ? (
                    <FormFeedback type="invalid">
                      {validationType.errors.email || error?.email?.[0]}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <Label className="form-label">Password</Label>
                  <PasswordInput
                    name="password"
                    placeholder="Password"
                    type="password"
                    onChange={validationType.handleChange}
                    onBlur={validationType.handleBlur}
                    value={validationType.values.password || ""}
                    invalid={
                      (validationType.touched.password &&
                        validationType.errors.password) ||
                      error?.password?.[0]
                        ? true
                        : false
                    }
                  />
                  {(validationType.touched.password &&
                    validationType.errors.password) ||
                  error?.password?.[0] ? (
                    <FormFeedback type="invalid">
                      {validationType.errors.password || error?.password?.[0]}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label className="form-label">Re-type Password</Label>
                  <PasswordInput
                    name="confirm"
                    placeholder="Password"
                    type="password"
                    onChange={validationType.handleChange}
                    onBlur={validationType.handleBlur}
                    value={validationType.values.confirm || ""}
                    invalid={
                      (validationType.touched.confirm &&
                        validationType.errors.confirm) ||
                      error?.confirm?.[0]
                        ? true
                        : false
                    }
                  />
                  {(validationType.touched.confirm &&
                    validationType.errors.confirm) ||
                  error?.confirm?.[0] ? (
                    <FormFeedback type="invalid">
                      {validationType.errors.confirm || error?.confirm?.[0]}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>

            <Row>
              {AllowInput(UserRole).includes("Province") && (
                <Col md={6}>
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="provinceSelect">
                      Province
                    </Label>

                    {provinces && (
                      <MultipleSelect
                        name="provinces"
                        options={provinces}
                        value={
                          isObject(validationType.values.provinces?.[0])
                            ? validationType.values.provinces
                            : provinces.filter(prov =>
                                validationType.values.provinces.includes(
                                  prov.value
                                )
                              )
                        }
                        onChange={onProvinceChangehandler}
                      />
                    )}

                    {validationType.errors.provinces ||
                    error?.provinces?.[0] ? (
                      <div
                        type="invalid"
                        style={{
                          color: "#f46a6a",
                          fontSize: "80%",
                        }}
                      >
                        {validationType.errors.provinces ||
                          error?.provinces?.[0]}
                      </div>
                    ) : null}
                  </div>
                </Col>
              )}

              {AllowInput(UserRole).includes("ItemCategory") && (
                <Col md={6}>
                  <div className="mb-3">
                    <Label className="form-label">Item Categories</Label>

                    {categories && (
                      <MultipleSelect
                        name="item_categories"
                        options={categories}
                        value={
                          isObject(validationType.values.item_categories?.[0])
                            ? validationType.values.item_categories
                            : categories.filter(prov =>
                                validationType.values.item_categories.includes(
                                  prov.value
                                )
                              )
                        }
                        onChange={onItemCategoryChange}
                      />
                    )}

                    {validationType.errors.item_categories ||
                    error?.item_categories?.[0] ? (
                      <div
                        type="invalid"
                        style={{
                          color: "#f46a6a",
                          fontSize: "80%",
                        }}
                      >
                        {validationType.errors.item_categories ||
                          error?.item_categories?.[0]}
                      </div>
                    ) : null}
                  </div>
                </Col>
              )}
            </Row>

            <Row>
              {AllowInput(UserRole).includes("MainIP") && (
                <Col md={6}>
                  <div className="mb-3">
                    <Label className="form-label">Main IP</Label>

                    {!regLoading && ips && (
                      <SingleSelect
                        loading={regLoading}
                        defaultValue={user?.user?.ip_id}
                        name="ip_id"
                        options={ips}
                        formik={validationType}
                        formMode={formMode}
                      />
                    )}

                    {validationType.errors.ip_id || error?.ip_id?.[0] ? (
                      <div
                        type="invalid"
                        style={{
                          color: "#f46a6a",
                          fontSize: "80%",
                        }}
                      >
                        {validationType.errors.ip_id || error?.ip_id?.[0]}
                      </div>
                    ) : null}
                  </div>
                </Col>
              )}
              {AllowInput(UserRole).includes("Providers") && (
                <Col md={6}>
                  <div className="mb-3">
                    <Label className="form-label">Providers</Label>

                    {providers && (
                      <MultipleSelect
                        value={
                          isObject(validationType.values.providers[0])
                            ? validationType.values.providers
                            : providers.filter(provider =>
                                validationType.values.providers.includes(
                                  provider.value
                                )
                              )
                        }
                        name="providers"
                        options={providers}
                        onChange={onProviderChangeHandler}
                      />
                    )}

                    {validationType.errors.providers ||
                    error?.providers?.[0] ? (
                      <div
                        type="invalid"
                        style={{
                          color: "#f46a6a",
                          fontSize: "80%",
                        }}
                      >
                        {validationType.errors.providers ||
                          error?.providers?.[0]}
                      </div>
                    ) : null}
                  </div>
                </Col>
              )}
            </Row>

            <Row>
              {AllowInput(UserRole).includes("Warehouses") && (
                <Col md={6}>
                  <div className="mb-3">
                    <Label className="form-label">Warehouses</Label>

                    {warehouses && (
                      <MultipleSelect
                        value={
                          isObject(validationType.values.warehouses[0])
                            ? validationType.values.warehouses
                            : warehouses.filter(ware =>
                                validationType.values.warehouses.includes(
                                  ware.value
                                )
                              )
                        }
                        name="warehouses"
                        options={warehouses}
                        onChange={onWarehouseChangeHandler}
                      />
                    )}

                    {validationType.errors.warehouses ||
                    error?.warehouses?.[0] ? (
                      <div
                        type="invalid"
                        style={{
                          color: "#f46a6a",
                          fontSize: "80%",
                        }}
                      >
                        {validationType.errors.warehouses ||
                          error?.warehouses?.[0]}
                      </div>
                    ) : null}
                  </div>
                </Col>
              )}
              {AllowInput(UserRole).includes("Projects") && (
                <Col>
                  <div className="mb-3">
                    <Label className="form-label">Projects</Label>

                    {projects && (
                      <MultipleSelect
                        value={
                          isObject(validationType.values.projects[0])
                            ? validationType.values.projects
                            : projects.filter(proj =>
                                validationType.values.projects.includes(
                                  proj.value
                                )
                              )
                        }
                        name="projects"
                        options={projects}
                        onChange={onProjectChangeHandler}
                      />
                    )}

                    {validationType.errors.projects || error?.projects?.[0] ? (
                      <div
                        type="invalid"
                        style={{
                          color: "#f46a6a",
                          fontSize: "80%",
                        }}
                      >
                        {validationType.errors.projects || error?.projects?.[0]}
                      </div>
                    ) : null}
                  </div>
                </Col>
              )}
            </Row>
            <Row>
              {AllowInput(UserRole).includes("IPs") && (
                <Col md={6}>
                  <div className="mb-3">
                    <Label className="form-label">IPs</Label>

                    {ips && (
                      <MultipleSelect
                        value={
                          isObject(validationType.values.ips[0])
                            ? validationType.values.ips
                            : ips.filter(ip =>
                                validationType.values.ips.includes(ip.value)
                              )
                        }
                        name="ips"
                        options={ips}
                        onChange={onIpChangeHandler}
                      />
                    )}

                    {validationType.errors.ips || error?.ips?.[0] ? (
                      <div
                        type="invalid"
                        style={{
                          color: "#f46a6a",
                          fontSize: "80%",
                        }}
                      >
                        {validationType.errors.ips || error?.ips?.[0]}
                      </div>
                    ) : null}
                  </div>
                </Col>
              )}
            </Row>

            <div className="d-flex flex-wrap gap-2">
              <Button type="submit" color="primary" disabled={loading}>
                {loading
                  ? "Please Wait..."
                  : formMode === "Add"
                  ? "Submit"
                  : "Update"}
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
      {regData?.data && user && (
        <PermissionsComponents
          permissions={regData?.data?.permissions}
          userDirectPermissions={user?.directPermissions}
          onChangePermission={onChangePermission}
          permissionResponse={permissionResponse}
          activePermissions={activePermissions}
        />
      )}
    </React.Fragment>
  )
}

export default UserForm
