import axiosInstance from "api/axiosInstance"
import { monitorReportsAction } from "./monitor-report-slice"
import FileSaver from "file-saver"
import exportAxiosInstance from "api/exportAxiosInstance"

export const getMonitorTransactions =
  (
    url,
    pageNumber = 20,
    according = { order: "desc", name: "date" },
    search = null,
    advancedSearch = {}
  ) =>
  dispatch => {
    dispatch(
      monitorReportsAction.setTransactionReport({
        loading: true,
      })
    )
    url = url || "/v1/get-monitor-transaction-reports"
    axiosInstance
      .get(url, {
        params: {
          pageNumber: pageNumber,
          according: according,
          search: search,
          advancedSearch: advancedSearch,
        },
      })
      .then(response => {
        if (response) {
          dispatch(
            monitorReportsAction.setTransactionReport({
              loading: false,
              error: null,
              data: response?.data,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          monitorReportsAction.setTransactionReport({
            loading: false,
            error: error?.response?.errors,
            data: null,
          })
        )
        console.log(error)
      })
  }

export const getMonitorReceiptReports =
  (
    url,
    pageNumber = 20,
    according = { order: "desc", name: "date" },
    search = null,
    advancedSearch = {}
  ) =>
  dispatch => {
    dispatch(
      monitorReportsAction.setMonitorReceiptReport({
        loading: true,
      })
    )
    url = url || "/v1/get-monitor-receipt-reports"
    axiosInstance
      .get(url, {
        params: {
          pageNumber: pageNumber,
          according: according,
          search: search,
          advancedSearch: advancedSearch,
        },
      })
      .then(response => {
        if (response) {
          dispatch(
            monitorReportsAction.setMonitorReceiptReport({
              loading: false,
              error: null,
              data: response?.data,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          monitorReportsAction.setMonitorReceiptReport({
            loading: false,
            error: error?.response?.errors,
            data: null,
          })
        )
        console.log(error)
      })
  }

export const getMonitorReceiptHealthReports =
  (
    url,
    pageNumber = 20,
    according = { order: "desc", name: "date" },
    search = null,
    advancedSearch = {}
  ) =>
  dispatch => {
    dispatch(
      monitorReportsAction.setMonitorReceiptHealthReport({
        loading: true,
      })
    )
    url = url || "/v1/get-monitor-receipt-health-report"
    axiosInstance
      .get(url, {
        params: {
          pageNumber: pageNumber,
          according: according,
          search: search,
          advancedSearch: advancedSearch,
        },
      })
      .then(response => {
        if (response) {
          dispatch(
            monitorReportsAction.setMonitorReceiptHealthReport({
              loading: false,
              error: null,
              data: response?.data,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          monitorReportsAction.setMonitorReceiptHealthReport({
            loading: false,
            error: error?.response?.errors,
            data: null,
          })
        )
        console.log(error)
      })
  }

export const getMonitorDistributionReports =
  (
    url,
    pageNumber = 20,
    according = { order: "desc", name: "date" },
    search = null,
    advancedSearch = {}
  ) =>
  dispatch => {
    dispatch(
      monitorReportsAction.setMonitorDistributionReport({
        loading: true,
      })
    )
    url = url || "/v1/get-monitor-distribution-reports"
    axiosInstance
      .get(url, {
        params: {
          pageNumber: pageNumber,
          according: according,
          search: search,
          advancedSearch: advancedSearch,
        },
      })
      .then(response => {
        if (response) {
          dispatch(
            monitorReportsAction.setMonitorDistributionReport({
              loading: false,
              error: null,
              data: response?.data,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          monitorReportsAction.setMonitorDistributionReport({
            loading: false,
            error: error?.response?.errors,
            data: null,
          })
        )
        console.log(error)
      })
  }

export const getMonitorProvinceDistributionReports =
  (
    url,
    pageNumber = 20,
    according = { order: "desc", name: "date" },
    search = null,
    advancedSearch = {}
  ) =>
  dispatch => {
    dispatch(
      monitorReportsAction.setMonitorProvicneDistributionReport({
        loading: true,
      })
    )
    url = url || "/v1/get-monitor-province-distributions"
    axiosInstance
      .get(url, {
        params: {
          pageNumber: pageNumber,
          according: according,
          search: search,
          advancedSearch: advancedSearch,
        },
      })
      .then(response => {
        if (response) {
          dispatch(
            monitorReportsAction.setMonitorProvicneDistributionReport({
              loading: false,
              error: null,
              data: response?.data,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          monitorReportsAction.setMonitorProvicneDistributionReport({
            loading: false,
            error: error?.response?.errors,
            data: null,
          })
        )
        console.log(error)
      })
  }

export const getMonitorConsumptionReports =
  (
    url,
    pageNumber = 20,
    according = { order: "desc", name: "date" },
    advancedSearch = {}
  ) =>
  dispatch => {
    dispatch(
      monitorReportsAction.setMonitorConsumptionReport({
        loading: true,
      })
    )
    advancedSearch.according = according
    advancedSearch.pageNumber = pageNumber
    url = url || "/v1/get-monitor-consumptions-report"
    axiosInstance
      .get(url, {
        params: advancedSearch,
      })
      .then(response => {
        if (response) {
          dispatch(
            monitorReportsAction.setMonitorConsumptionReport({
              loading: false,
              error: null,
              data: response?.data,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          monitorReportsAction.setMonitorConsumptionReport({
            loading: false,
            error: error?.response?.data,
            data: null,
          })
        )
      })
  }

export const getMonitorConsumptionReports2 =
  (
    url,
    pageNumber = 20,
    according = { order: "desc", name: "date" },
    advancedSearch = {}
  ) =>
  dispatch => {
    dispatch(
      monitorReportsAction.setMonitorConsumptionReport({
        loading: true,
      })
    )
    advancedSearch.according = according
    advancedSearch.pageNumber = pageNumber
    url = url || "/v1/get-monitor-consumptions-report-two"
    axiosInstance
      .get(url, {
        params: advancedSearch,
      })
      .then(response => {
        if (response) {
          dispatch(
            monitorReportsAction.setMonitorConsumptionReport({
              loading: false,
              error: null,
              data: response?.data,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          monitorReportsAction.setMonitorConsumptionReport({
            loading: false,
            error: error?.response?.data,
            data: null,
          })
        )
      })
  }

export const getMonitorQuantificationReport = params => dispatch => {
  dispatch(
    monitorReportsAction.setMonitorQuantificationReport({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-monitor-quantification-report`, {
      params: params ? params : {},
    })
    .then(respnose => {
      dispatch(
        monitorReportsAction.setMonitorQuantificationReport({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        monitorReportsAction.setMonitorQuantificationReport({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getMonitorQuantificationReportBasedOrganization =
  (url, params = {}) =>
  dispatch => {
    dispatch(
      monitorReportsAction.setMonitorQuantificationReport({
        loading: true,
        error: null,
        data: null,
      })
    )

    url = url || `/v1/get-monitor-quantification-report-baseOrganization`
    axiosInstance
      .get(url, {
        params: params,
      })
      .then(respnose => {
        dispatch(
          monitorReportsAction.setMonitorQuantificationReport({
            loading: false,
            error: null,
            data: respnose?.data,
          })
        )
      })
      .catch(error => {
        dispatch(
          monitorReportsAction.setMonitorQuantificationReport({
            loading: false,
            error: error?.response,
            data: null,
          })
        )
        console.log(error)
      })
  }

export const exportMonitorConsumptionReports2 =
  (according = { order: "desc", name: "date" }, advancedSearch = {}) =>
  dispatch => {
    dispatch(
      monitorReportsAction.setMonitorConsumptionExport({
        loading: true,
        error: null,
      })
    )
    advancedSearch.according = according
    exportAxiosInstance
      .get("/v1/export-monitor-consumptions-report-two", {
        params: advancedSearch,
      })
      .then(response => {
        if (response) {
          const dataBlob = new Blob([response?.data], {
            type: "application/pdf,",
          })
          FileSaver.saveAs(
            dataBlob,
            `Provider-Consumptions-Report-${Math.random().toString()}.xlsx`
          )
          dispatch(
            monitorReportsAction.setMonitorConsumptionExport({
              loading: false,
              error: null,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          monitorReportsAction.setMonitorConsumptionExport({
            loading: false,
            error: error?.response?.data,
          })
        )
      })
  }

export const exportMonitorTransactions =
  (
    pageNumber = 10,
    according = { order: "desc", name: "date" },
    search = null,
    advancedSearch = {}
  ) =>
  dispatch => {
    dispatch(
      monitorReportsAction.setTransactionExport({
        loading: true,
        error: null,
        data: null,
      })
    )
    exportAxiosInstance
      .get("/v1/get-monitor-transaction-export", {
        params: {
          pageNumber: pageNumber,
          according: according,
          search: search,
          advancedSearch: advancedSearch,
        },
      })
      .then(response => {
        if (response) {
          const dataBlob = new Blob([response?.data], {
            type: "application/pdf,",
          })
          FileSaver.saveAs(
            dataBlob,
            `Transaction-${Math.random().toString()}.xlsx`
          )
          dispatch(
            monitorReportsAction.setTransactionExport({
              loading: false,
              error: null,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          monitorReportsAction.setTransactionExport({
            loading: false,
            error: error?.response?.errors,
            data: null,
          })
        )
        console.log(error)
      })
  }

export const exportMonitorReceiptReport =
  (
    pageNumber = 10,
    according = { order: "desc", name: "date" },
    search = null,
    advancedSearch = {}
  ) =>
  dispatch => {
    dispatch(
      monitorReportsAction.setReceiptReportExport({
        loading: true,
        error: null,
      })
    )
    exportAxiosInstance
      .get("/v1/get-monitor-receipt-export", {
        params: {
          pageNumber: pageNumber,
          according: according,
          search: search,
          advancedSearch: advancedSearch,
        },
      })
      .then(response => {
        if (response) {
          const dataBlob = new Blob([response?.data], {
            type: "application/pdf,",
          })
          FileSaver.saveAs(
            dataBlob,
            `Receipt-Report-${Math.random().toString()}.xlsx`
          )
          dispatch(
            monitorReportsAction.setReceiptReportExport({
              loading: false,
              error: null,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          monitorReportsAction.setReceiptReportExport({
            loading: false,
            error: error?.response?.errors,
          })
        )
        console.log(error)
      })
  }

export const exportMonitorDistributionReport =
  (
    pageNumber = 10,
    according = { order: "desc", name: "date" },
    search = null,
    advancedSearch = {}
  ) =>
  dispatch => {
    dispatch(
      monitorReportsAction.setDistributionReportExport({
        loading: true,
        error: null,
      })
    )
    exportAxiosInstance
      .get("/v1/get-monitor-distribution-export", {
        params: {
          pageNumber: pageNumber,
          according: according,
          search: search,
          advancedSearch: advancedSearch,
        },
      })
      .then(response => {
        if (response) {
          const dataBlob = new Blob([response?.data], {
            type: "application/pdf,",
          })
          FileSaver.saveAs(
            dataBlob,
            `Distribution-Report-${Math.random().toString()}.xlsx`
          )
          dispatch(
            monitorReportsAction.setDistributionReportExport({
              loading: false,
              error: null,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          monitorReportsAction.setDistributionReportExport({
            loading: false,
            error: error?.response?.errors,
          })
        )
        console.log(error)
      })
  }

export const exportMonitorProvinceDistributionReport =
  (
    pageNumber = 10,
    according = { order: "desc", name: "date" },
    search = null,
    advancedSearch = {}
  ) =>
  dispatch => {
    dispatch(
      monitorReportsAction.setProvinceDistributionReportExport({
        loading: true,
        error: null,
      })
    )
    exportAxiosInstance
      .get("/v1/get-monitor-province-distributions-export", {
        params: {
          pageNumber: pageNumber,
          according: according,
          search: search,
          advancedSearch: advancedSearch,
        },
      })
      .then(response => {
        if (response) {
          const dataBlob = new Blob([response?.data], {
            type: "application/pdf,",
          })
          FileSaver.saveAs(
            dataBlob,
            `IP-Distribution-Report-${Math.random().toString()}.xlsx`
          )
          dispatch(
            monitorReportsAction.setProvinceDistributionReportExport({
              loading: false,
              error: null,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          monitorReportsAction.setProvinceDistributionReportExport({
            loading: false,
            error: error?.response?.errors,
          })
        )
        console.log(error)
      })
  }

export const exportMonitorInventoryReport = params => dispatch => {
  dispatch(
    monitorReportsAction.setMonitorInventoyExport({
      loading: true,
      error: null,
    })
  )
  exportAxiosInstance
    .get("/v1/get-monitor-inventory-export", {
      params: params ? params : {},
    })
    .then(response => {
      if (response) {
        const dataBlob = new Blob([response?.data], {
          type: "application/pdf,",
        })
        FileSaver.saveAs(
          dataBlob,
          `Monitor-Inventory-Report-${Math.random().toString()}.xlsx`
        )
        dispatch(
          monitorReportsAction.setMonitorInventoyExport({
            loading: false,
            error: null,
          })
        )
      }
    })
    .catch(error => {
      dispatch(
        monitorReportsAction.setMonitorInventoyExport({
          loading: false,
          error: error?.response?.errors,
        })
      )
      console.log(error)
    })
}

export const exportMonitorConsumptionReports =
  (according = { order: "desc", name: "date" }, advancedSearch = {}) =>
  dispatch => {
    dispatch(
      monitorReportsAction.setMonitorConsumptionExport({
        loading: true,
        error: null,
      })
    )
    advancedSearch.according = according
    exportAxiosInstance
      .get("/v1/export-monitor-consumptions-report", {
        params: advancedSearch,
      })
      .then(response => {
        if (response) {
          const dataBlob = new Blob([response?.data], {
            type: "application/pdf,",
          })
          FileSaver.saveAs(
            dataBlob,
            `Provider-Consumptions-Report-${Math.random().toString()}.xlsx`
          )
          dispatch(
            monitorReportsAction.setMonitorConsumptionExport({
              loading: false,
              error: null,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          monitorReportsAction.setMonitorConsumptionExport({
            loading: false,
            error: error?.response?.data,
          })
        )
      })
  }

export const exportMonitorReceiptHealthReport =
  (
    pageNumber = 10,
    according = { order: "desc", name: "date" },
    search = null,
    advancedSearch = {}
  ) =>
  dispatch => {
    dispatch(
      monitorReportsAction.setMonitorReceiptHealthExport({
        loading: true,
        error: null,
      })
    )
    exportAxiosInstance
      .get("/v1/export-monitor-receipt-health-report", {
        params: {
          pageNumber: pageNumber,
          according: according,
          search: search,
          advancedSearch: advancedSearch,
        },
      })
      .then(response => {
        if (response) {
          const dataBlob = new Blob([response?.data], {
            type: "application/pdf,",
          })
          FileSaver.saveAs(
            dataBlob,
            `Receipt-Health-Report-${Math.random().toString()}.xlsx`
          )
          dispatch(
            monitorReportsAction.setMonitorReceiptHealthExport({
              loading: false,
              error: null,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          monitorReportsAction.setMonitorReceiptHealthExport({
            loading: false,
            error: error?.response?.errors,
          })
        )
        console.log(error)
      })
  }

export const exportMonitorQuantificationReport =
  (params = {}) =>
  dispatch => {
    dispatch(
      monitorReportsAction.setMonitorQuantificationExport({
        loading: true,
        error: null,
      })
    )
    exportAxiosInstance
      .get("/v1/export-monitor-quantification-report", {
        params: params,
      })
      .then(response => {
        if (response) {
          const dataBlob = new Blob([response?.data], {
            type: "application/pdf,",
          })
          FileSaver.saveAs(
            dataBlob,
            `Provider-Quantification-report-${Math.random().toString()}.xlsx`
          )
          dispatch(
            monitorReportsAction.setMonitorQuantificationExport({
              loading: false,
              error: null,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          monitorReportsAction.setMonitorQuantificationExport({
            loading: false,
            error: error?.response?.errors,
          })
        )
        console.log(error)
      })
  }

export const exportMonitorQuantificationReportBasedOrganization =
  (params = {}) =>
  dispatch => {
    dispatch(
      monitorReportsAction.setMonitorQuantificationExport({
        loading: true,
        error: null,
      })
    )
    exportAxiosInstance
      .get("/v1/export-monitor-quantification-report-baseOrganization", {
        params: params,
      })
      .then(response => {
        if (response) {
          const dataBlob = new Blob([response?.data], {
            type: "application/pdf,",
          })
          FileSaver.saveAs(
            dataBlob,
            `Monitor-Quantification-based-organization-report-${Math.random().toString()}.xlsx`
          )
          dispatch(
            monitorReportsAction.setMonitorQuantificationExport({
              loading: false,
              error: null,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          monitorReportsAction.setMonitorQuantificationExport({
            loading: false,
            error: error?.response?.errors,
          })
        )
        console.log(error)
      })
  }
