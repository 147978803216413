import React from "react"
import moment from "moment"

const ProviderConsumptionHeaderTitle = ({ error }) => {
  return (
    <div style={{ textAlign: "center", fontSize: 17, color: "#888" }}>
      {error?.dateError && <p className="text-danger">{error?.dateError}</p>}
    </div>
  )
}

export default ProviderConsumptionHeaderTitle
