import React, { useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Col, Form, Label, Row } from "reactstrap"
import Select from "react-select"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import moment from "moment"
import {
  getProviderFacilities,
  getProviderItems,
} from "store/provider-dashboard/provider-dashboard-action"
import { providerDashboardAction } from "store/provider-dashboard/provider-dashboard-slice"
import { isObject } from "lodash"
import {
  DISTRIBUTEDDETAIL,
  DISTRIBUTIONTRANSACTIONTYPE,
  SHOWBASEDIPS,
} from "constants/data"
import { providerOutAction } from "store/provider-out/provider-out-slice"
import { getProviderNonIps } from "store/provider-out/provider-out-action"

const ProviderOutFilter = ({ onAdvancedSearchSubmit, loading }) => {
  const distributionFlatpickerfrom = useRef(null)
  const distributionFlatpickerto = useRef(null)
  const [category, setCategory] = useState(null)
  const [province, setProvince] = useState(null)
  const [facility, setFacility] = useState(null)
  const [item, setItem] = useState(null)
  const [ip, setIP] = useState(null)
  const [nonIp, setNonIp] = useState(null)
  const [project, setProject] = useState(null)
  const [transactionType, setTransactionType] = useState(null)
  const [showBasedIp, setShowBasedIp] = useState(null)
  const [warehouse_disribute, setWarehouseDistribute] = useState(null)
  const [expireDate, setExpireDate] = useState({
    from: null,
    to: null,
  })
  const [distributionDate, setDistributionDate] = useState({
    from: null,
    to: null,
  })
  const items = useSelector(state => state.providerDashboard.ProviderItems)
  const categories = useSelector(
    state => state.providerDashboard.ProviderItemCategoires
  )

  const provinces = useSelector(
    state => state.providerDashboard.providerProvinces
  )

  const projects = useSelector(
    state => state.providerDashboard.providerProjects
  )

  const ips = useSelector(state => state.providerDashboard.ProviderIps)
  const nonIps = useSelector(state => state.providerOut.NonIps)

  const facilities = useSelector(
    state => state.providerDashboard.providerFacilities
  )

  const filteredItems = useMemo(
    () =>
      items?.data.map(item => ({
        value: item.id,
        label: `${item.unfp_item_name} ( ${item.item_name} )`,
      })),
    [items]
  )

  const dispatch = useDispatch()

  const handleSubmit = () => {
    let data = {}
    if (item) {
      data.item = item.map(ite => (ite.value ? ite.value : ite))
    }

    if (nonIp) {
      data.nonIps = nonIp.map(non => (non.value ? non.value : non))
    }

    data.project = isObject(project) ? project.value : null
    data.transactionType = isObject(transactionType)
      ? transactionType.value
      : null
    data.showBasedIp = isObject(showBasedIp) ? showBasedIp.value : null
    data.category = isObject(category) ? category.value : null
    data.ip = isObject(ip) ? ip.value : null
    data.province = isObject(province) ? province.value : null
    data.facility = isObject(facility) ? facility.value : null
    data.warehouse_disribute = isObject(warehouse_disribute)
      ? warehouse_disribute.value
      : null
    data.distributionDate = {
      from: distributionDate.from ? distributionDate.from : null,
      to: distributionDate.to ? distributionDate.to : null,
    }
    onAdvancedSearchSubmit(data)
  }

  const onCategoryChangeHandler = cate => {
    setCategory(cate)
    dispatch(getProviderItems(cate.value))
  }

  const onItemChangeHandler = item => {
    const check = item.some(ite => ite.value === "all")
    if (check) {
      setItem(filteredItems)
    } else {
      setItem(item)
    }
  }

  const onProvinceChangeHandler = value => {
    setProvince(value)
    setFacility("")
    dispatch(getProviderFacilities(value.value))
  }

  const onFacilityChangeHandler = value => {
    setFacility(value)
  }

  const onIPChangeHandler = value => {
    setIP(value)
    setNonIp(null)
    dispatch(getProviderNonIps(value.value))
  }

  const onNonIpChangeHandler = value => {
    const check = value.some(ite => ite.value === "all")
    if (check) {
      setNonIp(nonIps?.data)
    } else {
      setNonIp(value)
    }
  }

  const onProjectChangeHandler = value => {
    setProject(value)
  }

  const onTransactionTypeHandler = value => {
    setTransactionType(value)
  }
  const onShowbasedIpchangehandler = value => {
    setShowBasedIp(value)
  }

  const onWarehouseDistributeChangeHandler = value => {
    setWarehouseDistribute(value)
  }

  const onClearHandler = () => {
    distributionFlatpickerfrom.current.flatpickr.clear()
    distributionFlatpickerto.current.flatpickr.clear()
    setCategory(null)
    setItem(null)
    setProvince(null)
    setFacility(null)
    setIP(null)
    setDistributionDate({ from: null, to: null })
    setProject(null)
    onAdvancedSearchSubmit({})
    setWarehouseDistribute(null)
    dispatch(providerDashboardAction.resetProviderItems())
    dispatch(providerDashboardAction.resetProviderFacilities())
    setNonIp(null)
    dispatch(providerOutAction.resetNoneIps())
    setShowBasedIp(null)
    setTransactionType(null)
  }

  return (
    <Form
      onSubmit={e => {
        e.preventDefault()
        handleSubmit()
        return false
      }}
    >
      <div className="mb-3">
        <Row>
          <Col>
            <Label className="form-label">IPs</Label>
            {ips?.data && (
              <Select
                options={ips?.data}
                value={ip}
                onChange={onIPChangeHandler}
              />
            )}
          </Col>
          <Col>
            <Label className="form-label">Non IPs</Label>
            {nonIps?.data && (
              <Select
                isMulti={true}
                options={[{ value: "all", label: "All" }, ...nonIps?.data]}
                value={nonIp}
                onChange={onNonIpChangeHandler}
              />
            )}
          </Col>
        </Row>
      </div>

      <div className="mb-3 ">
        <Row>
          <Col>
            <Label className="form-label">Warehouse Distribute</Label>
            <Select
              options={[{ value: "all", label: "All" }, ...DISTRIBUTEDDETAIL]}
              value={warehouse_disribute}
              onChange={onWarehouseDistributeChangeHandler}
            />
          </Col>
          <Col>
            <Label className="form-label">Projects</Label>
            {projects?.data && (
              <Select
                options={projects?.data}
                value={project}
                onChange={onProjectChangeHandler}
              />
            )}
          </Col>
        </Row>
      </div>

      <div className="mb-3">
        <Row>
          <Col>
            <Label className="form-label">Province</Label>
            {provinces?.data && (
              <Select
                options={provinces?.data}
                value={province}
                onChange={onProvinceChangeHandler}
              />
            )}
          </Col>
          <Col>
            <Label className="form-label">Facilities</Label>
            {facilities?.data && (
              <Select
                options={facilities?.data}
                value={facility}
                onChange={onFacilityChangeHandler}
              />
            )}
          </Col>
        </Row>
      </div>

      <div className="mb-3">
        <Row>
          <Col>
            <Label className="form-label">Item Categories</Label>
            {categories?.data && (
              <Select
                options={categories?.data}
                value={category || categories?.data[0]}
                onChange={onCategoryChangeHandler}
              />
            )}
          </Col>
          <Col>
            <Label className="form-label">Items</Label>
            {filteredItems && (
              <Select
                isMulti={true}
                options={[{ value: "all", label: "All" }, ...filteredItems]}
                value={item}
                onChange={onItemChangeHandler}
              />
            )}
          </Col>
        </Row>
      </div>

      <div className="mb-3">
        <Row>
          <Col>
            <Label className="form-label">Transaction Type</Label>
            <Select
              options={DISTRIBUTIONTRANSACTIONTYPE}
              value={transactionType}
              onChange={onTransactionTypeHandler}
            />
          </Col>
          <Col>
            <Label className="form-label">Show Based IPs</Label>
            <Select
              options={SHOWBASEDIPS}
              value={showBasedIp}
              onChange={onShowbasedIpchangehandler}
            />
          </Col>
        </Row>
      </div>

      <div className="mb-3 ">
        <Row>
          <Col>
            <Label className="form-label">Distribution From Date</Label>
            <Flatpickr
              ref={distributionFlatpickerfrom}
              className="form-control d-block"
              placeholder="dd M,yyyy"
              options={{
                altInput: true,
                altFormat: "Y-m-d",
                dateFormat: "Y-m-d",
              }}
              onChange={value => {
                setDistributionDate(prev => {
                  return {
                    ...prev,
                    from: moment(value[0]).format("YYYY-MM-DD"),
                  }
                })
              }}
            />
          </Col>
          <Col>
            <Label className="form-label">Distribution To Date</Label>
            <Flatpickr
              ref={distributionFlatpickerto}
              className="form-control d-block"
              placeholder="dd M,yyyy"
              options={{
                altInput: true,
                altFormat: "Y-m-d",
                dateFormat: "Y-m-d",
              }}
              onChange={value => {
                setDistributionDate(prev => {
                  return { ...prev, to: moment(value[0]).format("YYYY-MM-DD") }
                })
              }}
            />
          </Col>
        </Row>
      </div>

      <div className="d-flex flex-wrap gap-2 mb-4">
        <Button
          type="submit"
          className="btn btn-sm btn-primary"
          color="primary"
          disabled={loading}
        >
          {loading ? "Please Wait..." : "Search"}
        </Button>
        <Button
          onClick={onClearHandler}
          className="btn btn-sm btn-danger"
          color="primary"
        >
          Clear
        </Button>
      </div>
    </Form>
  )
}

export default ProviderOutFilter
