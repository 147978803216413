import axiosInstance from "api/axiosInstance"
import { monitorDistributionAction } from "./monitor-distribution-slice"
import { batch } from "react-redux"
import fileaxiosInstance from "api/fileAxiosInstance"

export const getMonitorDistributions =
  (
    url,
    pageNumber = 10,
    according = { order: "desc", name: "id" },
    search = null,
    advancedSearch = {}
  ) =>
  dispatch => {
    dispatch(monitorDistributionAction.setLoading(true))
    url = url || "/v1/get-provider-distributions"
    axiosInstance
      .get(url, {
        params: {
          pageNumber: pageNumber,
          according: according,
          search: search,
          advancedSearch: advancedSearch,
        },
      })
      .then(response => {
        if (response) {
          batch(() => {
            dispatch(monitorDistributionAction.setError(null))
            dispatch(monitorDistributionAction.setData(response.data))
            dispatch(monitorDistributionAction.setLoading(false))
          })
        }
      })
      .catch(error => {
        dispatch(monitorDistributionAction.setLoading(false))
        console.log(error)
      })
  }

export const getMonitorNonIps = ip_id => dispatch => {
  dispatch(
    monitorDistributionAction.setNoneIps({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-monitor-out-non-ips/${ip_id}`)
    .then(respnose => {
      dispatch(
        monitorDistributionAction.setNoneIps({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        monitorDistributionAction.setNoneIps({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}
