import UserForm from "components/User/form"
import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { getAllUserRegistrationData } from "store/general-renders/general-action"
import { regDataSelector } from "store/selectors"
import { storeUser } from "store/user/user-action"

const AddUser = () => {
  document.title = "Add User"

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { loading, error, data } = useSelector(state => state.user)

  const { regData, regLoading, regError } = useSelector(regDataSelector)

  const handleSubmit = values => {
    values.providers = values.providers.map(pr => (pr.value ? pr.value : pr))
    values.warehouses = values.warehouses.map(wr => (wr.value ? wr.value : wr))
    values.projects = values.projects.map(pr => (pr.value ? pr.value : pr))
    values.provinces = values.provinces.map(province =>
      province.value ? province.value : province
    )
    values.ips = values.ips.map(ip => (ip.value ? ip.value : ip))
    values.item_categories = values.item_categories.map(category =>
      category.value ? category.value : category
    )
    dispatch(storeUser(values, navigate))
  }

  useEffect(() => {
    dispatch(getAllUserRegistrationData())
  }, [])

  return (
    <React.Fragment>
      {regData?.data && (
        <UserForm
          onSubmit={handleSubmit}
          loading={loading}
          error={error}
          formMode={"Add"}
          regData={regData}
          regLoading={regLoading}
          regError={regError}
        />
      )}
    </React.Fragment>
  )
}

export default AddUser
