import axiosInstance from "api/axiosInstance"
import { providerExpireReceiptsAction } from "./p-expire-receipts-slice"
import { batch } from "react-redux"
import exportAxiosInstance from "api/exportAxiosInstance"
import FileSaver from "file-saver"

export const getExpireReceipts =
  (
    url,
    pageNumber = 10,
    according = { order: "desc", name: "id" },
    search = null,
    advancedSearch = {},
    based
  ) =>
  dispatch => {
    dispatch(providerExpireReceiptsAction.setLoading(true))
    url = url || "/v1/get-provider-expire-receipts"
    axiosInstance
      .get(url, {
        params: {
          pageNumber: pageNumber,
          according: according,
          search: search,
          advancedSearch: advancedSearch,
          based: based,
        },
      })
      .then(response => {
        if (response) {
          batch(() => {
            dispatch(providerExpireReceiptsAction.setError(null))
            dispatch(providerExpireReceiptsAction.setData(response.data))
            dispatch(providerExpireReceiptsAction.setLoading(false))
          })
        }
      })
      .catch(error => {
        dispatch(providerExpireReceiptsAction.setLoading(false))
        console.log(error)
      })
  }

export const getTotalExpireReceiptsCount = () => dispatch => {
  dispatch(providerExpireReceiptsAction.setCount(0))
  axiosInstance
    .get("/v1/get-provider-expire-receipts-count")
    .then(response => {
      if (response) {
        batch(() => {
          dispatch(providerExpireReceiptsAction.setCount(response?.data))
        })
      }
    })
    .catch(error => {
      dispatch(providerExpireReceiptsAction.setCount(0))
      console.log(error)
    })
}

export const exportProviderExpireReceipts =
  (
    pageNumber = 10,
    according = { order: "desc", name: "date" },
    search = null,
    advancedSearch = {},
    based
  ) =>
  dispatch => {
    dispatch(
      providerExpireReceiptsAction.setProviderExpireExport({
        loading: true,
        error: null,
      })
    )

    exportAxiosInstance
      .get("/v1/export-provider-expire-receipts", {
        params: {
          pageNumber: pageNumber,
          according: according,
          search: search,
          advancedSearch: advancedSearch,
          based: based,
        },
      })
      .then(response => {
        if (response) {
          const dataBlob = new Blob([response?.data], {
            type: "application/pdf,",
          })
          FileSaver.saveAs(
            dataBlob,
            `Expire-Receipt-Report-${Math.random().toString()}.xlsx`
          )
          dispatch(
            providerExpireReceiptsAction.setProviderExpireExport({
              loading: false,
              error: null,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          providerExpireReceiptsAction.setProviderExpireExport({
            loading: false,
            error: error?.response?.errors,
          })
        )
        console.log(error)
      })
  }
