import React, { useMemo } from "react"
import { useDispatch } from "react-redux"

const useSearch = (func, search) => {
  const dispatch = useDispatch()

  useMemo(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search != null) {
        dispatch(func)
      }
    }, 1500)
    return () => clearTimeout(delayDebounceFn)
  }, [search])
}

export default useSearch
