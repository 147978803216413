import { useFormik } from "formik"
import * as Yup from "yup"
import React from "react"
import {
  Card,
  CardBody,
  Label,
  Button,
  Form,
  Input,
  FormFeedback,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import CustomHeaderTitle from "components/Common/CustomHeaderTitle"

const ProvinceForm = ({ onSubmit, loading, error, formMode, province }) => {
  const validationType = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      province: province ? province?.province : "",
      region: province ? province?.region : "",
    },
    validationSchema: Yup.object().shape({
      province: Yup.string().required("This name is required").min(4),
      region: Yup.string().required("This name is required").min(4),
    }),
    onSubmit: values => {
      onSubmit(values)
    },
  })

  return (
    <Card>
      <CardBody>
        <CustomHeaderTitle
          loading={loading}
          title={formMode === "Edit" ? "Edit Provider" : "Add Provider"}
          lgColumn={3}
          rowCount={false}
          orderingList={false}
          searchBox={false}
          rightBtn={true}
          rightBtnTitle={"Back"}
          rightBtnRoute={"/provinces"}
        />

        <Form
          onSubmit={e => {
            e.preventDefault()
            validationType.handleSubmit()
            return false
          }}
        >
          <div className="mb-3">
            <Label className="form-label">Province</Label>
            <Input
              name="province"
              defaultValue={province?.province}
              placeholder="Province"
              type="text"
              onChange={validationType.handleChange}
              onBlur={validationType.handleBlur}
              invalid={
                (validationType.touched.province &&
                  validationType.errors.province) ||
                error?.province?.[0]
                  ? true
                  : false
              }
            />
            {(validationType.touched.province &&
              validationType.errors.province) ||
            error?.province?.[0] ? (
              <FormFeedback type="invalid">
                {validationType.errors.province || error?.province?.[0]}
              </FormFeedback>
            ) : null}
          </div>

          <div className="mb-3">
            <Label className="form-label">Region</Label>
            <Input
              name="region"
              placeholder="Region"
              type="text"
              onChange={validationType.handleChange}
              onBlur={validationType.handleBlur}
              value={validationType.values.region || ""}
              invalid={
                (validationType.touched.region &&
                  validationType.errors.region) ||
                error?.region?.[0]
                  ? true
                  : false
              }
            />
            {(validationType.touched.region && validationType.errors.region) ||
            error?.region?.[0] ? (
              <FormFeedback type="invalid">
                {validationType.errors.region || error?.region?.[0]}
              </FormFeedback>
            ) : null}
          </div>

          <div className="d-flex flex-wrap gap-2">
            <Button type="submit" color="primary" disabled={loading}>
              {loading
                ? "Please Wait..."
                : formMode === "Add"
                ? "Submit"
                : "Update"}
            </Button>
          </div>
        </Form>
      </CardBody>
    </Card>
  )
}

export default ProvinceForm
