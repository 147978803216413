import React, { useRef, useState } from "react"
import { Button, Col, Form, Label, Row } from "reactstrap"

import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import moment from "moment"
import { MONTHS } from "constants/data"
import { isObject } from "lodash"

import Select from "react-select"

const MonitorStockOutFilter = ({ onAdvancedSearchSubmit, loading }) => {
  const [month, setMonth] = useState("")
  const handleSubmit = () => {
    let data = {}
    data.month = isObject(month) ? month.value : 6
    onAdvancedSearchSubmit(data)
  }

  const onClearHandler = () => {
    setMonth("")
    onAdvancedSearchSubmit({})
  }

  const onMonthChangeHanlder = value => {
    setMonth(value)
  }

  return (
    <Form
      onSubmit={e => {
        e.preventDefault()
        handleSubmit()
        return false
      }}
    >
      <div className="mb-3 ">
        <Row>
          <Col>
            <Label className="form-label">Months</Label>
            {MONTHS && (
              <Select
                options={MONTHS}
                value={month}
                onChange={onMonthChangeHanlder}
              />
            )}
          </Col>
        </Row>
      </div>

      <div className="d-flex flex-wrap gap-2 mb-4">
        <Button
          type="submit"
          className="btn btn-sm btn-primary"
          color="primary"
          disabled={loading}
        >
          {loading ? "Please Wait..." : "Search"}
        </Button>
        <Button
          onClick={onClearHandler}
          className="btn btn-sm btn-danger"
          color="primary"
        >
          Clear
        </Button>
      </div>
    </Form>
  )
}

export default MonitorStockOutFilter
