import axiosInstance from "api/axiosInstance"
import { authAction } from "./auth-slice"
import SAlert from "components/Alert"

export const login = value => dispatch => onSuccess => {
  dispatch(authAction.setLoading(true))
  axiosInstance
    .post("/v1/login", value)
    .then(response => {
      const user = response?.data?.data?.user
      const token = JSON.stringify(response?.data?.data?.token)
      const role = response?.data?.data?.role
      const permissions = response?.data?.data?.permissions
      localStorage.setItem("token", token)
      dispatch(authAction.removeError())
      // dispatch(authAction.setToken(token))
      dispatch(authAction.loggedIn({ user, token, role, permissions }))
      dispatch(authAction.setLoading(false))
      onSuccess()
    })
    .catch(error => {
      console.log(error)
      dispatch(authAction.setLoading(false))
      dispatch(authAction.setError(error.response.data))
    })
}

export const changeUserPassword = value => dispatch => {
  dispatch(
    authAction.setChangePassword({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .post(`/v1/change-password`, value)
    .then(respnose => {
      SAlert({
        title: "Success",
        text: "Password successfully changed",
      })

      dispatch(
        authAction.setChangePassword({
          loading: false,
          error: null,
          data: true,
        })
      )
    })
    .catch(error => {
      dispatch(
        authAction.setChangePassword({
          loading: false,
          error: error?.response?.data.errors,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getLoginHistories = value => dispatch => {
  dispatch(
    authAction.setLoginHistories({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/login-history`)
    .then(respnose => {
      dispatch(
        authAction.setLoginHistories({
          loading: false,
          error: null,
          data: respnose?.data?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        authAction.setLoginHistories({
          loading: false,
          error: error?.response?.data.errors,
          data: null,
        })
      )
      console.log(error)
    })
}

export const changeUserProfile = value => dispatch => {
  dispatch(authAction.setLoading(true))
  axiosInstance
    .post(`/v1/update-profile`, value)
    .then(respnose => {
      SAlert({
        title: "Success",
        text: "Profile Updated",
      })
      dispatch(authAction.setUser(respnose?.data?.data))
      dispatch(authAction.setLoading(false))
    })
    .catch(error => {
      dispatch(
        authAction.setChangePassword({
          loading: false,
          error: error?.response?.data.errors,
          data: null,
        })
      )
      console.log(error)
    })
}

export const forgotPassword = params => dispatch => {
  dispatch(
    authAction.setForgotPassword({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .post(`/v1/forgot-password`, params)
    .then(respnose => {
      dispatch(
        authAction.setForgotPassword({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        authAction.setForgotPassword({
          loading: false,
          error: error?.response?.data?.message,
          data: null,
        })
      )
    })
}

export const checkResetPassword = token => dispatch => {
  dispatch(
    authAction.setCheckResetPassword({
      loading: true,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/check-reset-password/${token}`)
    .then(respnose => {
      dispatch(
        authAction.setCheckResetPassword({
          loading: false,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      console.log(error?.data)
      dispatch(
        authAction.setCheckResetPassword({
          loading: false,
          data: null,
        })
      )
    })
}

export const resetPassword = params => dispatch => onSuccess => {
  dispatch(
    authAction.setResetPassword({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .post(`/v1/reset-password`, params)
    .then(respnose => {
      dispatch(
        authAction.setResetPassword({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
      onSuccess()
    })
    .catch(error => {
      console.log(error?.response?.data.errors)
      dispatch(
        authAction.setResetPassword({
          loading: false,
          error: error?.response?.data.errors,
          data: null,
        })
      )
    })
}
