import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: [],
  loading: false,
  error: null,
  count: 0,
  exportStatus: { loading: false, error: null },
}

const provinceExpireReceiptsSlice = createSlice({
  name: "provinceExpireReceipts",
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    setError(state, action) {
      state.error = action.payload
    },
    setProject(state, action) {
      state.project = action.payload
    },
    setCount(state, action) {
      state.count = action.payload
    },
    setExportStatus(state, action) {
      state.exportStatus = {
        loading: action.payload.loading,
        error: action.payload.error,
      }
    },
    resetState(state) {
      state.data = []
      state.loading = false
      state.error = null
      state.project = null
    },
    resetExportStatus(state) {
      state.exportStatus = { loading: false, error: null }
    },
  },
})

export const provinceExpireReceiptsAction = provinceExpireReceiptsSlice.actions

export default provinceExpireReceiptsSlice
