import axiosInstance from "api/axiosInstance"
import SAlert from "components/Alert"
import { batch } from "react-redux"
import { providerStockoutAction } from "./provider-stockout-slice"
import exportAxiosInstance from "api/exportAxiosInstance"
import FileSaver from "file-saver"

export const getProviderStockOut =
  (params = {}) =>
  dispatch => {
    dispatch(providerStockoutAction.setLoading(true))
    axiosInstance
      .get("/v1/get-provider-stock-out", {
        params: params,
      })
      .then(response => {
        if (response) {
          batch(() => {
            dispatch(providerStockoutAction.setError(null))
            dispatch(providerStockoutAction.setData(response.data))
            dispatch(providerStockoutAction.setLoading(false))
          })
        }
      })
      .catch(error => {
        dispatch(providerStockoutAction.setLoading(false))
        console.log(error)
      })
  }

export const exportProviderStockOut =
  (params = {}) =>
  dispatch => {
    dispatch(
      providerStockoutAction.setExportStatus({
        loading: true,
        error: null,
      })
    )
    exportAxiosInstance
      .get("/v1/export-provider-stock-out", {
        params: params,
      })
      .then(response => {
        if (response) {
          const dataBlob = new Blob([response?.data], {
            type: "application/pdf,",
          })
          FileSaver.saveAs(
            dataBlob,
            `Provider-Stockout-Report-${Math.random().toString()}.xlsx`
          )
          dispatch(
            providerStockoutAction.setExportStatus({
              loading: false,
              error: null,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          providerStockoutAction.setExportStatus({
            loading: false,
            error: error?.response?.data,
          })
        )
      })
  }

export const getProviderStockOutCount = () => dispatch => {
  axiosInstance
    .get("/v1/get-provider-stock-out-count")
    .then(response => {
      if (response) {
        dispatch(providerStockoutAction.setCount(response?.data))
      }
    })
    .catch(error => {
      dispatch(providerStockoutAction.setCount(0))
      console.log(error)
    })
}
