import ProjectForm from "components/Projects/form"
import WarehouseForm from "components/Warehouse/form"
import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { storeProject } from "store/project/project-action"
import { storeWarehouse } from "store/warehouse/warehouse-action"

const AddProject = () => {
  document.title = "Add Project"

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { loading, error, data } = useSelector(state => state.project)

  const handleSubmit = values => {
    dispatch(storeProject(values, navigate))
  }

  return (
    <ProjectForm
      onSubmit={handleSubmit}
      loading={loading}
      error={error}
      formMode={"Add"}
    />
  )
}

export default AddProject
