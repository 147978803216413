import axiosInstance from "api/axiosInstance"
import { batch } from "react-redux"
import { itemAction } from "./item-slice"
import SAlert from "components/Alert"
import exportAxiosInstance from "api/exportAxiosInstance"
import FileSaver from "file-saver"

export const getItems =
  (
    url,
    pageNumber = 10,
    according = { order: "desc", name: "id" },
    search = null,
    filter = null
  ) =>
  dispatch => {
    dispatch(itemAction.setLoading(true))
    url = url || "/v1/items"
    axiosInstance
      .get(url, {
        params: {
          pageNumber: pageNumber,
          according: according,
          search: search,
          filter: filter,
        },
      })
      .then(response => {
        if (response) {
          batch(() => {
            dispatch(itemAction.setError(null))
            dispatch(itemAction.setData(response.data))
            dispatch(itemAction.setLoading(false))
          })
        }
      })
      .catch(error => {
        dispatch(itemAction.setLoading(false))
        console.log(error)
      })
  }

export const deleteItem = id => dispatch => {
  dispatch(itemAction.setLoading(true))
  axiosInstance
    .delete(`/v1/items/${id}`)
    .then(response => {
      SAlert({ title: "Success", text: "Item successfully deleted" })
      batch(() => {
        dispatch(getItems())
        dispatch(itemAction.setError(null))
        dispatch(itemAction.setLoading(false))
      })
    })
    .catch(error => {
      dispatch(itemAction.setLoading(false))
      console.log(error)
    })
}

export const storeItem = (data, navigate) => dispatch => {
  dispatch(itemAction.setLoading(true))
  axiosInstance
    .post("/v1/items", data)
    .then(response => {
      navigate("/items")
      SAlert({ title: "Success", text: "Item successfully inserted" })
      batch(() => {
        dispatch(itemAction.setError(null))
        dispatch(itemAction.setLoading(false))
      })
    })
    .catch(err => {
      dispatch(itemAction.setLoading(false))
      dispatch(itemAction.setError(err?.response?.data?.errors))
    })
}

export const updateItem = (id, data, navigate) => dispatch => {
  dispatch(itemAction.setLoading(true))
  axiosInstance
    .put(`/v1/items/${id}`, data)
    .then(respnose => {
      navigate("/items")
      SAlert({ title: "Success", text: "Item successfully updated" })
      batch(() => {
        dispatch(itemAction.setError(null))
        dispatch(itemAction.setLoading(false))
      })
    })
    .catch(error => {
      dispatch(itemAction.setLoading(false))
      dispatch(itemAction.setError(error?.response?.data?.errors))
    })
}

export const getByIdItem = id => dispatch => {
  dispatch(itemAction.setLoading(true))
  axiosInstance
    .get(`/v1/items/${id}`)
    .then(respnose => {
      batch(() => {
        dispatch(itemAction.setError(null))
        dispatch(itemAction.setLoading(false))
        dispatch(itemAction.setItem(respnose.data))
      })
    })
    .catch(error => {
      dispatch(itemAction.setLoading(false))
      console.log(error)
    })
}

export const exportItems =
  (search, filter = null) =>
  dispatch => {
    dispatch(
      itemAction.setExportStatus({
        loading: true,
        error: null,
      })
    )
    exportAxiosInstance
      .get("/v1/items-export", {
        params: { search: search, filter },
      })
      .then(response => {
        if (response) {
          const dataBlob = new Blob([response?.data], {
            type: "application/pdf,",
          })
          FileSaver.saveAs(dataBlob, `Items-${Math.random().toString()}.xlsx`)
          dispatch(
            itemAction.setExportStatus({
              loading: false,
              error: null,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          itemAction.setExportStatus({
            loading: false,
            error: error?.response?.errors,
          })
        )
        console.log(error)
      })
  }
