import { useFormik } from "formik"
import * as Yup from "yup"
import React, { useEffect, useMemo, useState } from "react"
import { Card, CardBody, Label, Form, Row, Col } from "reactstrap"
import CustomHeaderTitle from "components/Common/CustomHeaderTitle"
import Switch from "react-switch"

const PermissionsComponents = ({
  permissions,
  userDirectPermissions,
  url,
  rightBtn,
  onChangePermission,
  permissionResponse,
  activePermissions,
}) => {
  const [switchs, setSwitchs] = useState(
    activePermissions.concat(userDirectPermissions)
  )

  const onChangePermissionSwitch = permission => {
    setSwitchs(prev => {
      const check = switchs.includes(permission.value)
      if (check) {
        const updatedSwitch = switchs.filter(swit => swit !== permission.value)
        onChangePermission(permission, "delete")
        return updatedSwitch
      } else {
        onChangePermission(permission, "add")
        return [...prev, permission.value]
      }
    })
  }

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    )
  }

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    )
  }

  return (
    <Card>
      <CardBody>
        <CustomHeaderTitle
          loading={permissionResponse?.loading}
          title={"Permissions"}
          lgColumn={2}
          rowCount={false}
          orderingList={false}
          searchBox={false}
          rightBtn={rightBtn ? true : false}
          rightBtnTitle={"Back"}
          rightBtnRoute={url && url}
        />

        <Form>
          <Row>
            {permissions?.map((permission, key) => (
              <Col md={6} key={key}>
                <div className="mb-3">
                  <Row>
                    <Col md={6}>
                      <Label className="form-label m-2">
                        {permission?.value.replace("-", " ").toUpperCase()}
                      </Label>
                    </Col>
                    <Col md={6}>
                      <Switch
                        uncheckedIcon={<Offsymbol />}
                        checkedIcon={<OnSymbol />}
                        className="me-1 mb-sm-8 mb-2"
                        onColor="#626ed4"
                        onChange={value => {
                          onChangePermissionSwitch(permission)
                        }}
                        checked={switchs?.some(sw => sw === permission.value)}
                        disabled={activePermissions.includes(permission?.value)}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
            ))}
          </Row>
        </Form>
      </CardBody>
    </Card>
  )
}

export default PermissionsComponents
