import React from "react"
import { Card, CardBody } from "reactstrap"
import { useNavigate } from "react-router-dom"
import CustomHeaderTitle from "components/Common/CustomHeaderTitle"
import GeneralTables from "components/Common/GeneralTable"
import ActionColumns from "components/Common/GeneralTable/ActionColumns"

const ProvinceListComponent = ({
  data,
  error,
  loading,
  onPageClick,
  onSearch,
  onChangeRowNumber,
  onChangeOrdering,
  onExportBtnClick,
  exportStatus,
}) => {
  const navigate = useNavigate()
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CustomHeaderTitle
            loading={loading}
            title={"List"}
            rowCount={true}
            onChangeRowNumber={onChangeRowNumber}
            orderingList={true}
            onChangeOrdering={onChangeOrdering}
            searchBox={true}
            exportBtn={true}
            onExportBtnClick={onExportBtnClick}
            exportBtnLoading={exportStatus?.loading}
            onSearchHandler={onSearch}
            rightBtn={false}
            orderingOptions={[
              { value: "id", name: "ID" },
              { value: "province", name: "Province" },
              { value: "region", name: "Region" },
            ]}
          />
          <GeneralTables
            data={data?.data}
            loading={loading}
            headers={["#", "Province", "Region", "Action"]}
            pagination={{ meta: data?.meta, onPageClick: onPageClick }}
            renderItem={(province, index) => (
              <tr key={index}>
                <th scope="row">{data?.meta?.from + index}</th>
                <td>{province.province}</td>
                <td>{province.region}</td>
                <td>
                  <ActionColumns
                    data={province}
                    enableEdit={true}
                    enableDelete={false}
                    editRoute={`/edit-province/${province.id}`}
                  />
                </td>
              </tr>
            )}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default ProvinceListComponent
