import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: [],
  error: null,
  loading: false,
  province: null,
  store: { loading: false, error: null },
  export: { loading: false, error: null },
  provinceConsumpProviderAccount: { data: [], error: null, loading: false },
  provinceConsumpProjects: { data: [], error: null, loading: false },
  provinceConsumpProvinces: { data: [], error: null, loading: false },
  provinceConsumpFacilities: { data: [], error: null, loading: false },
}

const provinceConsumptionSlice = createSlice({
  name: "provinceConsumptions",
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload
    },
    setError(state, action) {
      state.error = action.payload
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    setProvince(state, action) {
      state.province = action.payload
    },
    setStore(state, action) {
      state.store = {
        loading: action.payload.loading,
        error: action.payload.error,
      }
    },
    setExport(state, action) {
      state.export = {
        loading: action.payload.loading,
        error: action.payload.error,
      }
    },
    setProvinceConsumptionProviderAccount(state, action) {
      state.provinceConsumpProviderAccount = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setProvinceConsumptionProjects(state, action) {
      state.provinceConsumpProjects = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setProvinceConsumptionProvince(state, action) {
      state.provinceConsumpProvinces = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setProvinceConsumptionFacilities(state, action) {
      state.provinceConsumpFacilities = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    resetState(state) {
      state.data = []
      state.error = null
      state.loading = false
      state.province = null
      state.store = { loading: false, error: null }
      state.export = { loading: false, error: null }
      state.provinceConsumpProvinces = { loading: false, error: null, data: [] }
      state.provinceConsumpProjects = { loading: false, error: null, data: [] }
      state.provinceConsumpFacilities = {
        loading: false,
        error: null,
        data: [],
      }
    },
    resetStore(state) {
      state.store = { loading: false, error: null }
    },
  },
})

export const provinceConsumptionAction = provinceConsumptionSlice.actions

export default provinceConsumptionSlice
