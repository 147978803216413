import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  provinceTransactions: { data: [], loading: false, error: null },
  provinceExportTransaction: { loading: false, error: null },
  provinceConsumptionReport: { data: [], loading: false, error: null },
  provinceConsumptionExport: { loading: false, error: null },
}
const provinceReportsSlice = createSlice({
  name: "provinceReports",
  initialState,
  reducers: {
    setTransactionReport(state, action) {
      state.provinceTransactions = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setTransactionExport(state, action) {
      state.provinceExportTransaction = {
        error: action.payload.error,
        loading: action.payload.loading,
      }
    },
    setProvinceConsumptionReport(state, action) {
      state.provinceConsumptionReport = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setProvinceConsumptionExport(state, action) {
      state.provinceConsumptionExport = {
        error: action.payload.error,
        loading: action.payload.loading,
      }
    },
    resetTransactionReport(state) {
      state.provinceTransactions = { data: [], loading: false, error: null }
      state.provinceExportTransaction = { loading: false, error: null }
    },
    resetProvinceConsumptionReport(state) {
      state.provinceConsumptionReport = {
        data: [],
        loading: false,
        error: null,
      }
    },
  },
})

export const provinceReportsAction = provinceReportsSlice.actions

export default provinceReportsSlice
