import SingleSelect from "components/Common/SelectBoxes/SingleSelect"
import React, { useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Col, Form, Input, Label, Row } from "reactstrap"
import Select from "react-select"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import moment from "moment"
import { providerDashboardAction } from "store/provider-dashboard/provider-dashboard-slice"
import { isObject } from "lodash"
import { getMonitorItems } from "store/monitor-dashboard/monitor-dashboard-action"

const MonitorQuantificationFilter = ({ onAdvancedSearchSubmit, loading }) => {
  const projects = useSelector(state => state.monitorDashboard.monitorProjects)
  const categories = useSelector(
    state => state.monitorDashboard.MonitorItemCategoires
  )
  const items = useSelector(state => state.monitorDashboard.MonitorItems)
  const ips = useSelector(state => state.monitorDashboard.MonitorIps)

  const filteredItems = useMemo(
    () =>
      items?.data.map(item => ({
        value: item.id,
        label: `${item.unfp_item_name} ( ${item.item_name} )`,
      })),
    [items]
  )

  const dispatch = useDispatch()

  const fromdateFlatpicker = useRef(null)
  const todateFlatpicker = useRef(null)
  const [Ips, setIps] = useState(null)
  const [category, setCategory] = useState(null)
  const [item, setItem] = useState(null)
  const [batchNumber, setBatchNumber] = useState("")
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [project, setProject] = useState(null)

  const handleSubmit = () => {
    let searchs = {}
    searchs.providerIP = isObject(Ips) ? Ips.value : null
    searchs.project = isObject(project) ? project.value : null
    searchs.category = isObject(category) ? category.value : null
    if (item) {
      searchs.item = item.map(ite => (ite.value ? ite.value : ite))
    }

    searchs.batchNumber = batchNumber
    searchs.from = fromDate
    searchs.to = toDate
    onAdvancedSearchSubmit(searchs)
  }

  const onIpsChangeHandler = ip => {
    setIps(ip)
  }

  const onCategoryChangeHandler = category => {
    setCategory(category)
    dispatch(getMonitorItems(category.value))
  }

  const onItemChangeHandler = item => {
    const check = item.some(ite => ite.value === "all")
    if (check) {
      setItem(filteredItems)
    } else {
      setItem(item)
    }
  }
  const onProjectChangeHandler = proj => {
    setProject(proj)
  }

  const onClearHandler = () => {
    fromdateFlatpicker.current.flatpickr.clear()
    todateFlatpicker.current.flatpickr.clear()
    setIps(null)
    setCategory(null)
    setItem("")
    setProject(null)
    setBatchNumber("")
    setFromDate(null)
    setToDate(null)
    dispatch(providerDashboardAction.resetProviderItems())
    onAdvancedSearchSubmit({})
  }

  return (
    <Form
      onSubmit={e => {
        e.preventDefault()
        handleSubmit()
        return false
      }}
    >
      <div className="mb-3">
        <Row>
          <Col>
            <Label className="form-label">Providers</Label>
            {ips?.data && (
              <Select
                options={[{ value: "all", label: "All" }, ...ips?.data]}
                value={Ips}
                onChange={onIpsChangeHandler}
              />
            )}
          </Col>
          <Col>
            <Label className="form-label">Projects</Label>
            {projects?.data && (
              <Select
                options={[{ value: "all", label: "All" }, ...projects?.data]}
                value={project}
                onChange={onProjectChangeHandler}
              />
            )}
          </Col>
        </Row>
      </div>

      <div className="mb-3">
        <Label className="form-label">Item Categories</Label>
        {categories?.data && (
          <Select
            options={categories?.data}
            value={category || categories?.data[0]}
            onChange={onCategoryChangeHandler}
          />
        )}
      </div>

      {filteredItems && (
        <div className="mb-3">
          <Label className="form-label">Items</Label>
          <Select
            isMulti={true}
            options={[{ value: "all", label: "All" }, ...filteredItems]}
            value={item}
            onChange={onItemChangeHandler}
          />
        </div>
      )}
      <div className="mb-3">
        <Label className="form-label">Batch Number</Label>
        <Input
          name="name"
          value={batchNumber}
          onChange={value => {
            setBatchNumber(value.target.value)
          }}
          placeholder="Name"
          type="text"
        />
      </div>

      <div className="mb-3 ">
        <Label className="form-label">From Date</Label>
        <Flatpickr
          ref={fromdateFlatpicker}
          className="form-control d-block"
          placeholder="dd M,yyyy"
          options={{
            altInput: true,
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
          }}
          onChange={value => {
            setFromDate(moment(value[0]).format("YYYY-MM-DD"))
          }}
        />
        <br />
        <Label className="form-label">To Date</Label>
        <Flatpickr
          ref={todateFlatpicker}
          className="form-control d-block"
          placeholder="dd M,yyyy"
          options={{
            altInput: true,
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
          }}
          onChange={value => {
            setToDate(moment(value[0]).format("YYYY-MM-DD"))
          }}
        />
      </div>

      <div className="d-flex flex-wrap gap-2">
        <Button
          type="submit"
          className="btn btn-sm btn-primary"
          color="primary"
          disabled={loading}
        >
          {loading ? "Please Wait..." : "Search"}
        </Button>
        <Button
          onClick={onClearHandler}
          className="btn btn-sm btn-danger"
          color="primary"
        >
          Clear
        </Button>
      </div>
    </Form>
  )
}

export default MonitorQuantificationFilter
