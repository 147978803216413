import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: [],
  error: null,
  loading: false,
  Ips: { error: null, loading: false, data: [] },
  ItemCategoires: { error: null, loading: false, data: [] },
  Items: { error: null, loading: false, data: [] },
  Warehouses: { error: null, loading: false, data: [] },
  Providers: { error: null, loading: false, data: [] },
  Provinces: { error: null, loading: false, data: [] },
  Facilities: { error: null, loading: false, data: [] },
  Projects: { error: null, loading: false, data: [] },
  users: { error: null, loading: false, data: [] },
}

const adminDashboardSlice = createSlice({
  name: "adminDashboard",
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload
    },
    setError(state, action) {
      state.error = action.payload
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    setIps(state, action) {
      state.Ips = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setItemCategories(state, action) {
      state.ItemCategoires = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setItems(state, action) {
      state.Items = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setWarehouses(state, action) {
      state.Warehouses = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setProviders(state, action) {
      state.Providers = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setProvinces(state, action) {
      state.Provinces = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setFacilities(state, action) {
      state.Facilities = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setProjects(state, action) {
      state.Projects = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setUsers(state, action) {
      state.users = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    resetIps(state) {
      state.Ips = { error: null, loading: false, data: [] }
    },
    resetItemCategories(state) {
      state.ItemCategoires = { error: null, loading: false, data: [] }
    },
    resetItems(state) {
      state.Items = { error: null, loading: false, data: [] }
    },
    resetMonitorWarehouses(state) {
      state.Warehouses = { data: [], error: null, loading: null }
    },
    resetProviders(state) {
      state.Monitors = { data: [], error: null, loading: null }
    },
    resetProvinces(state) {
      state.Provinces = { data: [], error: null, loading: null }
    },
    resetFacilities(state) {
      state.Facilities = { data: [], error: null, loading: null }
    },
    resetProjects(state) {
      state.Projects = { data: [], error: null, loading: null }
    },
    resetUser(state) {
      state.users = { data: [], error: null, loading: null }
    },
  },
})

export const adminDashboardAction = adminDashboardSlice.actions

export default adminDashboardSlice
