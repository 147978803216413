import React from "react"
import { Card, CardBody } from "reactstrap"
import { useNavigate } from "react-router-dom"
import CustomHeaderTitle from "components/Common/CustomHeaderTitle"
import GeneralTables from "components/Common/GeneralTable"
import ActionColumns from "components/Common/GeneralTable/ActionColumns"

const UserListComponent = ({
  data,
  error,
  loading,
  onPageClick,
  onSearch,
  onChangeRowNumber,
  onChangeOrdering,
  onClickDelete,
  handleDeleteFunc,
}) => {
  const navigate = useNavigate()
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CustomHeaderTitle
            loading={loading}
            title={"List"}
            rowCount={true}
            onChangeRowNumber={onChangeRowNumber}
            orderingList={true}
            onChangeOrdering={onChangeOrdering}
            searchBox={true}
            onSearchHandler={onSearch}
            rightBtn={true}
            rightBtnTitle={"Add user"}
            rightBtnRoute={"/add-user"}
            orderingOptions={[
              { value: "id", name: "ID" },
              { value: "name", name: "Name" },
            ]}
          />
          <GeneralTables
            data={data?.data}
            loading={loading}
            headers={[
              "#",
              "Name",
              "Email",
              "Role",
              "Province",
              "Item Category",
              "Providers",
              "Warehouses",
              "IP",
              "Projects",
              "Action",
            ]}
            pagination={{ meta: data?.meta, onPageClick: onPageClick }}
            renderItem={(user, index) => (
              <tr key={index}>
                <th scope="row">{data?.meta?.from + index}</th>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{user.role?.name}</td>
                <td>{user.provincesName.join(" - ")}</td>
                <td>{user.itemCategoriesName.join(" - ")}</td>
                <td>{user?.providersName.join(" - ")}</td>
                <td>{user?.warehousesName.join(" - ")}</td>
                <td>{user.ipsName?.join(" - ")}</td>
                <td>{user.projectsName?.join(" - ")}</td>
                <td>
                  <ActionColumns
                    data={user}
                    enableEdit={true}
                    enableDelete={true}
                    editRoute={`/edit-user/${user.id}`}
                    onClickDelete={onClickDelete}
                  />
                </td>
              </tr>
            )}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default UserListComponent
