import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  providerTransactions: { data: [], loading: false, error: null },
  providerExportTransaction: { loading: false, data: {}, error: null },
  providerReceiptsReport: { data: [], loading: false, error: null },
  providerExportReceiptReport: { loading: false, error: null },
  providerDistributionsReport: { data: [], loading: false, error: null },
  providerExportDistributionReport: { loading: false, error: null },
  providerProvicneDistributionReport: { data: [], loading: false, error: null },
  providerProvinceExportDistributionReport: { loading: false, error: null },
  providerInventory: { data: [], loading: false, error: null },
  providerInventoyExport: { loading: false, error: null },
  providerConsumptionReport: { data: [], loading: false, error: null },
  providerConsumptionExport: { loading: false, error: null },
  providerReceiptHealthReport: { data: [], loading: false, error: null },
  providerReceiptHealthExport: { loading: false, error: null },
  providerQuantificationReport: { data: [], loading: false, error: null },
  providerQuantificationExport: { loading: false, error: null },
}
const providerReportsSlice = createSlice({
  name: "providerReports",
  initialState,
  reducers: {
    setTransactionReport(state, action) {
      state.providerTransactions = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setProviderReceiptReport(state, action) {
      state.providerReceiptsReport = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setProviderDistributionReport(state, action) {
      state.providerDistributionsReport = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setProviderProvicneDistributionReport(state, action) {
      state.providerProvicneDistributionReport = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setProviderConsumptionReport(state, action) {
      state.providerConsumptionReport = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setProviderReceiptHealthReport(state, action) {
      state.providerReceiptHealthReport = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setProviderQuantificationReport(state, action) {
      state.providerQuantificationReport = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setTransactionExport(state, action) {
      state.providerExportTransaction = {
        error: action.payload.error,
        loading: action.payload.loading,
      }
    },
    setReceiptReportExport(state, action) {
      state.providerExportReceiptReport = {
        error: action.payload.error,
        loading: action.payload.loading,
      }
    },
    setDistributionReportExport(state, action) {
      state.providerExportDistributionReport = {
        error: action.payload.error,
        loading: action.payload.loading,
      }
    },
    setProvinceDistributionReportExport(state, action) {
      state.providerProvinceExportDistributionReport = {
        error: action.payload.error,
        loading: action.payload.loading,
      }
    },
    setProviderInventoyExport(state, action) {
      state.providerInventoyExport = {
        error: action.payload.error,
        loading: action.payload.loading,
      }
    },
    setProviderConsumptionExport(state, action) {
      state.providerConsumptionExport = {
        error: action.payload.error,
        loading: action.payload.loading,
      }
    },
    setProviderReceiptHealthExport(state, action) {
      state.providerReceiptHealthExport = {
        error: action.payload.error,
        loading: action.payload.loading,
      }
    },
    setProviderQuantificationExport(state, action) {
      state.providerQuantificationExport = {
        error: action.payload.error,
        loading: action.payload.loading,
      }
    },
    resetTransactionReport(state) {
      state.providerTransactions = { data: [], loading: false, error: null }
    },
    resetProviderReceiptReport(state) {
      state.providerReceiptsReport = { data: [], loading: false, error: null }
    },
    resetProviderQuantificationReport(state) {
      state.providerQuantificationReport = {
        data: [],
        loading: false,
        error: null,
      }
    },
    resetProviderReceiptReport(state) {
      state.providerReceiptsReport = { data: [], loading: false, error: null }
    },
    resetProviderDistributionReport(state) {
      state.providerDistributionsReport = {
        data: [],
        loading: false,
        error: null,
      }
    },
    resetTransactionExport(state) {
      state.providerExportTransaction = {
        data: [],
        loading: false,
        error: null,
      }
    },
    resetReceiptExport(state) {
      state.providerExportReceiptReport = {
        loading: false,
        error: null,
      }
    },
    resetDistributionExport(state) {
      state.providerExportDistributionReport = {
        loading: false,
        error: null,
      }
    },
    resetProviderQuantificationExport(state) {
      state.providerQuantificationExport = {
        loading: false,
        error: null,
      }
    },
    resetProvinceExportDistributionReport(state) {
      state.providerProvinceExportDistributionReport = {
        loading: false,
        error: null,
      }
    },
    resetProviderInventoyExport(state) {
      state.providerInventoyExport = { loading: false, error: null }
    },
    resetProviderConsumptionExport(state) {
      state.providerConsumptionExport = { loading: false, error: null }
    },
    resetProviderConsumptionReport(state) {
      state.providerConsumptionReport = {
        data: [],
        loading: false,
        error: null,
      }
    },
    resetProviderReceiptHealthReport(state) {
      state.providerReceiptHealthReport = {
        data: [],
        loading: false,
        error: null,
      }
    },
  },
})

export const providerReportsAction = providerReportsSlice.actions

export default providerReportsSlice
