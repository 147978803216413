import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { Collapse } from "reactstrap"
import { getProvinceTotalExpireReceiptsCount } from "store/province-exipre-receipts/province-expire-receipts-action"

const ProvinceNavbar = props => {
  const [report, setReport] = useState(false)

  const dispatch = useDispatch()

  const totalExpireCount = useSelector(
    state => state.provinceExpireReceipts.count
  )

  useEffect(() => {
    dispatch(getProvinceTotalExpireReceiptsCount())
  }, [])

  return (
    <Collapse
      isOpen={props.leftMenu}
      className="navbar-collapse"
      id="topnav-menu-content"
    >
      <ul className="navbar-nav">
        <li className="nav-item ">
          <Link className="nav-link " to="/dashboard">
            <i className="bx bx-home-circle me-2"></i>
            Dashboard
          </Link>
        </li>
        <li className="nav-item ">
          <Link className="nav-link " to="/province-receipts">
            <i className="bx bx-home-circle me-2"></i>
            Receipts ( In )
          </Link>
        </li>
        <li className="nav-item ">
          <Link className="nav-link " to="/province-distributions">
            <i className="bx bx-home-circle me-2"></i>
            Distributions ( Out )
          </Link>
        </li>
        <li className="nav-item ">
          <Link
            className="nav-link "
            to="/province-expire-receipts"
            style={totalExpireCount > 0 ? { color: "red" } : {}}
          >
            <i className="bx bx-home-circle me-2"></i>
            Expiry List{" "}
            <span className="text-danger" style={{ fontSize: 11 }}>
              ( {totalExpireCount} )
            </span>
          </Link>
        </li>
        <li className="nav-item ">
          <Link className="nav-link " to="/province-consumptions">
            <i className="bx bx-home-circle me-2"></i>
            Consumptions
          </Link>
        </li>
        <li className="nav-item dropdown">
          <Link
            className="nav-link dropdown-toggle arrow-none"
            onClick={e => {
              e.preventDefault()
              setReport(!report)
            }}
            to="#"
          >
            <i className="mdi mdi-order-bool-descending me-2"></i>
            Reports {props.menuOpen}
            <div className="arrow-down"></div>
          </Link>
          <div className={"dropdown-menu"}>
            <Link to="/province-transactions" className="dropdown-item">
              All Transactions ( In / Out )
            </Link>
            <Link to="/province-consumptions-reports" className="dropdown-item">
              Consumptions Report
            </Link>
          </div>
        </li>
      </ul>
    </Collapse>
  )
}

export default ProvinceNavbar
