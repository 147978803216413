import React from "react"
import { Col, Row, Table } from "reactstrap"

const ProvinceInTotalBox = ({ totals }) => {
  return (
    <Row
      style={{
        marginBottom: 40,
        justifyItems: "center",
        justifyContent: "center",
      }}
    >
      <Col lg={9}>
        <Table
          borderless={false}
          bordered={true}
          style={{ overflow: "hidden" }}
        >
          <tbody>
            <tr>
              <th style={{ color: "#19ada1", fontSize: 13 }}>Total Receipts</th>
              <td
                style={{
                  color: "#d08658",
                  fontSize: 13,
                  fontFamily: "monospace",
                }}
              >
                {totals?.receipts}
              </td>
              <th style={{ color: "#19ada1", fontSize: 13 }}>
                Total Available Receipts
              </th>
              <td
                style={{
                  color: "#d08658",
                  fontSize: 13,
                  fontFamily: "monospace",
                }}
              >
                {totals?.available_receipts}
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
  )
}

export default ProvinceInTotalBox
