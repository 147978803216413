export const getProvinceQuantityFromSubmited = (submitedArray, id) => {
  if (submitedArray.length > 0) {
    const check = submitedArray.some(subvalue => subvalue.province_in_id === id)
    if (check) {
      const total = submitedArray.reduce(
        (acc, { province_in_id, out_quantity }) => ({
          ...acc,
          [province_in_id]: {
            qty: acc[province_in_id]
              ? acc[province_in_id].qty + out_quantity
              : out_quantity,
          },
        }),
        {}
      )
      return total[id].qty
    } else {
      return 0
    }
  } else {
    return 0
  }
}
