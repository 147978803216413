import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getProviderInItems } from "store/provider-dashboard/provider-dashboard-action"
import { useSearchParams } from "react-router-dom"
import ProviderChartInItemsList from "components/ProviderDashboard/ProviderItems/ProviderChartInItemsList"
import { providerDashboardAction } from "store/provider-dashboard/provider-dashboard-slice"
import ProvinceChartInItemsListComponent from "components/ProvinceDashboard/ProvinceItems/ProvinceChartInItems"
import { getProvinceInItems } from "store/province-dashboard/province-dashboard-action"
import { provinceDashboardAction } from "store/province-dashboard/province-dashboard-slice"

const ProvinceChartInItems = () => {
  const dispatch = useDispatch()

  const [searchParams] = useSearchParams()
  const getParams = {
    itemId: searchParams.get("itemId"),
    from: searchParams.get("from") !== "null" ? searchParams.get("from") : null,
    to: searchParams.get("to") !== "null" ? searchParams.get("to") : null,
    type: searchParams.get("type"),
  }
  const type = searchParams.get("type")
  const ProvinceInItems = useSelector(
    state => state.provinceDashboard.ProvinceInItems
  )

  useEffect(() => {
    dispatch(getProvinceInItems(getParams))

    return () => {
      dispatch(provinceDashboardAction.resetProvinceInItems())
    }
  }, [])

  return (
    <ProvinceChartInItemsListComponent
      data={ProvinceInItems?.data?.data}
      loading={ProvinceInItems?.loading}
      type={type}
    />
  )
}

export default ProvinceChartInItems
