import { ROLES } from "constants/data"
import * as Yup from "yup"
export const ProviderValidation = Yup.object().shape({
  name: Yup.string().required("This province is required"),
  email: Yup.string().required("This province is required"),
  provinces: Yup.array()
    .required("This province is required")
    .min(1, "province should have at least 1"),
  role_id: Yup.string().required("This role is required"),
  ip_id: Yup.string().required("This role is required"),
  item_categories: Yup.array()
    .required("This item category is required")
    .min(1, "item category should have at least 1"),
  providers: Yup.array()
    .required("This providers is required")
    .min(1, "province should have at least 1"),
  warehouses: Yup.array()
    .required("This warehouses is required")
    .min(1, "warehouse should have at least 1"),
  projects: Yup.array()
    .required("This warehouses is required")
    .min(1, "projects should have at least 1"),
  ips: Yup.array()
    .required("This ip id is required")
    .min(1, "ips should have at least 1"),
})

export const AdminValidation = Yup.object().shape({
  name: Yup.string().required("This province is required"),
  email: Yup.string().required("This province is required"),
  role_id: Yup.string().required("This role is required"),
})

export const ProvinceValidation = Yup.object().shape({
  name: Yup.string().required("This province is required"),
  email: Yup.string().required("This province is required"),
  role_id: Yup.string().required("This role is required"),
  ip_id: Yup.string().required("This role is required"),
  item_categories: Yup.array()
    .required("This item category is required")
    .min(1, "item category should have at least 1"),
})

export const MonitorValidation = Yup.object().shape({
  name: Yup.string().required("This province is required"),
  email: Yup.string().required("This province is required"),
  provinces: Yup.array()
    .required("This province is required")
    .min(1, "province should have at least 1"),
  role_id: Yup.string().required("This role is required"),
  ip_id: Yup.string().required("This role is required"),
  item_categories: Yup.array()
    .required("This item category is required")
    .min(1, "item category should have at least 1"),
  providers: Yup.array()
    .required("This providers is required")
    .min(1, "province should have at least 1"),
  warehouses: Yup.array()
    .required("This warehouses is required")
    .min(1, "warehouse should have at least 1"),
  projects: Yup.array()
    .required("This warehouses is required")
    .min(1, "projects should have at least 1"),
  ips: Yup.array()
    .required("This ip id is required")
    .min(1, "ips should have at least 1"),
})

export function checkValidationRole(type) {
  const chekcRole = ROLES.find(role => role.value === type)
  if (chekcRole.label === "Admin") {
    return AdminValidation
  } else if (chekcRole.label === "Monitor") {
    return MonitorValidation
  } else if (chekcRole.label === "Province") {
    return ProvinceValidation
  } else {
    return ProviderValidation
  }
}

export function AllowInput(role) {
  if (role === "Admin") {
    return []
  } else if (role === "Monitor") {
    return [
      "Province",
      "ItemCategory",
      "MainIP",
      "Providers",
      "Warehouses",
      "Projects",
      "IPs",
    ]
  } else if (role === "Province") {
    return ["ItemCategory", "MainIP"]
  } else {
    return [
      "Province",
      "ItemCategory",
      "MainIP",
      "Providers",
      "Warehouses",
      "Projects",
      "IPs",
    ]
  }
}
