import React, { useEffect, useState } from "react"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import {
  exportProviderIpsStock,
  getProviderIps,
  getProviderIpsStock,
  getProviderItemCategories,
  getProviderItems,
  getProviderProjects,
  getProviderStock,
} from "store/provider-dashboard/provider-dashboard-action"
import ProviderDashboardComponent from "components/ProviderDashboard/ProviderDashboardComponent"
import { exportProviderInventoryReport } from "store/provider-reports/p-report-action"
import WelcomeComp from "pages/Dashboard/WelcomeComp"

const ProviderDashboard = props => {
  //meta title
  document.title = "Dashboard"
  const [fitlers, setFilters] = useState(null)
  const [IpFilters, setIPFilters] = useState(null)

  const providerStock = useSelector(
    state => state.providerDashboard.providerStock
  )

  const providerIpsStock = useSelector(
    state => state.providerDashboard.providerIpsStock
  )

  const dispatch = useDispatch()

  const onSubmit = values => {
    dispatch(getProviderStock(values))
  }

  const onCategoryChange = category => {
    dispatch(getProviderItems(category))
  }

  const onExport = () => {
    dispatch(exportProviderInventoryReport(fitlers))
  }

  const onProviderSearchHandler = values => {
    setFilters(values)
  }

  const onProviderIPsAdvancedSearchHandler = values => {
    setIPFilters(values)
  }

  const onProviderIPsStockExport = () => {
    dispatch(exportProviderIpsStock(IpFilters))
  }

  useEffect(() => {
    dispatch(getProviderStock())
    dispatch(getProviderIpsStock())
    dispatch(getProviderIps())
    dispatch(getProviderItemCategories())
    dispatch(getProviderProjects())
  }, [])

  return (
    <React.Fragment>
      <Breadcrumbs title={"Provider"} breadcrumbItem={"Dashboard"} />
      <WelcomeComp />
      <ProviderDashboardComponent
        provStock={providerStock?.data?.data?.providerReports}
        provIpsStock={providerIpsStock?.data?.data}
        provData={providerStock?.data?.data}
        onSubmit={onSubmit}
        onCategoryChange={onCategoryChange}
        onExport={onExport}
        onProviderSearchHandler={onProviderSearchHandler}
        onProviderIPsAdvancedSearchHandler={onProviderIPsAdvancedSearchHandler}
        onProviderIPsStockExport={onProviderIPsStockExport}
      />
    </React.Fragment>
  )
}

export default ProviderDashboard
