import React, { useEffect, useState, useMemo, memo } from "react"
import Select from "react-select"

const SingleSelect = ({
  options,
  onChange,
  //   loading,
  name,
  onBlur,
  value,
  defaultValue,
  formik,
  formMode,
  isMulti,
}) => {
  const [loading, setLoading] = useState(false)

  // useEffect(() => {
  //   if (formMode === "Edit" && formik.values[name]) {
  //     setLoading(false)
  //   }
  //   if (formMode === "Add") {
  //     setLoading(false)
  //   }
  // }, [formMode, formik])

  return (
    <React.Fragment>
      {!loading && (
        <Select
          className="basic-single"
          classNamePrefix="select"
          defaultValue={
            defaultValue
              ? options[
                  options.findIndex(value => value.value === defaultValue)
                ]
              : []
          }
          isLoading={loading}
          name={name}
          onChange={value => {
            formik.setFieldValue(name, value.value)
          }}
          value={
            options[options.find(val => val.value === formik.values[name])]
          }
          options={options}
        />
      )}
    </React.Fragment>
  )
}

export default SingleSelect
