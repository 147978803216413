import React, { useState } from "react"
import { Button, Card, CardBody, Collapse } from "reactstrap"
import { useNavigate } from "react-router-dom"
import CustomHeaderTitle from "components/Common/CustomHeaderTitle"
import GeneralTables from "components/Common/GeneralTable"
import ActionColumns from "components/Common/GeneralTable/ActionColumns"
import CSAlert from "components/Alert/ConfirmationAlert"
import { result } from "lodash"
import SAlert from "components/Alert"
import ProviderInFilter from "./ProvinceOutFilter"
import ProvinceOutFilter from "./ProvinceOutFilter"
import ProvinceOutTotalBox from "./ProvinceOutTotalBox"

const ProvinceOutListComponent = ({
  data,
  error,
  loading,
  onPageClick,
  onSearch,
  onChangeRowNumber,
  onChangeOrdering,
  onClickDelete,
  onAllDeleteClicked,
  onAdvancedSearchSubmit,
  permissions,
  onClickDetail,
  onExportBtnClick,
  exportStatus,
}) => {
  const navigate = useNavigate()
  const [ids, setIds] = useState([])
  const [filterOpen, setFilterOpen] = useState(false)

  const onCheckboxClick = (e, id) => {
    const check = ids.includes(id)
    if (check) {
      const updated = ids.filter(myId => myId !== id)
      setIds(updated)
    } else {
      setIds(prev => {
        return [...prev, id]
      })
    }
  }

  const onAllDeleteHandler = async () => {
    const results = await CSAlert({
      title: "Are you sure delete?",
    })
    if (results.isConfirmed) {
      onAllDeleteClicked(ids)
    }
  }

  const onParentCheckChange = status => {
    if (status) {
      const all = data?.data.map(dt => dt.id)
      setIds(all)
    } else {
      setIds([])
    }
  }

  const onAdvancedSearchHandler = result => {
    setFilterOpen(result)
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CustomHeaderTitle
            loading={loading}
            title={"List"}
            rowCount={true}
            onChangeRowNumber={onChangeRowNumber}
            orderingList={true}
            onChangeOrdering={onChangeOrdering}
            searchBox={true}
            onSearchHandler={onSearch}
            rightBtn={true}
            rightBtnTitle={"Add Distribution"}
            rightBtnRoute={"/add-province-distributions"}
            deleteBtn={permissions.includes("delete-province-out")}
            onAllDeleteHandler={onAllDeleteHandler}
            advnacedSearchBtn={true}
            advnacedSearchBtnTitle={"Filter"}
            onAdvancedSearch={onAdvancedSearchHandler}
            exportBtn={true}
            onExportBtnClick={onExportBtnClick}
            exportBtnLoading={exportStatus?.loading}
            orderingOptions={[
              { value: "id", name: "ID" },
              { value: "date", name: "Submission Date" },
            ]}
          />
          <ProvinceOutTotalBox totals={data?.totals} />
          <Collapse isOpen={filterOpen}>
            <ProvinceOutFilter
              loading={loading}
              onAdvancedSearchSubmit={onAdvancedSearchSubmit}
            />
          </Collapse>
          <GeneralTables
            data={data?.data}
            loading={loading}
            parentCheckbox={true}
            onParentCheckChange={onParentCheckChange}
            headers={[
              "SN",
              "PROJECT",
              "PROVIDER",
              "PROVINCE",
              "FACILITY",
              "DISTRIBUTOR",
              "UNFPA ITEM ID",
              "IP- ITEM DESCRIPTION",
              "UNFPA UOM",
              "IP UOM",
              "BATCH NUMBER",
              "EXPIRE DATE",
              "OUT QUANTITY	",
              "DISTRIBUTION DATE",

              "Action",
            ]}
            pagination={{ meta: data?.meta, onPageClick: onPageClick }}
            renderItem={(pOut, index) => (
              <tr key={index}>
                <th scope="row">
                  {" "}
                  <div className="form-check form-checkbox-outline form-check-primary mb-3">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={`checkbox-${pOut.id}`}
                      checked={ids.includes(pOut.id)}
                      onChange={e => onCheckboxClick(e, pOut.id)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`checkbox-${pOut.id}`}
                    >
                      {data?.meta?.from + index}
                    </label>
                  </div>
                </th>
                <td>{pOut?.project?.name}</td>
                <td>{pOut?.provider?.title}</td>
                <td>{pOut?.province?.province}</td>
                <td>{pOut?.facility?.facility_name}</td>
                <td>{pOut?.distributor}</td>
                <td>{pOut?.item?.unfp_item_name}</td>
                <td>{pOut?.item?.ip_item_description}</td>
                <td>
                  {pOut?.item.uom} &nbsp;
                  {pOut?.item.unfpa_uom_quantity > 1 &&
                    pOut?.item.unfpa_uom_quantity}
                </td>
                <td>
                  {pOut?.item.ip_unit_umo} &nbsp;
                  {pOut?.item.ip_uom_quantity > 1 && pOut?.item.ip_uom_quantity}
                </td>

                <td>{pOut?.province_in?.batch_number}</td>
                <td>{pOut?.province_in?.expire_date}</td>
                <td>{pOut?.out_quantity}</td>
                <td>{pOut?.date}</td>
                <td>
                  {pOut.deleteStatus === 0 ? (
                    <ActionColumns
                      data={pOut}
                      enableEdit={permissions.includes("update-province-out")}
                      enableDelete={permissions.includes("delete-province-out")}
                      enableDetail={true}
                      editRoute={`/edit-province-distributions/${pOut.id}`}
                      onClickDelete={onClickDelete}
                      onClickDetail={onClickDetail}
                    />
                  ) : (
                    <Button className="btn btn-info btn-sm">Pending</Button>
                  )}
                </td>
              </tr>
            )}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default ProvinceOutListComponent
