import React from "react"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"

const MySwal = withReactContent(Swal)

const SAlert = props => {
  const { status, title, text, timer } = props
  MySwal.fire({
    position: "top-end",
    // title: title && title,
    text: text && text,
    icon: status ? status : "success",
    showConfirmButton: false,
    timer: timer ? timer : 1000,
    customClass: "s-alert",
    toast: true,
  })
}

export default SAlert
