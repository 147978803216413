import React, { useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import {
  getByIdProvider,
  updateProvider,
} from "store/providers/provider-action"
import ProviderForm from "components/Provider/form"
import { providerAction } from "store/providers/provider-slice"
import Preloader from "components/Common/Loader"

const EditProvider = () => {
  document.title = "Edit Provider"

  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { loading, provider, error } = useSelector(state => state.provider)

  const onSubmit = values => {
    const form = new FormData()
    form.append("title", values.title)
    form.append("id", id)
    dispatch(updateProvider(id, form, navigate))
  }

  useEffect(() => {
    dispatch(getByIdProvider(id))

    return () => {
      dispatch(providerAction.resetState())
    }
  }, [])

  return (
    <React.Fragment>
      {provider ? (
        <ProviderForm
          loading={loading}
          provider={provider?.data}
          error={error}
          onSubmit={onSubmit}
          formMode={"Edit"}
        />
      ) : (
        <Preloader loading={true} />
      )}
    </React.Fragment>
  )
}

export default EditProvider
