import React from "react"
import { Card, CardBody } from "reactstrap"
import { useNavigate } from "react-router-dom"
import CustomHeaderTitle from "components/Common/CustomHeaderTitle"
import GeneralTables from "components/Common/GeneralTable"
import ActionColumns from "components/Common/GeneralTable/ActionColumns"

const FacilityListComponent = ({
  data,
  error,
  loading,
  onPageClick,
  onSearch,
  onChangeRowNumber,
  onChangeOrdering,
  onClickDelete,
  handleDeleteFunc,
  onExportBtnClick,
  exportStatus,
}) => {
  const navigate = useNavigate()
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CustomHeaderTitle
            loading={loading}
            title={"List"}
            rowCount={true}
            onChangeRowNumber={onChangeRowNumber}
            orderingList={true}
            onChangeOrdering={onChangeOrdering}
            searchBox={true}
            onSearchHandler={onSearch}
            exportBtn={true}
            onExportBtnClick={onExportBtnClick}
            exportBtnLoading={exportStatus?.loading}
            rightBtn={true}
            rightBtnTitle={"Add Facility"}
            rightBtnRoute={"/add-facility"}
            orderingOptions={[
              { value: "id", name: "ID" },
              { value: "facility_name", name: "Facility Name" },
              { value: "facility_type", name: "Facility Type" },
              { value: "facility_code", name: "Facility Code" },
            ]}
          />
          <GeneralTables
            data={data?.data}
            loading={loading}
            headers={[
              "#",
              "Province",
              "Facility Name",
              "Facility Type",
              "Facility Code",
              "Action",
            ]}
            pagination={{ meta: data?.meta, onPageClick: onPageClick }}
            renderItem={(province, index) => (
              <tr key={index}>
                <th scope="row">{data?.meta?.from + index}</th>
                <td>{province.province.province}</td>
                <td>{province.facility_name}</td>
                <td>{province.facility_type}</td>
                <td>{province.facility_code}</td>
                <td>
                  <ActionColumns
                    data={province}
                    enableEdit={true}
                    enableDelete={true}
                    editRoute={`/edit-facility/${province.id}`}
                    onClickDelete={onClickDelete}
                  />
                </td>
              </tr>
            )}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default FacilityListComponent
