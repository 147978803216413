import React, { useEffect, useState } from "react"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import {
  getProviderItemCategories,
  getProviderProjects,
} from "store/provider-dashboard/provider-dashboard-action"
import {
  exportProviderQuantificationReport,
  getProviderQuantificationReport,
} from "store/provider-reports/p-report-action"
import ProviderQuantificationReportListComponent from "components/ProviderReports/Quantification"

const ProviderQuantificationReport = () => {
  //meta title
  document.title = "Quantification Report"

  const [params, setParams] = useState({})
  const { data, error, loading, exportStatus } = useSelector(state => ({
    data: state.providerReports.providerQuantificationReport.data,
    error: state.providerReports.providerQuantificationReport.error,
    loading: state.providerReports.providerQuantificationReport.loading,
    exportStatus: state.providerReports.providerQuantificationExport,
  }))

  const dispatch = useDispatch()

  const onExport = () => {
    dispatch(exportProviderQuantificationReport(params))
  }

  useEffect(() => {
    dispatch(getProviderQuantificationReport())
    dispatch(getProviderProjects())
    dispatch(getProviderItemCategories())
  }, [])

  const onSearchHandler = filter => {
    setParams(filter)
    dispatch(getProviderQuantificationReport(filter))
  }

  return (
    <React.Fragment>
      <Breadcrumbs
        title={"Provider"}
        breadcrumbItem={"Quantification Report"}
      />
      <ProviderQuantificationReportListComponent
        data={data}
        error={error}
        loading={loading}
        onSearchHandler={onSearchHandler}
        onExportBtnClick={onExport}
        exportStatus={exportStatus}
      />
    </React.Fragment>
  )
}

export default ProviderQuantificationReport
