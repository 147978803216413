import React, { useState, useLayoutEffect } from "react"

import AdminDashboard from "pages/Admin/Dashboard"
import MonitorDashboard from "pages/Monitor/Dashboard"
import ProviderDashboard from "pages/Provider/Dashboard"
import ProvinceDashboard from "pages/Province/Dashboard"
import { useRouteLoaderData } from "react-router-dom"
import { useSelector } from "react-redux"

const Dashboard = props => {
  //meta title
  const { role } = useSelector(state => state.auth)
  const [dashbaord, setDashboard] = useState(null)
  useLayoutEffect(() => {
    if (role) {
      if (role === "Admin") {
        setDashboard(<AdminDashboard />)
      }
      if (role === "Monitor") {
        setDashboard(<MonitorDashboard />)
      }
      if (role === "Provider") {
        setDashboard(<ProviderDashboard />)
      }
      if (role === "Province") {
        setDashboard(<ProvinceDashboard />)
      }
    }
  }, [role])

  return dashbaord && dashbaord
}

export default Dashboard
