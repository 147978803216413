import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import DeleteModal from "components/Common/DeleteModal"
import WarehouseListComponent from "components/Warehouse/list"
import {
  deleteWarehouse,
  getWarehouses,
} from "store/warehouse/warehouse-action"
import {
  deleteProject,
  exportProjects,
  getProjects,
} from "store/project/project-action"
import ProjectListComponent from "components/Projects/list"

const ProjectList = () => {
  const dispatch = useDispatch()
  const { data, error, loading, exportStatus } = useSelector(
    state => state.project
  )
  const [deleteModal, setDeleteModal] = useState(false)
  const [project, setProject] = useState(null)
  const [search, setSearch] = useState(null)
  const [pageNumber, setPageNumber] = useState(10)
  const [according, setAccording] = useState({ order: "desc", name: "id" })

  const handleDeleteFunc = data => {
    if (project) {
      dispatch(deleteProject(project.id))
    }
    setDeleteModal(false)
  }

  const onClickDelete = data => {
    setProject(data)
    setDeleteModal(true)
  }

  const onPageClick = link => {
    dispatch(getProjects(link.url, pageNumber, according, search))
  }

  const onSearchHandler = value => {
    setSearch(value)
  }

  const onChangeRowNumber = value => {
    setPageNumber(value)
  }

  const onChangeOrdering = value => {
    if (value.order) {
      setAccording(prev => {
        return { ...prev, order: value.order }
      })
    }
    if (value.name) {
      setAccording(prev => {
        return { ...prev, name: value.name }
      })
    }
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(getProjects(undefined, undefined, undefined, search))
    }, 1500)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  useEffect(() => {
    dispatch(getProjects(undefined, pageNumber, according, search))
  }, [pageNumber, according])

  const onExportBtnClick = () => {
    dispatch(exportProjects(search))
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteFunc}
        onCloseClick={() => setDeleteModal(false)}
      />

      <Breadcrumbs title={"Admin"} breadcrumbItem={"Projects"} />
      <ProjectListComponent
        data={data}
        error={error}
        loading={loading}
        onClickDelete={onClickDelete}
        handleDeleteFunc={handleDeleteFunc}
        onPageClick={onPageClick}
        onSearch={onSearchHandler}
        onChangeRowNumber={onChangeRowNumber}
        onChangeOrdering={onChangeOrdering}
        onExportBtnClick={onExportBtnClick}
        exportStatus={exportStatus}
      />
    </React.Fragment>
  )
}

export default ProjectList
