import React, { useState } from "react"
import { useSelector } from "react-redux"
import { Button, Input, Table } from "reactstrap"

const ProvinceConsumptionItems = ({
  data,
  form,
  setForm,
  onSubmitQuantity,
  onUpdateFunc,
  setQuantity,
  quantity,
  storeStatus,
}) => {
  const [editField, setEditField] = useState(null)

  const onEditConsumption = consum => {
    setEditField({
      item_id: consum?.item_id,
      quantity: consum?.currentConsume?.consume,
      comment: consum?.currentConsume?.comment,
      id: consum?.currentConsume?.id,
    })
    setQuantity([
      {
        item_id: consum?.item_id,
        quantity: 0,
        comment: consum?.currentConsume?.comment,
      },
    ])
  }

  const onUpdateConsumption = (value, consum) => {
    if (value) {
      value.province_id = consum?.province_id
      value.facility_id = consum?.facility_id
      value.item_category_id = consum?.item_category_id
      value.id = consum?.currentConsume?.id
      value.consume = value?.quantity
        ? value?.quantity
        : consum?.currentConsume?.consume
      value.comment = value?.comment
        ? value?.comment
        : consum?.currentConsume?.comment
      value.year = consum?.currentConsume?.year
      value.month = consum?.currentConsume?.month
      setQuantity([])
      setEditField(null)
      onUpdateFunc(value)
    }
  }

  const onQuantityChange = (e, item_id) => {
    if (e.target.value < 0) {
      return
    }
    setQuantity(prev =>
      prev.some(p => p.item_id === item_id)
        ? prev.map(qty =>
            qty.item_id === item_id ? { ...qty, quantity: e.target.value } : qty
          )
        : [...prev, { item_id: item_id, quantity: e.target.value }]
    )
  }

  const onCommnetChange = (e, item_id) => {
    if (e.target.value < 0) {
      return
    }
    setQuantity(prev =>
      prev.some(p => p.item_id === item_id)
        ? prev.map(qty =>
            qty.item_id === item_id ? { ...qty, comment: e.target.value } : qty
          )
        : [...prev, { item_id: item_id, comment: e.target.value }]
    )
  }

  const showTotal = (qty, item_id) => {
    const check = quantity.find(qty => qty.item_id === item_id)

    if (check) {
      if (editField?.item_id === item_id) {
        return qty + editField?.quantity - check.quantity
      } else {
        return qty - check.quantity
      }
    } else {
      return qty
    }
  }

  const SubmitQty = (value, consum) => {
    value.facility_id = consum?.facility_id
    value.province_id = consum?.province_id
    value.item_category_id = consum?.item_category_id
    value.provided_by = consum?.provided_by
    value.project_id = consum?.project_id
    setQuantity([])
    setEditField(null)
    onSubmitQuantity(value)
  }

  const checkFormAdd = (qty, consum) => {
    const value = quantity.find(qty => qty.item_id === consum?.item_id)

    if (value && value?.quantity !== "") {
      const total = showTotal(qty, consum?.item_id)
      if (total >= 0 && total <= qty && total !== NaN) {
        return (
          <Button
            onClick={e => SubmitQty(value, consum)}
            className="btn btn-sm btn-info"
            type="button"
            disabled={storeStatus?.loading}
          >
            {storeStatus?.loading ? "Please wait..." : "Add"}
          </Button>
        )
      } else {
        return <span className="text-danger">Out of Quantity</span>
      }
    } else {
      return
    }
  }

  const checkFormUpdate = (qty, consum) => {
    const value = quantity.find(qty => qty.item_id === consum?.item_id)
    if (value && value?.quantity !== "") {
      const total = showTotal(qty, consum?.item_id)
      if (total >= 0 && total <= qty + editField?.quantity && total !== NaN) {
        return (
          <Button
            onClick={e => onUpdateConsumption(value, consum)}
            className="btn btn-sm btn-success"
            type="button"
            disabled={storeStatus?.loading}
          >
            Update
          </Button>
        )
      } else {
        return <span className="text-danger">Out of Quantity</span>
      }
    } else {
      return
    }
  }

  return (
    <div className="table-responsive">
      <Table>
        <thead>
          <tr>
            <th style={{ whiteSpace: "nowrap" }}>UNFPA ITEM ID</th>
            <th style={{ whiteSpace: "nowrap" }}>IP- ITEM DESCRIPTION</th>
            <th style={{ whiteSpace: "nowrap" }}>UNFPA UOM</th>
            <th style={{ whiteSpace: "nowrap" }}>IP UOM</th>
            <th style={{ whiteSpace: "nowrap" }}>PROVINCE</th>
            <th style={{ whiteSpace: "nowrap" }}>FACILITY</th>
            <th style={{ whiteSpace: "nowrap" }}>OPENING BALANCE</th>
            <th style={{ whiteSpace: "nowrap" }}>CONSUMPTION</th>
            <th style={{ whiteSpace: "nowrap" }}>
              COMMENT &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </th>
            <th style={{ whiteSpace: "nowrap" }}>LAST BALANCE</th>
            <th style={{ whiteSpace: "nowrap" }}></th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data?.map((consum, key) => {
              const consumption = consum?.currentConsume ? (
                editField?.item_id === consum?.item_id ? (
                  <Input
                    name="update_consume"
                    placeholder="Qty"
                    defaultValue={editField?.quantity}
                    onChange={e => onQuantityChange(e, consum.item_id)}
                  />
                ) : (
                  consum?.currentConsume?.consume
                )
              ) : (
                <Input
                  name="consume"
                  placeholder="Qty"
                  onChange={e => onQuantityChange(e, consum.item_id)}
                />
              )

              console.log(consum)
              const consumptionComment = consum?.currentConsume ? (
                editField?.item_id === consum?.item_id ? (
                  <Input
                    name="update_comment"
                    placeholder="comment"
                    defaultValue={editField?.comment}
                    onChange={e => onCommnetChange(e, consum.item_id)}
                  />
                ) : (
                  consum?.currentConsume?.comment
                )
              ) : (
                <Input
                  name="comment"
                  placeholder="comment"
                  onChange={e => onCommnetChange(e, consum.item_id)}
                />
              )

              const opeining_balance =
                consum?.totalQty - parseInt(consum?.consumes)

              const currConsume = consum?.currentConsume
                ? consum?.currentConsume?.consume
                : 0

              const lastBalance =
                parseInt(consum?.totalQty) -
                (currConsume + parseInt(consum?.consumes))
              return (
                <tr key={key}>
                  <td style={{ whiteSpace: "nowrap" }}>
                    {consum?.item.unfp_item_name}
                  </td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    {consum?.item.ip_item_description}
                  </td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    {consum?.item.uom}{" "}
                    {consum?.item.unfpa_uom_quantity > 1 &&
                      consum?.item.unfpa_uom_quantity}
                  </td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    {consum?.item.ip_unit_umo}{" "}
                    {consum?.item.ip_uom_quantity > 1 &&
                      consum?.item.ip_uom_quantity}
                  </td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    {consum?.province?.province}
                  </td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    {consum?.facility?.facility_name} {consum?.facility_id}
                  </td>
                  <td>{opeining_balance}</td>
                  <td>{consumption}</td>
                  <td>{consumptionComment}</td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    {showTotal(lastBalance, consum?.item_id)}
                  </td>
                  <td>
                    {consum?.currentConsume &&
                      !consum?.afterCurrentConsume &&
                      consum?.item_id !== editField?.item_id && (
                        <Button
                          onClick={e => onEditConsumption(consum)}
                          className="btn btn-sm btn-info"
                          type="button"
                          disabled={storeStatus?.loading}
                        >
                          Edit
                        </Button>
                      )}
                    {consum?.item_id === editField?.item_id
                      ? checkFormUpdate(lastBalance, consum)
                      : checkFormAdd(lastBalance, consum)}
                  </td>
                </tr>
              )
            })}
        </tbody>
      </Table>
    </div>
  )
}

export default ProvinceConsumptionItems
