import React, { useState } from "react"
import { Pagination, PaginationItem, PaginationLink } from "reactstrap"
import { isEmpty, map } from "lodash"
const PaginationLinks = ({ meta, onPageClick }) => {
  const handlePageClick = (ev, link) => {
    ev.preventDefault()
    if (link.url) {
      onPageClick(link)
    }
  }

  return (
    <React.Fragment>
      <Pagination className="pagination pagination-rounded justify-content-center mt-3 mb-4 pb-1">
        {meta.links &&
          meta.links.map((link, ind) => (
            <PaginationItem active={link.active} disabled={!link.url} key={ind}>
              <PaginationLink
                previous={ind === 0}
                next={ind === meta.links.length - 1}
                onClick={ev => handlePageClick(ev, link)}
                to="#"
              >
                {ind !== 0 && ind !== meta.links.length - 1 && link.label}
              </PaginationLink>
            </PaginationItem>
          ))}
      </Pagination>

      <div style={{ textAlign: "right" }}>
        <p className="p-3">
          Showing {meta.from} to {meta.to} from {meta.total}
        </p>
      </div>
    </React.Fragment>
  )
}

export default PaginationLinks
