import axiosInstance from "api/axiosInstance"
import { monitorDashboardAction } from "./monitor-dashboard-slice"
import exportAxiosInstance from "api/exportAxiosInstance"
import FileSaver from "file-saver"

export const getMonitorStock = params => dispatch => {
  dispatch(
    monitorDashboardAction.setMonitorStock({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-monitor-stock`, { params: params ? params : {} })
    .then(respnose => {
      dispatch(
        monitorDashboardAction.setMonitorStock({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        monitorDashboardAction.setMonitorStock({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getMonitorIps = () => dispatch => {
  dispatch(
    monitorDashboardAction.setMonitorIps({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-monitor-ips`)
    .then(respnose => {
      dispatch(
        monitorDashboardAction.setMonitorIps({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        monitorDashboardAction.setMonitorIps({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getMonitorItemCategories = () => dispatch => {
  dispatch(
    monitorDashboardAction.setMonitorItemCategories({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-monitor-itemCategories`)
    .then(respnose => {
      dispatch(
        monitorDashboardAction.setMonitorItemCategories({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        monitorDashboardAction.setMonitorItemCategories({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getMonitorItems = id => dispatch => {
  dispatch(
    monitorDashboardAction.setMonitorItems({
      loading: true,
      error: null,
      data: [],
    })
  )
  axiosInstance
    .get(`/v1/get-monitor-items/${id}`)
    .then(respnose => {
      dispatch(
        monitorDashboardAction.setMonitorItems({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        monitorDashboardAction.setMonitorItems({
          loading: false,
          error: error?.response,
          data: [],
        })
      )
      console.log(error)
    })
}

export const exportMonitorIpsStock = params => dispatch => {
  dispatch(
    monitorDashboardAction.setMonitorIpsStockExport({
      loading: true,
      error: null,
    })
  )
  exportAxiosInstance
    .get(`/v1/export-monitor-ips-stock`, { params: params ? params : {} })
    .then(response => {
      const dataBlob = new Blob([response?.data], {
        type: "application/pdf,",
      })
      FileSaver.saveAs(
        dataBlob,
        `Monitor-IPs-Inventory-Report-${Math.random().toString()}.xlsx`
      )
      dispatch(
        monitorDashboardAction.setMonitorIpsStockExport({
          loading: false,
          error: null,
        })
      )
    })
    .catch(error => {
      dispatch(
        monitorDashboardAction.setMonitorIpsStockExport({
          loading: false,
          error: error?.response,
        })
      )
    })
}

export const getMonitorProjects = () => dispatch => {
  dispatch(
    monitorDashboardAction.setMonitorProjects({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-monitor-projects`)
    .then(respnose => {
      dispatch(
        monitorDashboardAction.setMonitorProjects({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        monitorDashboardAction.setMonitorProjects({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getMonitorInItems = params => dispatch => {
  dispatch(
    monitorDashboardAction.setMonitorInItems({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-monitor-stock-ins`, { params: params ? params : {} })
    .then(respnose => {
      dispatch(
        monitorDashboardAction.setMonitorInItems({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        monitorDashboardAction.setMonitorInItems({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getMonitorOutItems = params => dispatch => {
  dispatch(
    monitorDashboardAction.setMonitorOutItems({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-monitor-stock-outs`, { params: params ? params : {} })
    .then(respnose => {
      dispatch(
        monitorDashboardAction.setMonitorOutItems({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        monitorDashboardAction.setMonitorOutItems({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getMonitorIpsStock = params => dispatch => {
  dispatch(
    monitorDashboardAction.setMonitorIpsStock({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-monitor-ips-stock`, { params: params ? params : {} })
    .then(respnose => {
      dispatch(
        monitorDashboardAction.setMonitorIpsStock({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        monitorDashboardAction.setMonitorIpsStock({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getMonitorIPsInItems = params => dispatch => {
  dispatch(
    monitorDashboardAction.setMonitorInItems({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-monitor-ips-stock-ins`, { params: params ? params : {} })
    .then(respnose => {
      dispatch(
        monitorDashboardAction.setMonitorInItems({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        monitorDashboardAction.setMonitorInItems({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getMonitorIPsOutItems = params => dispatch => {
  dispatch(
    monitorDashboardAction.setMonitorOutItems({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-monitor-ips-stock-outs`, { params: params ? params : {} })
    .then(respnose => {
      dispatch(
        monitorDashboardAction.setMonitorOutItems({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        monitorDashboardAction.setMonitorOutItems({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getMonitorWarehouses = () => dispatch => {
  dispatch(
    monitorDashboardAction.setMonitorWarehouses({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-monitor-warehouses`)
    .then(respnose => {
      dispatch(
        monitorDashboardAction.setMonitorWarehouses({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        monitorDashboardAction.setMonitorWarehouses({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getMonitorProviders = () => dispatch => {
  dispatch(
    monitorDashboardAction.setMonitorProviders({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-monitor-providers`)
    .then(respnose => {
      dispatch(
        monitorDashboardAction.setMonitorProviders({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        monitorDashboardAction.setMonitorProviders({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getMonitorProviderUsers = ip_id => dispatch => {
  dispatch(
    monitorDashboardAction.setMonitorProviderUsers({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-monitor-provider-users/${ip_id}`)
    .then(respnose => {
      dispatch(
        monitorDashboardAction.setMonitorProviderUsers({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        monitorDashboardAction.setMonitorProviderUsers({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getMonitorProvinces = () => dispatch => {
  dispatch(
    monitorDashboardAction.setMonitorProvinces({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-monitor-provinces`)
    .then(respnose => {
      dispatch(
        monitorDashboardAction.setMonitorProvinces({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        monitorDashboardAction.setMonitorProvinces({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getMonitorFacilities = id => dispatch => {
  dispatch(
    monitorDashboardAction.setMonitorFacilities({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-monitor-facilities/${id}`)
    .then(respnose => {
      dispatch(
        monitorDashboardAction.setMonitorFacilities({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        monitorDashboardAction.setMonitorFacilities({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}
