import React, { useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Col, Form, Label, Row } from "reactstrap"
import Select from "react-select"
import { isObject } from "lodash"
import { provinceDashboardAction } from "store/province-dashboard/province-dashboard-slice"
import {
  getProvinceConsumptions,
  getProvinceConsumptionsFacilities,
  getProvinceConsumptionsProjects,
  getProvinceConsumptionsProvinces,
} from "store/province-consumptions/province-consumption-action"
import { getYears } from "utils/utils"
import { provinceConsumptionAction } from "store/province-consumptions/province-consumption-slice"

const ProvinceConsumptionFilter = ({
  onAdvancedSearchSubmit,
  loading,
  setQuantity,
  error,
}) => {
  const [providerAccount, setProviderAccount] = useState(null)
  const [project, setProject] = useState(null)
  const [province, setProvince] = useState(null)
  const [facility, setFacility] = useState(null)
  const [month, setMonth] = useState("")
  const [year, setYear] = useState("")

  const providerAccounts = useSelector(
    state => state.provinceConsumptions.provinceConsumpProviderAccount
  )
  const projects = useSelector(
    state => state.provinceConsumptions.provinceConsumpProjects
  )

  const provinces = useSelector(
    state => state.provinceConsumptions.provinceConsumpProvinces
  )

  const facilities = useSelector(
    state => state.provinceConsumptions.provinceConsumpFacilities
  )

  const years = useMemo(() => getYears())

  const dispatch = useDispatch()

  const handleSubmit = () => {
    let data = {}
    data.provided_by = isObject(providerAccount) ? providerAccount.value : null
    data.province_id = isObject(province) ? province.value : null
    data.project_id = isObject(project) ? project.value : null
    data.facility_id = isObject(facility) ? facility.value : null
    data.year = isObject(year) ? year.value : null
    data.month = isObject(month) ? month.value : null
    setQuantity([])
    onAdvancedSearchSubmit(data)
    dispatch(provinceConsumptionAction.resetStore())
  }

  const onProvinceChangeHandler = value => {
    setProvince(value)
    setFacility("")
    dispatch(
      getProvinceConsumptionsFacilities({
        providerAccount: providerAccount.value,
        project_id: project.value,
        province_id: value.value,
      })
    )
  }

  const onFacilityChangeHandler = value => {
    setFacility(value)
  }

  const onProviderAccountChangeHandler = value => {
    dispatch(
      getProvinceConsumptionsProjects({
        providerAccount: value.value,
      })
    )
    setProviderAccount(value)
  }
  const onProjectChangeHandler = value => {
    dispatch(
      getProvinceConsumptionsProvinces({
        providerAccount: providerAccount.value,
        project_id: value.value,
      })
    )
    setProject(value)
  }

  const onClearHandler = () => {
    setProvince(null)
    setFacility(null)
    setYear(null)
    setMonth(null)
    setProviderAccount(null)
    setProject(null)
    onAdvancedSearchSubmit({})
    setQuantity([])
    dispatch(provinceDashboardAction.resetProvinceItems())
    dispatch(provinceConsumptionAction.resetState())
    dispatch(provinceConsumptionAction.resetStore())
  }

  return (
    <Form
      onSubmit={e => {
        e.preventDefault()
        handleSubmit()
        return false
      }}
    >
      <div className="mb-3">
        <Row>
          <Col>
            <Label className="form-label">Provider Account</Label>
            {providerAccounts?.data && (
              <Select
                options={providerAccounts?.data}
                value={providerAccount}
                onChange={onProviderAccountChangeHandler}
              />
            )}
            {error?.errors?.province_id?.[0] && (
              <p className="text-danger" style={{ fontSize: 10 }}>
                {error?.errors?.province_id?.[0]}
              </p>
            )}
          </Col>
          <Col>
            <Label className="form-label">Projects</Label>
            {projects?.data && (
              <Select
                options={projects?.data}
                value={project}
                onChange={onProjectChangeHandler}
              />
            )}
            {error?.errors?.facility_id?.[0] && (
              <p className="text-danger" style={{ fontSize: 10 }}>
                {error?.errors?.facility_id?.[0]}
              </p>
            )}
          </Col>
        </Row>
      </div>

      <div className="mb-3">
        <Row>
          <Col>
            <Label className="form-label">Province</Label>
            {provinces?.data && (
              <Select
                options={provinces?.data}
                value={province}
                onChange={onProvinceChangeHandler}
              />
            )}
            {error?.errors?.province_id?.[0] && (
              <p className="text-danger" style={{ fontSize: 10 }}>
                {error?.errors?.province_id?.[0]}
              </p>
            )}
          </Col>
          <Col>
            <Label className="form-label">Facilities</Label>
            {facilities?.data && (
              <Select
                options={facilities?.data}
                value={facility}
                onChange={onFacilityChangeHandler}
              />
            )}
            {error?.errors?.facility_id?.[0] && (
              <p className="text-danger" style={{ fontSize: 10 }}>
                {error?.errors?.facility_id?.[0]}
              </p>
            )}
          </Col>
        </Row>
      </div>

      <div className="mb-3">
        <Row>
          <Col>
            <Label className="form-label">Month</Label>
            <Select
              options={[
                { value: "01", label: "January" },
                { value: "02", label: "February" },
                { value: "03", label: "March" },
                { value: "04", label: "April" },
                { value: "05", label: "May" },
                { value: "06", label: "June" },
                { value: "07", label: "July" },
                { value: "08", label: "August" },
                { value: "09", label: "September" },
                { value: "10", label: "October" },
                { value: "11", label: "November" },
                { value: "12", label: "December" },
              ]}
              value={month}
              onChange={value => {
                setMonth(value)
              }}
            />
            {error?.errors?.month?.[0] && (
              <p className="text-danger" style={{ fontSize: 10 }}>
                {error?.errors?.month?.[0]}
              </p>
            )}
          </Col>
          <Col>
            <Label className="form-label">Year</Label>
            <Select
              options={years}
              value={year}
              onChange={value => {
                setYear(value)
              }}
            />
            {error?.errors?.year?.[0] && (
              <p className="text-danger" style={{ fontSize: 10 }}>
                {error?.errors?.year?.[0]}
              </p>
            )}
          </Col>
        </Row>
      </div>

      <div className="d-flex flex-wrap gap-2 mb-4">
        <Button
          type="submit"
          className="btn btn-sm btn-primary"
          color="primary"
          disabled={loading}
        >
          {loading ? "Please Wait..." : "Search"}
        </Button>
        <Button
          onClick={onClearHandler}
          className="btn btn-sm btn-danger"
          color="primary"
        >
          Clear
        </Button>
      </div>
    </Form>
  )
}

export default ProvinceConsumptionFilter
