import React, { useState } from "react"
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"

const ProvinceInDetailModal = ({ data, open, setOpen }) => {
  return (
    <Modal
      isOpen={open}
      role="dialog"
      autoFocus={true}
      centered={true}
      tabIndex="-1"
      toggle={() => {
        setOpen(!open)
      }}
      size="xl"
    >
      <div>
        <ModalHeader
          toggle={() => {
            setOpen(!open)
          }}
        >
          Summary
        </ModalHeader>
        <ModalBody>
          <div className="table-responsive">
            <div
              style={{
                borderBottom: "0.8px solid #9facc2",
                padding: 20,
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              <Row>
                <Col lg={6}>
                  <div>
                    <h6 className="detailTitle">Current Quantity</h6>
                    <p className="detailValue">{data?.in_qty} </p>
                  </div>
                </Col>
                <Col lg={6}>
                  <div>
                    <h6 className="detailTitle">First Quantity</h6>
                    <p className="detailValue">{data?.first_in_qty} </p>
                  </div>
                </Col>
              </Row>
            </div>
            <Row
              style={{
                justifyContent: "center",
                textAlign: "center",
                marginTop: 20,
              }}
            >
              <Col lg={6}>
                <div>
                  <h6 className="detailTitle">Provider</h6>
                  <p className="detailValue">{data?.provider?.title}</p>
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <h6 className="detailTitle">Item Category</h6>
                  <p className="detailValue">{data?.item_category?.name}</p>
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <h6 className="detailTitle">Item</h6>
                  <p className="detailValue">{data?.item?.item_name}</p>
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <h6 className="detailTitle">Province</h6>
                  <p className="detailValue">{data?.province?.province}</p>
                </div>
              </Col>

              <Col lg={6}>
                <div>
                  <h6 className="detailTitle">Batch / lot number</h6>
                  <p className="detailValue">{data?.batch_number}</p>
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <h6 className="detailTitle">Expiry / best before date</h6>
                  <p className="detailValue">{data?.expire_date}</p>
                </div>
              </Col>
            </Row>
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default ProvinceInDetailModal
