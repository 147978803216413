import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  provinceStock: { error: null, loading: false, data: [] },
  ProvinceIps: { error: null, loading: false, data: [] },
  ProvinceItemCategoires: { error: null, loading: false, data: [] },
  ProvinceItems: { error: null, loading: false, data: [] },
  ProvinceInItems: { data: [], error: null, loading: null },
  ProvinceOutItems: { data: [], error: null, loading: null },
  provinceWarehouses: { error: null, loading: false, data: [] },
  provinceProvinces: { error: null, loading: false, data: [] },
  provinceProviders: { error: null, loading: false, data: [] },
  provinceFacilities: { error: null, loading: false, data: [] },
  provinceProjects: { error: null, loading: false, data: [] },
  exportStatus: { loading: false, error: null },
}

const provinceDashboardSlice = createSlice({
  name: "provinceDashboard",
  initialState,
  reducers: {
    setProvinceStock(state, action) {
      state.provinceStock = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setProvinceIps(state, action) {
      state.ProvinceIps = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setProvinceProjects(state, action) {
      state.provinceProjects = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setProvinceItemCategories(state, action) {
      state.ProvinceItemCategoires = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setProvinceItems(state, action) {
      state.ProvinceItems = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setProvinceInItems(state, action) {
      state.ProvinceInItems = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setProvinceOutItems(state, action) {
      state.ProvinceOutItems = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },

    setProvinceWarehouses(state, action) {
      state.provinceWarehouses = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setProvinceProvinces(state, action) {
      state.provinceProvinces = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setProvinceProvider(state, action) {
      state.provinceProviders = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },

    setProvinceFacilities(state, action) {
      state.provinceFacilities = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setExportStatus(state, action) {
      state.exportStatus = {
        loading: action.payload.loading,
        error: action.payload.error,
      }
    },
    resetProvinceStock(state) {
      state.provinceStock = { error: null, loading: false, data: [] }
    },
    resetProvinceIps(state) {
      state.ProvinceIps = { error: null, loading: false, data: [] }
    },
    resetProvinceItemCategories(state) {
      state.ProvinceItemCategoires = { error: null, loading: false, data: [] }
    },
    resetProvinceItems(state) {
      state.ProvinceItems = { error: null, loading: false, data: [] }
    },
    resetProvinceInItems(state) {
      state.ProvinceInItems = { data: [], error: null, loading: null }
    },
    resetProvinceOutItems(state) {
      state.ProvinceOutItems = { data: [], error: null, loading: null }
    },

    resetProvinceWarehouses(state) {
      state.provinceWarehouses = { data: [], error: null, loading: null }
    },
    resetProvinceProvinces(state) {
      state.provinceProvinces = { data: [], error: null, loading: null }
    },
    resetProvinceProvider(state) {
      state.provinceProviders = { data: [], error: null, loading: null }
    },

    resetProvinceFacilities(state) {
      state.provinceFacilities = { data: [], error: null, loading: null }
    },
    resetProvinceProjects(state) {
      state.provinceProjects = { data: [], error: null, loading: null }
    },
    resetExportStatus(state) {
      state.exportStatus = { loading: false, error: null }
    },
  },
})

export const provinceDashboardAction = provinceDashboardSlice.actions

export default provinceDashboardSlice
