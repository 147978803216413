import React from "react"
import { Row, Col, CardBody, Card } from "reactstrap"
import WelcomeComp from "./Welcome"
import OtherLinks from "./OtherLinks"

const AdminDashboardComponent = ({ data, error, loading, user }) => {
  return (
    <div>
      {!loading && (
        <Row>
          <Col lg={4}>
            <WelcomeComp user={user} />
            <OtherLinks />
          </Col>
          <Col>
            {" "}
            <Row>
              <Row>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            {"Total Warehouse"}
                          </p>
                          <h4 className="mb-0">{data?.warehouseCount}</h4>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            {"Total Providers"}
                          </p>
                          <h4 className="mb-0">{data?.providerCount}</h4>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">{"Total IPs"}</p>
                          <h4 className="mb-0">{data?.ipCount}</h4>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            {"Total Item Category"}
                          </p>
                          <h4 className="mb-0">{data?.itemCategoryCount}</h4>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            {"Total Items"}
                          </p>
                          <h4 className="mb-0">{data?.itemCount}</h4>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            {"Total Facility"}
                          </p>
                          <h4 className="mb-0">{data?.facilityCount}</h4>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            {"Total Province"}
                          </p>
                          <h4 className="mb-0">{data?.provinceCount}</h4>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">{"Total User"}</p>
                          <h4 className="mb-0">{data?.userCount}</h4>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
      )}
    </div>
  )
}

export default AdminDashboardComponent
