import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { isEmpty, isObject } from "lodash"
import Preloader from "components/Common/Loader"
import { providerOutAction } from "store/provider-out/provider-out-slice"
import {
  getProvinceIps,
  getProvinceItemCategories,
  getProvinceProvider,
  getProvinceProvinces,
} from "store/province-dashboard/province-dashboard-action"
import {
  getByIdProvinceOut,
  updateProvinceOut,
} from "store/province-out/province-out-action"
import ProvinceOutEditForm from "components/ProvinceOut/EditForm"
import { provinceOutAction } from "store/province-out/province-out-slice"

const EditProvinceOut = () => {
  document.title = "Edit Distributon"

  const params = useParams()
  const { id } = params

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { loading, error } = useSelector(state => state.provinceOut)
  const distribution = useSelector(state => state.provinceOut.distribution)

  const providers = useSelector(
    state => state.provinceDashboard.provinceProviders
  )

  const categories = useSelector(
    state => state.provinceDashboard.ProvinceItemCategoires
  )
  // const ips = useSelector(state => state.provinceDashboard.ProvinceIps)
  const items = useSelector(state => state.provinceDashboard.ProvinceItems)
  const provinces = useSelector(
    state => state.provinceDashboard.provinceProvinces
  )
  const facilities = useSelector(
    state => state.provinceDashboard.provinceFacilities
  )

  // console.log(distribution?.data?.province_in_id)

  const handleSubmit = values => {
    const forms = new FormData()

    forms.append("_method", "put")
    forms.append("province_in_id", distribution?.data?.province_in_id)
    forms.append("id", distribution?.data?.id)
    forms.append(
      "item_category_id",
      isObject(values.item_category_id)
        ? values.item_category_id.value
        : values.item_category_id
    )
    forms.append(
      "item_id",
      isObject(values.item_id) ? values.item_id.value : values.item_id
    )

    forms.append(
      "provider_id",
      isObject(values.provider_id)
        ? values.provider_id.value
        : values.provider_id
    )
    forms.append(
      "province_id",
      isObject(values.province_id)
        ? values.province_id.value
        : values.province_id
    )
    forms.append(
      "facility_id",
      isObject(values.facility_id)
        ? values.facility_id.value
        : values.facility_id
    )
    forms.append("out_quantity", values.out_quantity)
    forms.append("distributor", values.distributor)
    forms.append("date", values.date)

    dispatch(updateProvinceOut(id, forms, navigate))
  }

  useEffect(() => {
    dispatch(getProvinceProvider())
    dispatch(getProvinceItemCategories())
    dispatch(getProvinceProvinces())
    // dispatch(getProvinceIps())
    dispatch(getByIdProvinceOut(id))
    return () => {
      dispatch(provinceOutAction.resetState())
    }
  }, [])

  return (
    <React.Fragment>
      {!isEmpty(distribution?.data) ? (
        <ProvinceOutEditForm
          onSubmit={handleSubmit}
          loading={loading}
          error={error}
          formMode={"Edit"}
          providers={providers?.data}
          categories={categories?.data}
          items={items?.data}
          // ips={ips?.data}
          provinces={provinces?.data}
          facilities={facilities?.data}
          distribution={distribution?.data}
        />
      ) : (
        <Preloader loading={true} />
      )}
    </React.Fragment>
  )
}

export default EditProvinceOut
