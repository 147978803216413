import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: [],
  loading: false,
  error: null,
  request: null,
  ActionRequestCount: { receipt: 0, distribution: 0 },
  ids: [],
}

const ProvActionRequestSlice = createSlice({
  name: "ProvActionRequest",
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    setError(state, action) {
      state.error = action.payload
    },
    setProject(state, action) {
      state.project = action.payload
    },
    setActionRequestCount(state, action) {
      state.ActionRequestCount = action.payload
    },
    setIds(state, action) {
      state.ids = action.payload
    },
    setNewIds(state, action) {
      state.ids = [...state.ids, action.payload]
    },
    resetState(state) {
      state.data = []
      state.loading = false
      state.error = null
      state.project = null
    },
    resetIds(state) {
      state.ids = []
    },
  },
})

export const ProvActionRequestAction = ProvActionRequestSlice.actions

export default ProvActionRequestSlice
