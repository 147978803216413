import CustomHeaderTitle from "components/Common/CustomHeaderTitle"
import GeneralTables from "components/Common/GeneralTable"
import React from "react"
import { Card, CardBody } from "reactstrap"

const MonitorChartInItemsList = ({ data, loading, type }) => {
  let total = 0
  if (type === "balance") {
    total = data?.reduce((curr, obj) => curr + parseInt(obj.in_qty_per_ip), 0)
  } else {
    total = data?.reduce(
      (curr, obj) => curr + parseInt(obj.first_in_qty_per_ip),
      0
    )
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CustomHeaderTitle
            loading={loading}
            title={`Provider ${type === "balance" ? "Balance" : "Receipts"}`}
            lgColumn={4}
            rowCount={false}
            orderingList={false}
            searchBox={false}
            rightBtn={true}
            rightBtnTitle={"Back"}
            rightBtnRoute={"/dashboard"}
          />

          <GeneralTables
            data={data}
            loading={loading}
            headers={[
              "#",
              "UNFPA ITEM ID",
              "IP-ITEM DESCRIPTION",
              "BATCH NUMBER",
              "EXPIRE DATE",
              "QUANTITY",
              "RECEIPTS DATE",
            ]}
            renderItem={(ins, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{ins.item?.unfp_item_name}</td>
                <td>{ins.item?.ip_item_description}</td>
                <td>{ins.batch_number}</td>
                <td>{ins.expire_date}</td>
                <td>
                  {type === "balance"
                    ? ins.in_qty_per_ip
                    : ins.first_in_qty_per_ip}
                </td>
                <td>{ins.date}</td>
              </tr>
            )}
          />
          <br />

          <div style={{ textAlign: "center", fontSize: 18, fontWeight: 500 }}>
            <p>Total: {total}</p>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default MonitorChartInItemsList
