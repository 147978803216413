import Preloader from "components/Common/Loader"
import RoleForm from "components/Role/form"
import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { getByIdRole, updateRolePermission } from "store/role/role-action"
import { roleAction } from "store/role/role-slice"

const EditRole = () => {
  document.title = "Edit Role"
  const { id } = useParams()
  const navigate = useNavigate()
  const { loading, error, role } = useSelector(state => state.role)
  const permissionResponse = useSelector(state => state.role.permissionChange)

  const dispatch = useDispatch()

  const onChangePermission = (permission, status) => {
    dispatch(updateRolePermission(id, permission, status))
  }

  useEffect(() => {
    dispatch(getByIdRole(id))

    return () => {
      dispatch(roleAction.resetState())
    }
  }, [dispatch])

  if (!role) {
    return <Preloader loading={true} />
  }

  return (
    <React.Fragment>
      {role && (
        <>
          <RoleForm
            loading={loading}
            error={error}
            role={role}
            formMode={"Edit"}
            onChangePermission={onChangePermission}
            permissionResponse={permissionResponse}
          />
        </>
      )}
    </React.Fragment>
  )
}

export default EditRole
