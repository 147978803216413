import fileaxiosInstance from "api/fileAxiosInstance"
import SAlert from "components/Alert"
import { batch } from "react-redux"
import { provinceOutAction } from "./province-out-slice"
import axiosInstance from "api/axiosInstance"
import exportAxiosInstance from "api/exportAxiosInstance"
import FileSaver from "file-saver"

export const getProvinceOuts =
  (
    url,
    pageNumber = 10,
    according = { order: "desc", name: "id" },
    search = null,
    advancedSearch = {}
  ) =>
  dispatch => {
    dispatch(provinceOutAction.setLoading(true))
    url = url || "/v1/province-outs"
    fileaxiosInstance
      .get(url, {
        params: {
          pageNumber: pageNumber,
          according: according,
          search: search,
          advancedSearch: advancedSearch,
        },
      })
      .then(response => {
        if (response) {
          batch(() => {
            dispatch(provinceOutAction.setError(null))
            dispatch(provinceOutAction.setData(response.data))
            dispatch(provinceOutAction.setLoading(false))
          })
        }
      })
      .catch(error => {
        dispatch(provinceOutAction.setLoading(false))
      })
  }

export const deleteManyProvinceOut = ids => dispatch => {
  dispatch(provinceOutAction.setLoading(true))
  fileaxiosInstance
    .post("/v1/province-outs/delete/outs", { ids })
    .then(response => {
      dispatch(getProvinceOuts())
      SAlert({ title: "Success", text: "Distributions successfully delete" })
      batch(() => {
        dispatch(provinceOutAction.setError(null))
        dispatch(provinceOutAction.setLoading(false))
      })
    })
    .catch(error => {
      dispatch(provinceOutAction.setLoading(false))
      if (error?.response?.data?.deleteRistraction) {
        SAlert({
          text: error?.response?.data?.deleteRistraction,
          status: "warning",
          timer: 5000,
        })
      }
      console.log(error)
    })
}

export const deleteProvinceOut = id => dispatch => {
  dispatch(provinceOutAction.setLoading(true))
  fileaxiosInstance
    .delete(`/v1/province-outs/${id}`)
    .then(response => {
      dispatch(getProvinceOuts())
      SAlert({ title: "Success", text: "Receipt successfully delete" })
      batch(() => {
        dispatch(provinceOutAction.setError(null))
        dispatch(provinceOutAction.setLoading(false))
      })
    })
    .catch(error => {
      dispatch(provinceOutAction.setLoading(false))
      if (error?.response?.data?.deleteRistraction) {
        SAlert({
          text: error?.response?.data?.deleteRistraction,
          status: "warning",
          timer: 5000,
        })
      }
      console.log(error)
    })
}

export const storeProvinceOut = (requests, navigate) => dispatch => {
  dispatch(provinceOutAction.setLoading(true))
  axiosInstance
    .post(`/v1/province-outs`, requests)
    .then(response => {
      SAlert({ title: "Success", text: "Receipt successfully inserted" })
      navigate("/province-distributions")
      batch(() => {
        dispatch(provinceOutAction.setError(null))
        dispatch(provinceOutAction.setLoading(false))
      })
    })
    .catch(error => {
      dispatch(provinceOutAction.setLoading(false))
      dispatch(provinceOutAction.setError(error?.response?.data?.errors))
      if (error?.response?.data?.error) {
        dispatch(
          provinceOutAction.setStoreQuantityError(error?.response?.data?.error)
        )
      }
    })
}

export const updateProvinceOut = (id, form, navigate) => dispatch => {
  dispatch(provinceOutAction.setLoading(true))
  axiosInstance
    .put(`/v1/province-outs/${id}`, form)
    .then(response => {
      navigate("/province-distributions")
      SAlert({ title: "Success", text: "Distribution successfully updated" })
      batch(() => {
        dispatch(provinceOutAction.setError(null))
        dispatch(provinceOutAction.setLoading(false))
      })
    })
    .catch(error => {
      dispatch(provinceOutAction.setLoading(false))
      dispatch(provinceOutAction.setError(error?.response?.data?.errors))
      console.log(error)
    })
}

export const getByIdProvinceOut = id => dispatch => {
  dispatch(provinceOutAction.setLoading(true))
  fileaxiosInstance
    .get(`/v1/province-outs/${id}`)
    .then(response => {
      batch(() => {
        dispatch(provinceOutAction.setError(null))
        dispatch(provinceOutAction.setProvinceOut(response?.data))
        dispatch(provinceOutAction.setLoading(false))
      })
    })
    .catch(error => {
      dispatch(provinceOutAction.setLoading(false))
      console.log(error)
    })
}

export const getProvinceOutReceipts =
  (params = {}) =>
  dispatch => {
    dispatch(
      provinceOutAction.setProvinceOutReceipts({
        loading: true,
        error: null,
        data: null,
      })
    )
    fileaxiosInstance
      .get(`/v1/get-province-out-receipts`, { params: params })
      .then(respnose => {
        dispatch(
          provinceOutAction.setProvinceOutReceipts({
            loading: false,
            error: null,
            data: respnose?.data,
          })
        )
      })
      .catch(error => {
        dispatch(
          provinceOutAction.setProvinceOutReceipts({
            loading: false,
            error: error?.response,
            data: null,
          })
        )
        console.log(error)
      })
  }

export const getProvinceOutProvinces = () => dispatch => {
  dispatch(
    provinceOutAction.setProvinces({
      loading: true,
      error: null,
      data: [],
    })
  )
  axiosInstance
    .get(`/v1/get-province-out-provinces`)
    .then(respnose => {
      dispatch(
        provinceOutAction.setProvinces({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        provinceOutAction.setProvinces({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getProvinceOutItems = category_id => dispatch => {
  dispatch(
    provinceOutAction.setItems({
      loading: true,
      error: null,
      data: [],
    })
  )
  axiosInstance
    .get(`/v1/get-province-out-items/${category_id}`)
    .then(respnose => {
      dispatch(
        provinceOutAction.setItems({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        provinceOutAction.setItems({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const exportProvinceOut =
  (advancedSearch = {}) =>
  dispatch => {
    dispatch(
      provinceOutAction.setExportStatus({
        loading: true,
        error: null,
      })
    )

    exportAxiosInstance
      .get("/v1/export-province-outs", {
        params: advancedSearch,
      })
      .then(response => {
        if (response) {
          const dataBlob = new Blob([response?.data], {
            type: "application/pdf,",
          })
          FileSaver.saveAs(
            dataBlob,
            `Province-Out-${Math.random().toString()}.xlsx`
          )
          dispatch(
            provinceOutAction.setExportStatus({
              loading: false,
              error: null,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          provinceOutAction.setExportStatus({
            loading: false,
            error: error?.response?.errors,
          })
        )
        console.log(error)
      })
  }
