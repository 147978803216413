import React, { useState } from "react"
import { Input } from "reactstrap"

const PasswordInput = ({
  placeholder,
  name,
  onChange,
  onBlur,
  value,
  invalid,
}) => {
  const [show, setShow] = useState(false)

  return (
    <React.Fragment>
      <Input
        name={name}
        placeholder={placeholder ? placeholder : "Password"}
        type={show ? "text" : "password"}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        invalid={invalid}
      />
      <div
        style={{ position: "absolute", top: "46%", right: 20 }}
        onClick={() => setShow(!show)}
      >
        {show ? (
          <i className="bx bx-hide" style={{ fontSize: 19 }}></i>
        ) : (
          <i
            className="bx bx-show
          "
            style={{ fontSize: 19 }}
          ></i>
        )}
      </div>
    </React.Fragment>
  )
}

export default PasswordInput
