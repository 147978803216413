import React, { useEffect } from "react"
import HorizontalLayout from "components/HorizontalLayout"
import { Navigate, Outlet, useRouteLoaderData } from "react-router-dom"
import { getAuthToken } from "utils/authUtil"
import { useDispatch, useSelector } from "react-redux"
import { authAction } from "store/auth/auth-slice"
import { Container } from "reactstrap"
import axiosInstance from "api/axiosInstance"
import Preloader from "components/Common/Loader"
import { isEmpty } from "lodash"

const RootLayout = () => {
  const { isLoggedIn, loading, user } = useSelector(state => state.auth)
  const loaderToken = useRouteLoaderData("root")

  const dispatch = useDispatch()
  const token = getAuthToken()
  // const privateAxios = usePrivateAxios()

  const getUserRefreshData = () => {
    if (token) {
      dispatch(authAction.setLoading(true))
      axiosInstance
        .get("/v1/get-user")
        .then(response => {
          const user = response?.data?.data?.user
          const role = response?.data?.data?.role
          const permissions = response?.data?.data?.permissions
          dispatch(authAction.setLoading(false))
          dispatch(authAction.loggedIn({ user, token, role, permissions }))
        })
        .catch(error => {
          dispatch(authAction.setLoading(false))
          console.log(error)
        })
    } else {
      dispatch(authAction.setLoading(false))
    }
  }

  useEffect(() => {
    getUserRefreshData()
  }, [isLoggedIn])

  if (token && isEmpty(user)) {
    return <Preloader loading={true} />
  }

  if (isLoggedIn) {
    return (
      <HorizontalLayout>
        <div className="page-content">
          <Outlet />
        </div>
      </HorizontalLayout>
    )
  } else {
    return <Navigate to="/login" />
  }
}

export default RootLayout
