import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import useSearch from "hooks/useSearch"
import {
  exportMonitorProvinceDistributionReport,
  getMonitorProvinceDistributionReports,
} from "store/monitor-reports/monitor-report-action"
import {
  getMonitorIps,
  getMonitorItemCategories,
} from "store/monitor-dashboard/monitor-dashboard-action"
import MonitorProvinceDistributionListComponent from "components/MonitorReports/IPDistribution"

const MonitorProvinceDistributionReportList = () => {
  document.title = "IP Distribution Reports"

  const dispatch = useDispatch()
  const distributions = useSelector(
    state => state.monitorReports.monitorProvicneDistributionReport
  )
  const exportStatus = useSelector(
    state => state.monitorReports.monitorProvinceExportDistributionReport
  )
  const [search, setSearch] = useState(null)
  const [pageNumber, setPageNumber] = useState(20)
  const [according, setAccording] = useState({ order: "desc", name: "date" })
  const [advnacedSearch, setAdvancedSearch] = useState({})

  const onSearchHandler = value => {
    setSearch(value)
  }

  const onPageClick = link => {
    dispatch(
      getMonitorProvinceDistributionReports(
        link.url,
        pageNumber,
        according,
        search,
        advnacedSearch
      )
    )
  }

  const onChangeRowNumber = value => {
    setPageNumber(value)
  }

  const onChangeOrdering = value => {
    if (value.order) {
      setAccording(prev => {
        return { ...prev, order: value.order }
      })
    }
    if (value.name) {
      setAccording(prev => {
        return { ...prev, name: value.name }
      })
    }
  }

  useSearch(
    getMonitorProvinceDistributionReports(
      undefined,
      undefined,
      undefined,
      search,
      advnacedSearch
    ),
    search
  )

  useEffect(() => {
    dispatch(
      getMonitorProvinceDistributionReports(
        undefined,
        pageNumber,
        according,
        search,
        advnacedSearch
      )
    )
  }, [pageNumber, according])

  useEffect(() => {
    dispatch(getMonitorItemCategories())
    dispatch(getMonitorIps())
  }, [])

  const onAdvancedSearchSubmit = data => {
    setAdvancedSearch(data)
    dispatch(
      getMonitorProvinceDistributionReports(
        undefined,
        undefined,
        undefined,
        search,
        data
      )
    )
  }

  const onExportBtnClick = () => {
    dispatch(
      exportMonitorProvinceDistributionReport(
        pageNumber,
        according,
        search,
        advnacedSearch
      )
    )
  }

  return (
    <React.Fragment>
      <Breadcrumbs
        title={"Monitor"}
        breadcrumbItem={"IPs Distribution Report"}
      />
      <MonitorProvinceDistributionListComponent
        data={distributions?.data}
        error={distributions?.error}
        loading={distributions?.loading}
        onSearch={onSearchHandler}
        onChangeRowNumber={onChangeRowNumber}
        onChangeOrdering={onChangeOrdering}
        onAdvancedSearchSubmit={onAdvancedSearchSubmit}
        onExportBtnClick={onExportBtnClick}
        exportTransaction={exportStatus}
        onPageClick={onPageClick}
      />
    </React.Fragment>
  )
}

export default MonitorProvinceDistributionReportList
