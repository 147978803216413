import { userAction } from "./user-slice"
import axiosInstance from "api/axiosInstance"
import SAlert from "components/Alert"
import { batch } from "react-redux"

export const getUsers =
  (
    url,
    pageNumber = 10,
    according = { order: "desc", name: "id" },
    search = null
  ) =>
  dispatch => {
    dispatch(userAction.setLoading(true))
    url = url || "/v1/users"
    axiosInstance
      .get(url, {
        params: {
          pageNumber: pageNumber,
          according: according,
          search: search,
        },
      })
      .then(response => {
        if (response) {
          batch(() => {
            dispatch(userAction.setError(null))
            dispatch(userAction.setData(response.data))
            dispatch(userAction.setLoading(false))
          })
        }
      })
      .catch(error => {
        dispatch(userAction.setLoading(false))
        console.log(error)
      })
  }

export const storeUser = (data, navigate) => dispatch => {
  dispatch(userAction.setLoading(true))
  axiosInstance
    .post("/v1/register-user", data)
    .then(response => {
      navigate("/users")
      SAlert({ title: "Success", text: "User successfully inserted" })
      batch(() => {
        dispatch(userAction.setError(null))
        dispatch(userAction.setLoading(false))
      })
    })
    .catch(err => {
      dispatch(userAction.setLoading(false))
      dispatch(userAction.setError(err?.response?.data?.errors))
    })
}

export const updateUser = (id, data, navigate) => dispatch => {
  dispatch(userAction.setLoading(true))
  axiosInstance
    .post(`/v1/update-user/${id}`, data)
    .then(response => {
      navigate("/users")
      SAlert({ title: "Success", text: "User successfully update" })
      batch(() => {
        dispatch(userAction.setError(null))
        dispatch(userAction.setLoading(false))
      })
    })
    .catch(err => {
      dispatch(userAction.setLoading(false))
      dispatch(userAction.setError(err?.response?.data?.errors))
    })
}

export const getByIdUser = id => dispatch => {
  dispatch(userAction.setLoading(true))
  axiosInstance
    .get(`/v1/user/${id}`)
    .then(respnose => {
      batch(() => {
        dispatch(userAction.setError(null))
        dispatch(userAction.setLoading(false))
        dispatch(userAction.setUser(respnose.data))
      })
    })
    .catch(error => {
      dispatch(userAction.setLoading(false))
      console.log(error)
    })
}

export const deleteUser = id => dispatch => {
  dispatch(userAction.setLoading(true))
  SAlert({ title: "Success", text: "User successfully deleted" })
  axiosInstance
    .delete(`/v1/user/${id}`)
    .then(respnose => {
      dispatch(getUsers())
      batch(() => {
        dispatch(userAction.setError(null))
        dispatch(userAction.setLoading(false))
      })
    })
    .catch(error => {
      dispatch(userAction.setLoading(false))
      console.log(error)
    })
}

export const updateDirectPermission = (id, permission, status) => dispatch => {
  dispatch(
    userAction.setPermissionChange({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .post(`/v1/user/${id}/update-permission`, { permission, status })
    .then(respnose => {
      SAlert({
        title: "Success",
        text: "User Direct Permission successfully updated",
      })
      dispatch(
        userAction.setPermissionChange({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        userAction.setPermissionChange({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}
