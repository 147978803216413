import { configureStore } from "@reduxjs/toolkit"
import layoutSlice from "./layout/layout-slice"
import authSlice from "./auth/auth-slice"
import warehouseSlice from "./warehouse/warehouse-slice"
import providerSlice from "./providers/provider-slice"
import provinceSlice from "./province/province-slice"
import facilitySlice from "./facility/facility-slice"
import generalSlice from "./general-renders/general-slice"
import ipsSlice from "./ips/ips-slice"
import itemCategorySlice from "./item-category/item-category-slice"
import itemSlice from "./item/item-slice"
import adminDashboardSlice from "./admin/admin-dashboard-slice"
import userSlice from "./user/user-slice"
import roleSlice from "./role/role-slice"
import projectSlice from "./project/project-slice"
import providerDashboardSlice from "./provider-dashboard/provider-dashboard-slice"
import providerInSlice from "./provider-in/provider-in-slice"
import providerOutSlice from "./provider-out/provider-out-slice"
import providerExpireReceiptsSlice from "./provider-exipre-receipts/p-expire-receipts-slice"
import providerReportsSlice from "./provider-reports/p-report-slice"
import provinceDashboardSlice from "./province-dashboard/province-dashboard-slice"
import provinceInSlice from "./province-in/province-in-slice"
import provinceOutSlice from "./province-out/province-out-slice"
import provinceExpireReceiptsSlice from "./province-exipre-receipts/province-expire-receipts-slice"
import provinceReportsSlice from "./province-reports/province-report-slice"
import provinceConsumptionSlice from "./province-consumptions/province-consumption-slice"
import providerConsumptionSlice from "./provider-consumption/province-consumption-slice"
import monitorDashboardSlice from "./monitor-dashboard/monitor-dashboard-slice"
import monitorReceiptsAction from "./monitor-receipts/monitor-receipt-slice"
import monitorDistributionSlice from "./monitor-distributions/monitor-distribution-slice"
import monitorExpireReceiptsSlice from "./monitor-expire-receipts/monitor-expire-slice"
import monitorConsumptionSlice from "./monitor-consumption/monitor-consumption-slice"
import monitorReportsSlice from "./monitor-reports/monitor-report-slice"
import activityLogsSlice from "./activity-logs/activity-logs-slice"
import ProvActionRequestSlice from "./provider-action-requests/provider-action-request-slice"
import providerStockoutSlice from "./provider-stock-out/provider-stockout-slice"
import ProvinceActionRequestSlice from "./province-action-requests/province-action-request-slice"
import monitorStockoutSlice from "./monitor-stock-out/monitor-stockout-slice"

const store = configureStore({
  reducer: {
    Layout: layoutSlice.reducer,
    auth: authSlice.reducer,
    warehouse: warehouseSlice.reducer,
    provider: providerSlice.reducer,
    province: provinceSlice.reducer,
    facility: facilitySlice.reducer,
    generalRenders: generalSlice.reducer,
    ips: ipsSlice.reducer,
    itemCategory: itemCategorySlice.reducer,
    item: itemSlice.reducer,
    adminDashboard: adminDashboardSlice.reducer,
    user: userSlice.reducer,
    role: roleSlice.reducer,
    project: projectSlice.reducer,
    providerDashboard: providerDashboardSlice.reducer,
    providerIn: providerInSlice.reducer,
    providerOut: providerOutSlice.reducer,
    providerExpireReceipts: providerExpireReceiptsSlice.reducer,
    providerReports: providerReportsSlice.reducer,
    provinceDashboard: provinceDashboardSlice.reducer,
    provinceIn: provinceInSlice.reducer,
    provinceOut: provinceOutSlice.reducer,
    provinceExpireReceipts: provinceExpireReceiptsSlice.reducer,
    provinceReports: provinceReportsSlice.reducer,
    provinceConsumptions: provinceConsumptionSlice.reducer,
    providerConsumptions: providerConsumptionSlice.reducer,
    monitorDashboard: monitorDashboardSlice.reducer,
    monitorReceipts: monitorReceiptsAction.reducer,
    monitorDistributions: monitorDistributionSlice.reducer,
    monitorExpireReceipts: monitorExpireReceiptsSlice.reducer,
    monitorConsumptions: monitorConsumptionSlice.reducer,
    monitorReports: monitorReportsSlice.reducer,
    activityLogs: activityLogsSlice.reducer,
    provActionRequests: ProvActionRequestSlice.reducer,
    providerStockout: providerStockoutSlice.reducer,
    monitorStockout: monitorStockoutSlice.reducer,
    provinceActionRequest: ProvinceActionRequestSlice.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
})

export default store
