import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
import ProvinceChartOutItemsListComponent from "components/ProvinceDashboard/ProvinceItems/ProvinceChartOutList"
import { getProvinceOutItems } from "store/province-dashboard/province-dashboard-action"
import { provinceDashboardAction } from "store/province-dashboard/province-dashboard-slice"

const ProvinceChartOutItems = () => {
  const dispatch = useDispatch()

  const [searchParams] = useSearchParams()
  const getParams = {
    itemId: searchParams.get("itemId"),
    from: searchParams.get("from") !== "null" ? searchParams.get("from") : null,
    to: searchParams.get("to") !== "null" ? searchParams.get("to") : null,
  }
  const ProvinceOutItems = useSelector(
    state => state.provinceDashboard.ProvinceOutItems
  )

  useEffect(() => {
    dispatch(getProvinceOutItems(getParams))

    return () => {
      dispatch(provinceDashboardAction.resetProvinceOutItems())
    }
  }, [])

  return (
    <ProvinceChartOutItemsListComponent
      data={ProvinceOutItems?.data?.data}
      loading={ProvinceOutItems?.loading}
    />
  )
}

export default ProvinceChartOutItems
