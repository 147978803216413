import axiosInstance from "api/axiosInstance"
import { generalAction } from "./general-slice"
import { batch } from "react-redux"

export const getAllProvinces = () => dispatch => {
  dispatch(generalAction.setProvinceLoading(true))
  axiosInstance
    .get("/v1/get-provinces")
    .then(response => {
      batch(() => {
        dispatch(generalAction.setProvinceError(null))
        dispatch(generalAction.setProvincesData(response?.data))
        dispatch(generalAction.setProvinceLoading(false))
      })
    })
    .catch(error => {
      dispatch(generalAction.setProvinceLoading(false))
      console.log(error)
    })
}

export const getAllItemCategories = () => dispatch => {
  dispatch(generalAction.setItemCategoryLoading(true))
  axiosInstance
    .get("/v1/get-item-categories")
    .then(response => {
      batch(() => {
        dispatch(generalAction.setItemCategoryError(null))
        dispatch(generalAction.setItemCategoryData(response?.data))
        dispatch(generalAction.setItemCategoryLoading(false))
      })
    })
    .catch(error => {
      dispatch(generalAction.setItemCategoryLoading(false))
      console.log(error)
    })
}

export const getAllRoles = () => dispatch => {
  dispatch(generalAction.setRoleLoading(true))
  axiosInstance
    .get("/v1/get-roles")
    .then(response => {
      batch(() => {
        dispatch(generalAction.setRoleError(null))
        dispatch(generalAction.setRoleData(response?.data))
        dispatch(generalAction.setRoleLoading(false))
      })
    })
    .catch(error => {
      dispatch(generalAction.setRoleLoading(false))
      console.log(error)
    })
}

export const getAllProviders = () => dispatch => {
  dispatch(generalAction.setProviderLoading(true))
  axiosInstance
    .get("/v1/get-providers")
    .then(response => {
      batch(() => {
        dispatch(generalAction.setProviderError(null))
        dispatch(generalAction.setProviderData(response?.data))
        dispatch(generalAction.setProviderLoading(false))
      })
    })
    .catch(error => {
      dispatch(generalAction.setProviderLoading(false))
      console.log(error)
    })
}

export const getAllWarehouses = () => dispatch => {
  dispatch(generalAction.setWarehouseLoading(true))
  axiosInstance
    .get("/v1/get-warehouses")
    .then(response => {
      batch(() => {
        dispatch(generalAction.setWarehouseError(null))
        dispatch(generalAction.setWarehouseData(response?.data))
        dispatch(generalAction.setWarehouseLoading(false))
      })
    })
    .catch(error => {
      dispatch(generalAction.setWarehouseLoading(false))
      console.log(error)
    })
}

export const getAllIPs = () => dispatch => {
  dispatch(generalAction.setIPLoading(true))
  axiosInstance
    .get("/v1/get-warehouses")
    .then(response => {
      batch(() => {
        dispatch(generalAction.setIPError(null))
        dispatch(generalAction.setIPData(response?.data))
        dispatch(generalAction.setIPLoading(false))
      })
    })
    .catch(error => {
      dispatch(generalAction.setIPLoading(false))
      console.log(error)
    })
}

export const getAllUserRegistrationData = () => dispatch => {
  dispatch(generalAction.setUserRegistrationDataLoading(true))
  axiosInstance
    .get("/v1/getUserRegistrationData")
    .then(response => {
      batch(() => {
        dispatch(generalAction.setUserRegistrationDataError(null))
        dispatch(generalAction.setUserRegistrationData(response?.data))
        dispatch(generalAction.setUserRegistrationDataLoading(false))
      })
    })
    .catch(error => {
      dispatch(generalAction.setUserRegistrationDataLoading(false))
      console.log(error)
    })
}
