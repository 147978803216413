import axiosInstance from "api/axiosInstance"
import { provinceReportsAction } from "./province-report-slice"
import exportAxiosInstance from "api/exportAxiosInstance"
import FileSaver from "file-saver"

export const getProvinceTransactions =
  (
    url,
    pageNumber = 20,
    according = { order: "desc", name: "date" },
    search = null,
    advancedSearch = {}
  ) =>
  dispatch => {
    dispatch(
      provinceReportsAction.setTransactionReport({
        loading: true,
      })
    )
    url = url || "/v1/get-province-transaction-reports"
    axiosInstance
      .get(url, {
        params: {
          pageNumber: pageNumber,
          according: according,
          search: search,
          advancedSearch: advancedSearch,
        },
      })
      .then(response => {
        if (response) {
          dispatch(
            provinceReportsAction.setTransactionReport({
              loading: false,
              error: null,
              data: response?.data,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          provinceReportsAction.setTransactionReport({
            loading: false,
            error: error?.response?.errors,
            data: null,
          })
        )
        console.log(error)
      })
  }

export const getProvinceConsumptionReports =
  (
    according = { order: "desc", name: "date" },
    advancedSearch = {},
    pageNumber = 20,
    url
  ) =>
  dispatch => {
    dispatch(
      provinceReportsAction.setProvinceConsumptionReport({
        loading: true,
      })
    )
    advancedSearch.according = according
    advancedSearch.pageNumber = pageNumber
    url = url || "/v1/get-province-consumptions-report"
    axiosInstance
      .get(url, {
        params: advancedSearch,
      })
      .then(response => {
        if (response) {
          dispatch(
            provinceReportsAction.setProvinceConsumptionReport({
              loading: false,
              error: null,
              data: response?.data,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          provinceReportsAction.setProvinceConsumptionReport({
            loading: false,
            error: error?.response?.data,
            data: null,
          })
        )
      })
  }

export const exportProvinceTransactions =
  (
    pageNumber = 10,
    according = { order: "desc", name: "date" },
    search = null,
    advancedSearch = {}
  ) =>
  dispatch => {
    dispatch(
      provinceReportsAction.setTransactionExport({
        loading: true,
        error: null,
        data: null,
      })
    )
    exportAxiosInstance
      .get("/v1/get-province-transaction-export", {
        params: {
          pageNumber: pageNumber,
          according: according,
          search: search,
          advancedSearch: advancedSearch,
        },
      })
      .then(response => {
        if (response) {
          const dataBlob = new Blob([response?.data], {
            type: "application/pdf,",
          })
          FileSaver.saveAs(
            dataBlob,
            `Transaction-${Math.random().toString()}.xlsx`
          )
          dispatch(
            provinceReportsAction.setTransactionExport({
              loading: false,
              error: null,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          provinceReportsAction.setTransactionExport({
            loading: false,
            error: error?.response?.errors,
            data: null,
          })
        )
        console.log(error)
      })
  }

export const exportProvinceConsumptionReports =
  (according = { order: "desc", name: "date" }, advancedSearch = {}) =>
  dispatch => {
    dispatch(
      provinceReportsAction.setProvinceConsumptionExport({
        loading: true,
        error: null,
      })
    )

    exportAxiosInstance
      .get("/v1/export-province-consumptions-report", {
        params: advancedSearch,
      })
      .then(response => {
        if (response) {
          const dataBlob = new Blob([response?.data], {
            type: "application/pdf,",
          })
          FileSaver.saveAs(
            dataBlob,
            `Province-Consumptions-${Math.random().toString()}.xlsx`
          )
          dispatch(
            provinceReportsAction.setProvinceConsumptionExport({
              loading: false,
              error: null,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          provinceReportsAction.setProvinceConsumptionExport({
            loading: false,
            error: error?.response?.errors,
            data: null,
          })
        )
        console.log(error)
      })
  }
