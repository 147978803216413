import React, { useState } from "react"
import { Card, CardBody, Collapse, Table } from "reactstrap"
import { useNavigate } from "react-router-dom"
import CustomHeaderTitle from "components/Common/CustomHeaderTitle"
import GeneralTables from "components/Common/GeneralTable"
import ProviderReceiptReportFilter from "./filter"
import ProviderConsumptionReporFilter from "./filter"
import Chart2 from "components/Charts/Chart2"
import ProviderQuantificationFilter from "./filter"
import ProviderQuantificatinRenderItem from "./renderItem"

const ProviderQuantificationReportListComponent = ({
  data,
  error,
  loading,
  onExportBtnClick,
  onSearchHandler,
  exportStatus,
}) => {
  const navigate = useNavigate()
  const [filterOpen, setFilterOpen] = useState(false)
  const [filter, setFilter] = useState({})

  const onAdvancedSearchHandler = result => {
    setFilterOpen(result)
  }

  const onAdvancedSearchSubmit = data => {
    setFilter(data)
    onSearchHandler(data)
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CustomHeaderTitle
            loading={loading}
            title={"Quantification Report"}
            lgColumn={7}
            exportBtn={true}
            exportBtnLoading={exportStatus?.loading}
            onExportBtnClick={onExportBtnClick}
            rowCount={false}
            searchBox={false}
            rightBtn={false}
            advnacedSearchBtn={true}
            advnacedSearchBtnTitle={"Filter"}
            onAdvancedSearch={onAdvancedSearchHandler}
          />
          <Collapse isOpen={filterOpen}>
            <ProviderQuantificationFilter
              loading={loading}
              error={error}
              onAdvancedSearchSubmit={onAdvancedSearchSubmit}
            />
          </Collapse>

          <ProviderQuantificatinRenderItem data={data} />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default ProviderQuantificationReportListComponent
