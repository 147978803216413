import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: [],
  loading: false,
  error: null,
  distribution: {},
  receipts: { error: null, loading: false, data: [] },
  storeQuantityError: [],
  provinces: { error: null, loading: false, data: [] },
  items: { error: null, loading: false, data: [] },
  exportStatus: { loading: false, error: null },
}

const provinceOutSlice = createSlice({
  name: "provinceOut",
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    setError(state, action) {
      state.error = action.payload
    },
    setProvinceOut(state, action) {
      state.distribution = action.payload
    },
    setProvinces(state, action) {
      state.provinces = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setItems(state, action) {
      state.items = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setProvinceOutReceipts(state, action) {
      state.receipts = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setStoreQuantityError(state, action) {
      state.storeQuantityError = action.payload
    },
    setExportStatus(state, action) {
      state.exportStatus = {
        loading: action.payload.loading,
        error: action.payload.error,
      }
    },
    resetState(state) {
      state.data = []
      state.loading = false
      state.error = null
      state.distribution = {}
      state.receipts = { error: null, loading: false, data: [] }
      state.storeQuantityError = []
    },
    resetStoreQuantityError(state) {
      state.storeQuantityError = []
    },
    resetProvinces(state) {
      state.provinces = { error: null, loading: false, data: [] }
    },
    resetItems(state) {
      state.items = { error: null, loading: false, data: [] }
    },
    resetReceipts(state) {
      state.receipts = { error: null, loading: false, data: [] }
    },
    resetExportStatus(state) {
      state.exportStatus = { loading: false, error: null }
    },
  },
})

export const provinceOutAction = provinceOutSlice.actions

export default provinceOutSlice
