import React, { useState } from "react"
import { Card, CardBody, Collapse } from "reactstrap"
import { useNavigate } from "react-router-dom"
import CustomHeaderTitle from "components/Common/CustomHeaderTitle"
import GeneralTables from "components/Common/GeneralTable"
import ProviderReceiptHealthReportFilter from "./filter"
import ReceiptHealthListItems from "./ReceiptHealthListItems"
import MonitorReceiptHealthListItems from "./ReceiptHealthListItems"
import MonitorReceiptHealthReportFilter from "./filter"

const MonitorReceiptHealthReportListComponent = ({
  data,
  error,
  loading,
  onSearch,
  onChangeRowNumber,
  onChangeOrdering,
  onExportBtnClick,
  onAdvancedSearchSubmit,
  exportStatus,
  onPageClick,
}) => {
  const navigate = useNavigate()
  const [filterOpen, setFilterOpen] = useState(false)

  const onAdvancedSearchHandler = result => {
    setFilterOpen(result)
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CustomHeaderTitle
            loading={loading}
            // pageRowNumberOptions={[20, 100, 150]}
            title={""}
            exportBtn={true}
            exportBtnLoading={exportStatus?.loading}
            onExportBtnClick={onExportBtnClick}
            rowCount={true}
            onChangeRowNumber={onChangeRowNumber}
            orderingList={true}
            onChangeOrdering={onChangeOrdering}
            searchBox={true}
            onSearchHandler={onSearch}
            rightBtn={false}
            advnacedSearchBtn={true}
            advnacedSearchBtnTitle={"Filter"}
            onAdvancedSearch={onAdvancedSearchHandler}
            orderingOptions={[
              { value: "date", name: "Date" },
              //   { value: "date", name: "Submission Date" },
            ]}
          />
          <Collapse isOpen={filterOpen}>
            <MonitorReceiptHealthReportFilter
              loading={loading}
              onAdvancedSearchSubmit={onAdvancedSearchSubmit}
            />
          </Collapse>
          <MonitorReceiptHealthListItems data={data?.data} />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default MonitorReceiptHealthReportListComponent
