import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: [],
  error: null,
  loading: false,
  itemCategory: null,
  exportStatus: { loading: false, error: null },
}

const itemCategorySlice = createSlice({
  name: "itemCategory",
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload
    },
    setError(state, action) {
      state.error = action.payload
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    setItemCategory(state, action) {
      state.itemCategory = action.payload
    },
    setExportStatus(state, action) {
      state.exportStatus = {
        loading: action.payload.loading,
        error: action.payload.error,
      }
    },
    resetState(state) {
      state.data = []
      state.error = null
      state.loading = false
      state.itemCategory = null
      state.exportStatus = { loading: false, error: null }
    },
  },
})

export const itemCategoryAction = itemCategorySlice.actions

export default itemCategorySlice
