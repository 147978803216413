import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { isObject } from "lodash"
import {
  getProvinceIps,
  getProvinceItemCategories,
  getProvinceProvider,
  getProvinceProvinces,
  getProvinceWarehouses,
} from "store/province-dashboard/province-dashboard-action"
import ProvinceOutForm from "components/ProvinceOut/form"
import {
  getProvinceOutProvinces,
  storeProvinceOut,
} from "store/province-out/province-out-action"
import { provinceOutAction } from "store/province-out/province-out-slice"
import { getProvinceInProviders } from "store/province-in/province-in-action"

const AddProvinceOut = () => {
  document.title = "Add Distribution"

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { loading, error } = useSelector(state => state.provinceOut)

  const providers = useSelector(state => state.provinceIn.providers)

  const categories = useSelector(
    state => state.provinceDashboard.ProvinceItemCategoires
  )
  const ips = useSelector(state => state.provinceDashboard.ProvinceIps)
  const items = useSelector(state => state.provinceOut.items)
  const provinces = useSelector(state => state.provinceOut.provinces)
  const facilities = useSelector(
    state => state.provinceDashboard.provinceFacilities
  )

  const receipts = useSelector(state => state.provinceOut.receipts)

  const handleSubmit = values => {
    const document = new FormData()
    document.append("distributions", JSON.stringify(values))
    dispatch(storeProvinceOut(document, navigate))
  }

  useEffect(() => {
    dispatch(getProvinceInProviders())
    dispatch(getProvinceItemCategories())
    dispatch(getProvinceOutProvinces())
    dispatch(getProvinceIps())

    return () => {
      dispatch(provinceOutAction.resetState())
      dispatch(provinceOutAction.resetProvinces())
      dispatch(provinceOutAction.resetItems())
    }
  }, [])

  return (
    <ProvinceOutForm
      onSubmit={handleSubmit}
      loading={loading}
      error={error}
      formMode={"Add"}
      providers={providers?.data}
      categories={categories?.data}
      items={items?.data}
      itemLoading={items?.loading}
      ips={ips?.data}
      provinces={provinces?.data}
      facilities={facilities?.data}
      facilityLoading={facilities?.loading}
      receipts={receipts?.data}
    />
  )
}

export default AddProvinceOut
