import axiosInstance from "api/axiosInstance"
import fileaxiosInstance from "api/fileAxiosInstance"
import exportAxiosInstance from "api/exportAxiosInstance"
import FileSaver from "file-saver"
import { monitorConsumptionAction } from "./monitor-consumption-slice"

// export const getMonitorConsumptions =
//   (params = {}) =>
//   dispatch => {
//     dispatch(monitorConsumptionAction.setLoading(true))
//     dispatch(monitorConsumptionAction.setData([]))
//     axiosInstance
//       .get("/v1/get-monitor-consumptions", { params: params })
//       .then(response => {
//         if (response) {
//           dispatch(monitorConsumptionAction.setError(null))
//           dispatch(monitorConsumptionAction.setData(response.data))
//           dispatch(monitorConsumptionAction.setLoading(false))
//         }
//       })
//       .catch(error => {
//         dispatch(monitorConsumptionAction.setLoading(false))
//         dispatch(monitorConsumptionAction.setError(error?.response?.data))
//       })
//   }

export const getMonitorConsumptionsProjects =
  (params = {}) =>
  dispatch => {
    dispatch(
      monitorConsumptionAction.setMonitorConsumptionProjects({
        loading: true,
        error: null,
        data: [],
      })
    )

    axiosInstance
      .get("/v1/get-monitor-comsumption-projects", { params })
      .then(response => {
        if (response) {
          dispatch(
            monitorConsumptionAction.setMonitorConsumptionProjects({
              loading: false,
              error: null,
              data: response?.data,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          monitorConsumptionAction.setMonitorConsumptionProjects({
            loading: false,
            error: error?.response,
            data: [],
          })
        )
      })
  }

export const getMonitorConsumptionsIPs =
  (params = {}) =>
  dispatch => {
    dispatch(
      monitorConsumptionAction.setMonitorConsumptionIPs({
        loading: true,
        error: null,
        data: [],
      })
    )
    axiosInstance
      .get("/v1/get-monitor-comsumption-ips", { params })
      .then(response => {
        if (response) {
          dispatch(
            monitorConsumptionAction.setMonitorConsumptionIPs({
              loading: false,
              error: null,
              data: response?.data,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          monitorConsumptionAction.setMonitorConsumptionIPs({
            loading: false,
            error: error?.response,
            data: [],
          })
        )
      })
  }

export const getMonitorConsumptionsProvince =
  (params = {}) =>
  dispatch => {
    dispatch(
      monitorConsumptionAction.setMonitorConsumptionProvince({
        loading: true,
        error: null,
        data: [],
      })
    )
    axiosInstance
      .get("/v1/get-monitor-comsumption-provinces", { params })
      .then(response => {
        if (response) {
          dispatch(
            monitorConsumptionAction.setMonitorConsumptionProvince({
              loading: false,
              error: null,
              data: response?.data,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          monitorConsumptionAction.setMonitorConsumptionProvince({
            loading: false,
            error: error?.response,
            data: [],
          })
        )
      })
  }

export const getMonitorConsumptionsFacilities =
  (params = {}) =>
  dispatch => {
    dispatch(
      monitorConsumptionAction.setMonitorConsumptionFacilities({
        loading: true,
        error: null,
        data: [],
      })
    )
    axiosInstance
      .get(`/v1/get-monitor-comsumption-facilitis`, { params })
      .then(response => {
        if (response) {
          console.log(response?.data)
          dispatch(
            monitorConsumptionAction.setMonitorConsumptionFacilities({
              loading: false,
              error: null,
              data: response?.data,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          monitorConsumptionAction.setMonitorConsumptionFacilities({
            loading: false,
            error: error?.response,
            data: [],
          })
        )
        console.log(error)
      })
  }

export const exportMonitorConsumptions = values => dispatch => {
  dispatch(
    monitorConsumptionAction.setExport({
      loading: true,
    })
  )
  exportAxiosInstance
    .post("/v1/export-monitor-consumptions", values)
    .then(response => {
      if (response) {
        const dataBlob = new Blob([response?.data], {
          type: "application/pdf,",
        })
        FileSaver.saveAs(
          dataBlob,
          `Consumption-${Math.random().toString()}.xlsx`
        )
        dispatch(
          monitorConsumptionAction.setExport({
            loading: false,
          })
        )
      }
    })
    .catch(error => {
      dispatch(
        monitorConsumptionAction.setExport({
          loading: false,
          error: error?.response?.data,
        })
      )
    })
}
