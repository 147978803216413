import React, { useEffect } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import AdminDashboardComponent from "components/AdminDashbaord"
import { useSelector, useDispatch } from "react-redux"
import { getAdminDashboardData } from "store/admin/admin-dashboard-action"
import { getProviderActionRequestCount } from "store/provider-action-requests/provider-action-request-action"

const AdminDashboard = props => {
  //meta title
  document.title = "Dashboard"

  const dispatch = useDispatch()
  const { data, error, loading } = useSelector(state => state.adminDashboard)
  const { user } = useSelector(state => ({ user: state.auth.user }))

  useEffect(() => {
    dispatch(getAdminDashboardData())
    dispatch(getProviderActionRequestCount())
  }, [])

  return (
    <React.Fragment>
      <Breadcrumbs title={"Admin"} breadcrumbItem={"Dashboard"} />
      <AdminDashboardComponent
        loading={loading}
        data={data?.data}
        error={error}
        user={user}
      />
    </React.Fragment>
  )
}

export default AdminDashboard
