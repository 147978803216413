import React, { useState } from "react"
import { Button, Card, CardBody, Collapse } from "reactstrap"
import { useNavigate } from "react-router-dom"
import CustomHeaderTitle from "components/Common/CustomHeaderTitle"
import GeneralTables from "components/Common/GeneralTable"
import ActivityLogFilters from "./ActivityLogFilter"
import { getTimestamp } from "utils/utils"

const ActivityLogsComponents = ({
  data,
  error,
  loading,
  onPageClick,
  onSearch,
  onChangeRowNumber,
  onChangeOrdering,
  onAdvancedSearchSubmit,
}) => {
  const navigate = useNavigate()
  const [filterOpen, setFilterOpen] = useState(false)

  const onAdvancedSearchHandler = result => {
    setFilterOpen(result)
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CustomHeaderTitle
            loading={loading}
            title={"List"}
            rowCount={true}
            onChangeRowNumber={onChangeRowNumber}
            orderingList={true}
            onChangeOrdering={onChangeOrdering}
            searchBox={true}
            onSearchHandler={onSearch}
            rightBtn={false}
            advnacedSearchBtn={true}
            advnacedSearchBtnTitle={"Filter"}
            onAdvancedSearch={onAdvancedSearchHandler}
            orderingOptions={[
              { value: "id", name: "ID" },
              { value: "created_at", name: "Created At" },
            ]}
          />

          <Collapse isOpen={filterOpen}>
            <ActivityLogFilters
              loading={loading}
              onAdvancedSearchSubmit={onAdvancedSearchSubmit}
            />
          </Collapse>
          <GeneralTables
            data={data?.data}
            loading={loading}
            parentCheckbox={true}
            headers={["SN", "User", "Role", "Part", "Action", "Date"]}
            pagination={{ meta: data?.meta, onPageClick: onPageClick }}
            renderItem={(logs, index) => (
              <tr key={index}>
                <th scope="row">{data?.meta?.from + index}</th>
                <td>{logs?.user?.email}</td>
                <td>{logs?.role}</td>
                <td>{logs?.part}</td>
                <td>{logs?.action}</td>
                <td style={{ whiteSpace: "nowrap" }}>
                  {getTimestamp(logs?.created_at)}
                </td>
              </tr>
            )}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default ActivityLogsComponents
