import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import useSearch from "hooks/useSearch"
import { getMonitorDistributions } from "store/monitor-distributions/monitor-distribution-action"
import {
  getMonitorIps,
  getMonitorItemCategories,
  getMonitorProjects,
  getMonitorProvinces,
} from "store/monitor-dashboard/monitor-dashboard-action"
import MonitorDistributionComponents from "components/MonitorDistributions/list"
import ProviderOutDetailModal from "components/ProviderOut/details"
import { exportMonitorDistributionReport } from "store/monitor-reports/monitor-report-action"

const MonitorDistributions = () => {
  document.title = "Distributions"

  const dispatch = useDispatch()
  const { data, error, loading } = useSelector(
    state => state.monitorDistributions
  )
  const exportStatus = useSelector(
    state => state.monitorReports.monitorExportDistributionReport
  )
  const [proOut, setProOut] = useState(null)
  const [search, setSearch] = useState(null)
  const [pageNumber, setPageNumber] = useState(10)
  const [according, setAccording] = useState({ order: "desc", name: "id" })
  const [advnacedSearch, setAdvancedSearch] = useState({})
  const [detailModal, setDetailModal] = useState(false)

  const onPageClick = link => {
    dispatch(
      getMonitorDistributions(
        link.url,
        pageNumber,
        according,
        search,
        advnacedSearch
      )
    )
  }

  const onSearchHandler = value => {
    setSearch(value)
  }

  const onChangeRowNumber = value => {
    setPageNumber(value)
  }

  const onChangeOrdering = value => {
    if (value.order) {
      setAccording(prev => {
        return { ...prev, order: value.order }
      })
    }
    if (value.name) {
      setAccording(prev => {
        return { ...prev, name: value.name }
      })
    }
  }

  useSearch(
    getMonitorDistributions(
      undefined,
      undefined,
      undefined,
      search,
      advnacedSearch
    ),
    search
  )

  useEffect(() => {
    dispatch(
      getMonitorDistributions(
        undefined,
        pageNumber,
        according,
        search,
        advnacedSearch
      )
    )
  }, [pageNumber, according])

  useEffect(() => {
    dispatch(getMonitorItemCategories())
    dispatch(getMonitorProvinces())
    dispatch(getMonitorIps())
    dispatch(getMonitorProjects())
  }, [])

  const onAdvancedSearchSubmit = data => {
    setAdvancedSearch(data)
    dispatch(
      getMonitorDistributions(undefined, undefined, undefined, search, data)
    )
  }

  const onClickDetail = data => {
    setProOut(data)
    setDetailModal(true)
  }

  const onExportBtnClick = () => {
    dispatch(
      exportMonitorDistributionReport(
        pageNumber,
        according,
        search,
        advnacedSearch
      )
    )
  }

  return (
    <React.Fragment>
      <Breadcrumbs title={"Monitor"} breadcrumbItem={"Distributions"} />
      <ProviderOutDetailModal
        open={detailModal}
        setOpen={setDetailModal}
        data={proOut}
      />
      <MonitorDistributionComponents
        data={data}
        error={error}
        loading={loading}
        onPageClick={onPageClick}
        onSearch={onSearchHandler}
        onChangeRowNumber={onChangeRowNumber}
        onChangeOrdering={onChangeOrdering}
        onAdvancedSearchSubmit={onAdvancedSearchSubmit}
        onClickDetail={onClickDetail}
        onExportBtnClick={onExportBtnClick}
        exportStatus={exportStatus}
      />
    </React.Fragment>
  )
}

export default MonitorDistributions
