import axiosInstance from "api/axiosInstance"
import { ProvinceActionRequestAction } from "./province-action-request-slice"
import { batch } from "react-redux"
import SAlert from "components/Alert"

export const getProvinceDistributionActionRequests =
  (
    url,
    pageNumber = 10,
    according = { order: "desc", name: "id" },
    search = null,
    advancedSearch = {}
  ) =>
  dispatch => {
    dispatch(ProvinceActionRequestAction.setLoading(true))
    url = url || "/v1/get-province-distribution-action-request"
    axiosInstance
      .get(url, {
        params: {
          pageNumber: pageNumber,
          according: according,
          search: search,
          advancedSearch: advancedSearch,
        },
      })
      .then(response => {
        if (response) {
          batch(() => {
            dispatch(ProvinceActionRequestAction.setError(null))
            dispatch(ProvinceActionRequestAction.setData(response.data))
            dispatch(ProvinceActionRequestAction.setLoading(false))
          })
        }
      })
      .catch(error => {
        dispatch(ProvinceActionRequestAction.setLoading(false))
        console.log(error)
      })
  }

export const sendProvinceDistributionActionRequests =
  (ids, status) => dispatch => {
    dispatch(ProvinceActionRequestAction.setLoading(true))
    axiosInstance
      .post("/v1/send-province-distribution-action-request", { ids, status })
      .then(response => {
        dispatch(getProvinceDistributionActionRequests())
        dispatch(getProvinceActionRequestCount())
        SAlert({
          title: "Success",
          text: `Request has been ${status} successfully`,
        })
        batch(() => {
          dispatch(ProvinceActionRequestAction.setError(null))
          dispatch(ProvinceActionRequestAction.setLoading(false))
          dispatch(ProvinceActionRequestAction.resetIds())
        })
      })
      .catch(error => {
        dispatch(ProvinceActionRequestAction.setLoading(false))
        dispatch(ProvinceActionRequestAction.resetIds())
        console.log(error)
      })
  }

export const getProvinceActionRequestCount = () => dispatch => {
  dispatch(
    ProvinceActionRequestAction.setActionRequestCount({
      distribution: 0,
    })
  )
  axiosInstance
    .get("/v1/get-count-province-action-request")
    .then(response => {
      if (response) {
        dispatch(
          ProvinceActionRequestAction.setActionRequestCount({
            distribution: response?.data,
          })
        )
      }
    })
    .catch(error => {
      dispatch(
        ProvinceActionRequestAction.setActionRequestCount({
          receipt: 0,
          distribution: 0,
        })
      )
      console.log(error)
    })
}
