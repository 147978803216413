import axiosInstance from "api/axiosInstance"
import SAlert from "components/Alert"
import { projectAction } from "./project-slice"
import { batch } from "react-redux"
import exportAxiosInstance from "api/exportAxiosInstance"
import FileSaver from "file-saver"

export const getProjects =
  (
    url,
    pageNumber = 10,
    according = { order: "desc", name: "id" },
    search = null
  ) =>
  dispatch => {
    dispatch(projectAction.setLoading(true))
    url = url || "/v1/projects"
    axiosInstance
      .get(url, {
        params: {
          pageNumber: pageNumber,
          according: according,
          search: search,
        },
      })
      .then(response => {
        if (response) {
          batch(() => {
            dispatch(projectAction.setError(null))
            dispatch(projectAction.setData(response.data))
            dispatch(projectAction.setLoading(false))
          })
        }
      })
      .catch(error => {
        dispatch(projectAction.setLoading(false))
        console.log(error)
      })
  }

export const deleteProject = id => dispatch => {
  dispatch(projectAction.setLoading(true))
  axiosInstance
    .delete(`/v1/projects/${id}`)
    .then(response => {
      dispatch(getProjects())
      SAlert({ title: "Success", text: "Project successfully delete" })
      batch(() => {
        dispatch(projectAction.setError(null))
        dispatch(projectAction.setLoading(false))
      })
    })
    .catch(error => {
      dispatch(projectAction.setLoading(false))
      console.log(error)
    })
}

export const storeProject = (data, navigate) => dispatch => {
  dispatch(projectAction.setLoading(true))
  axiosInstance
    .post("/v1/projects", data)
    .then(response => {
      navigate("/projects")
      SAlert({ title: "Success", text: "Project successfully inserted" })
      dispatch(projectAction.setError(null))
      dispatch(projectAction.setLoading(false))
    })
    .catch(err => {
      dispatch(projectAction.setLoading(false))
      dispatch(projectAction.setError(err?.response?.data?.errors))
    })
}

export const updateProject = (id, data, navigate) => dispatch => {
  dispatch(projectAction.setLoading(true))
  axiosInstance
    .put(`/v1/projects/${id}`, data)
    .then(respnose => {
      navigate("/projects")
      SAlert({ title: "Success", text: "Project successfully updated" })
      dispatch(projectAction.setError(null))
      dispatch(projectAction.setLoading(false))
    })
    .catch(error => {
      dispatch(projectAction.setLoading(false))
      dispatch(projectAction.setError(error?.response?.data?.errors))
    })
}

export const getByIdProject = id => dispatch => {
  dispatch(projectAction.setLoading(true))
  axiosInstance
    .get(`/v1/projects/${id}`)
    .then(respnose => {
      batch(() => {
        dispatch(projectAction.setError(null))
        dispatch(projectAction.setLoading(false))
        dispatch(projectAction.setProject(respnose.data))
      })
    })
    .catch(error => {
      dispatch(projectAction.setLoading(false))
      console.log(error)
    })
}

export const exportProjects = search => dispatch => {
  dispatch(
    projectAction.setExportStatus({
      loading: true,
      error: null,
    })
  )
  exportAxiosInstance
    .get("/v1/project-export", {
      params: { search },
    })
    .then(response => {
      if (response) {
        const dataBlob = new Blob([response?.data], {
          type: "application/pdf,",
        })
        FileSaver.saveAs(dataBlob, `Projects-${Math.random().toString()}.xlsx`)
        dispatch(
          projectAction.setExportStatus({
            loading: false,
            error: null,
          })
        )
      }
    })
    .catch(error => {
      dispatch(
        projectAction.setExportStatus({
          loading: false,
          error: error?.response?.errors,
        })
      )
      console.log(error)
    })
}
