import React from "react"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"

const MySwal = withReactContent(Swal)

const CSAlert = async props => {
  const { status, title, text } = props
  return await MySwal.fire({
    title: title && title,
    showCancelButton: true,
    confirmButtonText: "Confirm",
  })
}

export default CSAlert
