import axiosInstance from "api/axiosInstance"
import { providerAction } from "./provider-slice"
import SAlert from "components/Alert"
import exportAxiosInstance from "api/exportAxiosInstance"
import FileSaver from "file-saver"

export const getProviders =
  (
    url,
    pageNumber = 10,
    according = { order: "desc", name: "id" },
    search = null
  ) =>
  dispatch => {
    dispatch(providerAction.setLoading(true))
    url = url || "/v1/providers"
    axiosInstance
      .get(url, {
        params: {
          pageNumber: pageNumber,
          according: according,
          search: search,
        },
      })
      .then(response => {
        if (response) {
          dispatch(providerAction.setError(null))
          dispatch(providerAction.setData(response.data))
          dispatch(providerAction.setLoading(false))
        }
      })
      .catch(error => {
        dispatch(providerAction.setLoading(false))
        console.log(error)
      })
  }

export const deleteProvider = id => dispatch => {
  dispatch(providerAction.setLoading(true))
  axiosInstance
    .delete(`/v1/providers/${id}`)
    .then(response => {
      dispatch(getProviders())
      SAlert({ title: "Success", text: "Provider successfully delete" })
      dispatch(providerAction.setError(null))
      dispatch(providerAction.setLoading(false))
    })
    .catch(error => {
      dispatch(providerAction.setLoading(false))
      console.log(error)
    })
}

export const storeProvider = (data, navigate) => dispatch => {
  dispatch(providerAction.setLoading(true))
  axiosInstance
    .post("/v1/providers", data)
    .then(response => {
      navigate("/providers")
      SAlert({ title: "Success", text: "Provider successfully inserted" })
      dispatch(providerAction.setError(null))
      dispatch(providerAction.setLoading(false))
    })
    .catch(err => {
      dispatch(providerAction.setLoading(false))
      dispatch(providerAction.setError(err?.response?.data?.errors))
    })
}

export const updateProvider = (id, data, navigate) => dispatch => {
  dispatch(providerAction.setLoading(true))
  axiosInstance
    .put(`/v1/providers/${id}`, data)
    .then(respnose => {
      navigate("/providers")
      SAlert({ title: "Success", text: "Provider successfully updated " })
      dispatch(providerAction.setError(null))
      dispatch(providerAction.setLoading(false))
    })
    .catch(error => {
      dispatch(providerAction.setLoading(false))
      dispatch(providerAction.setError(error?.response?.data?.errors))
    })
}

export const getByIdProvider = id => dispatch => {
  dispatch(providerAction.setLoading(true))
  axiosInstance
    .get(`/v1/providers/${id}`)
    .then(respnose => {
      dispatch(providerAction.setError(null))
      dispatch(providerAction.setLoading(false))
      dispatch(providerAction.setProvider(respnose?.data))
    })
    .catch(error => {
      dispatch(providerAction.setLoading(false))
      console.log(error)
    })
}

export const exportProviders = search => dispatch => {
  dispatch(
    providerAction.setExportStatus({
      loading: true,
      error: null,
    })
  )
  exportAxiosInstance
    .get("/v1/provider-export", {
      params: { search },
    })
    .then(response => {
      if (response) {
        const dataBlob = new Blob([response?.data], {
          type: "application/pdf,",
        })
        FileSaver.saveAs(dataBlob, `Projects-${Math.random().toString()}.xlsx`)
        dispatch(
          providerAction.setExportStatus({
            loading: false,
            error: null,
          })
        )
      }
    })
    .catch(error => {
      dispatch(
        providerAction.setExportStatus({
          loading: false,
          error: error?.response?.errors,
        })
      )
      console.log(error)
    })
}
