import axiosInstance from "api/axiosInstance"
import { monitorReceiptsAction } from "./monitor-receipt-slice"
import { batch } from "react-redux"

export const getMonitorProviderIns =
  (
    url,
    pageNumber = 10,
    according = { order: "desc", name: "id" },
    search = null,
    advancedSearch = {}
  ) =>
  dispatch => {
    dispatch(monitorReceiptsAction.setLoading(true))
    url = url || "/v1/get-provider-receipts"
    axiosInstance
      .get(url, {
        params: {
          pageNumber: pageNumber,
          according: according,
          search: search,
          advancedSearch: advancedSearch,
        },
      })
      .then(response => {
        if (response) {
          batch(() => {
            dispatch(monitorReceiptsAction.setError(null))
            dispatch(monitorReceiptsAction.setData(response.data))
            dispatch(monitorReceiptsAction.setLoading(false))
          })
        }
      })
      .catch(error => {
        dispatch(monitorReceiptsAction.setLoading(false))
        console.log(error)
      })
  }
