import React from "react"
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts"

const Chart2 = ({ data, DistributionTitle }) => {
  //   console.log(data)
  //   const filterdData = data?.data.map(dt => {
  //     return { name: dt.items, }
  //   })

  //   console.log(filterdData)

  const chartData = [
    {
      name: "ITem",
      balance: 4000,
      pv: 2400,
      amt: 2400,
    },
  ]
  return (
    <div
      style={{
        width: "100%",
        height: 400,
        backgroundColor: "#fff",
        padding: 30,
      }}
    >
      <ResponsiveContainer>
        <BarChart
          data={data}
          margin={{
            top: 10,
            right: 10,
            left: 10,
            bottom: 10,
          }}
          stackOffset="sign"
          isAnimationActive={false}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="items"
            textAnchor="end"
            sclaeToFit="true"
            verticalAnchor="start"
            interval={0}
            angle="-50"
            height={100}
            stroke="#888"
          />
          <YAxis stroke="#888" type="number" allowDataOverflow={true} />
          <Tooltip />
          <Legend />
          <Bar dataKey="in_qty" name="RECEIPTS" fill="rgba(0, 170, 85)" />
          <Bar
            dataKey="out_qty"
            name={DistributionTitle ? DistributionTitle : "Distributions"}
            fill="rgba(243, 32, 19)"
          />
          <Bar dataKey="balance" name="BALANCE" fill="rgba(0,0,255)" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

export default Chart2
