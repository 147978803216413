import { createSelector } from "@reduxjs/toolkit"
import axiosInstance from "api/axiosInstance"
import { useSelector } from "react-redux"

export const regDataSelector = state => ({
  regData: state.generalRenders.userRegistrationData,
  regLoading: state.generalRenders.userRegistrationDataLoading,
  regError: state.generalRenders.userRegistrationDataError,
})

export const provinceDataSelector = state => ({
  provinces: state.generalRenders.provinces,
  provinceLoading: state.generalRenders.provinceLoading,
  provinceError: state.generalRenders.provinceError,
})

export const ItemCategorySelector = state => ({
  itemCategories: state.generalRenders.itemCategories,
  itemCategoryLoading: state.generalRenders.itemCategoryLoading,
  itemCategoryError: state.generalRenders.itemCategoryError,
})
