import React from "react"
import RolePermissions from "./RolePermissions"

const RoleForm = ({
  loading,
  error,
  role,
  onChangePermission,
  permissionResponse,
  activePermissions,
}) => {
  return (
    <RolePermissions
      permissions={role?.permissions}
      loading={loading}
      onChangePermission={onChangePermission}
      permissionResponse={permissionResponse}
      activePermissions={role?.activePermissions}
      role={role}
      rightBtn={true}
      url={"/roles"}
    />
  )
}

export default RoleForm
