import React, { useEffect, useState, useMemo, memo } from "react"
import Select from "react-select"

const MultipleSelect = ({ options, onChange, name, defaultValue, value }) => {
  return (
    <React.Fragment>
      <Select
        isMulti
        name={name}
        options={[{ value: "select-all", label: "Select All" }, ...options]}
        defaultValue={defaultValue}
        value={value}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={onChange}
      />
    </React.Fragment>
  )
}

export default MultipleSelect
