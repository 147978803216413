import React from "react"
import { Card, CardBody } from "reactstrap"
import { useNavigate } from "react-router-dom"
import CustomHeaderTitle from "components/Common/CustomHeaderTitle"
import GeneralTables from "components/Common/GeneralTable"
import ActionColumns from "components/Common/GeneralTable/ActionColumns"

const WarehouseListComponent = ({
  data,
  error,
  loading,
  onClickDelete,
  onPageClick,
  onSearch,
  onChangeRowNumber,
  onChangeOrdering,
  onExportBtnClick,
  exportStatus,
}) => {
  const navigate = useNavigate()

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CustomHeaderTitle
            loading={loading}
            title={"List"}
            rowCount={true}
            onChangeRowNumber={onChangeRowNumber}
            orderingList={true}
            onChangeOrdering={onChangeOrdering}
            searchBox={true}
            onSearchHandler={onSearch}
            exportBtn={true}
            onExportBtnClick={onExportBtnClick}
            exportBtnLoading={exportStatus?.loading}
            rightBtn={true}
            rightBtnTitle={"Add Warehouse"}
            rightBtnRoute={"/add-warehouse"}
            orderingOptions={[
              { value: "id", name: "ID" },
              { value: "name", name: "Name" },
            ]}
          />

          <GeneralTables
            data={data?.data}
            loading={loading}
            headers={["#", "Title", "Action"]}
            pagination={{ meta: data?.meta, onPageClick: onPageClick }}
            renderItem={(warehouse, index) => (
              <tr key={index}>
                <th scope="row">{data?.meta?.from + index}</th>
                <td>{warehouse.name}</td>
                <td>
                  <ActionColumns
                    data={warehouse}
                    enableEdit={true}
                    enableDelete={true}
                    editRoute={`/edit-warehouse/${warehouse.id}`}
                    onClickDelete={onClickDelete}
                  />
                </td>
              </tr>
            )}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default WarehouseListComponent
