import React from "react"

import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"

const WelcomeComp = () => {
  const user = useSelector(state => state.auth.user)

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <CardBody className="pt-0">
          <Row>
            <Col sm="6" className="mt-2">
              <h5 className="font-size-15 text-truncate">{user?.name}</h5>
              <p className="text-muted mb-0 text-truncate">{user?.email}</p>
            </Col>

            <Col sm="6" style={{ textAlign: "right" }}>
              <div className="mt-4">
                <Link to="/profile" className="btn btn-primary  btn-sm">
                  View Profile <i className="mdi mdi-arrow-right ms-1"></i>
                </Link>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default WelcomeComp
