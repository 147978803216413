import React from "react"
import { Table } from "reactstrap"

const ProviderQuantificatinRenderItem = ({ data }) => {
  return (
    <div className="table-responsive">
      <Table className="table mb-0">
        <thead>
          <tr>
            <th style={{ whiteSpace: "nowrap" }}>SN</th>
            <th style={{ whiteSpace: "nowrap" }}>Item</th>
            <th style={{ whiteSpace: "nowrap" }}>UoM</th>
            <th style={{ whiteSpace: "nowrap" }}>Cost / UoM</th>

            <th
              style={{
                whiteSpace: "nowrap",
              }}
              className="text-info"
            >
              Opening Balance
            </th>
            <th style={{ whiteSpace: "nowrap", color: "rgba(0, 170, 85)" }}>
              Total QTY Received
            </th>
            <th style={{ whiteSpace: "nowrap", color: "rgba(243, 32, 19)" }}>
              Total QTy Distributed
            </th>
            <th style={{ whiteSpace: "nowrap", color: "rgba(0,0,255)" }}>
              Current Balance
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.data?.map((data, index) => {
            let receipt = !isNaN(parseInt(data?.totalReceipts))
              ? parseInt(data?.totalReceipts)
              : 0

            let totalItemPrices = !isNaN(parseInt(data?.totalItemPrices))
              ? (data?.totalItemPrices / data?.countItemPrices).toFixed(2)
              : 0

            let distribution = !isNaN(parseInt(data?.totalDistibution))
              ? parseInt(data?.totalDistibution)
              : 0

            let openingBalance = !isNaN(parseInt(data?.openingBalance))
              ? parseInt(data?.openingBalance)
              : 0
            let beforDateDistribution = !isNaN(
              parseInt(data?.beforeDateDistribution)
            )
              ? parseInt(data?.beforeDateDistribution)
              : 0
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{data?.ip_item_description}</td>
                <td>
                  {data?.ip_unit_umo}{" "}
                  {data?.ip_uom_quantity > 1 && data?.ip_uom_quantity}
                </td>
                <td>{totalItemPrices}</td>

                <td className="text-info">
                  {openingBalance - beforDateDistribution}
                </td>

                <td style={{ color: "rgba(0, 170, 85)" }}>{receipt}</td>
                <td style={{ color: "rgba(243, 32, 19)" }}>{distribution}</td>
                <td style={{ color: "rgba(0,0,255)" }}>
                  {receipt +
                    openingBalance -
                    (distribution + beforDateDistribution)}
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </div>
  )
}

export default ProviderQuantificatinRenderItem
