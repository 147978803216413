import axiosInstance from "api/axiosInstance"
import { warehouseAction } from "./warehouse-slice"
import SAlert from "components/Alert"
import exportAxiosInstance from "api/exportAxiosInstance"
import FileSaver from "file-saver"

export const getWarehouses =
  (
    url,
    pageNumber = 10,
    according = { order: "desc", name: "id" },
    search = null
  ) =>
  dispatch => {
    dispatch(warehouseAction.setLoading(true))
    url = url || "/v1/warehouses"
    axiosInstance
      .get(url, {
        params: {
          pageNumber: pageNumber,
          according: according,
          search: search,
        },
      })
      .then(response => {
        if (response) {
          dispatch(warehouseAction.setError(null))
          dispatch(warehouseAction.setData(response.data))
          dispatch(warehouseAction.setLoading(false))
        }
      })
      .catch(error => {
        dispatch(warehouseAction.setLoading(false))
        console.log(error)
      })
  }

export const deleteWarehouse = id => dispatch => {
  dispatch(warehouseAction.setLoading(true))
  axiosInstance
    .delete(`/v1/warehouses/${id}`)
    .then(response => {
      SAlert({ title: "Success", text: "Warehouse successfully delete" })
      dispatch(getWarehouses())
      dispatch(warehouseAction.setError(null))
      dispatch(warehouseAction.setLoading(false))
    })
    .catch(error => {
      dispatch(warehouseAction.setLoading(false))
      console.log(error)
    })
}

export const storeWarehouse = (data, navigate) => dispatch => {
  dispatch(warehouseAction.setLoading(true))
  axiosInstance
    .post("/v1/warehouses", data)
    .then(response => {
      navigate("/warehouse")
      SAlert({ title: "Success", text: "Warehouse successfully inserted" })
      dispatch(warehouseAction.setError(null))
      dispatch(warehouseAction.setLoading(false))
    })
    .catch(err => {
      dispatch(warehouseAction.setLoading(false))
      dispatch(warehouseAction.setError(err?.response?.data?.errors))
    })
}

export const updateWarehouse = (id, data, navigate) => dispatch => {
  dispatch(warehouseAction.setLoading(true))
  axiosInstance
    .put(`/v1/warehouses/${id}`, data)
    .then(respnose => {
      navigate("/warehouse")
      SAlert({ title: "Success", text: "Warehouse successfully updated" })
      dispatch(warehouseAction.setError(null))
      dispatch(warehouseAction.setLoading(false))
    })
    .catch(error => {
      dispatch(warehouseAction.setLoading(false))
      dispatch(warehouseAction.setError(error?.response?.data?.errors))
    })
}

export const getByIdWarehouse = id => dispatch => {
  dispatch(warehouseAction.setLoading(true))
  axiosInstance
    .get(`/v1/warehouses/${id}`)
    .then(respnose => {
      dispatch(warehouseAction.setError(null))
      dispatch(warehouseAction.setLoading(false))
      dispatch(warehouseAction.setWarehouse(respnose.data))
    })
    .catch(error => {
      dispatch(warehouseAction.setLoading(false))
      console.log(error)
    })
}

export const exportWarehouse = search => dispatch => {
  dispatch(
    warehouseAction.setExportStatus({
      loading: true,
      error: null,
    })
  )
  exportAxiosInstance
    .get("/v1/warehouses-export", {
      params: { search },
    })
    .then(response => {
      if (response) {
        const dataBlob = new Blob([response?.data], {
          type: "application/pdf,",
        })
        FileSaver.saveAs(
          dataBlob,
          `Warehouses-${Math.random().toString()}.xlsx`
        )
        dispatch(
          warehouseAction.setExportStatus({
            loading: false,
            error: null,
          })
        )
      }
    })
    .catch(error => {
      dispatch(
        warehouseAction.setExportStatus({
          loading: false,
          error: error?.response?.errors,
        })
      )
      console.log(error)
    })
}
