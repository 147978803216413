import React, { useState } from "react"
import { Card, CardBody, Collapse } from "reactstrap"
import { useNavigate } from "react-router-dom"
import CustomHeaderTitle from "components/Common/CustomHeaderTitle"

import ProvinceConsumptionItems from "./ConsumptionItems"
import ProvinceConsumptionFilter from "./ConsumptionFilter"
import ProvinceConsumptionHeaderTitle from "./HeaderTitle"

const ProvinceConsumptionListComponent = ({
  data,
  error,
  loading,
  onAdvancedSearchSubmit,
  onSubmit,
  onUpdateFunc,
  filters,
  storeStatus,
  onExport,
  exportLoading,
}) => {
  const navigate = useNavigate()
  const [filterOpen, setFilterOpen] = useState(false)
  const [form, setForm] = useState({})
  const [quantity, setQuantity] = useState([])

  const onAdvancedSearchHandler = result => {
    setFilterOpen(result)
  }

  const onSubmitQuantity = value => {
    onSubmit(value)
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CustomHeaderTitle
            loading={loading}
            title={"List"}
            rowCount={false}
            orderingList={false}
            searchBox={false}
            advnacedSearchBtn={true}
            exportBtn={data}
            exportBtnLoading={exportLoading}
            onExportBtnClick={onExport}
            advnacedSearchBtnTitle={"Add Consumption"}
            onAdvancedSearch={onAdvancedSearchHandler}
          />
          <Collapse isOpen={filterOpen}>
            <ProvinceConsumptionFilter
              loading={loading}
              onAdvancedSearchSubmit={onAdvancedSearchSubmit}
              setQuantity={setQuantity}
              error={error}
            />
          </Collapse>
          <ProvinceConsumptionHeaderTitle
            filters={filters}
            storeStatus={storeStatus}
          />
          <ProvinceConsumptionItems
            data={data}
            onSubmitQuantity={onSubmitQuantity}
            form={form}
            setForm={setForm}
            quantity={quantity}
            setQuantity={setQuantity}
            onUpdateFunc={onUpdateFunc}
            storeStatus={storeStatus}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default ProvinceConsumptionListComponent
