import SingleSelect from "components/Common/SelectBoxes/SingleSelect"
import React, { useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Col, Form, Input, Label, Row } from "reactstrap"
import Select from "react-select"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import moment from "moment"
import { getProviderItems } from "store/provider-dashboard/provider-dashboard-action"
import { providerDashboardAction } from "store/provider-dashboard/provider-dashboard-slice"
import { getMonitorItems } from "store/monitor-dashboard/monitor-dashboard-action"
import {
  getMonitorConsumptionsIPs,
  getMonitorConsumptionsProjects,
  getMonitorConsumptionsProvince,
} from "store/monitor-consumption/monitor-consumption-action"
import { isObject } from "lodash"
import { monitorConsumptionAction } from "store/monitor-consumption/monitor-consumption-slice"

const MonitorQuantificationOrganizationFilter = ({
  onAdvancedSearchSubmit,
  loading,
}) => {
  const dateFlatpickerfrom = useRef(null)
  const dateFlatpickerto = useRef(null)
  const [category, setCategory] = useState(null)
  const [item, setItem] = useState(null)
  const [batch_number, setBatchNumber] = useState("")
  const [province, setProvince] = useState(null)
  const [facility, setFacility] = useState(null)
  const [ip, setIP] = useState(null)
  const [provinceIp, setProvinceIp] = useState(null)
  const [project, setProject] = useState(null)
  const [qtr, setQtr] = useState([])

  const dispatch = useDispatch()

  const [date, setDate] = useState({
    from: null,
    to: null,
  })

  const categories = useSelector(
    state => state.monitorDashboard.MonitorItemCategoires
  )
  const ips = useSelector(state => state.monitorDashboard.MonitorIps)
  const Provinceips = useSelector(
    state => state.monitorConsumptions.monitorConsumpIPs
  )

  const items = useSelector(state => state.monitorDashboard.MonitorItems)

  const projects = useSelector(
    state => state.monitorConsumptions.monitorConsumpProjects
  )
  const provinces = useSelector(
    state => state.monitorConsumptions.monitorConsumpProvinces
  )

  const facilities = useSelector(
    state => state.monitorConsumptions.monitorConsumpFacilities
  )

  const filteredItems = useMemo(
    () =>
      items?.data.map(item => ({
        value: item.id,
        label: `${item.unfp_item_name} ( ${item.item_name} )`,
      })),
    [items]
  )

  const handleSubmit = () => {
    let data = {}

    data.date = {
      from: date.from ? date.from : null,
      to: date.to ? date.to : null,
    }
    if (item) {
      data.item = item.map(ite => (ite.value ? ite.value : ite))
    }
    data.category = isObject(category) ? category.value : null
    data.providerIP = isObject(ip) ? ip.value : null
    data.ip_id = isObject(provinceIp) ? provinceIp.value : null
    data.province = isObject(province) ? province.value : null
    data.project_id = isObject(project) ? project.value : null
    data.batch_number = batch_number
    if (qtr) {
      data.qtr = qtr.map(qt => (qt.value ? qt.value : qt))
    }
    onAdvancedSearchSubmit(data)
  }

  const onClearHandler = () => {
    dateFlatpickerfrom.current.flatpickr.clear()
    dateFlatpickerto.current.flatpickr.clear()
    setItem(null)
    setCategory(null)
    setBatchNumber("")
    setDate({ from: null, to: null })
    setProvince(null)
    setIP(null)
    setProject("")
    setProvinceIp("")
    setQtr([])
    dispatch(monitorConsumptionAction.resetState())
    onAdvancedSearchSubmit({})
  }

  const onCategoryChangeHandler = cate => {
    setCategory(cate)
    dispatch(getMonitorItems(cate.value))
  }

  const onItemChangeHandler = item => {
    const check = item.some(ite => ite.value === "all")
    if (check) {
      setItem(filteredItems)
    } else {
      setItem(item)
    }
  }

  const onIPChangeHandler = ips => {
    setIP(ips)
    dispatch(getMonitorConsumptionsProjects({ ip_id: ips.value }))
  }

  const onProjectChangeHandler = proj => {
    dispatch(
      getMonitorConsumptionsIPs({ project_id: proj.value, ip_id: ip.value })
    )
    setProject(proj)
  }

  const onProvinceIPChangeHandler = ips => {
    setProvinceIp(ips)
    dispatch(
      getMonitorConsumptionsProvince({
        providerIP: ip.value,
        project_id: project.value,
        ip_id: ips.value,
      })
    )
  }
  const onProvinceChangeHandler = prov => {
    setProvince(prov)
  }

  const onBatchNumberChange = batch_number => {
    setBatchNumber(batch_number.target.value)
  }

  return (
    <Form
      onSubmit={e => {
        e.preventDefault()
        handleSubmit()
        return false
      }}
    >
      <div className="mb-3">
        <Row>
          <Col>
            <Label className="form-label">Providers</Label>
            {ips?.data && (
              <Select
                options={ips?.data}
                value={ip}
                onChange={onIPChangeHandler}
              />
            )}
          </Col>
          <Col>
            <Label className="form-label">Projects</Label>
            {projects?.data && (
              <Select
                options={projects?.data}
                value={project}
                onChange={onProjectChangeHandler}
              />
            )}
          </Col>
        </Row>
      </div>
      <div className="mb-3">
        <Row>
          <Col>
            <Label className="form-label">IPs</Label>
            {Provinceips?.data && (
              <Select
                options={Provinceips?.data}
                value={provinceIp}
                onChange={onProvinceIPChangeHandler}
              />
            )}
          </Col>
          <Col>
            <Label className="form-label">Provinces</Label>
            {provinces?.data && (
              <Select
                options={provinces?.data}
                value={province}
                onChange={onProvinceChangeHandler}
              />
            )}
          </Col>
        </Row>
      </div>

      <div className="mb-3 ">
        <Row>
          <Col>
            <Label className="form-label">From</Label>
            <Flatpickr
              ref={dateFlatpickerfrom}
              className="form-control d-block"
              placeholder="dd M,yyyy"
              options={{
                altInput: true,
                altFormat: "Y-m-d",
                dateFormat: "Y-m-d",
              }}
              onChange={value => {
                setDate(prev => {
                  return {
                    ...prev,
                    from: moment(value[0]).format("YYYY-MM-DD"),
                  }
                })
              }}
            />
          </Col>
          <Col>
            <Label className="form-label">To</Label>
            <Flatpickr
              ref={dateFlatpickerto}
              className="form-control d-block"
              placeholder="dd M,yyyy"
              options={{
                altInput: true,
                altFormat: "Y-m-d",
                dateFormat: "Y-m-d",
              }}
              onChange={value => {
                setDate(prev => {
                  return { ...prev, to: moment(value[0]).format("YYYY-MM-DD") }
                })
              }}
            />
          </Col>
        </Row>
      </div>

      <div className="d-flex flex-wrap gap-2 mb-4">
        <Button
          type="submit"
          className="btn btn-sm btn-primary"
          color="primary"
          disabled={loading}
        >
          {loading ? "Please Wait..." : "Search"}
        </Button>
        <Button
          onClick={onClearHandler}
          className="btn btn-sm btn-danger"
          color="primary"
        >
          Clear
        </Button>
      </div>
    </Form>
  )
}

export default MonitorQuantificationOrganizationFilter
