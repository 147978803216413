import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: [],
  loading: false,
  error: null,
  distribution: {},
  receipts: { error: null, loading: false, data: [] },
  providerAccounts: { error: null, loading: false, data: [] },
  storeQuantityError: [],
  NonIps: { error: null, loading: false, data: [] },
}

const providerOutSlice = createSlice({
  name: "providerOut",
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    setError(state, action) {
      state.error = action.payload
    },
    setProviderOut(state, action) {
      state.distribution = action.payload
    },
    setProviderOutReceipts(state, action) {
      state.receipts = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setNoneIps(state, action) {
      state.NonIps = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setProviderAccount(state, action) {
      state.providerAccounts = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setStoreQuantityError(state, action) {
      state.storeQuantityError = action.payload
    },
    resetState(state) {
      state.data = []
      state.loading = false
      state.error = null
      state.distribution = {}
      state.receipts = { error: null, loading: false, data: [] }
      state.storeQuantityError = []
    },
    resetStoreQuantityError(state) {
      state.storeQuantityError = []
    },
    resetReceiptOut(state) {
      state.receipts = { error: null, loading: false, data: [] }
    },
    resetProviderAccount(state) {
      state.providerAccounts = { error: null, loading: false, data: [] }
    },
    resetNoneIps(state) {
      state.NonIps = { error: null, loading: false, data: [] }
    },
  },
})

export const providerOutAction = providerOutSlice.actions

export default providerOutSlice
