import React, { useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Form, Input, Label } from "reactstrap"
import Select from "react-select"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import moment from "moment"
import { getProviderIpsStock } from "store/provider-dashboard/provider-dashboard-action"
import { getMonitorIpsStock } from "store/monitor-dashboard/monitor-dashboard-action"

const MonitorIPsStockFilter = ({
  onCategoryChange,
  provIpsStockLoading,
  getSearchData,
  onIPsAdvanceSearchHandler,
}) => {
  const ips = useSelector(state => state.monitorDashboard.MonitorIps)
  const items = useSelector(state => state.monitorDashboard.MonitorItems)
  const categories = useSelector(
    state => state.monitorDashboard.MonitorItemCategoires
  )

  const filteredItems = useMemo(
    () =>
      items?.data.map(item => ({
        value: item.id,
        label: `${item.unfp_item_name} ( ${item.item_name} )`,
      })),
    [items]
  )

  const dispatch = useDispatch()

  const fromdateFlatpicker = useRef(null)
  const todateFlatpicker = useRef(null)
  const [Ips, setIps] = useState(null)
  const [category, setCategory] = useState(null)
  const [item, setItem] = useState(null)
  const [batchNumber, setBatchNumber] = useState("")
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)

  const handleSubmit = () => {
    let searchs = {}
    searchs.ips = Ips ? Ips.value : null
    searchs.category = category ? category.value : null
    if (item) {
      searchs.item = item.map(ite => (ite.value ? ite.value : ite))
    }
    searchs.batchNumber = batchNumber
    searchs.from = fromDate
    searchs.to = toDate
    getSearchData({ Ips, category, item, batchNumber, fromDate, toDate })
    onIPsAdvanceSearchHandler(searchs)
  }

  const IpsChangeHandler = ip => {
    setIps(ip)
  }

  const onCategoryChangeHandler = category => {
    onCategoryChange(category.value)
    setCategory(category)
  }

  const onItemChangeHandler = item => {
    const check = item.some(ite => ite.value === "all")
    if (check) {
      setItem(filteredItems)
    } else {
      setItem(item)
    }
  }

  const onClearHandler = () => {
    fromdateFlatpicker.current.flatpickr.clear()
    todateFlatpicker.current.flatpickr.clear()
    setIps(null)
    setCategory(null)
    setItem(null)
    setBatchNumber("")
    setFromDate(null)
    setToDate(null)
    getSearchData({ Ips, category, item, batchNumber, fromDate, toDate })
    onIPsAdvanceSearchHandler({})
  }

  return (
    <Form
      onSubmit={e => {
        e.preventDefault()
        handleSubmit()
        return false
      }}
    >
      <div className="mb-3">
        <Label className="form-label">IPs</Label>
        {ips?.data && (
          <Select
            options={[{ value: "all", label: "All" }, ...ips?.data]}
            value={Ips}
            onChange={IpsChangeHandler}
          />
        )}
      </div>

      <div className="mb-3">
        <Label className="form-label">Item Categories</Label>
        {categories?.data && (
          <Select
            options={categories?.data}
            value={category || categories?.data[0]}
            onChange={onCategoryChangeHandler}
          />
        )}
      </div>

      {filteredItems && (
        <div className="mb-3">
          <Label className="form-label">Items</Label>
          <Select
            isMulti={true}
            options={[{ value: "all", label: "All" }, ...filteredItems]}
            value={item}
            onChange={onItemChangeHandler}
          />
        </div>
      )}
      <div className="mb-3">
        <Label className="form-label">Batch Number</Label>
        <Input
          name="name"
          value={batchNumber}
          onChange={value => {
            setBatchNumber(value.target.value)
          }}
          placeholder="Name"
          type="text"
        />
      </div>

      <div className="mb-3 ">
        <Label className="form-label">From Date</Label>
        <Flatpickr
          ref={fromdateFlatpicker}
          className="form-control d-block"
          placeholder="dd M,yyyy"
          options={{
            altInput: true,
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
          }}
          onChange={value => {
            setFromDate(moment(value[0]).format("YYYY-MM-DD"))
          }}
        />
        <br />
        <Label className="form-label">To Date</Label>
        <Flatpickr
          ref={todateFlatpicker}
          className="form-control d-block"
          placeholder="dd M,yyyy"
          options={{
            altInput: true,
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
          }}
          onChange={value => {
            setToDate(moment(value[0]).format("YYYY-MM-DD"))
          }}
        />
      </div>

      <div className="d-flex flex-wrap gap-2">
        <Button
          type="submit"
          className="btn btn-sm btn-primary"
          color="primary"
          disabled={provIpsStockLoading}
        >
          {provIpsStockLoading ? "Please Wait..." : "Search"}
        </Button>
        <Button
          onClick={onClearHandler}
          className="btn btn-sm btn-danger"
          color="primary"
        >
          Clear
        </Button>
      </div>
    </Form>
  )
}

export default MonitorIPsStockFilter
