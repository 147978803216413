import { createSelector, createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axiosInstance from "api/axiosInstance"

const initialState = {
  provinces: [],
  provinceLoading: false,
  provinceError: null,
  itemCategories: [],
  itemCategoryLoading: false,
  itemCategoryError: null,
  roles: [],
  roleLoading: false,
  roleError: null,
  providers: [],
  providerLoading: false,
  providerError: null,
  warehouses: [],
  warehouseLoading: false,
  warehouseError: null,
  ips: [],
  ipLoading: false,
  ipError: null,
  userRegistrationData: [],
  userRegistrationDataLoading: false,
  userRegistrationDataError: null,
}

export const getRegData = createAsyncThunk("/getRegData", async () => {
  try {
    const response = await axiosInstance
      .get("/v1/getUserRegistrationData")
      .then(response => {
        return response?.data
      })
      .catch(error => {
        return error
      })
  } catch (error) {
    console.log(error)
  }
})

const generalSlice = createSlice({
  name: "generalRenders",
  initialState,
  reducers: {
    setProvincesData(state, action) {
      state.provinces = action.payload
    },
    setProvinceError(state, action) {
      state.provinceError = action.payload
    },
    setProvinceLoading(state, action) {
      state.provinceLoading = action.payload
    },
    setItemCategoryData(state, action) {
      state.itemCategories = action.payload
    },
    setItemCategoryError(state, action) {
      state.itemCategoryError = action.payload
    },
    setItemCategoryLoading(state, action) {
      state.itemCategoryLoading = action.payload
    },

    setRoleData(state, action) {
      state.roles = action.payload
    },
    setRoleError(state, action) {
      state.roleError = action.payload
    },
    setRoleLoading(state, action) {
      state.roleLoading = action.payload
    },

    setProviderData(state, action) {
      state.providers = action.payload
    },
    setProviderError(state, action) {
      state.providerError = action.payload
    },
    setProviderLoading(state, action) {
      state.providerLoading = action.payload
    },

    setWarehouseData(state, action) {
      state.warehouses = action.payload
    },
    setWarehouseError(state, action) {
      state.warehouseError = action.payload
    },
    setWarehouseLoading(state, action) {
      state.warehouseLoading = action.payload
    },

    setIPData(state, action) {
      state.ips = action.payload
    },
    setIPError(state, action) {
      state.ipError = action.payload
    },
    setIPLoading(state, action) {
      state.ipLoading = action.payload
    },

    setUserRegistrationData(state, action) {
      state.userRegistrationData = action.payload
    },
    setUserRegistrationDataLoading(state, action) {
      state.userRegistrationDataLoading = action.payload
    },
    setUserRegistrationDataError(state, action) {
      state.userRegistrationDataError = action.payload
    },
    resetUserRegData(state) {
      state.userRegistrationData = []
      state.userRegistrationDataLoading = true
      state.userRegistrationDataError = null
    },

    resetProvince(state) {
      state.provinces = []
      state.provinceLoading = false
      state.provinceError = null
    },
    resetItemCategory(state) {
      state.itemCategories = []
      state.itemCategoryLoading = false
      state.itemCategoryError = null
    },
  },
})

export const generalAction = generalSlice.actions

export default generalSlice
