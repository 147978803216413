import axiosInstance from "api/axiosInstance"
import { ProvActionRequestAction } from "./provider-action-request-slice"
import { batch } from "react-redux"
import SAlert from "components/Alert"

export const getProviderActionRequestCount = () => dispatch => {
  dispatch(
    ProvActionRequestAction.setActionRequestCount({
      receipt: 0,
      distribution: 0,
    })
  )
  axiosInstance
    .get("/v1/get-count-provider-action-rerquest")
    .then(response => {
      if (response) {
        dispatch(
          ProvActionRequestAction.setActionRequestCount({
            receipt: response?.data?.receipt,
            distribution: response?.data?.distribution,
          })
        )
      }
    })
    .catch(error => {
      dispatch(
        ProvActionRequestAction.setActionRequestCount({
          receipt: 0,
          distribution: 0,
        })
      )
      console.log(error)
    })
}

export const getProviderReceiptActionRequests =
  (
    url,
    pageNumber = 10,
    according = { order: "desc", name: "id" },
    search = null,
    advancedSearch = {}
  ) =>
  dispatch => {
    dispatch(ProvActionRequestAction.setLoading(true))
    url = url || "/v1/get-provider-receipts-action-rerquest"
    axiosInstance
      .get(url, {
        params: {
          pageNumber: pageNumber,
          according: according,
          search: search,
          advancedSearch: advancedSearch,
        },
      })
      .then(response => {
        if (response) {
          batch(() => {
            dispatch(ProvActionRequestAction.setError(null))
            dispatch(ProvActionRequestAction.setData(response.data))
            dispatch(ProvActionRequestAction.setLoading(false))
          })
        }
      })
      .catch(error => {
        dispatch(ProvActionRequestAction.setLoading(false))
        console.log(error)
      })
  }

export const sendProviderReceiptActionRequests = (ids, status) => dispatch => {
  dispatch(ProvActionRequestAction.setLoading(true))
  axiosInstance
    .post("/v1/send-provider-receipts-action-rerquest", { ids, status })
    .then(response => {
      dispatch(getProviderReceiptActionRequests())
      SAlert({
        title: "Success",
        text: `request has been ${status} successfully`,
      })
      console.log(response?.data)
      batch(() => {
        dispatch(ProvActionRequestAction.setError(null))
        dispatch(ProvActionRequestAction.setLoading(false))
      })
    })
    .catch(error => {
      console.log(error)
    })
}

export const getProviderDistributionActionRequests =
  (
    url,
    pageNumber = 10,
    according = { order: "desc", name: "id" },
    search = null,
    advancedSearch = {}
  ) =>
  dispatch => {
    dispatch(ProvActionRequestAction.setLoading(true))
    url = url || "/v1/get-provider-distribution-action-rerquest"
    axiosInstance
      .get(url, {
        params: {
          pageNumber: pageNumber,
          according: according,
          search: search,
          advancedSearch: advancedSearch,
        },
      })
      .then(response => {
        if (response) {
          batch(() => {
            dispatch(ProvActionRequestAction.setError(null))
            dispatch(ProvActionRequestAction.setData(response.data))
            dispatch(ProvActionRequestAction.setLoading(false))
          })
        }
      })
      .catch(error => {
        dispatch(ProvActionRequestAction.setLoading(false))
        console.log(error)
      })
  }

export const sendProviderDistributionActionRequests =
  (ids, status) => dispatch => {
    dispatch(ProvActionRequestAction.setLoading(true))
    axiosInstance
      .post("/v1/send-provider-distribution-action-rerquest", { ids, status })
      .then(response => {
        dispatch(getProviderDistributionActionRequests())
        SAlert({
          title: "Success",
          text: `request has been ${status} successfully`,
        })
        console.log(response?.data)
        batch(() => {
          dispatch(ProvActionRequestAction.setError(null))
          dispatch(ProvActionRequestAction.setLoading(false))
          dispatch(ProvActionRequestAction.resetIds())
        })
      })
      .catch(error => {
        dispatch(ProvActionRequestAction.setLoading(false))
        dispatch(ProvActionRequestAction.resetIds())
        console.log(error)
      })
  }
