import React from "react"
import { Card, CardBody } from "reactstrap"
import { useNavigate } from "react-router-dom"
import CustomHeaderTitle from "components/Common/CustomHeaderTitle"
import GeneralTables from "components/Common/GeneralTable"
import ActionColumns from "components/Common/GeneralTable/ActionColumns"

const ProviderListComponent = ({
  data,
  error,
  loading,
  onClickDelete,
  onPageClick,
  onSearch,
  onChangeRowNumber,
  onChangeOrdering,
  onExportBtnClick,
  exportStatus,
}) => {
  const navigate = useNavigate()

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CustomHeaderTitle
            loading={loading}
            title={"List"}
            rowCount={true}
            onChangeRowNumber={onChangeRowNumber}
            orderingList={true}
            onChangeOrdering={onChangeOrdering}
            searchBox={true}
            onSearchHandler={onSearch}
            exportBtn={true}
            onExportBtnClick={onExportBtnClick}
            exportBtnLoading={exportStatus?.loading}
            rightBtn={true}
            rightBtnTitle={"Add Provider"}
            rightBtnRoute={"/add-provider"}
            orderingOptions={[
              { value: "id", name: "ID" },
              { value: "title", name: "Title" },
            ]}
          />
          <GeneralTables
            data={data?.data}
            loading={loading}
            headers={["#", "Title", "Action"]}
            pagination={{ meta: data?.meta, onPageClick: onPageClick }}
            renderItem={(provider, index) => (
              <tr key={index}>
                <th scope="row">{data?.meta?.from + index}</th>
                <td>{provider.title}</td>
                <td>
                  <ActionColumns
                    data={provider}
                    enableEdit={true}
                    enableDelete={true}
                    editRoute={`/edit-provider/${provider.id}`}
                    onClickDelete={onClickDelete}
                  />
                </td>
              </tr>
            )}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default ProviderListComponent
