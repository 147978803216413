import React, { useState } from "react"
import { Button, Card, CardBody, Collapse } from "reactstrap"
import { useNavigate } from "react-router-dom"
import CustomHeaderTitle from "components/Common/CustomHeaderTitle"
import GeneralTables from "components/Common/GeneralTable"
import ActionColumns from "components/Common/GeneralTable/ActionColumns"
import CSAlert from "components/Alert/ConfirmationAlert"
import { result, update } from "lodash"
import ProvinceDistributionActionRequestFilter from "./filter"
import { useDispatch, useSelector } from "react-redux"
import { ProvinceActionRequestAction } from "store/province-action-requests/province-action-request-slice"

const ProvinceDistributionActionRequestComponent = ({
  data,
  error,
  loading,
  onPageClick,
  onSearch,
  onChangeRowNumber,
  onChangeOrdering,
  onClickDelete,
  onAllDeleteClicked,
  onAdvancedSearchSubmit,
  permissions,
  onClickDetail,
  onCancelDeleteClicked,
}) => {
  const navigate = useNavigate()
  const [filterOpen, setFilterOpen] = useState(false)

  const actionIds = useSelector(state => state.provinceActionRequest.ids)
  const dispatch = useDispatch()

  const onCheckboxClick = (e, id) => {
    const check = actionIds.includes(id)
    if (check) {
      const updated = actionIds.filter(myId => myId !== id)
      dispatch(ProvinceActionRequestAction.setIds(updated))
    } else {
      dispatch(ProvinceActionRequestAction.setNewIds(id))
    }
  }

  const onAllDeleteHandler = async () => {
    const results = await CSAlert({
      title: "Are you sure delete?",
    })
    if (results.isConfirmed) {
      onAllDeleteClicked(actionIds)
    }
  }

  const onParentCheckChange = status => {
    if (status) {
      const all = data?.data.map(dt => dt.id)
      dispatch(ProvinceActionRequestAction.setIds(all))
    } else {
      dispatch(ProvinceActionRequestAction.resetIds())
    }
  }

  const onCancelDeleteHandler = async () => {
    const results = await CSAlert({
      title: "Are you sure for cancel?",
    })
    if (results.isConfirmed) {
      onCancelDeleteClicked(actionIds)
    }
  }

  const onAdvancedSearchHandler = result => {
    setFilterOpen(result)
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CustomHeaderTitle
            loading={loading}
            title={"List"}
            rowCount={true}
            onChangeRowNumber={onChangeRowNumber}
            orderingList={true}
            onChangeOrdering={onChangeOrdering}
            searchBox={true}
            onSearchHandler={onSearch}
            rightBtn={false}
            deleteBtn={true}
            onAllDeleteHandler={onAllDeleteHandler}
            deleteBtnTitle={"Approve"}
            advnacedSearchBtn={true}
            advnacedSearchBtnTitle={"Filter"}
            cancelBtn={true}
            onAdvancedSearch={onAdvancedSearchHandler}
            onCancelDeleteHandler={onCancelDeleteHandler}
            orderingOptions={[
              { value: "id", name: "ID" },
              { value: "date", name: "Submission Date" },
            ]}
          />
          <Collapse isOpen={filterOpen}>
            <ProvinceDistributionActionRequestFilter
              loading={loading}
              onAdvancedSearchSubmit={onAdvancedSearchSubmit}
            />
          </Collapse>
          <GeneralTables
            data={data?.data}
            loading={loading}
            parentCheckbox={true}
            onParentCheckChange={onParentCheckChange}
            headers={[
              "SN",
              "PROJECT",
              "PROVIDER",
              "PROVINCE",
              "FACILITY",
              "DISTRIBUTOR",
              "UNFPA ITEM ID",
              "IP- ITEM DESCRIPTION",
              "UNFPA UOM",
              "IP UOM",
              "BATCH NUMBER",
              "EXPIRE DATE",
              "OUT QUANTITY	",
              "DISTRIBUTION DATE",

              "Action",
            ]}
            pagination={{ meta: data?.meta, onPageClick: onPageClick }}
            renderItem={(pOut, index) => (
              <tr key={index}>
                <th scope="row">
                  {" "}
                  <div className="form-check form-checkbox-outline form-check-primary mb-3">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={`checkbox-${pOut.id}`}
                      checked={actionIds.includes(pOut.id)}
                      onChange={e => onCheckboxClick(e, pOut.id)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`checkbox-${pOut.id}`}
                    >
                      {data?.meta?.from + index}
                    </label>
                  </div>
                </th>
                <td>{pOut?.project?.name}</td>
                <td>{pOut?.provider?.title}</td>
                <td>{pOut?.province?.province}</td>
                <td>{pOut?.facility?.facility_name}</td>
                <td>{pOut?.distributor}</td>
                <td>{pOut?.item?.unfp_item_name}</td>
                <td>{pOut?.item?.ip_item_description}</td>
                <td>
                  {pOut?.item.uom} &nbsp;
                  {pOut?.item.unfpa_uom_quantity > 1 &&
                    pOut?.item.unfpa_uom_quantity}
                </td>
                <td>
                  {pOut?.item.ip_unit_umo} &nbsp;
                  {pOut?.item.ip_uom_quantity > 1 && pOut?.item.ip_uom_quantity}
                </td>

                <td>{pOut?.province_in?.batch_number}</td>
                <td>{pOut?.province_in?.expire_date}</td>
                <td>{pOut?.out_quantity}</td>
                <td>{pOut?.date}</td>
                <td>
                  <ActionColumns
                    data={pOut}
                    enableDetail={true}
                    onClickDetail={onClickDetail}
                  />
                </td>
              </tr>
            )}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default ProvinceDistributionActionRequestComponent
