import WarehouseForm from "components/Warehouse/form"
import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { storeWarehouse } from "store/warehouse/warehouse-action"

const AddWarehouse = () => {
  document.title = "Add Warehouse"

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { loading, error, data } = useSelector(state => state.warehouse)

  const handleSubmit = values => {
    dispatch(storeWarehouse(values, navigate))
  }

  return (
    <WarehouseForm
      onSubmit={handleSubmit}
      loading={loading}
      error={error}
      formMode={"Add"}
    />
  )
}

export default AddWarehouse
