import IPForm from "components/IP/form"
import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { storeIP } from "store/ips/ips-action"

const AddIP = () => {
  document.title = "Add IP"
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { loading, error, data } = useSelector(state => state.ips)

  const handleSubmit = values => {
    dispatch(storeIP(values, navigate))
  }

  return (
    <IPForm
      onSubmit={handleSubmit}
      loading={loading}
      error={error}
      formMode={"Add"}
    />
  )
}

export default AddIP
