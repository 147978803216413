import React, { useEffect, useState, useMemo, memo } from "react"
import Select from "react-select"

const CustomSelect = ({
  options,
  onChange,
  name,
  defaultValue,
  value,
  isDisabled,
  isLoading,
}) => {
  return (
    <React.Fragment>
      <Select
        name={name}
        options={options}
        defaultValue={defaultValue}
        value={value}
        className="basic-single"
        classNamePrefix="select"
        onChange={onChange}
        isDisabled={isDisabled}
        isLoading={isLoading && isLoading}
      />
    </React.Fragment>
  )
}

export default CustomSelect
