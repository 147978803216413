import React, { useEffect, useState } from "react"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import {
  getProviderIps,
  getProviderItemCategories,
  getProviderProjects,
  getProviderProvinces,
} from "store/provider-dashboard/provider-dashboard-action"
import {
  exportProviderQuantificationReport,
  exportProviderQuantificationReportBasedOrganization,
  getProviderQuantificationReportBasedOrganization,
} from "store/provider-reports/p-report-action"
import ProviderQuantificationOrganizationReportListComponent from "components/ProviderReports/QuantificationBasedOrganization"
import { getProviderConsumptionsProjects } from "store/provider-consumption/province-consumption-action"
import {
  exportMonitorQuantificationReportBasedOrganization,
  getMonitorQuantificationReportBasedOrganization,
} from "store/monitor-reports/monitor-report-action"
import {
  getMonitorIps,
  getMonitorItemCategories,
  getMonitorProjects,
  getMonitorProvinces,
} from "store/monitor-dashboard/monitor-dashboard-action"
import { getMonitorConsumptionsProjects } from "store/monitor-consumption/monitor-consumption-action"
import MonitorQuantificationOrganizationReportListComponent from "components/MonitorReports/QuantificationBasedOrganization"

const MonitorQuantificationOrganizationReport = () => {
  //meta title
  document.title = "Quantification Based Organization Report"

  const [params, setParams] = useState({})
  const { data, error, loading, exportStatus } = useSelector(state => ({
    data: state.monitorReports.monitorQuantificationReport.data,
    error: state.monitorReports.monitorQuantificationReport.error,
    loading: state.monitorReports.monitorQuantificationReport.loading,
    exportStatus: state.monitorReports.monitorQuantificationExport,
  }))

  const dispatch = useDispatch()

  const onPageClick = link => {
    dispatch(getMonitorQuantificationReportBasedOrganization(link.url, params))
  }

  const onChangeRowNumber = value => {
    setParams(prev => {
      return { ...prev, pageNumber: value }
    })
    dispatch(
      getMonitorQuantificationReportBasedOrganization(undefined, {
        ...params,
        pageNumber: value,
      })
    )
  }

  const onExport = () => {
    dispatch(exportMonitorQuantificationReportBasedOrganization(params))
  }

  useEffect(() => {
    dispatch(getMonitorQuantificationReportBasedOrganization())
    dispatch(getMonitorProjects())
    dispatch(getMonitorIps())
    dispatch(getMonitorProvinces())
  }, [])

  const onSearchHandler = filter => {
    setParams(filter)
    dispatch(getMonitorQuantificationReportBasedOrganization(undefined, filter))
  }

  return (
    <React.Fragment>
      <Breadcrumbs
        title={"Monitor"}
        breadcrumbItem={"Quantification Based Organization Report"}
      />
      <MonitorQuantificationOrganizationReportListComponent
        data={data}
        error={error}
        loading={loading}
        onSearchHandler={onSearchHandler}
        onExportBtnClick={onExport}
        exportStatus={exportStatus}
        onPageClick={onPageClick}
        onChangeRowNumber={onChangeRowNumber}
      />
    </React.Fragment>
  )
}

export default MonitorQuantificationOrganizationReport
