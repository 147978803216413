import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: [],
  error: null,
  loading: false,
  ip: null,
  exportStatus: { loading: false, error: null },
}

const ipsSlice = createSlice({
  name: "ips",
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload
    },
    setError(state, action) {
      state.error = action.payload
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    setIP(state, action) {
      state.ip = action.payload
    },
    setExportStatus(state, action) {
      state.exportStatus = {
        loading: action.payload.loading,
        error: action.payload.error,
      }
    },
    resetState(state) {
      state.data = []
      state.error = null
      state.loading = false
      state.ip = null
      state.exportStatus = { loading: false, error: null }
    },
  },
})

export const ipsAction = ipsSlice.actions

export default ipsSlice
