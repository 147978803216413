import axiosInstance from "api/axiosInstance"
import { adminDashboardAction } from "./admin-dashboard-slice"
import { batch } from "react-redux"

export const getAdminDashboardData = () => dispatch => {
  dispatch(adminDashboardAction.setLoading(true))
  axiosInstance
    .get("/v1/get-admin-dashboards")
    .then(response => {
      batch(() => {
        dispatch(adminDashboardAction.setError(null))
        dispatch(adminDashboardAction.setData(response?.data))
        dispatch(adminDashboardAction.setLoading(false))
      })
    })
    .catch(error => {
      dispatch(adminDashboardAction.setLoading(false))
      console.log(error)
    })
}

export const getIps = () => dispatch => {
  dispatch(
    adminDashboardAction.setIps({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-ips`)
    .then(respnose => {
      dispatch(
        adminDashboardAction.setIps({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        adminDashboardAction.setIps({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getUsers = role => dispatch => {
  dispatch(
    adminDashboardAction.setUsers({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-users/${role}`)
    .then(respnose => {
      dispatch(
        adminDashboardAction.setUsers({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        adminDashboardAction.setUsers({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getItemCategories = () => dispatch => {
  dispatch(
    adminDashboardAction.setItemCategories({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-itemCategories`)
    .then(respnose => {
      dispatch(
        adminDashboardAction.setItemCategories({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        adminDashboardAction.setItemCategories({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getItems = id => dispatch => {
  dispatch(
    adminDashboardAction.setItems({
      loading: true,
      error: null,
      data: [],
    })
  )
  axiosInstance
    .get(`/v1/get-items/${id}`)
    .then(respnose => {
      dispatch(
        adminDashboardAction.setItems({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        adminDashboardAction.setItems({
          loading: false,
          error: error?.response,
          data: [],
        })
      )
      console.log(error)
    })
}

export const getProjects = () => dispatch => {
  dispatch(
    adminDashboardAction.setProjects({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-projects`)
    .then(respnose => {
      dispatch(
        adminDashboardAction.setProjects({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        adminDashboardAction.setProjects({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getWarehouses = () => dispatch => {
  dispatch(
    adminDashboardAction.setWarehouses({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-warehouses`)
    .then(respnose => {
      dispatch(
        adminDashboardAction.setWarehouses({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        adminDashboardAction.setWarehouses({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getProviders = () => dispatch => {
  dispatch(
    adminDashboardAction.setProviders({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-providers`)
    .then(respnose => {
      dispatch(
        adminDashboardAction.setProviders({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        adminDashboardAction.setProviders({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getProvinces = () => dispatch => {
  dispatch(
    adminDashboardAction.setProvinces({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-provinces`)
    .then(respnose => {
      dispatch(
        adminDashboardAction.setProvinces({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        adminDashboardAction.setProvinces({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}

export const getFacilities = id => dispatch => {
  dispatch(
    adminDashboardAction.setFacilities({
      loading: true,
      error: null,
      data: null,
    })
  )
  axiosInstance
    .get(`/v1/get-facilities/${id}`)
    .then(respnose => {
      dispatch(
        adminDashboardAction.setFacilities({
          loading: false,
          error: null,
          data: respnose?.data,
        })
      )
    })
    .catch(error => {
      dispatch(
        adminDashboardAction.setFacilities({
          loading: false,
          error: error?.response,
          data: null,
        })
      )
      console.log(error)
    })
}
