import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: [],
  loading: false,
  error: null,
  receipt: {},
  projects: { data: [], loading: false, error: null },
  providers: { data: [], loading: false, error: null },
  exportStatus: { loading: false, error: null },
  provinces: { data: [], loading: false, error: null },
}

const provinceInSlice = createSlice({
  name: "provinceIn",
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    setError(state, action) {
      state.error = action.payload
    },
    setProviderIn(state, action) {
      state.receipt = action.payload
    },
    setProjects(state, action) {
      state.projects = {
        data: action.payload.data,
        loading: action.payload.loading,
        error: action.payload.error,
      }
    },
    setProvinces(state, action) {
      state.provinces = {
        data: action.payload.data,
        loading: action.payload.loading,
        error: action.payload.error,
      }
    },
    setProviders(state, action) {
      state.providers = {
        data: action.payload.data,
        loading: action.payload.loading,
        error: action.payload.error,
      }
    },
    setExportStatus(state, action) {
      state.exportStatus = {
        loading: action.payload.loading,
        error: action.payload.error,
      }
    },
    resetState(state) {
      state.data = []
      state.loading = false
      state.error = null
      state.receipt = {}
    },
    resetExportStatus(state) {
      state.exportStatus = { loading: false, error: null }
    },
    resetProvinces(state) {
      state.provinces = { loading: false, error: null, data: [] }
    },
  },
})

export const provinceInAction = provinceInSlice.actions

export default provinceInSlice
