import React, { useMemo, useRef, useState } from "react"
import { Button, Col, Form, Label, Row } from "reactstrap"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import moment from "moment"

const ActivityLogFilters = ({ onAdvancedSearchSubmit, loading }) => {
  const dateFlatpickerfrom = useRef(null)
  const dateFlatpickerto = useRef(null)
  const [from, setFrom] = useState("")
  const [to, setTo] = useState("")

  const handleSubmit = () => {
    let data = {}
    data.from = from
    data.to = to
    onAdvancedSearchSubmit(data)
  }

  const onClearHandler = () => {
    dateFlatpickerfrom.current.flatpickr.clear()
    dateFlatpickerto.current.flatpickr.clear()
    setFrom("")
    setTo("")
    onAdvancedSearchSubmit({})
  }

  return (
    <Form
      onSubmit={e => {
        e.preventDefault()
        handleSubmit()
        return false
      }}
    >
      <div className="mb-3 ">
        <Label className="form-label">Created At</Label>
        <Row>
          <Col>
            <Flatpickr
              ref={dateFlatpickerfrom}
              className="form-control d-block"
              placeholder="dd M,yyyy"
              options={{
                altInput: true,
                altFormat: "Y-m-d",
                dateFormat: "Y-m-d",
              }}
              onChange={value => {
                setFrom(moment(value[0]).format("YYYY-MM-DD"))
              }}
            />
          </Col>
          <Col>
            <Flatpickr
              ref={dateFlatpickerto}
              className="form-control d-block"
              placeholder="dd M,yyyy"
              options={{
                altInput: true,
                altFormat: "Y-m-d",
                dateFormat: "Y-m-d",
              }}
              onChange={value => {
                setTo(moment(value[0]).format("YYYY-MM-DD"))
              }}
            />
          </Col>
        </Row>
      </div>

      <div className="d-flex flex-wrap gap-2 mb-4">
        <Button
          type="submit"
          className="btn btn-sm btn-primary"
          color="primary"
          disabled={loading}
        >
          {loading ? "Please Wait..." : "Search"}
        </Button>
        <Button
          onClick={onClearHandler}
          className="btn btn-sm btn-danger"
          color="primary"
        >
          Clear
        </Button>
      </div>
    </Form>
  )
}

export default ActivityLogFilters
