import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getProviderInItems } from "store/provider-dashboard/provider-dashboard-action"
import { useSearchParams } from "react-router-dom"
import ProviderChartInItemsList from "components/ProviderDashboard/ProviderItems/ProviderChartInItemsList"
import { providerDashboardAction } from "store/provider-dashboard/provider-dashboard-slice"
import MonitorChartInItemsList from "components/MonitorDashboard/MonitorItems/MonitorChartInItemsList"
import { monitorDashboardAction } from "store/monitor-dashboard/monitor-dashboard-slice"
import { getMonitorInItems } from "store/monitor-dashboard/monitor-dashboard-action"

const MonitorChartInItems = () => {
  const dispatch = useDispatch()

  const [searchParams] = useSearchParams()
  const getParams = {
    itemId: searchParams.get("itemId"),
    from: searchParams.get("from") !== "null" ? searchParams.get("from") : null,
    to: searchParams.get("to") !== "null" ? searchParams.get("to") : null,
    providerIP:
      searchParams.get("providerIP") !== "null"
        ? searchParams.get("providerIP")
        : null,
    providerUser:
      searchParams.get("providerUser") !== "null"
        ? searchParams.get("providerUser")
        : null,
    type: searchParams.get("type"),
  }
  const type = searchParams.get("type")
  const MonitorInItems = useSelector(
    state => state.monitorDashboard.MonitorInItems
  )

  useEffect(() => {
    dispatch(getMonitorInItems(getParams))

    return () => {
      dispatch(monitorDashboardAction.resetMonitorInItems())
    }
  }, [])

  return (
    <MonitorChartInItemsList
      data={MonitorInItems?.data?.data}
      loading={MonitorInItems?.loading}
      type={type}
    />
  )
}

export default MonitorChartInItems
