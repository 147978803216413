import React from "react"
import MonitorIPsStock from "./MonitorIPsStock"
import MonitorStock from "./MonitorStock"
import MonitorConsumptionReportList from "pages/Monitor/Reports/ConsumptionReport"

const MonitorDashboardComponent = ({
  provStock,
  onSubmit,
  onCategoryChange,
  provIpsStock,
  onAdvancedSearchHandler,
  onProviderStockExport,
  onIPsAdvanceSearchHandler,
  onIPStockExport = { onIPStockExport },
  provData,
}) => {
  return (
    <React.Fragment>
      <MonitorStock
        provStock={provStock}
        provData={provData}
        onSubmit={onSubmit}
        onCategoryChange={onCategoryChange}
        onAdvancedSearchHandler={onAdvancedSearchHandler}
        onProviderStockExport={onProviderStockExport}
      />
      <br />
      <br />
      <MonitorIPsStock
        provIPsStock={provIpsStock}
        onCategoryChange={onCategoryChange}
        onIPsAdvanceSearchHandler={onIPsAdvanceSearchHandler}
        onIPStockExport={onIPStockExport}
      />

      <br />
      <br />

      <MonitorConsumptionReportList from={"Dashboard"} />
    </React.Fragment>
  )
}

export default MonitorDashboardComponent
