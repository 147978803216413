import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: [],
  error: null,
  loading: false,
  province: null,
  store: { loading: false, error: null },
  export: { loading: false, error: null },
  monitorConsumpProvinces: { data: [], error: null, loading: false },
  monitorConsumpFacilities: { data: [], error: null, loading: false },
  monitorConsumpProjects: { data: [], error: null, loading: false },
  monitorConsumpIPs: { data: [], error: null, loading: false },
}

const monitorConsumptionSlice = createSlice({
  name: "monitorConsumptions",
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload
    },
    setError(state, action) {
      state.error = action.payload
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    setProvince(state, action) {
      state.province = action.payload
    },
    setStore(state, action) {
      state.store = {
        loading: action.payload.loading,
        error: action.payload.error,
      }
    },
    setExport(state, action) {
      state.export = {
        loading: action.payload.loading,
        error: action.payload.error,
      }
    },
    setMonitorConsumptionProvince(state, action) {
      state.monitorConsumpProvinces = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setMonitorConsumptionFacilities(state, action) {
      state.monitorConsumpFacilities = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setMonitorConsumptionProjects(state, action) {
      state.monitorConsumpProjects = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setMonitorConsumptionIPs(state, action) {
      state.monitorConsumpIPs = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    resetState(state) {
      state.data = []
      state.error = null
      state.loading = false
      state.province = null
      state.store = { loading: false, error: null }
      state.export = { loading: false, error: null }
      state.monitorConsumpProvinces = { loading: false, error: null, data: [] }
      state.monitorConsumpFacilities = {
        loading: false,
        error: null,
        data: [],
      }
      state.monitorConsumpProjects = { data: [], error: null, loading: false }
      state.monitorConsumpIPs = { data: [], error: null, loading: false }
    },
  },
})

export const monitorConsumptionAction = monitorConsumptionSlice.actions

export default monitorConsumptionSlice
