import React, { useEffect, useMemo, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import {
  exportProviderConsumptionReports,
  exportProviderConsumptionReports2,
  getProviderConsumptionReports,
  getProviderConsumptionReports2,
} from "store/provider-reports/p-report-action"
import {
  getProviderIps,
  getProviderItemCategories,
  getProviderProjects,
} from "store/provider-dashboard/provider-dashboard-action"
import ProviderConsumptionReportListComponent from "components/ProviderReports/Consumption"
import { getProviderConsumptionsProjects } from "store/provider-consumption/province-consumption-action"
import ProviderConsumptionReportListComponent2 from "components/ProviderReports/Consumption2"

const ProviderConsumptionReportList2 = ({ from }) => {
  document.title = "Consumption Reports"

  const dispatch = useDispatch()
  const consumptions = useSelector(
    state => state.providerReports.providerConsumptionReport
  )
  const exportStatus = useSelector(
    state => state.providerReports.providerConsumptionExport
  )

  const [according, setAccording] = useState({ order: "desc", name: "date" })
  const [advnacedSearch, setAdvancedSearch] = useState({})
  const [pageNumber, setPageNumber] = useState(20)

  const onPageClick = link => {
    dispatch(
      getProviderConsumptionReports2(
        link.url,
        pageNumber,
        according,
        advnacedSearch
      )
    )
  }

  const onChangeRowNumber = value => {
    setPageNumber(value)
  }

  const onChangeOrdering = value => {
    if (value.order) {
      setAccording(prev => {
        return { ...prev, order: value.order }
      })
    }
    if (value.name) {
      setAccording(prev => {
        return { ...prev, name: value.name }
      })
    }
  }

  useEffect(() => {
    dispatch(
      getProviderConsumptionReports2(
        undefined,
        pageNumber,
        according,
        advnacedSearch
      )
    )
  }, [according, pageNumber])

  useEffect(() => {
    dispatch(getProviderItemCategories())
    dispatch(getProviderConsumptionsProjects())
  }, [])

  const onSearchHandler = data => {
    setAdvancedSearch(data)
    dispatch(
      getProviderConsumptionReports2(undefined, pageNumber, undefined, data)
    )
  }

  const onExportBtnClick = () => {
    dispatch(exportProviderConsumptionReports2(according, advnacedSearch))
  }

  return (
    <React.Fragment>
      <Breadcrumbs
        title={"Provider"}
        breadcrumbItem={"Consumption Reports 2"}
      />
      <ProviderConsumptionReportListComponent2
        data={consumptions?.data}
        error={consumptions?.error}
        loading={consumptions?.loading}
        onChangeOrdering={onChangeOrdering}
        onSearchHandler={onSearchHandler}
        onExportBtnClick={onExportBtnClick}
        exportStatus={exportStatus}
        onPageClick={onPageClick}
        onChangeRowNumber={onChangeRowNumber}
      />
    </React.Fragment>
  )
}

export default ProviderConsumptionReportList2
