import axiosInstance from "api/axiosInstance"
import { batch } from "react-redux"
import { provinceInAction } from "./province-in-slice"
import exportAxiosInstance from "api/exportAxiosInstance"
import FileSaver from "file-saver"

export const getProvinceIns =
  (
    url,
    pageNumber = 10,
    according = { order: "desc", name: "id" },
    search = null,
    advancedSearch = {}
  ) =>
  dispatch => {
    dispatch(provinceInAction.setLoading(true))
    url = url || "/v1/province-ins"
    axiosInstance
      .get(url, {
        params: {
          pageNumber: pageNumber,
          according: according,
          search: search,
          advancedSearch: advancedSearch,
        },
      })
      .then(response => {
        if (response) {
          batch(() => {
            dispatch(provinceInAction.setError(null))
            dispatch(provinceInAction.setData(response.data))
            dispatch(provinceInAction.setLoading(false))
          })
        }
      })
      .catch(error => {
        dispatch(provinceInAction.setLoading(false))
        console.log(error)
      })
  }

export const getByIdProvinceIn = id => dispatch => {
  dispatch(provinceInAction.setLoading(true))
  axiosInstance
    .get(`/v1/province-ins/${id}`)
    .then(response => {
      batch(() => {
        dispatch(provinceInAction.setError(null))
        dispatch(provinceInAction.setProviderIn(response?.data))
        dispatch(provinceInAction.setLoading(false))
      })
    })
    .catch(error => {
      dispatch(provinceInAction.setLoading(false))
      console.log(error)
    })
}

export const getProvinceInProjects = () => dispatch => {
  dispatch(
    provinceInAction.setProjects({
      loading: true,
      error: null,
      data: [],
    })
  )
  axiosInstance
    .get("/v1/get-province-in-projects")
    .then(response => {
      if (response) {
        dispatch(
          provinceInAction.setProjects({
            loading: false,
            error: null,
            data: response?.data,
          })
        )
      }
    })
    .catch(error => {
      dispatch(
        provinceInAction.setProjects({
          loading: false,
          error: error?.response,
          data: [],
        })
      )
      console.log(error)
    })
}

export const getProvinceInProviders = () => dispatch => {
  dispatch(
    provinceInAction.setProviders({
      loading: true,
      error: null,
      data: [],
    })
  )
  axiosInstance
    .get("/v1/get-province-in-providers")
    .then(response => {
      if (response) {
        dispatch(
          provinceInAction.setProviders({
            loading: false,
            error: null,
            data: response?.data,
          })
        )
      }
    })
    .catch(error => {
      dispatch(
        provinceInAction.setProviders({
          loading: false,
          error: error?.response,
          data: [],
        })
      )
      console.log(error)
    })
}

export const exportProvinceIn =
  (advancedSearch = {}) =>
  dispatch => {
    dispatch(
      provinceInAction.setExportStatus({
        loading: true,
        error: null,
      })
    )

    exportAxiosInstance
      .get("/v1/export-province-in", {
        params: advancedSearch,
      })
      .then(response => {
        if (response) {
          const dataBlob = new Blob([response?.data], {
            type: "application/pdf,",
          })
          FileSaver.saveAs(
            dataBlob,
            `Province-In-${Math.random().toString()}.xlsx`
          )
          dispatch(
            provinceInAction.setExportStatus({
              loading: false,
              error: null,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          provinceInAction.setExportStatus({
            loading: false,
            error: error?.response?.errors,
          })
        )
        console.log(error)
      })
  }

export const getProvinceInProvinces = () => dispatch => {
  dispatch(
    provinceInAction.setProvinces({
      loading: true,
      error: null,
      data: [],
    })
  )
  axiosInstance
    .get("/v1/get-province-in-provinces")
    .then(response => {
      if (response) {
        dispatch(
          provinceInAction.setProvinces({
            loading: false,
            error: null,
            data: response?.data,
          })
        )
      }
    })
    .catch(error => {
      dispatch(
        provinceInAction.setProvinces({
          loading: false,
          error: error?.response,
          data: [],
        })
      )
      console.log(error)
    })
}
