import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { Collapse } from "reactstrap"
import { getMonitorTotalExpireReceiptsCount } from "store/monitor-expire-receipts/monitor-expire-action"
import { getMonitorStockOutCount } from "store/monitor-stock-out/monitor-stockout-action"

const MonitorNavbar = props => {
  const [expireList, setExpireList] = useState(false)

  const dispatch = useDispatch()
  const totalExpireCount = useSelector(
    state => state.monitorExpireReceipts.count
  )

  const totalStockOut = useSelector(state => state.monitorStockout.count)

  useEffect(() => {
    dispatch(getMonitorTotalExpireReceiptsCount())
    dispatch(getMonitorStockOutCount())
  }, [])

  return (
    <Collapse
      isOpen={props.leftMenu}
      className="navbar-collapse"
      id="topnav-menu-content"
    >
      <ul className="navbar-nav">
        <li className="nav-item ">
          <Link className="nav-link " to="/dashboard">
            <i className="bx bx-home-circle me-2"></i>
            Dashboard
          </Link>
        </li>
        <li className="nav-item ">
          <Link className="nav-link " to="/monitor-receipts">
            <i className="bx bx-home-circle me-2"></i>
            Receipts ( In )
          </Link>
        </li>
        <li className="nav-item ">
          <Link className="nav-link " to="/monitor-distributions">
            <i className="bx bx-home-circle me-2"></i>
            Distributions ( Out )
          </Link>
        </li>
        <li className="nav-item dropdown">
          <Link
            className="nav-link dropdown-toggle arrow-none"
            onClick={e => {
              e.preventDefault()
              setExpireList(!expireList)
            }}
            to="#"
            style={
              totalExpireCount?.countPerMonth > 0 ||
              totalExpireCount?.countPerYear > 0
                ? { color: "red" }
                : {}
            }
          >
            <i className="mdi mdi-order-bool-descending me-2"></i>
            Expire Lists{props.menuOpen}
            <div className="arrow-down"></div>
          </Link>
          <div className={"dropdown-menu"}>
            <Link to="/monitor-exipre-receipts" className="dropdown-item">
              Expire List / 6 Month &nbsp;
              <span className="text-danger" style={{ fontSize: 11 }}>
                ( {totalExpireCount?.countPerMonth} )
              </span>
            </Link>

            <Link
              to="/monitor-exipre-receipts-based-12"
              className="dropdown-item"
            >
              Expire List / 12 Month &nbsp;
              <span className="text-danger" style={{ fontSize: 11 }}>
                ( {totalExpireCount?.countPerYear} )
              </span>
            </Link>
          </div>
        </li>
        <li className="nav-item ">
          <Link className="nav-link " to="/monitor-consumptions-reports">
            <i className="bx bx-home-circle me-2"></i>
            Consumptions
          </Link>
        </li>
        <li className="nav-item ">
          <Link className="nav-link " to="/monitor-stock-out">
            <i className="bx bx-home-circle me-2"></i>
            Stock Out &nbsp;
            <span className="text-danger" style={{ fontSize: 11 }}>
              ( {totalStockOut} )
            </span>
          </Link>
        </li>

        <li className="nav-item dropdown">
          <Link
            className="nav-link dropdown-toggle arrow-none"
            onClick={e => {
              e.preventDefault()
              setReport(!report)
            }}
            to="#"
          >
            <i className="mdi mdi-order-bool-descending me-2"></i>
            Reports {props.menuOpen}
            <div className="arrow-down"></div>
          </Link>
          <div className={"dropdown-menu"}>
            <Link to="/monitor-transactions" className="dropdown-item">
              All Transactions ( In / Out )
            </Link>
            <Link to="/monitor-receipt-reports" className="dropdown-item">
              Receipts ( In )
            </Link>
            <Link to="/monitor-distribution-reports" className="dropdown-item">
              Distributions ( Out )
            </Link>
            <Link
              to="/monitor-ip-distribution-reports"
              className="dropdown-item"
            >
              IP Distributions ( Out )
            </Link>
            <Link to="/monitor-inventory-reports" className="dropdown-item">
              Inventory
            </Link>
            <Link
              to="/monitor-consumptions-reports-two"
              className="dropdown-item"
            >
              Consumptions Report
            </Link>
            <Link
              to="/monitor-receipt-health-reports"
              className="dropdown-item"
            >
              Receipt Health ( In )
            </Link>
            <Link to="/monitor-quantification-report" className="dropdown-item">
              Quantification Report
            </Link>
            <Link
              to="/monitor-quantification-report-based-organization"
              className="dropdown-item"
            >
              Quantification Based Organization Report
            </Link>
          </div>
        </li>
      </ul>
    </Collapse>
  )
}

export default MonitorNavbar
