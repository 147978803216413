import React, { useEffect, useMemo, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import ProviderConsumptionReportListComponent2 from "components/ProviderReports/Consumption2"
import {
  exportMonitorConsumptionReports2,
  getMonitorConsumptionReports2,
} from "store/monitor-reports/monitor-report-action"
import {
  getMonitorIps,
  getMonitorItemCategories,
} from "store/monitor-dashboard/monitor-dashboard-action"
import { getMonitorConsumptionsProjects } from "store/monitor-consumption/monitor-consumption-action"
import MonitorConsumptionReportListComponent2 from "components/MonitorReports/Consumption2"

const MonitorConsumptionReportList2 = ({ from }) => {
  document.title = "Consumption Reports 2"

  const dispatch = useDispatch()
  const consumptions = useSelector(
    state => state.monitorReports.monitorConsumptionReport
  )
  const exportStatus = useSelector(
    state => state.monitorReports.monitorConsumptionExport
  )

  const [according, setAccording] = useState({ order: "desc", name: "date" })
  const [advnacedSearch, setAdvancedSearch] = useState({})
  const [pageNumber, setPageNumber] = useState(20)

  const onPageClick = link => {
    dispatch(
      getMonitorConsumptionReports2(
        link.url,
        pageNumber,
        according,
        advnacedSearch
      )
    )
  }

  const onChangeRowNumber = value => {
    setPageNumber(value)
  }

  const onChangeOrdering = value => {
    if (value.order) {
      setAccording(prev => {
        return { ...prev, order: value.order }
      })
    }
    if (value.name) {
      setAccording(prev => {
        return { ...prev, name: value.name }
      })
    }
  }

  useEffect(() => {
    dispatch(
      getMonitorConsumptionReports2(
        undefined,
        pageNumber,
        according,
        advnacedSearch
      )
    )
  }, [according, pageNumber])

  useEffect(() => {
    dispatch(getMonitorConsumptionsProjects())
    dispatch(getMonitorItemCategories())
    dispatch(getMonitorIps())
  }, [])

  const onSearchHandler = data => {
    setAdvancedSearch(data)
    dispatch(
      getMonitorConsumptionReports2(undefined, pageNumber, undefined, data)
    )
  }

  const onExportBtnClick = () => {
    dispatch(exportMonitorConsumptionReports2(according, advnacedSearch))
  }

  return (
    <React.Fragment>
      <Breadcrumbs title={"Monitor"} breadcrumbItem={"Consumption Reports 2"} />
      <MonitorConsumptionReportListComponent2
        data={consumptions?.data}
        error={consumptions?.error}
        loading={consumptions?.loading}
        onChangeOrdering={onChangeOrdering}
        onSearchHandler={onSearchHandler}
        onExportBtnClick={onExportBtnClick}
        exportStatus={exportStatus}
        onPageClick={onPageClick}
        onChangeRowNumber={onChangeRowNumber}
      />
    </React.Fragment>
  )
}

export default MonitorConsumptionReportList2
