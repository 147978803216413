import { useFormik } from "formik"
import * as Yup from "yup"
import React from "react"
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  FormFeedback,
  CardSubtitle,
  Spinner,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import CustomHeaderTitle from "components/Common/CustomHeaderTitle"

const ProviderForm = ({ onSubmit, loading, error, formMode, provider }) => {
  const validationType = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: provider ? provider?.title : "",
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required("This name is required"),
    }),
    onSubmit: values => {
      onSubmit(values)
    },
  })

  return (
    <Card>
      <CardBody>
        <CustomHeaderTitle
          loading={loading}
          title={formMode === "Edit" ? "Edit Provider" : "Add Provider"}
          lgColumn={3}
          rowCount={false}
          orderingList={false}
          searchBox={false}
          rightBtn={true}
          rightBtnTitle={"Back"}
          rightBtnRoute={"/providers"}
        />

        <Form
          onSubmit={e => {
            e.preventDefault()
            validationType.handleSubmit()
            return false
          }}
        >
          <div className="mb-3">
            <Label className="form-label">Name</Label>
            <Input
              name="title"
              defaultValue={provider?.title}
              placeholder="Title"
              type="text"
              onChange={validationType.handleChange}
              onBlur={validationType.handleBlur}
              value={validationType.values.title || ""}
              invalid={
                (validationType.touched.title && validationType.errors.title) ||
                error?.title?.[0]
                  ? true
                  : false
              }
            />
            {(validationType.touched.title && validationType.errors.title) ||
            error?.title?.[0] ? (
              <FormFeedback type="invalid">
                {validationType.errors.title || error?.title?.[0]}
              </FormFeedback>
            ) : null}
          </div>

          <div className="d-flex flex-wrap gap-2">
            <Button type="submit" color="primary" disabled={loading}>
              {loading
                ? "Please Wait..."
                : formMode === "Add"
                ? "Submit"
                : "Update"}
            </Button>
          </div>
        </Form>
      </CardBody>
    </Card>
  )
}

export default ProviderForm
