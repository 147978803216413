import React, { useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import {
  getByIdItemCategory,
  updateItemCategory,
} from "store/item-category/item-category-action"
import ItemCategoryForm from "components/ItemCategory/form"
import { itemCategoryAction } from "store/item-category/item-category-slice"
import Preloader from "components/Common/Loader"

const EditItemCategory = () => {
  document.title = "Edit Item Category"

  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { loading, itemCategory, error } = useSelector(
    state => state.itemCategory
  )

  const onSubmit = values => {
    const form = new FormData()
    form.append("name", values.name)
    form.append("id", id)
    dispatch(updateItemCategory(id, form, navigate))
  }

  useEffect(() => {
    dispatch(getByIdItemCategory(id))

    return () => {
      dispatch(itemCategoryAction.resetState())
    }
  }, [])

  return (
    <React.Fragment>
      {itemCategory ? (
        <ItemCategoryForm
          loading={loading}
          itemCategory={itemCategory?.data}
          error={error}
          onSubmit={onSubmit}
          formMode={"Edit"}
        />
      ) : (
        <Preloader loading={true} />
      )}
    </React.Fragment>
  )
}

export default EditItemCategory
