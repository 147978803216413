import WarehouseForm from "components/Warehouse/form"
import React, { useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import {
  getByIdWarehouse,
  updateWarehouse,
} from "store/warehouse/warehouse-action"
import { getByIdProject, updateProject } from "store/project/project-action"
import ProjectForm from "components/Projects/form"
import { projectAction } from "store/project/project-slice"
import Preloader from "components/Common/Loader"

const EditProject = () => {
  document.title = "Edit Project"

  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { loading, project, error } = useSelector(state => state.project)

  const onSubmit = values => {
    values.id = id
    dispatch(updateProject(id, values, navigate))
  }

  useEffect(() => {
    dispatch(getByIdProject(id))

    return () => {
      dispatch(projectAction.resetState())
    }
  }, [])

  return (
    <React.Fragment>
      {project ? (
        <ProjectForm
          loading={loading}
          project={project?.data}
          error={error}
          onSubmit={onSubmit}
          formMode={"Edit"}
        />
      ) : (
        <Preloader loading={true} />
      )}
    </React.Fragment>
  )
}

export default EditProject
