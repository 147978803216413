import React, { useState } from "react"
import { Card, CardBody, Collapse } from "reactstrap"
import { useNavigate } from "react-router-dom"
import CustomHeaderTitle from "components/Common/CustomHeaderTitle"
import GeneralTables from "components/Common/GeneralTable"
import ProviderDistributionReportFilter from "./filter"

const ProviderDistributionListComponent = ({
  data,
  error,
  loading,
  onSearch,
  onChangeRowNumber,
  onChangeOrdering,
  onExportBtnClick,
  onAdvancedSearchSubmit,
  exportTransaction,
  onPageClick,
}) => {
  const navigate = useNavigate()
  const [filterOpen, setFilterOpen] = useState(false)

  const onAdvancedSearchHandler = result => {
    setFilterOpen(result)
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CustomHeaderTitle
            loading={loading}
            // pageRowNumberOptions={[20, 100, 150]}
            title={""}
            exportBtn={true}
            exportBtnLoading={exportTransaction?.loading}
            onExportBtnClick={onExportBtnClick}
            rowCount={true}
            onChangeRowNumber={onChangeRowNumber}
            orderingList={true}
            onChangeOrdering={onChangeOrdering}
            searchBox={true}
            onSearchHandler={onSearch}
            rightBtn={false}
            advnacedSearchBtn={true}
            advnacedSearchBtnTitle={"Filter"}
            onAdvancedSearch={onAdvancedSearchHandler}
            orderingOptions={[
              { value: "date", name: "Transaction Date" },
              //   { value: "date", name: "Submission Date" },
            ]}
          />
          <Collapse isOpen={filterOpen}>
            <ProviderDistributionReportFilter
              loading={loading}
              onAdvancedSearchSubmit={onAdvancedSearchSubmit}
            />
          </Collapse>
          <GeneralTables
            data={data?.data}
            loading={loading}
            headers={[
              "WAREHOUSE NAME",
              "TRANSACTION DATE",
              "TRANSACTION TYPE",
              "DISTRIBUTION TYPE",
              "IP",
              "UNFPA ITEM ID",
              "P- ITEM DESCRIPTION",
              "UNFPA UOM",
              "IP UOM",
              "UNIT PRICE (AS PER UNFPA)",
              "UNIT PRICE (AS PER IP UOM)",
              "BATCH / LOT NUMBER",
              "EXPIRY / BEST BEFORE DATE",
              "QUANTITY (UNFPA UOM)",
              "QUANTITY (IP UOM)",
              "VALUE ( AS PER UNFPA UOM)",
              "RECIPIENT FACILITY (WAREHOUSES, SDPS AND OTHER PARTNERS)",
              "GEOGRAPHIC REGION COVERED BY THE RECIPIENT",
              "DONAR",
            ]}
            pagination={{ meta: data?.meta, onPageClick: onPageClick }}
            renderItem={(transaction, index) => (
              <tr key={index}>
                <td style={{ whiteSpace: "nowrap" }}>
                  {transaction?.warehouse?.name}
                </td>
                <td style={{ whiteSpace: "nowrap" }}>{transaction?.date}</td>
                <td style={{ whiteSpace: "nowrap" }}>{transaction?.type}</td>
                <td style={{ whiteSpace: "nowrap" }}>
                  {transaction?.distributed_type}
                </td>
                <td style={{ whiteSpace: "nowrap" }}>
                  {transaction?.ip?.name}{" "}
                  {![undefined, "", null].includes(transaction?.distributor) &&
                    " - " + transaction?.distributor}
                </td>
                <td style={{ whiteSpace: "nowrap" }}>
                  {transaction?.item?.unfp_item_name}
                </td>
                <td style={{ whiteSpace: "nowrap" }}>
                  {transaction?.item?.ip_item_description}
                </td>

                <td>
                  {transaction?.item?.uom} &nbsp;
                  {transaction?.provider_in?.unit_uom_quantity_per_unfpa > 1 &&
                    transaction?.provider_in?.unit_uom_quantity_per_unfpa}
                </td>
                <td>
                  {transaction?.item?.ip_unit_umo} &nbsp;
                  {transaction?.provider_in?.unit_umo_quantity_per_ip > 1 &&
                    transaction?.provider_in?.unit_umo_quantity_per_ip}
                </td>
                <td>{transaction?.provider_in?.unit_price_per_unfpa}</td>
                <td>{transaction?.provider_in?.unit_price_per_ip}</td>
                <td>{transaction?.provider_in?.batch_number}</td>
                <td>{transaction?.provider_in?.expire_date}</td>
                <td>{transaction?.qty_per_unfpa}</td>
                <td>{transaction?.qty_per_ip}</td>
                <td>{transaction?.total_value}</td>
                <td>
                  {transaction?.type === "Receipts"
                    ? transaction?.warehouse.name + " Warehouses"
                    : transaction?.province?.province}
                  &nbsp;&nbsp;
                  {transaction?.facility &&
                    transaction?.facility?.facility_name !== "N/A" &&
                    transaction?.facility?.facility_name}
                  &nbsp;&nbsp;
                  {transaction?.other_hfs != "Receipts" &&
                    transaction?.other_hfs != null &&
                    transaction?.other_hfs}
                </td>
                <td>
                  {transaction?.province?.region} &nbsp;{" "}
                  {transaction?.province?.province}
                </td>
                <td>{"UNFPA"}</td>
              </tr>
            )}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default ProviderDistributionListComponent
