import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import {
  exportProviderDistributionReport,
  exportProviderProvinceDistributionReport,
  getProviderDistributionReports,
  getProviderProvinceDistributionReports,
} from "store/provider-reports/p-report-action"
import {
  getProviderIps,
  getProviderItemCategories,
  getProviderProvinces,
  getProviderWarehouses,
} from "store/provider-dashboard/provider-dashboard-action"
import useSearch from "hooks/useSearch"
import ProviderDistributionListComponent from "components/ProviderReports/Distribution"
import ProviderProvinceDistributionListComponent from "components/ProviderReports/IPDistribution"

const ProviderProvinceDistributionReportList = () => {
  document.title = "IP Distribution Reports"

  const dispatch = useDispatch()
  const distributions = useSelector(
    state => state.providerReports.providerProvicneDistributionReport
  )
  const exportStatus = useSelector(
    state => state.providerReports.providerProvinceExportDistributionReport
  )
  const [search, setSearch] = useState(null)
  const [pageNumber, setPageNumber] = useState(20)
  const [according, setAccording] = useState({ order: "desc", name: "date" })
  const [advnacedSearch, setAdvancedSearch] = useState({})

  const onSearchHandler = value => {
    setSearch(value)
  }

  const onPageClick = link => {
    dispatch(
      getProviderProvinceDistributionReports(
        link.url,
        pageNumber,
        according,
        search,
        advnacedSearch
      )
    )
  }

  const onChangeRowNumber = value => {
    setPageNumber(value)
  }

  const onChangeOrdering = value => {
    if (value.order) {
      setAccording(prev => {
        return { ...prev, order: value.order }
      })
    }
    if (value.name) {
      setAccording(prev => {
        return { ...prev, name: value.name }
      })
    }
  }

  useSearch(
    getProviderProvinceDistributionReports(
      undefined,
      undefined,
      undefined,
      search,
      advnacedSearch
    ),
    search
  )

  useEffect(() => {
    dispatch(
      getProviderProvinceDistributionReports(
        undefined,
        pageNumber,
        according,
        search,
        advnacedSearch
      )
    )
  }, [pageNumber, according])

  useEffect(() => {
    dispatch(getProviderItemCategories())
    dispatch(getProviderProvinces())
    dispatch(getProviderIps())
  }, [])

  const onAdvancedSearchSubmit = data => {
    setAdvancedSearch(data)
    dispatch(
      getProviderProvinceDistributionReports(
        undefined,
        undefined,
        undefined,
        search,
        data
      )
    )
  }

  const onExportBtnClick = () => {
    dispatch(
      exportProviderProvinceDistributionReport(
        pageNumber,
        according,
        search,
        advnacedSearch
      )
    )
  }

  return (
    <React.Fragment>
      <Breadcrumbs
        title={"Provider"}
        breadcrumbItem={"IPs Distribution Report"}
      />
      <ProviderProvinceDistributionListComponent
        data={distributions?.data}
        error={distributions?.error}
        loading={distributions?.loading}
        onSearch={onSearchHandler}
        onChangeRowNumber={onChangeRowNumber}
        onChangeOrdering={onChangeOrdering}
        onAdvancedSearchSubmit={onAdvancedSearchSubmit}
        onExportBtnClick={onExportBtnClick}
        exportTransaction={exportStatus}
        onPageClick={onPageClick}
      />
    </React.Fragment>
  )
}

export default ProviderProvinceDistributionReportList
