import React from "react"
import moment from "moment"

const ProvinceConsumptionHeaderTitle = ({ filters, storeStatus }) => {
  const year = filters?.year ? filters.year : new Date().getFullYear()
  const month = filters?.month
    ? moment()
        .month(filters.month - 1)
        .format("MMMM")
    : moment().format("MMMM")
  return (
    <div style={{ textAlign: "center", fontSize: 17, color: "#888" }}>
      <p>
        {year}-{month}
      </p>
      {storeStatus?.error?.consumDateError && (
        <p className="text-danger">
          {storeStatus?.error?.consumDateError?.msg}{" "}
          {storeStatus?.error?.consumDateError?.year}{" "}
          {moment()
            .month(storeStatus?.error?.consumDateError?.month - 1)
            .format("MMMM")}{" "}
        </p>
      )}

      {storeStatus?.error?.restricted && (
        <p className="text-danger">
          Ristricted for consumption
          <br />
          <span style={{ fontSize: 12 }}>
            Note: you can't consume from current month
          </span>
        </p>
      )}
    </div>
  )
}

export default ProvinceConsumptionHeaderTitle
