import ProviderStockOutComponent from "components/ProviderStockOut"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  exportProviderStockOut,
  getProviderStockOut,
} from "store/provider-stock-out/provider-stockout-action"

const ProviderStockOut = () => {
  document.title = "Stock Out"
  const dispatch = useDispatch()
  const [params, setParams] = useState({})
  const { data, error, loading, exportStatus } = useSelector(state => ({
    data: state.providerStockout.data,
    error: state.providerStockout.error,
    loading: state.providerStockout.loading,
    exportStatus: state.providerStockout.exportStatus,
  }))

  useEffect(() => {
    dispatch(getProviderStockOut())
  }, [])

  const onAdvancedSearch = filters => {
    setParams(filters)
    dispatch(getProviderStockOut(filters))
  }

  const onExportBtnClick = () => {
    dispatch(exportProviderStockOut(params))
  }

  return (
    <ProviderStockOutComponent
      data={data}
      error={error}
      loading={loading}
      exportStatus={exportStatus}
      onAdvancedSearch={onAdvancedSearch}
      onExportBtnClick={onExportBtnClick}
    />
  )
}

export default ProviderStockOut
