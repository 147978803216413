import React from "react"
import ProviderStock from "./ProviderStock"
import ProviderIPsStock from "./ProviderIPsStock"
import ProviderConsumptionReportList from "pages/Provider/Reports/ConsumptionReport"

const ProviderDashboardComponent = ({
  provStock,
  onSubmit,
  onCategoryChange,
  provIpsStock,
  onExport,
  onProviderSearchHandler,
  onProviderIPsAdvancedSearchHandler,
  onProviderIPsStockExport,
  provData,
}) => {
  return (
    <React.Fragment>
      <ProviderStock
        provStock={provStock}
        provData={provData}
        onSubmit={onSubmit}
        onCategoryChange={onCategoryChange}
        onExport={onExport}
        onProviderSearchHandler={onProviderSearchHandler}
      />
      <br />
      <br />
      <ProviderIPsStock
        provIPsStock={provIpsStock}
        onCategoryChange={onCategoryChange}
        onProviderIPsAdvancedSearchHandler={onProviderIPsAdvancedSearchHandler}
        onProviderIPsStockExport={onProviderIPsStockExport}
      />
      <br />
      <br />
      <ProviderConsumptionReportList from="Dashboard" />
    </React.Fragment>
  )
}

export default ProviderDashboardComponent
