import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { authAction } from "store/auth/auth-slice"

const Logout = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(authAction.loggedOut())
    localStorage.removeItem("token")
  }, [])
}

export default Logout
