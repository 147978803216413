import axiosInstance from "api/axiosInstance"
import { monitorExpireReceiptsAction } from "./monitor-expire-slice"
import { batch } from "react-redux"
import exportAxiosInstance from "api/exportAxiosInstance"
import FileSaver from "file-saver"

export const getMonitorExpireReceipts =
  (
    url,
    pageNumber = 10,
    according = { order: "desc", name: "id" },
    search = null,
    advancedSearch = {},
    based
  ) =>
  dispatch => {
    dispatch(monitorExpireReceiptsAction.setLoading(true))
    url = url || "/v1/get-monitor-expire-receipts"
    axiosInstance
      .get(url, {
        params: {
          pageNumber: pageNumber,
          according: according,
          search: search,
          advancedSearch: advancedSearch,
          based: based,
        },
      })
      .then(response => {
        if (response) {
          batch(() => {
            dispatch(monitorExpireReceiptsAction.setError(null))
            dispatch(monitorExpireReceiptsAction.setData(response.data))
            dispatch(monitorExpireReceiptsAction.setLoading(false))
          })
        }
      })
      .catch(error => {
        dispatch(monitorExpireReceiptsAction.setLoading(false))
        console.log(error)
      })
  }

export const exportMonitorExpireReceipts =
  (
    pageNumber = 10,
    according = { order: "desc", name: "date" },
    search = null,
    advancedSearch = {},
    based
  ) =>
  dispatch => {
    dispatch(
      monitorExpireReceiptsAction.setMonitorExpireExport({
        loading: true,
        error: null,
      })
    )

    exportAxiosInstance
      .get("/v1/export-monitor-expire-receipts", {
        params: {
          pageNumber: pageNumber,
          according: according,
          search: search,
          advancedSearch: advancedSearch,
          based: based,
        },
      })
      .then(response => {
        if (response) {
          const dataBlob = new Blob([response?.data], {
            type: "application/pdf,",
          })
          FileSaver.saveAs(
            dataBlob,
            `Expire-Receipt-Report-${Math.random().toString()}.xlsx`
          )
          dispatch(
            monitorExpireReceiptsAction.setMonitorExpireExport({
              loading: false,
              error: null,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          monitorExpireReceiptsAction.setMonitorExpireExport({
            loading: false,
            error: error?.response?.errors,
          })
        )
        console.log(error)
      })
  }

export const getMonitorTotalExpireReceiptsCount = () => dispatch => {
  dispatch(monitorExpireReceiptsAction.setCount(0))
  axiosInstance
    .get("/v1/get-monitor-expire-receipts-count")
    .then(response => {
      if (response) {
        batch(() => {
          dispatch(monitorExpireReceiptsAction.setCount(response?.data))
        })
      }
    })
    .catch(error => {
      dispatch(monitorExpireReceiptsAction.setCount(0))
      console.log(error)
    })
}
