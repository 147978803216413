import React, { useState } from "react"
import { Button, Card, CardBody, Collapse } from "reactstrap"
import { useNavigate } from "react-router-dom"
import CustomHeaderTitle from "components/Common/CustomHeaderTitle"
import GeneralTables from "components/Common/GeneralTable"
import MonitorReceiptFilter from "./MonitorReceiptFilter"
import ActionColumns from "components/Common/GeneralTable/ActionColumns"
import ProviderInTotalBox from "components/ProviderIn/ProviderInTotalBox"
import { downloadURL } from "api/APP_URL"

const MonitorReceiptsComponent = ({
  data,
  error,
  loading,
  onPageClick,
  onSearch,
  onChangeRowNumber,
  onChangeOrdering,
  onAdvancedSearchSubmit,
  onClickDetail,
  onExportBtnClick,
  exportStatus,
}) => {
  const navigate = useNavigate()

  const [filterOpen, setFilterOpen] = useState(false)

  const onAdvancedSearchHandler = result => {
    setFilterOpen(result)
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CustomHeaderTitle
            loading={loading}
            title={"List"}
            rowCount={true}
            onChangeRowNumber={onChangeRowNumber}
            orderingList={true}
            onChangeOrdering={onChangeOrdering}
            searchBox={true}
            onSearchHandler={onSearch}
            rightBtn={false}
            advnacedSearchBtn={true}
            advnacedSearchBtnTitle={"Filter"}
            exportBtn={true}
            onExportBtnClick={onExportBtnClick}
            exportBtnLoading={exportStatus?.loading}
            onAdvancedSearch={onAdvancedSearchHandler}
            orderingOptions={[
              { value: "id", name: "ID" },
              { value: "expire_date", name: "Exipre Date" },
              { value: "date", name: "Submission" },
            ]}
          />
          <ProviderInTotalBox totals={data?.totals} />
          <Collapse isOpen={filterOpen}>
            <MonitorReceiptFilter
              loading={loading}
              onAdvancedSearchSubmit={onAdvancedSearchSubmit}
            />
          </Collapse>
          <GeneralTables
            data={data?.data}
            loading={loading}
            headers={[
              "SN",
              "PROJECT",
              "PROVIDER",
              "PROVIDER IP",
              "UNFPA ITEM ID",
              "IP- ITEM DESCRIPTION",
              "UNFPA UNIT OF MEASURE (UOM))",
              "IP UNIT UOM",
              "UNIT PRICE (AS PER UNFPA)",
              "UNIT PRICE (AS PER IP UOM)",
              "BATCH NUMBER",
              "EXPIRE DATE",
              "QUANTITY (UNFPA UOM)",
              "QUANTITY (IP UOM)",
              "COMMENT",
              "RECEIPT DATE",
              "Action",
            ]}
            pagination={{ meta: data?.meta, onPageClick: onPageClick }}
            renderItem={(pIn, index) => (
              <tr key={index}>
                <th scope="row">{data?.meta?.from + index}</th>
                <td>{pIn?.project?.name}</td>
                <td>{pIn?.provider?.title}</td>
                <td>{pIn?.ip?.name}</td>
                <td>{pIn?.item?.unfp_item_name}</td>
                <td>{pIn?.item?.ip_item_description}</td>
                <td>
                  {pIn?.item.uom} &nbsp;
                  {pIn?.item.unfpa_uom_quantity > 1 &&
                    pIn?.item.unfpa_uom_quantity}
                </td>
                <td>
                  {pIn?.item.ip_unit_umo} &nbsp;
                  {pIn?.item.ip_uom_quantity > 1 && pIn?.item.ip_uom_quantity}
                </td>
                <td>{pIn.unit_price_per_unfpa}</td>
                <td>{pIn.unit_price_per_ip}</td>
                <td>{pIn.batch_number}</td>
                <td>{pIn.expire_date}</td>
                <td>{pIn.first_in_qty_per_unfpa}</td>
                <td>{pIn.first_in_qty_per_ip}</td>
                <td>{pIn.lost_comment}</td>
                <td>{pIn.date}</td>
                <td>
                  <ActionColumns
                    enableDetail={true}
                    data={pIn}
                    onClickDetail={onClickDetail}
                    enableDocumentLink={pIn?.document}
                    documentLink={`${downloadURL}/${pIn?.document}`}
                  />
                </td>
              </tr>
            )}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default MonitorReceiptsComponent
