import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  monitorTransactions: { data: [], loading: false, error: null },
  monitorExportTransaction: { loading: false, data: {}, error: null },
  monitorReceiptsReport: { data: [], loading: false, error: null },
  monitorExportReceiptReport: { loading: false, error: null },
  monitorDistributionsReport: { data: [], loading: false, error: null },
  monitorExportDistributionReport: { loading: false, error: null },
  monitorProvicneDistributionReport: { data: [], loading: false, error: null },
  monitorProvinceExportDistributionReport: { loading: false, error: null },
  monitorInventory: { data: [], loading: false, error: null },
  monitorInventoyExport: { loading: false, error: null },
  monitorConsumptionReport: { data: [], loading: false, error: null },
  monitorConsumptionExport: { loading: false, error: null },
  monitorReceiptHealthReport: { data: [], loading: false, error: null },
  monitorReceiptHealthExport: { loading: false, error: null },
  monitorQuantificationReport: { data: [], loading: false, error: null },
  monitorQuantificationExport: { loading: false, error: null },
}
const monitorReportsSlice = createSlice({
  name: "monitorReports",
  initialState,
  reducers: {
    setTransactionReport(state, action) {
      state.monitorTransactions = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setMonitorReceiptReport(state, action) {
      state.monitorReceiptsReport = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setMonitorDistributionReport(state, action) {
      state.monitorDistributionsReport = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setMonitorProvicneDistributionReport(state, action) {
      state.monitorProvicneDistributionReport = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setMonitorConsumptionReport(state, action) {
      state.monitorConsumptionReport = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setMonitorReceiptHealthReport(state, action) {
      state.monitorReceiptHealthReport = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },
    setMonitorQuantificationReport(state, action) {
      state.monitorQuantificationReport = {
        error: action.payload.error,
        loading: action.payload.loading,
        data: action.payload.data,
      }
    },

    setTransactionExport(state, action) {
      state.monitorExportTransaction = {
        error: action.payload.error,
        loading: action.payload.loading,
      }
    },
    setReceiptReportExport(state, action) {
      state.monitorExportReceiptReport = {
        error: action.payload.error,
        loading: action.payload.loading,
      }
    },
    setDistributionReportExport(state, action) {
      state.monitorExportDistributionReport = {
        error: action.payload.error,
        loading: action.payload.loading,
      }
    },
    setProvinceDistributionReportExport(state, action) {
      state.monitorProvinceExportDistributionReport = {
        error: action.payload.error,
        loading: action.payload.loading,
      }
    },
    setMonitorInventoyExport(state, action) {
      state.monitorInventoyExport = {
        error: action.payload.error,
        loading: action.payload.loading,
      }
    },
    setMonitorConsumptionExport(state, action) {
      state.monitorConsumptionExport = {
        error: action.payload.error,
        loading: action.payload.loading,
      }
    },
    setMonitorReceiptHealthExport(state, action) {
      state.monitorReceiptHealthExport = {
        error: action.payload.error,
        loading: action.payload.loading,
      }
    },
    setMonitorQuantificationExport(state, action) {
      state.monitorQuantificationExport = {
        error: action.payload.error,
        loading: action.payload.loading,
      }
    },
    resetTransactionReport(state) {
      state.monitorTransactions = { data: [], loading: false, error: null }
    },
    resetMonitorReceiptReport(state) {
      state.monitorReceiptsReport = { data: [], loading: false, error: null }
    },
    resetMonitorDistributionReport(state) {
      state.monitorDistributionsReport = {
        data: [],
        loading: false,
        error: null,
      }
    },
    resetMonitorQuantificationReport(state) {
      state.monitorQuantificationReport = {
        data: [],
        loading: false,
        error: null,
      }
    },
    resetTransactionExport(state) {
      state.monitorExportTransaction = {
        data: [],
        loading: false,
        error: null,
      }
    },
    resetReceiptExport(state) {
      state.monitorExportReceiptReport = {
        loading: false,
        error: null,
      }
    },
    resetDistributionExport(state) {
      state.monitorExportDistributionReport = {
        loading: false,
        error: null,
      }
    },
    resetProvinceExportDistributionReport(state) {
      state.monitorProvinceExportDistributionReport = {
        loading: false,
        error: null,
      }
    },
    resetMonitorQuantificationExport(state) {
      state.monitorQuantificationExport = {
        loading: false,
        error: null,
      }
    },
    resetMonitorInventoyExport(state) {
      state.monitorInventoyExport = { loading: false, error: null }
    },
    resetMonitorConsumptionReport(state) {
      state.monitorConsumptionReport = {
        data: [],
        loading: false,
        error: null,
      }
    },
    resetMonitorReceiptHealthReport(state) {
      state.monitorReceiptHealthReport = {
        data: [],
        loading: false,
        error: null,
      }
    },
  },
})

export const monitorReportsAction = monitorReportsSlice.actions

export default monitorReportsSlice
