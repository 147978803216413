import axiosInstance from "api/axiosInstance"
import fileaxiosInstance from "api/fileAxiosInstance"
import exportAxiosInstance from "api/exportAxiosInstance"
import { providerConsumptionAction } from "./province-consumption-slice"
import FileSaver from "file-saver"

export const getProviderConsumptions =
  (params = {}) =>
  dispatch => {
    dispatch(providerConsumptionAction.setLoading(true))
    dispatch(providerConsumptionAction.setData([]))
    axiosInstance
      .get("/v1/get-provider-consumptions", { params: params })
      .then(response => {
        if (response) {
          dispatch(providerConsumptionAction.setError(null))
          dispatch(providerConsumptionAction.setData(response.data))
          dispatch(providerConsumptionAction.setLoading(false))
        }
      })
      .catch(error => {
        dispatch(providerConsumptionAction.setLoading(false))
        dispatch(providerConsumptionAction.setError(error?.response?.data))
      })
  }

export const getProviderConsumptionsProjects =
  (params = {}) =>
  dispatch => {
    dispatch(
      providerConsumptionAction.setProviderConsumptionProjects({
        loading: true,
        error: null,
        data: [],
      })
    )
    axiosInstance
      .get("/v1/get-provider-comsumption-projects", { params })
      .then(response => {
        if (response) {
          dispatch(
            providerConsumptionAction.setProviderConsumptionProjects({
              loading: false,
              error: null,
              data: response?.data,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          providerConsumptionAction.setProviderConsumptionProjects({
            loading: false,
            error: error?.response,
            data: [],
          })
        )
      })
  }

export const getProviderConsumptionsIPs =
  (params = {}) =>
  dispatch => {
    dispatch(
      providerConsumptionAction.setProviderConsumptionIPs({
        loading: true,
        error: null,
        data: [],
      })
    )
    axiosInstance
      .get("/v1/get-provider-comsumption-ips", { params })
      .then(response => {
        if (response) {
          dispatch(
            providerConsumptionAction.setProviderConsumptionIPs({
              loading: false,
              error: null,
              data: response?.data,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          providerConsumptionAction.setProviderConsumptionIPs({
            loading: false,
            error: error?.response,
            data: [],
          })
        )
      })
  }

export const getProviderConsumptionsProvince =
  (params = {}) =>
  dispatch => {
    dispatch(
      providerConsumptionAction.setProviderConsumptionProvince({
        loading: true,
        error: null,
        data: [],
      })
    )
    axiosInstance
      .get("/v1/get-provider-comsumption-provinces", { params })
      .then(response => {
        if (response) {
          dispatch(
            providerConsumptionAction.setProviderConsumptionProvince({
              loading: false,
              error: null,
              data: response?.data,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          providerConsumptionAction.setProviderConsumptionProvince({
            loading: false,
            error: error?.response,
            data: [],
          })
        )
      })
  }

export const getProviderConsumptionsFacilities =
  (params = {}) =>
  dispatch => {
    dispatch(
      providerConsumptionAction.setProviderConsumptionFacilities({
        loading: true,
        error: null,
        data: [],
      })
    )
    axiosInstance
      .get(`/v1/get-provider-comsumption-facilitis`, { params })
      .then(response => {
        if (response) {
          dispatch(
            providerConsumptionAction.setProviderConsumptionFacilities({
              loading: false,
              error: null,
              data: response?.data,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          providerConsumptionAction.setProviderConsumptionFacilities({
            loading: false,
            error: error?.response,
            data: [],
          })
        )
      })
  }

export const exportProviderConsumptions = values => dispatch => {
  dispatch(
    providerConsumptionAction.setExport({
      loading: true,
    })
  )
  exportAxiosInstance
    .post("/v1/export-provider-consumptions", values)
    .then(response => {
      if (response) {
        const dataBlob = new Blob([response?.data], {
          type: "application/pdf,",
        })
        FileSaver.saveAs(
          dataBlob,
          `Consumption-${Math.random().toString()}.xlsx`
        )
        dispatch(
          providerConsumptionAction.setExport({
            loading: false,
          })
        )
      }
    })
    .catch(error => {
      dispatch(
        providerConsumptionAction.setExport({
          loading: false,
          error: error?.response?.data,
        })
      )
    })
}
