import React, { useMemo, useState } from "react"
import { Card, CardBody, Collapse } from "reactstrap"
import { useNavigate } from "react-router-dom"
import CustomHeaderTitle from "components/Common/CustomHeaderTitle"
import GeneralTables from "components/Common/GeneralTable"
import ProviderExpireReceiptFilter from "./ProviderExpireReceiptFilter"
import moment from "moment"
import {
  getExpirationDateBaseMonth,
  getExpirationDateBaseYear,
} from "utils/utils"

const ProviderExpireListComponent = ({
  data,
  error,
  loading,
  onPageClick,
  onSearch,
  onChangeRowNumber,
  onChangeOrdering,
  onExportBtnClick,
  onAdvancedSearchSubmit,
  exportStatus,
  based,
}) => {
  const navigate = useNavigate()
  const [filterOpen, setFilterOpen] = useState(false)

  const getExpiredDate = useMemo(
    () =>
      based == 6 ? getExpirationDateBaseMonth() : getExpirationDateBaseYear(),
    []
  )

  const onAdvancedSearchHandler = result => {
    setFilterOpen(result)
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CustomHeaderTitle
            loading={loading}
            title={"List"}
            rowCount={true}
            onChangeRowNumber={onChangeRowNumber}
            orderingList={true}
            onChangeOrdering={onChangeOrdering}
            searchBox={true}
            onSearchHandler={onSearch}
            rightBtn={false}
            exportBtn={true}
            onExportBtnClick={onExportBtnClick}
            exportBtnLoading={exportStatus?.loading}
            advnacedSearchBtn={true}
            advnacedSearchBtnTitle={"Filter"}
            onAdvancedSearch={onAdvancedSearchHandler}
            orderingOptions={[
              { value: "id", name: "ID" },
              { value: "expire_date", name: "Expire Date" },
              { value: "date", name: "Submission Date" },
            ]}
          />
          <Collapse isOpen={filterOpen}>
            <ProviderExpireReceiptFilter
              loading={loading}
              onAdvancedSearchSubmit={onAdvancedSearchSubmit}
            />
          </Collapse>
          <GeneralTables
            data={data?.data}
            loading={loading}
            headers={[
              "SN",
              "UNFPA ITEM ID",
              "IP- ITEM DESCRIPTION",

              "BATCH NUMBER",
              "QUANTITY (IP UOM)",
              "EXPIRE DATE",
              "Count By Month",
            ]}
            pagination={{ meta: data?.meta, onPageClick: onPageClick }}
            renderItem={(pIn, index) => (
              <tr key={index}>
                <th scope="row">{data?.meta?.from + index}</th>
                <td>{pIn?.item?.unfp_item_name}</td>
                <td>{pIn?.item?.ip_item_description}</td>
                <td>{pIn.batch_number}</td>
                <td>{pIn.in_qty_per_ip}</td>
                <td>{pIn.expire_date}</td>
                <td>
                  {moment(pIn.expire_date, "YYYY-MM-DD").diff(
                    moment(),
                    "months"
                  )}
                </td>
              </tr>
            )}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default ProviderExpireListComponent
