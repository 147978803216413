import React from "react"
import { RouterProvider, createBrowserRouter } from "react-router-dom"
// Import scss
import "./assets/scss/theme.scss"
import RoutePage from "routes"

const App = () => {
  return <RoutePage />
}

export default App
