import { useFormik } from "formik"
import * as Yup from "yup"
import React, { useMemo, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Label,
  Button,
  Form,
  Input,
  FormFeedback,
  Row,
  Col,
} from "reactstrap"
import CustomHeaderTitle from "components/Common/CustomHeaderTitle"
import CustomSelect from "components/Common/SelectBoxes/CustomSelect"
import { isEmpty, isObject } from "lodash"
import { useDispatch, useSelector } from "react-redux"
import Flatpickr from "react-flatpickr"
import moment from "moment"
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"
import { isArray } from "lodash"
import ReceiptsList from "./ReceiptsList"
import SubmitedList from "./SubmitedList"
import { getSelectValueWithDefault } from "utils/providerOutUtils"
import {
  getProvinceFacilities,
  getProvinceItems,
} from "store/province-dashboard/province-dashboard-action"
import {
  getProvinceOutItems,
  getProvinceOutReceipts,
} from "store/province-out/province-out-action"
import ProvinceReceiptsList from "./ReceiptsList"
import { provinceOutAction } from "store/province-out/province-out-slice"

const ProvinceOutForm = ({
  onSubmit,
  loading,
  formMode,
  error,
  warehosues,
  providers,
  categories,
  items,
  distribution,
  ips,
  provinces,
  facilities,
  itemLoading,
  facilityLoading,
  receipts,
}) => {
  const [submited, setSubmited] = useState([])
  const submissionDateRef = useRef(null)
  const [customErrors, setCustomErrors] = useState({
    province: null,
    facility: null,
    date: null,
  })

  const storeQuantityError = useSelector(
    state => state.provinceOut.storeQuantityError
  )

  const onSubmitAddHandler = value => {
    if (isEmpty(distributeValidation.values.province_id)) {
      setCustomErrors(prev => {
        return { ...prev, province: "Please Select your provicne" }
      })
    } else {
      setCustomErrors(prev => {
        return { ...prev, province: null }
      })
    }

    if (isEmpty(distributeValidation.values.facility_id)) {
      setCustomErrors(prev => {
        return { ...prev, facility: "Please Select your facility" }
      })
    } else {
      setCustomErrors(prev => {
        return { ...prev, facility: null }
      })
    }

    if (isEmpty(distributeValidation.values.date)) {
      setCustomErrors(prev => {
        return { ...prev, date: "Submission date is required" }
      })
    } else {
      setCustomErrors(prev => {
        return { ...prev, date: null }
      })
    }

    if (value?.province_id !== distributeValidation.values.province_id.value) {
      setCustomErrors(prev => {
        return {
          ...prev,
          province_id:
            "Your receipted province is not same as your distribution province",
        }
      })
    } else {
      setCustomErrors(prev => {
        return { ...prev, province_id: null }
      })
    }

    if (
      isEmpty(distributeValidation.values.date) ||
      isEmpty(distributeValidation.values.facility_id) ||
      isEmpty(distributeValidation.values.province_id) ||
      value?.province_id !== distributeValidation.values.province_id.value
    ) {
      return
    }

    setSubmited(prev => {
      return [
        ...prev,
        {
          provider_id: getSelectValueWithDefault(
            distributeValidation.values.provider_id,
            providers
          ),
          province_id: distributeValidation.values.province_id.value,
          facility_id: isObject(distributeValidation.values.facility_id)
            ? distributeValidation.values.facility_id.value
            : null,
          item_category_id: distributeValidation.values.item_category_id.value,
          item_id: distributeValidation.values.item_id.value,
          province_in_id: value.province_in_id,
          out_quantity: value.out_quantity,
          date: distributeValidation.values.date,
          distributor: distributeValidation.values.distributor,
          batch_number: value.batch_number,
          project_id: value.project_id,
          item1: distributeValidation.values.province_id.label,
          item2: isObject(distributeValidation.values.facility_id)
            ? distributeValidation.values.facility_id.label
            : null,
          item3: distributeValidation.values.item_id.label,
          item4: value.batch_number,
          item5: value.out_quantity,
        },
      ]
    })
  }

  const dispatch = useDispatch()
  const distributeValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      provider_id: distribution ? distribution?.provider_id : "",
      item_category_id: distribution ? distribution?.item_category_id : "",
      item_id: distribution ? distribution?.item_id : "",
      province_id: distribution ? distribution?.province_id : "",
      facility_id: distribution ? distribution?.facility_id : "",
      province_in_id: distribution ? distribution?.province_in_id : "",
      distributor: distribution ? distribution?.distributor : "",
    },
    validationSchema: Yup.object().shape({
      date: Yup.string().required("This in quantity IP is required"),
    }),

    onSubmit: values => {
      onSubmit(submited)
    },
  })

  const resetAllForm = () => {
    distributeValidation.resetForm()
    submissionDateRef.current.flatpickr.clear()
  }

  const onProviderChangeHandler = value => {
    distributeValidation.setFieldValue("provider_id", value)
  }

  const onIpChangeHandler = value => {
    distributeValidation.setFieldValue("ip_id", value)
  }

  const onProvinceChangeHandler = value => {
    distributeValidation.setFieldValue("province_id", value)
    distributeValidation.setFieldValue("facility_id", "")
    dispatch(getProvinceFacilities(value.value))
  }

  const onItemCategoryChangeHandler = value => {
    if (isEmpty(distributeValidation.values.date)) {
      setCustomErrors(prev => {
        return { ...prev, date: "Submission date is required" }
      })
    } else {
      setCustomErrors(prev => {
        return { ...prev, date: null }
      })
    }

    if (isEmpty(distributeValidation.values.province_id)) {
      setCustomErrors(prev => {
        return { ...prev, province_id: "Province is required" }
      })
    } else {
      setCustomErrors(prev => {
        return { ...prev, province_id: null }
      })
    }

    distributeValidation.setFieldValue("item_category_id", value)
    dispatch(getProvinceOutItems(value.value))
    dispatch(provinceOutAction.resetReceipts())
    distributeValidation.setFieldValue("item_id", [])
  }

  const onItemChangeHandler = value => {
    distributeValidation.setFieldValue("item_id", value)
    const date = distributeValidation.values.date
    const province_id = distributeValidation.values.province_id
    const facility_id = distributeValidation.values.facility_id
    dispatch(
      getProvinceOutReceipts({
        itemId: value.value,
        submitionDate: date,
        province_id: province_id,
        facility_id: facility_id,
      })
    )
  }

  const onDistributeDetailChangeHandler = value => {
    setDistributeDetail(value)
    if (value.value === "HFs") {
      setFacilityVisible(true)
    } else {
      setFacilityVisible(false)
    }

    distributeValidation.setFieldValue("warehouse_disribute", value.value)
  }
  const onFacilityChangeHandler = value => {
    distributeValidation.setFieldValue("facility_id", value)
  }

  const filteredItems = useMemo(
    () =>
      items?.map(item => ({
        value: item.id,
        label: `${item.unfp_item_name} ( ${item.item_name} )`,
      })),
    [items]
  )

  return (
    <Card>
      <CardBody>
        <CustomHeaderTitle
          loading={loading}
          title={formMode === "Edit" ? "Edit Distribution" : "Add Distribution"}
          lgColumn={4}
          rowCount={false}
          orderingList={false}
          searchBox={false}
          rightBtn={true}
          rightBtnTitle={"Back"}
          rightBtnRoute={"/province-distributions"}
        />

        <Form
          onSubmit={e => {
            e.preventDefault()
            distributeValidation.handleSubmit()
            return false
          }}
        >
          <div className="mb-3">
            <Row>
              <Col>
                <Label className="form-label">Providers</Label>
                {providers && (
                  <CustomSelect
                    name="warehouse_id"
                    options={providers}
                    value={
                      isObject(distributeValidation.values.provider_id)
                        ? distributeValidation.values.provider_id
                        : providers.find(
                            prov =>
                              distributeValidation.values.provider_id ===
                              prov.value
                          ) || providers[0]
                    }
                    onChange={onProviderChangeHandler}
                  />
                )}
              </Col>
              <Col>
                <Label className="form-label">Provinces</Label>
                {provinces && (
                  <CustomSelect
                    name="province_id"
                    options={provinces}
                    value={
                      isObject(distributeValidation.values.province_id)
                        ? distributeValidation.values.province_id
                        : provinces.find(
                            prov =>
                              distributeValidation.values.province_id ===
                              prov.value
                          )
                    }
                    onChange={onProvinceChangeHandler}
                  />
                )}
              </Col>
            </Row>
          </div>

          <div className="mb-3">
            <Row>
              <Col>
                <Label className="form-label">Facilities</Label>
                {facilities && (
                  <CustomSelect
                    name="facility_id"
                    options={facilities}
                    value={
                      isObject(distributeValidation.values.facility_id)
                        ? distributeValidation.values.facility_id
                        : facilities.find(
                            facil =>
                              distributeValidation.values.facility_id ===
                              facil.value
                          )
                    }
                    isLoading={facilityLoading && facilityLoading}
                    onChange={onFacilityChangeHandler}
                  />
                )}
              </Col>
              {/* {distributeValidation.values.facility_id?.label ===
                "N/A( N/A )" && (
                <Col>
                  <Label className="form-label">Distributor Name</Label>
                  <Input
                    name="distributor"
                    placeholder=" Distributor Name"
                    type="text"
                    onChange={distributeValidation.handleChange}
                    onBlur={distributeValidation.handleBlur}
                    value={distributeValidation.values.distributor || ""}
                    invalid={
                      (distributeValidation.touched.distributor &&
                        distributeValidation.errors.distributor) ||
                      error?.distributor?.[0]
                        ? true
                        : false
                    }
                  />
                  {(distributeValidation.touched.distributor &&
                    distributeValidation.errors.distributor) ||
                  error?.distributor?.[0] ? (
                    <FormFeedback type="invalid">
                      {distributeValidation.errors.distributor ||
                        error?.distributor?.[0]}
                    </FormFeedback>
                  ) : null}
                </Col>
              )} */}
            </Row>
          </div>

          {/* <Row>
            {distributeValidation.values.facility_id?.label ===
              "N/A( N/A )" && (
              <Col>
                <Label className="form-label">Other HFs</Label>
                <Input
                  name="other_hfs"
                  placeholder=" Other HFs"
                  type="text"
                  onChange={distributeValidation.handleChange}
                  onBlur={distributeValidation.handleBlur}
                  value={distributeValidation.values.other_hfs || ""}
                  invalid={
                    (distributeValidation.touched.other_hfs &&
                      distributeValidation.errors.other_hfs) ||
                    error?.other_hfs?.[0]
                      ? true
                      : false
                  }
                />
                {(distributeValidation.touched.other_hfs &&
                  distributeValidation.errors.other_hfs) ||
                error?.other_hfs?.[0] ? (
                  <FormFeedback type="invalid">
                    {distributeValidation.errors.other_hfs ||
                      error?.other_hfs?.[0]}
                  </FormFeedback>
                ) : null}
              </Col>
            )}
          </Row> */}
          <div className="mb-3">
            <Label className="form-label">Submission Date</Label>
            <Flatpickr
              ref={submissionDateRef}
              className="form-control d-block"
              placeholder="dd M,yyyy"
              options={{
                altInput: true,
                altFormat: "Y-m-d",
                dateFormat: "Y-m-d",
                defaultDate: distributeValidation.values.date,
                maxDate: moment().format("YYYY-MM-DD"),
              }}
              onChange={value => {
                dispatch(provinceOutAction.resetReceipts())
                distributeValidation.setFieldValue(
                  "date",
                  moment(value[0]).format("YYYY-MM-DD")
                )
              }}
            />
            {(distributeValidation.touched.date &&
              distributeValidation.errors.date) ||
            error?.date?.[0] ? (
              <FormFeedback type="invalid">
                {distributeValidation.errors.date || error?.date?.[0]}
              </FormFeedback>
            ) : null}
          </div>

          <div className="mb-3">
            <Row>
              <Col>
                <Label className="form-label">Item Categories</Label>
                {categories && (
                  <CustomSelect
                    name="item_category_id"
                    options={categories}
                    value={
                      isObject(distributeValidation.values.item_category_id)
                        ? distributeValidation.values.item_category_id
                        : categories.filter(
                            cate =>
                              distributeValidation.values.item_category_id ===
                              cate.value
                          )
                    }
                    onChange={onItemCategoryChangeHandler}
                  />
                )}
              </Col>
              <Col>
                <Label className="form-label">Items</Label>
                {filteredItems && (
                  <CustomSelect
                    name="item_id"
                    options={filteredItems}
                    value={
                      isObject(distributeValidation.values.item_id)
                        ? distributeValidation.values.item_id
                        : filteredItems.find(
                            item =>
                              distributeValidation.values.item_id === item.value
                          )
                    }
                    isLoading={itemLoading && itemLoading}
                    onChange={onItemChangeHandler}
                  />
                )}
              </Col>
            </Row>
          </div>

          {Object.values(customErrors).map((val, key) => (
            <p className="text-danger" key={key}>
              {val}
            </p>
          ))}

          {receipts && (
            <ProvinceReceiptsList
              data={receipts}
              onSubmitAddHandler={onSubmitAddHandler}
              submited={submited}
            />
          )}

          {storeQuantityError?.length > 0 &&
            storeQuantityError?.map((item, key) => (
              <p className="text-danger" key={key}>
                {item}
              </p>
            ))}
          {submited.length > 0 && (
            <SubmitedList submited={submited} setSubmited={setSubmited} />
          )}

          <div className="d-flex flex-wrap gap-2">
            <Button type="submit" color="primary" disabled={loading}>
              {loading
                ? "Please Wait..."
                : formMode === "Add"
                ? "Save All"
                : "Update"}
            </Button>
          </div>
        </Form>
        <br />
      </CardBody>
    </Card>
  )
}

export default ProvinceOutForm
