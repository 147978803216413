import ProviderForm from "components/Provider/form"
import ProviderInForm from "components/ProviderIn/form"
import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import {
  getProviderIps,
  getProviderItemCategories,
  getProviderItems,
  getProviderProjects,
  getProviderProviders,
  getProviderProvinces,
  getProviderWarehouses,
} from "store/provider-dashboard/provider-dashboard-action"
import {
  getByIdProviderIn,
  storeProviderIn,
  updateProviderIn,
} from "store/provider-in/provider-in-action"
import { storeProvider } from "store/providers/provider-action"
import { isEmpty, isNaN, isObject } from "lodash"
import { providerInAction } from "store/provider-in/provider-in-slice"
import Preloader from "components/Common/Loader"

const EditProviderIn = () => {
  document.title = "Add Receipts"

  const params = useParams()
  const { id } = params

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { loading, error } = useSelector(state => state.providerIn)
  const receipt = useSelector(state => state.providerIn.receipt)

  const warehosues = useSelector(
    state => state.providerDashboard.providerWarehouses
  )
  const providers = useSelector(
    state => state.providerDashboard.providerProviders
  )

  const categories = useSelector(
    state => state.providerDashboard.ProviderItemCategoires
  )
  const items = useSelector(state => state.providerDashboard.ProviderItems)

  const projects = useSelector(
    state => state.providerDashboard.providerProjects
  )

  const ips = useSelector(state => state.providerDashboard.ProviderIps)

  const handleSubmit = (values, file) => {
    const forms = new FormData()

    if (!isEmpty(file)) {
      forms.append("document", file)
    }
    forms.append("_method", "put")
    forms.append("batch_number", values.batch_number)
    forms.append("date", values.date)

    forms.append("old_document", receipt?.data?.document)
    forms.append("expire_date", values.expire_date)
    forms.append("in_qty_per_ip", values.in_qty_per_ip)
    forms.append("in_qty_per_unfpa", values.in_qty_per_unfpa)
    forms.append(
      "item_category_id",
      isObject(values.item_category_id)
        ? values.item_category_id.value
        : values.item_category_id
    )
    forms.append(
      "item_id",
      isObject(values.item_id) ? values.item_id.value : values.item_id
    )
    forms.append(
      "lost_comment",
      isEmpty(values.lost_comment) ? "" : values.lost_comment
    )
    const lost_quantity = isNaN(parseInt(values.lost_quantity))
      ? 0
      : parseInt(values.lost_quantity)

    forms.append("lost_quantity", lost_quantity)
    forms.append(
      "provider_id",
      isObject(values.provider_id)
        ? values.provider_id.value
        : values.provider_id
    )
    forms.append("total_value", values.total_value)
    forms.append("unit_price_per_ip", values.unit_price_per_ip)
    forms.append("unit_price_per_unfpa", values.unit_price_per_unfpa)
    forms.append("unit_umo_quantity_per_ip", values.unit_umo_quantity_per_ip)
    forms.append(
      "unit_uom_quantity_per_unfpa",
      values.unit_uom_quantity_per_unfpa
    )
    forms.append(
      "warehouse_id",
      isObject(values.warehouse_id)
        ? values.warehouse_id.value
        : values.warehouse_id
    )

    forms.append(
      "project_id",
      isObject(values.project_id) ? values.project_id.value : values.project_id
    )

    dispatch(updateProviderIn(id, forms, navigate))
  }

  useEffect(() => {
    dispatch(getProviderWarehouses())
    dispatch(getProviderProviders())
    dispatch(getProviderItemCategories())
    dispatch(getProviderProjects())
    dispatch(getProviderIps())
    dispatch(getByIdProviderIn(id))

    return () => {
      dispatch(providerInAction.resetState())
    }
  }, [])

  useEffect(() => {
    dispatch(getProviderItems(receipt?.data?.item_category_id))
  }, [receipt])

  return (
    <React.Fragment>
      {!isEmpty(receipt) && items?.data.length > 0 ? (
        <ProviderInForm
          onSubmit={handleSubmit}
          loading={loading}
          error={error}
          formMode={"Edit"}
          warehosues={warehosues?.data}
          providers={providers?.data}
          categories={categories?.data}
          items={items?.data}
          receipt={receipt?.data}
          projects={projects?.data}
          ips={ips?.data}
        />
      ) : (
        <Preloader loading={true} />
      )}
    </React.Fragment>
  )
}

export default EditProviderIn
