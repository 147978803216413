import React from "react"
import { Card, CardBody, CardTitle } from "reactstrap"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"

const OtherLinks = () => {
  const actionRequestCount = useSelector(
    state => state.provActionRequests.ActionRequestCount
  )

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-5">Actions</CardTitle>
          <ul className="verti-timeline list-unstyled">
            <li className="event-list active">
              <div className="event-timeline-dot">
                <i className="bx bxs-right-arrow-circle font-size-18 bx-fade-right" />
              </div>
              <div className="flex-shrink-0 d-flex">
                <div className="flex-grow-1 mx-3" style={{ fontSize: 13 }}>
                  <Link to={"/provider-receipt-action-requests"}>
                    Provider Receipts Delete Requests{" "}
                    <span className="text-info" style={{ fontSize: 13 }}>
                      ( {actionRequestCount.receipt} )
                    </span>
                  </Link>
                </div>
              </div>
            </li>
            <li className="event-list active">
              <div className="event-timeline-dot">
                <i className="bx bxs-right-arrow-circle font-size-18 bx-fade-right" />
              </div>
              <div className="flex-shrink-0 d-flex">
                <div className="flex-grow-1 mx-3" style={{ fontSize: 13 }}>
                  <Link to={"/provider-distribution-action-requests"}>
                    Provider Distribution Delete Requests{" "}
                    <span className="text-info" style={{ fontSize: 13 }}>
                      ( {actionRequestCount.distribution} )
                    </span>
                  </Link>
                </div>
              </div>
            </li>
            <li className="event-list active">
              <div className="event-timeline-dot">
                <i className="bx bxs-right-arrow-circle font-size-18 bx-fade-right" />
              </div>
              <div className="flex-shrink-0 d-flex">
                <div className="flex-grow-1 mx-3" style={{ fontSize: 13 }}>
                  <Link to={"/activity-logs"}>Activity Logs</Link>
                </div>
              </div>
            </li>
          </ul>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default OtherLinks
