import Chart1 from "components/Charts/Chart1"
import React, { useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  CardHeader,
  Button,
  Collapse,
  Spinner,
  Table,
} from "reactstrap"
import { useSelector } from "react-redux"
import Chart2 from "components/Charts/Chart2"
import { isNaN } from "lodash"
import { Link } from "react-router-dom"
import moment from "moment"
import ProviderStockFilter from "components/ProviderDashboard/ProviderStockFilter"
import ProviderStockItemList from "components/ProviderDashboard/ProviderStockItemList"

const ProviderInventoryListComponent = ({
  onSubmit,
  onCategoryChange,
  provStock,
  onExport,
  provData,
  onProviderSearchHandler,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [searchDate, setSearchDate] = useState({
    fromDate: null,
    toDate: null,
  })

  const [category, setCategory] = useState({
    value: 10,
    label: "Family Planning Commodities",
  })

  let data = []
  if (provStock) {
    data = provStock?.map(stock => {
      const items = stock.item_name
      const in_qty = stock.provider_ins_sum_first_in_qty_per_ip
        ? stock.provider_ins_sum_first_in_qty_per_ip
        : 0
      const out_qty = stock.provider_outs_sum_out_quantity
        ? parseInt(stock.provider_outs_sum_out_quantity)
        : 0
      const balance =
        stock.provider_ins_sum_first_in_qty_per_ip -
        parseInt(stock.provider_outs_sum_out_quantity)
      return { items, in_qty, out_qty, balance }
    })
  }

  const provStockLoading = useSelector(
    state => state.providerDashboard.providerStock.loading
  )

  const exportStatus = useSelector(
    state => state.providerReports.providerInventoyExport
  )

  const getSearchData = values => {
    setSearchDate({
      fromDate: values.fromDate ? values.fromDate : null,
      toDate: values.toDate ? values.toDate : null,
    })
    values.Categories
      ? setCategory(values.Categories)
      : setCategory({
          value: 10,
          label: "Family Planning Commodities",
        })
  }

  return (
    <CardBody>
      <Card>
        <CardHeader>
          <Row>
            <Col lg={6}>
              <Row>
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span style={{ fontSize: 20 }}>
                    Stock ( {category.label} )
                    {searchDate.fromDate && (
                      <p
                        style={{
                          fontSize: 15,
                          color: "#19ada1",
                          fontWeight: 700,
                        }}
                      >
                        ( {searchDate.fromDate} ) - ( {searchDate.toDate} )
                      </p>
                    )}
                  </span>
                  &nbsp;
                  {provStockLoading && (
                    <Spinner
                      className="ms-1"
                      style={{ padding: 7, marginTop: 5 }}
                      size="sm"
                      color="primary"
                    />
                  )}
                </Col>
              </Row>
            </Col>
            <Col lg={6} style={{ textAlign: "right" }}>
              <Button
                onClick={onExport}
                className="btn btn-info"
                disabled={exportStatus?.loading}
              >
                {exportStatus?.loading ? "Please Wait..." : "Export"}
              </Button>
              &nbsp;
              <Button
                onClick={() => {
                  setIsOpen(!isOpen)
                }}
                className="btn btn-success "
              >
                {isOpen ? "Hide" : "Search"}
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Collapse isOpen={isOpen}>
            <ProviderStockFilter
              onCategoryChange={onCategoryChange}
              onSubmit={onSubmit}
              provStockLoading={provStockLoading}
              getSearchData={getSearchData}
              onProviderSearchHandler={onProviderSearchHandler}
            />
          </Collapse>
          <ProviderStockItemList
            provData={provData}
            data={provStock}
            searchDate={searchDate}
          />
        </CardBody>
      </Card>
    </CardBody>
  )
}

export default ProviderInventoryListComponent
