import SubmitedBoxComponent2 from "components/Common/SubmitedBox/index2"
import React from "react"
import { Button, Col, Row } from "reactstrap"

const ReceiptsSubmitedList = ({ data, setSubmited }) => {
  const RemoveItemHandler = itemKey => {
    setSubmited(prev => {
      const updateItems = prev.filter((ite, key) => key !== itemKey)
      return [...updateItems]
    })
  }

  return (
    <SubmitedBoxComponent2
      submited={data}
      columns={["SN", "", "", "ITEM", "BATCH NUMBER", "QTY", "Action"]}
      RemoveHandler={RemoveItemHandler}
    />
  )
}

export default ReceiptsSubmitedList
