import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Collapse } from "reactstrap"

const AdminNav = props => {
  const [items, setItems] = useState(false)
  const [userManagement, setUserManagement] = useState(false)

  return (
    <Collapse
      isOpen={props.leftMenu}
      className="navbar-collapse"
      id="topnav-menu-content"
    >
      <ul className="navbar-nav">
        <li className="nav-item ">
          <Link className="nav-link " to="/dashboard">
            <i className="bx bx-home-circle me-2"></i>
            Dashboard
          </Link>
        </li>
        <li className="nav-item ">
          <Link className="nav-link " to="/warehouse">
            <i className="mdi mdi-warehouse me-2"></i>
            Warehouse
          </Link>
        </li>
        <li className="nav-item ">
          <Link className="nav-link " to="/projects ">
            <i className="mdi mdi-order-bool-descending me-2"></i>
            Project
          </Link>
        </li>
        <li className="nav-item ">
          <Link className="nav-link " to="/providers">
            <i className="mdi mdi-order-bool-descending me-2"></i>
            Providers
          </Link>
        </li>
        <li className="nav-item ">
          <Link className="nav-link " to="/provinces">
            <i className="mdi mdi-google-maps me-2"></i>
            Province
          </Link>
        </li>
        <li className="nav-item ">
          <Link className="nav-link " to="/facilities">
            <i className="mdi mdi-google-maps me-2"></i>
            Facilities
          </Link>
        </li>
        <li className="nav-item ">
          <Link className="nav-link " to="/ips">
            <i className="mdi mdi-account-group me-2"></i>
            IPs
          </Link>
        </li>
        <li className="nav-item dropdown">
          <Link
            className="nav-link dropdown-toggle arrow-none"
            onClick={e => {
              e.preventDefault()
              setItems(!items)
            }}
            to="/items"
          >
            <i className="mdi mdi-order-bool-descending me-2"></i>
            Items {props.menuOpen}
            <div className="arrow-down"></div>
          </Link>
          <div className={"dropdown-menu"}>
            <Link to="/item-categories" className="dropdown-item">
              Item Category
            </Link>
            <Link to="/items" className="dropdown-item">
              Item
            </Link>
          </div>
        </li>

        <li className="nav-item dropdown">
          <Link
            className="nav-link dropdown-toggle arrow-none"
            onClick={e => {
              e.preventDefault()
              setUserManagement(!userManagement)
            }}
            to="/users"
          >
            <i className="bx bx-user me-2"></i>
            User Management {props.menuOpen}
            <div className="arrow-down"></div>
          </Link>
          <div className={"dropdown-menu"}>
            <Link to="/users" className="dropdown-item">
              Users
            </Link>
            <Link to="/roles" className="dropdown-item">
              Roles
            </Link>
          </div>
        </li>
      </ul>
    </Collapse>
  )
}

export default AdminNav
