import React, { useEffect, useMemo, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import ProviderTransactionListComponent from "components/ProviderReports/Transaction"
import {
  exportProviderReceiptHealthReport,
  exportProviderReceiptReport,
  exportProviderTransactions,
  getProviderReceiptHealthReports,
  getProviderReceiptReports,
  getProviderTransactions,
} from "store/provider-reports/p-report-action"
import {
  getProviderItemCategories,
  getProviderWarehouses,
} from "store/provider-dashboard/provider-dashboard-action"
import useSearch from "hooks/useSearch"
import ProviderReceiptReportListComponent from "components/ProviderReports/Receipt"
import ProviderReceiptHealthReportListComponent from "components/ProviderReports/ReceiptHealth"
import {
  exportMonitorReceiptHealthReport,
  getMonitorReceiptHealthReports,
} from "store/monitor-reports/monitor-report-action"
import {
  getMonitorIps,
  getMonitorItemCategories,
  getMonitorWarehouses,
} from "store/monitor-dashboard/monitor-dashboard-action"
import MonitorReceiptHealthReportListComponent from "components/MonitorReports/ReceiptHealth"

const MonitorReceiptHealthReportList = () => {
  document.title = "Receipt Health Reports"

  const dispatch = useDispatch()
  const receipts = useSelector(
    state => state.monitorReports.monitorReceiptHealthReport
  )
  const exportStatus = useSelector(
    state => state.monitorReports.monitorReceiptHealthExport
  )
  const [search, setSearch] = useState(null)
  const [pageNumber, setPageNumber] = useState(20)
  const [according, setAccording] = useState({ order: "desc", name: "date" })
  const [advnacedSearch, setAdvancedSearch] = useState({})

  const onSearchHandler = value => {
    setSearch(value)
  }

  const onPageClick = link => {
    dispatch(
      getMonitorReceiptHealthReports(
        link.url,
        pageNumber,
        according,
        search,
        advnacedSearch
      )
    )
  }

  const onChangeRowNumber = value => {
    setPageNumber(value)
  }

  const onChangeOrdering = value => {
    if (value.order) {
      setAccording(prev => {
        return { ...prev, order: value.order }
      })
    }
    if (value.name) {
      setAccording(prev => {
        return { ...prev, name: value.name }
      })
    }
  }

  useSearch(
    getMonitorReceiptHealthReports(
      undefined,
      undefined,
      undefined,
      search,
      advnacedSearch
    ),
    search
  )

  useEffect(() => {
    dispatch(
      getMonitorReceiptHealthReports(
        undefined,
        pageNumber,
        according,
        search,
        advnacedSearch
      )
    )
  }, [pageNumber, according])

  useEffect(() => {
    dispatch(getMonitorItemCategories())
    dispatch(getMonitorWarehouses())
    dispatch(getMonitorIps())
  }, [])

  const onAdvancedSearchSubmit = data => {
    setAdvancedSearch(data)
    dispatch(
      getMonitorReceiptHealthReports(
        undefined,
        undefined,
        undefined,
        search,
        data
      )
    )
  }

  const onExportBtnClick = () => {
    dispatch(
      exportMonitorReceiptHealthReport(
        pageNumber,
        according,
        search,
        advnacedSearch
      )
    )
  }

  return (
    <React.Fragment>
      <Breadcrumbs
        title={"Monitor"}
        breadcrumbItem={"Receipt Health Reports"}
      />
      <MonitorReceiptHealthReportListComponent
        data={receipts?.data}
        error={receipts?.error}
        loading={receipts?.loading}
        onSearch={onSearchHandler}
        onChangeRowNumber={onChangeRowNumber}
        onChangeOrdering={onChangeOrdering}
        onAdvancedSearchSubmit={onAdvancedSearchSubmit}
        onExportBtnClick={onExportBtnClick}
        exportStatus={exportStatus}
        onPageClick={onPageClick}
      />
    </React.Fragment>
  )
}

export default MonitorReceiptHealthReportList
