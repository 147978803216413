import Chart1 from "components/Charts/Chart1"
import React, { useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  CardHeader,
  Button,
  Collapse,
  Spinner,
  Table,
} from "reactstrap"
import ProviderStockFilter from "./MonitorStockFilter"
import { useSelector } from "react-redux"
import Chart2 from "components/Charts/Chart2"
import { isNaN } from "lodash"
import MonitorIPsStockFilter from "./MonitorIPsStockFilter"
import MonitorIPsStockItemlist from "./MonitorIPsStockItemlist"

const MonitorIPsStock = ({
  onCategoryChange,
  provIPsStock,
  onIPsAdvanceSearchHandler,
  onIPStockExport,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [ips, setIps] = useState({ value: "all", label: "All" })
  const [searchDate, setSearchDate] = useState({
    fromDate: null,
    toDate: null,
  })

  let data = []
  if (provIPsStock) {
    data = provIPsStock?.map(stock => {
      const items = stock.item_name
      const in_qty = !isNaN(parseInt(stock.province_ins_sum_first_in_qty))
        ? parseInt(stock.province_ins_sum_first_in_qty)
        : 0

      const out_qty = !isNaN(parseInt(stock.province_outs_sum_out_quantity))
        ? parseInt(stock.province_outs_sum_out_quantity)
        : 0

      const balance = in_qty - out_qty
      return { items, in_qty, out_qty, balance }
    })
  }

  const provIpsStockLoading = useSelector(
    state => state.providerDashboard.providerIpsStock.loading
  )

  const exportStatus = useSelector(
    state => state.monitorDashboard.monitorIpsStockExport
  )

  const getSearchData = values => {
    setSearchDate({
      fromDate: values.fromDate ? values.fromDate : null,
      toDate: values.toDate ? values.toDate : null,
    })
    if (values.Ips) {
      setIps(values.Ips)
    } else {
      setIps({ value: "all", label: "All" })
    }
  }

  return (
    <CardBody>
      <Card>
        <CardHeader>
          <Row>
            <Col lg={6}>
              <Row>
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span style={{ fontSize: 20 }}>
                    IPs Stock ( {ips.label} )
                    {searchDate.fromDate && (
                      <p
                        style={{
                          fontSize: 15,
                          color: "#19ada1",
                          fontWeight: 700,
                        }}
                      >
                        ( {searchDate.fromDate} ) - ( {searchDate.toDate} )
                      </p>
                    )}
                  </span>
                  &nbsp;
                  {provIpsStockLoading && (
                    <Spinner
                      className="ms-1"
                      style={{ padding: 7, marginTop: 5 }}
                      size="sm"
                      color="primary"
                    />
                  )}
                </Col>
              </Row>
            </Col>
            <Col lg={6} style={{ textAlign: "right" }}>
              <Button
                onClick={onIPStockExport}
                className="btn btn-info "
                disabled={exportStatus?.loading}
              >
                {exportStatus?.loading ? "Please Wait..." : "Export"}
              </Button>
              &nbsp;
              <Button
                onClick={() => {
                  setIsOpen(!isOpen)
                }}
                className="btn btn-success "
              >
                {isOpen ? "Hide" : "Search"}
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Collapse isOpen={isOpen}>
            <MonitorIPsStockFilter
              onCategoryChange={onCategoryChange}
              provIpsStockLoading={provIpsStockLoading}
              getSearchData={getSearchData}
              onIPsAdvanceSearchHandler={onIPsAdvanceSearchHandler}
            />
          </Collapse>
          <Chart2 data={data} />
          <MonitorIPsStockItemlist
            data={provIPsStock}
            searchDate={searchDate}
            ips={ips}
          />
        </CardBody>
      </Card>
    </CardBody>
  )
}

export default MonitorIPsStock
