import React from "react"

import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"

import avatar1 from "../../assets/images/users/avatar-1.jpg"
import profileImg from "../../assets/images/profile-img.png"

const WelcomeComp = ({ user }) => {
  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary bg-soft">
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h5 className="text-primary">Welcome Back !</h5>
                <p>eLMIS Admin Dashboard</p>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="7">
              {/* <div className="avatar-md profile-user-wid mb-4">
                <img
                  src={avatar1}
                  alt=""
                  className="img-thumbnail rounded-circle"
                />
              </div> */}
              <br />
              <h5 className="font-size-15 text-truncate">{user?.name}</h5>
              <p className="text-muted mb-0 text-truncate">{user?.email}</p>
            </Col>

            <Col sm="5">
              <div className="pt-4">
                <div className="" style={{ textAlign: "right" }}>
                  <Link to="/profile" className="btn btn-primary  btn-sm">
                    View Profile <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
          <br />
          <br />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default WelcomeComp
