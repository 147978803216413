import React, { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"

import FacilityForm from "components/Facility/form"
import { getByIdFacility, updateFacility } from "store/facility/facility-action"
import { getAllProvinces } from "store/general-renders/general-action"
import { facilityAction } from "store/facility/facility-slice"
import { provinceDataSelector } from "store/selectors"
import { generalAction } from "store/general-renders/general-slice"
import Preloader from "components/Common/Loader"

const EditFacility = () => {
  document.title = "Edit Facility"

  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { loading, facility, error } = useSelector(state => state.facility)
  const { provinces, provinceLoading, provinceError } =
    useSelector(provinceDataSelector)
  const [loaded, setLoaded] = useState(false)

  const onSubmit = values => {
    values.id = id
    dispatch(updateFacility(id, values, navigate))
  }

  useEffect(() => {
    dispatch(getByIdFacility(id))
    dispatch(getAllProvinces())
    setLoaded(true)
    return () => {
      dispatch(facilityAction.resetState())
      dispatch(generalAction.resetProvince())
    }
  }, [])

  return (
    <React.Fragment>
      {facility?.data && provinces ? (
        <FacilityForm
          loading={loading}
          facility={facility?.data}
          error={error}
          onSubmit={onSubmit}
          provinces={provinces}
          provinceLoading={provinceLoading}
          provinceError={provinceError}
          formMode={"Edit"}
        />
      ) : (
        <Preloader loading={true} />
      )}
    </React.Fragment>
  )
}

export default EditFacility
