import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import {
  exportProvinceInExpireReceipts,
  getProvinceExpireReceipts,
} from "store/province-exipre-receipts/province-expire-receipts-action"
import useSearch from "hooks/useSearch"
import ProvinceExpireListComponent from "components/ProvinceExpireReceipts/list"

const ProvinceExpireReceiptList = () => {
  document.title = "Expire Receipts"

  const dispatch = useDispatch()
  const { data, error, loading, exportStatus } = useSelector(state => ({
    data: state.provinceExpireReceipts.data,
    error: state.provinceExpireReceipts.error,
    loading: state.provinceExpireReceipts.loading,
    exportStatus: state.provinceExpireReceipts.exportStatus,
  }))
  const [search, setSearch] = useState(null)
  const [pageNumber, setPageNumber] = useState(10)
  const [according, setAccording] = useState({ order: "desc", name: "id" })
  const [advnacedSearch, setAdvancedSearch] = useState({})

  const onPageClick = link => {
    dispatch(
      getProvinceExpireReceipts(
        link.url,
        pageNumber,
        according,
        search,
        advnacedSearch
      )
    )
  }

  const onSearchHandler = value => {
    setSearch(value)
  }

  const onChangeRowNumber = value => {
    setPageNumber(value)
  }

  const onChangeOrdering = value => {
    if (value.order) {
      setAccording(prev => {
        return { ...prev, order: value.order }
      })
    }
    if (value.name) {
      setAccording(prev => {
        return { ...prev, name: value.name }
      })
    }
  }

  useSearch(
    getProvinceExpireReceipts(
      undefined,
      undefined,
      undefined,
      search,
      advnacedSearch
    ),
    search
  )

  useEffect(() => {
    dispatch(
      getProvinceExpireReceipts(
        undefined,
        pageNumber,
        according,
        search,
        advnacedSearch
      )
    )
  }, [pageNumber, according])

  const onAdvancedSearchSubmit = data => {
    setAdvancedSearch(data)
    dispatch(
      getProvinceExpireReceipts(undefined, undefined, undefined, search, data)
    )
  }

  const onExportBtnClick = () => {
    dispatch(exportProvinceInExpireReceipts(advnacedSearch))
  }

  return (
    <React.Fragment>
      <Breadcrumbs title={"Province"} breadcrumbItem={"Expire Receipts"} />
      <ProvinceExpireListComponent
        data={data}
        error={error}
        loading={loading}
        onPageClick={onPageClick}
        onSearch={onSearchHandler}
        onChangeRowNumber={onChangeRowNumber}
        onChangeOrdering={onChangeOrdering}
        onAdvancedSearchSubmit={onAdvancedSearchSubmit}
        onExportBtnClick={onExportBtnClick}
        exportStatus={exportStatus}
      />
    </React.Fragment>
  )
}

export default ProvinceExpireReceiptList
