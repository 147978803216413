import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: [],
  user: null,
  error: null,
  loading: false,
  editLoading: true,
  permissionChange: null,
}

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload
    },
    setError(state, action) {
      state.error = action.payload
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    resetState(state) {
      state.data = []
      state.error = null
      state.loading = true
      state.user = null
      state.permissionChange = { loading: false, error: null, data: null }
    },
    setUser(state, action) {
      state.user = action.payload
    },
    setPermissionChange(state, action) {
      state.permissionChange = {
        loading: action.payload.loading,
        error: action.payload.error,
        data: action.payload.data,
      }
    },
  },
})

export const userAction = userSlice.actions

export default userSlice
