import React, { useEffect, useMemo, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import useSearch from "hooks/useSearch"
import {
  exportProvinceTransactions,
  getProvinceTransactions,
} from "store/province-reports/province-report-action"
import { getProvinceItemCategories } from "store/province-dashboard/province-dashboard-action"
import ProvinceTransactionListComponent from "components/ProvinceReports/Transaction"
import { getProvinceOutProvinces } from "store/province-out/province-out-action"
import { getProvinceInProjects } from "store/province-in/province-in-action"
import { getProvinceConsumptionsProviderAccount } from "store/province-consumptions/province-consumption-action"

const ProvinceTransactionList = () => {
  document.title = "Transactions"

  const dispatch = useDispatch()
  const transactions = useSelector(
    state => state.provinceReports.provinceTransactions
  )
  const exportTransaction = useSelector(
    state => state.provinceReports.provinceExportTransaction
  )
  const [search, setSearch] = useState(null)
  const [pageNumber, setPageNumber] = useState(20)
  const [according, setAccording] = useState({ order: "desc", name: "date" })
  const [advnacedSearch, setAdvancedSearch] = useState({})

  const onSearchHandler = value => {
    setSearch(value)
  }

  const onPageClick = link => {
    dispatch(
      getProvinceTransactions(
        link.url,
        pageNumber,
        according,
        search,
        advnacedSearch
      )
    )
  }

  const onChangeRowNumber = value => {
    setPageNumber(value)
  }

  const onChangeOrdering = value => {
    if (value.order) {
      setAccording(prev => {
        return { ...prev, order: value.order }
      })
    }
    if (value.name) {
      setAccording(prev => {
        return { ...prev, name: value.name }
      })
    }
  }

  useSearch(
    getProvinceTransactions(
      undefined,
      undefined,
      undefined,
      search,
      advnacedSearch
    ),
    search
  )

  useEffect(() => {
    dispatch(
      getProvinceTransactions(
        undefined,
        pageNumber,
        according,
        search,
        advnacedSearch
      )
    )
  }, [pageNumber, according])

  useEffect(() => {
    dispatch(getProvinceItemCategories())
    dispatch(getProvinceOutProvinces())
    dispatch(getProvinceInProjects())
    dispatch(getProvinceConsumptionsProviderAccount())
  }, [])

  const onAdvancedSearchSubmit = data => {
    setAdvancedSearch(data)
    dispatch(
      getProvinceTransactions(undefined, undefined, undefined, search, data)
    )
  }

  const onExportBtnClick = () => {
    dispatch(
      exportProvinceTransactions(pageNumber, according, search, advnacedSearch)
    )
  }

  return (
    <React.Fragment>
      <Breadcrumbs title={"Province"} breadcrumbItem={"Transactions"} />
      <ProvinceTransactionListComponent
        data={transactions?.data}
        error={transactions?.error}
        loading={transactions?.loading}
        onSearch={onSearchHandler}
        onChangeRowNumber={onChangeRowNumber}
        onChangeOrdering={onChangeOrdering}
        onAdvancedSearchSubmit={onAdvancedSearchSubmit}
        onExportBtnClick={onExportBtnClick}
        exportTransaction={exportTransaction}
        onPageClick={onPageClick}
      />
    </React.Fragment>
  )
}

export default ProvinceTransactionList
