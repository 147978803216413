import React from "react"

import { Link } from "react-router-dom"
import { Container, Row, Col } from "reactstrap"

const UnAuthorized = () => {
  document.title = "Dashboard"

  return (
    <React.Fragment>
      <div className=" pt-5" style={{ marginTop: 200 }}>
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <h1 className="display-2 fw-medium">
                  4<i className="bx bx-buoy bx-spin text-primary display-3" />3
                </h1>
                <h4 className="text-uppercase">You're not authorized</h4>
                <div className="mt-5 text-center">
                  <Link
                    className="btn btn-primary waves-effect waves-light "
                    to="/dashboard"
                  >
                    Back to Dashboard
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UnAuthorized
