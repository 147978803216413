import React, { useEffect } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import { getRoles } from "store/role/role-action"
import { roleAction } from "store/role/role-slice"
import RoleListComponent from "components/Role/list"

const RoleList = () => {
  const dispatch = useDispatch()
  const { data, error, loading } = useSelector(state => state.role)

  useEffect(() => {
    dispatch(getRoles())

    return () => {
      dispatch(roleAction.resetState())
    }
  }, [])

  return (
    <React.Fragment>
      <Breadcrumbs title={"Admin"} breadcrumbItem={"Roles"} />
      <RoleListComponent data={data} error={error} loading={loading} />
    </React.Fragment>
  )
}

export default RoleList
