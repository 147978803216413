import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: [],
  error: null,
  loading: false,
  provider: null,
  exportStatus: { loading: false, error: null },
}

const providerSlice = createSlice({
  name: "provider",
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload
    },
    setError(state, action) {
      state.error = action.payload
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    setProvider(state, action) {
      state.provider = action.payload
    },
    setExportStatus(state, action) {
      state.exportStatus = {
        loading: action.payload.loading,
        error: action.payload.error,
      }
    },
    resetState(state) {
      state.data = []
      state.error = null
      state.loading = false
      state.provider = null
      state.exportStatus = { loading: false, error: null }
    },
  },
})

export const providerAction = providerSlice.actions

export default providerSlice
