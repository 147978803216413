import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Col, Form, Label, Row } from "reactstrap"
import Select from "react-select"
import "flatpickr/dist/themes/material_blue.css"
import { isObject } from "lodash"

const ItemFilter = ({ onAdvancedSearchSubmit, loading, categories }) => {
  const [category, setCategory] = useState(null)

  const handleSubmit = () => {
    let data = {}
    data.category = isObject(category) ? category.value : null
    onAdvancedSearchSubmit(data)
  }

  const onCategoryChangeHandler = cate => {
    setCategory(cate)
  }

  const onClearHandler = () => {
    setCategory(null)
    onAdvancedSearchSubmit({})
  }

  return (
    <Form
      onSubmit={e => {
        e.preventDefault()
        handleSubmit()
        return false
      }}
    >
      <div className="mb-3">
        <Row>
          <Col>
            <Label className="form-label">Item Categories</Label>
            {categories && (
              <Select
                options={categories}
                value={category}
                onChange={onCategoryChangeHandler}
              />
            )}
          </Col>
        </Row>
      </div>

      <div className="d-flex flex-wrap gap-2 mb-4">
        <Button
          type="submit"
          className="btn btn-sm btn-primary"
          color="primary"
          disabled={loading}
        >
          {loading ? "Please Wait..." : "Search"}
        </Button>
        <Button
          onClick={onClearHandler}
          className="btn btn-sm btn-danger"
          color="primary"
        >
          Clear
        </Button>
      </div>
    </Form>
  )
}
export default ItemFilter
