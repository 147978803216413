import React, { useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Col, Form, Label, Row } from "reactstrap"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import moment from "moment"
import Select from "react-select"
import { isObject } from "lodash"
import { provinceDashboardAction } from "store/province-dashboard/province-dashboard-slice"
import {
  getProvinceConsumptions,
  getProvinceConsumptionsFacilities,
  getProvinceConsumptionsProjects,
  getProvinceConsumptionsProvinces,
} from "store/province-consumptions/province-consumption-action"
import { getYears } from "utils/utils"
import { provinceConsumptionAction } from "store/province-consumptions/province-consumption-slice"

const ProvinceConsumptionReporFilter = ({
  onAdvancedSearchSubmit,
  loading,
}) => {
  const dateFlatpickerfrom = useRef(null)
  const dateFlatpickerto = useRef(null)
  const [providerAccount, setProviderAccount] = useState(null)
  const [project, setProject] = useState(null)
  const [province, setProvince] = useState(null)
  const [facility, setFacility] = useState(null)
  const [date, setDate] = useState({
    from: null,
    to: null,
  })

  const providerAccounts = useSelector(
    state => state.provinceConsumptions.provinceConsumpProviderAccount
  )
  const projects = useSelector(
    state => state.provinceConsumptions.provinceConsumpProjects
  )

  const provinces = useSelector(
    state => state.provinceConsumptions.provinceConsumpProvinces
  )

  const facilities = useSelector(
    state => state.provinceConsumptions.provinceConsumpFacilities
  )

  const years = useMemo(() => getYears())

  const dispatch = useDispatch()

  const handleSubmit = () => {
    let data = {}
    data.provided_by = isObject(providerAccount) ? providerAccount.value : null
    data.province_id = isObject(province) ? province.value : null
    data.project_id = isObject(project) ? project.value : null
    data.facility = isObject(facility) ? facility.value : null
    data.date = {
      from: date.from ? date.from : null,
      to: date.to ? date.to : null,
    }
    onAdvancedSearchSubmit(data)
    dispatch(provinceConsumptionAction.resetStore())
  }

  const onProvinceChangeHandler = value => {
    setProvince(value)
    setFacility("")
    dispatch(
      getProvinceConsumptionsFacilities({
        providerAccount: providerAccount.value,
        project_id: project.value,
        province_id: value.value,
      })
    )
  }

  const onFacilityChangeHandler = value => {
    setFacility(value)
  }

  const onProviderAccountChangeHandler = value => {
    dispatch(
      getProvinceConsumptionsProjects({
        providerAccount: value.value,
      })
    )
    setProviderAccount(value)
  }
  const onProjectChangeHandler = value => {
    dispatch(
      getProvinceConsumptionsProvinces({
        providerAccount: providerAccount.value,
        project_id: value.value,
      })
    )
    setProject(value)
  }

  const onClearHandler = () => {
    setProvince(null)
    setFacility(null)
    setDate({ from: null, to: null })
    setProviderAccount(null)
    setProject(null)
    onAdvancedSearchSubmit({})
    dispatch(provinceDashboardAction.resetProvinceItems())
    dispatch(provinceConsumptionAction.resetState())
    dispatch(provinceConsumptionAction.resetStore())
  }

  return (
    <Form
      onSubmit={e => {
        e.preventDefault()
        handleSubmit()
        return false
      }}
    >
      <div className="mb-3">
        <Row>
          <Col>
            <Label className="form-label">Provider Account</Label>
            {providerAccounts?.data && (
              <Select
                options={providerAccounts?.data}
                value={providerAccount}
                onChange={onProviderAccountChangeHandler}
              />
            )}
          </Col>
          <Col>
            <Label className="form-label">Projects</Label>
            {projects?.data && (
              <Select
                options={projects?.data}
                value={project}
                onChange={onProjectChangeHandler}
              />
            )}
          </Col>
        </Row>
      </div>

      <div className="mb-3">
        <Row>
          <Col>
            <Label className="form-label">Province</Label>
            {provinces?.data && (
              <Select
                options={provinces?.data}
                value={province}
                onChange={onProvinceChangeHandler}
              />
            )}
          </Col>
          <Col>
            <Label className="form-label">Facilities</Label>
            {facilities?.data && (
              <Select
                options={facilities?.data}
                value={facility}
                onChange={onFacilityChangeHandler}
              />
            )}
          </Col>
        </Row>
      </div>

      <div className="mb-3 ">
        <Row>
          <Col>
            <Label className="form-label">From Date</Label>
            <Flatpickr
              ref={dateFlatpickerfrom}
              className="form-control d-block"
              placeholder="dd M,yyyy"
              options={{
                altInput: true,
                altFormat: "Y-m-d",
                dateFormat: "Y-m-d",
              }}
              onChange={value => {
                setDate(prev => {
                  return {
                    ...prev,
                    from: moment(value[0]).format("YYYY-MM-DD"),
                  }
                })
              }}
            />
          </Col>
          <Col>
            <Label className="form-label">To Date</Label>
            <Flatpickr
              ref={dateFlatpickerto}
              className="form-control d-block"
              placeholder="dd M,yyyy"
              options={{
                altInput: true,
                altFormat: "Y-m-d",
                dateFormat: "Y-m-d",
              }}
              onChange={value => {
                setDate(prev => {
                  return { ...prev, to: moment(value[0]).format("YYYY-MM-DD") }
                })
              }}
            />
          </Col>
        </Row>
      </div>

      <div className="d-flex flex-wrap gap-2 mb-4">
        <Button
          type="submit"
          className="btn btn-sm btn-primary"
          color="primary"
          disabled={loading}
        >
          {loading ? "Please Wait..." : "Search"}
        </Button>
        <Button
          onClick={onClearHandler}
          className="btn btn-sm btn-danger"
          color="primary"
        >
          Clear
        </Button>
      </div>
    </Form>
  )
}

export default ProvinceConsumptionReporFilter
