import ProviderForm from "components/Provider/form"
import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { storeProvider } from "store/providers/provider-action"

const AddProvider = () => {
  document.title = "Add Provider"

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { loading, error, data } = useSelector(state => state.provider)

  const handleSubmit = values => {
    dispatch(storeProvider(values, navigate))
  }

  return (
    <ProviderForm
      onSubmit={handleSubmit}
      loading={loading}
      error={error}
      formMode={"Add"}
    />
  )
}

export default AddProvider
