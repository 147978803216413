import React, { useEffect, useMemo, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import ProviderTransactionListComponent from "components/ProviderReports/Transaction"
import {
  exportProviderTransactions,
  exportProviderTransactionsWithProject,
  getProviderTransactions,
} from "store/provider-reports/p-report-action"
import {
  getProviderItemCategories,
  getProviderProjects,
  getProviderWarehouses,
} from "store/provider-dashboard/provider-dashboard-action"
import useSearch from "hooks/useSearch"
import ProviderTransactionByProjectListComponent from "components/ProviderReports/TransactionByProject"

const ProviderTransactionByProjectList = () => {
  document.title = "Transactions By Project"

  const dispatch = useDispatch()
  const transactions = useSelector(
    state => state.providerReports.providerTransactions
  )
  const exportTransaction = useSelector(
    state => state.providerReports.providerExportTransaction
  )
  const [search, setSearch] = useState(null)
  const [pageNumber, setPageNumber] = useState(20)
  const [according, setAccording] = useState({ order: "desc", name: "date" })
  const [advnacedSearch, setAdvancedSearch] = useState({})

  const onSearchHandler = value => {
    setSearch(value)
  }

  const onPageClick = link => {
    dispatch(
      getProviderTransactions(
        link.url,
        pageNumber,
        according,
        search,
        advnacedSearch
      )
    )
  }

  const onChangeRowNumber = value => {
    setPageNumber(value)
  }

  const onChangeOrdering = value => {
    if (value.order) {
      setAccording(prev => {
        return { ...prev, order: value.order }
      })
    }
    if (value.name) {
      setAccording(prev => {
        return { ...prev, name: value.name }
      })
    }
  }

  useSearch(
    getProviderTransactions(
      undefined,
      undefined,
      undefined,
      search,
      advnacedSearch
    ),
    search
  )

  useEffect(() => {
    dispatch(
      getProviderTransactions(
        undefined,
        pageNumber,
        according,
        search,
        advnacedSearch
      )
    )
  }, [pageNumber, according])

  useEffect(() => {
    dispatch(getProviderItemCategories())
    dispatch(getProviderWarehouses())
    dispatch(getProviderProjects())
  }, [])

  const onAdvancedSearchSubmit = data => {
    setAdvancedSearch(data)
    dispatch(
      getProviderTransactions(undefined, undefined, undefined, search, data)
    )
  }

  const onExportBtnClick = () => {
    dispatch(
      exportProviderTransactionsWithProject(
        pageNumber,
        according,
        search,
        advnacedSearch
      )
    )
  }

  return (
    <React.Fragment>
      <Breadcrumbs
        title={"Provider"}
        breadcrumbItem={"Transactions By Project"}
      />
      <ProviderTransactionByProjectListComponent
        data={transactions?.data}
        error={transactions?.error}
        loading={transactions?.loading}
        onSearch={onSearchHandler}
        onChangeRowNumber={onChangeRowNumber}
        onChangeOrdering={onChangeOrdering}
        onAdvancedSearchSubmit={onAdvancedSearchSubmit}
        onExportBtnClick={onExportBtnClick}
        exportTransaction={exportTransaction}
        onPageClick={onPageClick}
      />
    </React.Fragment>
  )
}

export default ProviderTransactionByProjectList
