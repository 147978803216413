import axiosInstance from "api/axiosInstance"
import { provinceConsumptionAction } from "./province-consumption-slice"
import fileaxiosInstance from "api/fileAxiosInstance"
import exportAxiosInstance from "api/exportAxiosInstance"
import FileSaver from "file-saver"

export const getProvinceConsumptions =
  (params = {}) =>
  dispatch => {
    dispatch(provinceConsumptionAction.setLoading(true))
    dispatch(provinceConsumptionAction.setData([]))
    axiosInstance
      .get("/v1/get-province-consumptions", { params: params })
      .then(response => {
        if (response) {
          dispatch(provinceConsumptionAction.setError(null))
          dispatch(provinceConsumptionAction.setData(response.data))
          dispatch(provinceConsumptionAction.setLoading(false))
        }
      })
      .catch(error => {
        dispatch(provinceConsumptionAction.setLoading(false))
        dispatch(provinceConsumptionAction.setError(error?.response?.data))
        console.log(error)
      })
  }

export const getProvinceConsumptionsProviderAccount = () => dispatch => {
  dispatch(
    provinceConsumptionAction.setProvinceConsumptionProviderAccount({
      loading: true,
      error: null,
      data: [],
    })
  )
  axiosInstance
    .get("/v1/get-province-comsumption-provider")
    .then(response => {
      if (response) {
        dispatch(
          provinceConsumptionAction.setProvinceConsumptionProviderAccount({
            loading: false,
            error: null,
            data: response?.data,
          })
        )
      }
    })
    .catch(error => {
      dispatch(
        provinceConsumptionAction.setProvinceConsumptionProviderAccount({
          loading: false,
          error: error?.response,
          data: [],
        })
      )
      console.log(error)
    })
}

export const getProvinceConsumptionsProjects =
  (params = {}) =>
  dispatch => {
    dispatch(
      provinceConsumptionAction.setProvinceConsumptionProjects({
        loading: true,
        error: null,
        data: [],
      })
    )
    axiosInstance
      .get("/v1/get-province-comsumption-projects", { params: params })
      .then(response => {
        if (response) {
          dispatch(
            provinceConsumptionAction.setProvinceConsumptionProjects({
              loading: false,
              error: null,
              data: response?.data,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          provinceConsumptionAction.setProvinceConsumptionProjects({
            loading: false,
            error: error?.response,
            data: [],
          })
        )
        console.log(error)
      })
  }

export const getProvinceConsumptionsProvinces =
  (params = {}) =>
  dispatch => {
    dispatch(
      provinceConsumptionAction.setProvinceConsumptionProvince({
        loading: true,
        error: null,
        data: [],
      })
    )
    axiosInstance
      .get("/v1/get-province-comsumption-provinces", { params: params })
      .then(response => {
        if (response) {
          dispatch(
            provinceConsumptionAction.setProvinceConsumptionProvince({
              loading: false,
              error: null,
              data: response?.data,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          provinceConsumptionAction.setProvinceConsumptionProvince({
            loading: false,
            error: error?.response,
            data: [],
          })
        )
        console.log(error)
      })
  }

export const getProvinceConsumptionsFacilities =
  (params = {}) =>
  dispatch => {
    dispatch(
      provinceConsumptionAction.setProvinceConsumptionFacilities({
        loading: true,
        error: null,
        data: [],
      })
    )
    axiosInstance
      .get(`/v1/get-province-comsumption-facilitis`, { params: params })
      .then(response => {
        if (response) {
          dispatch(
            provinceConsumptionAction.setProvinceConsumptionFacilities({
              loading: false,
              error: null,
              data: response?.data,
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          provinceConsumptionAction.setProvinceConsumptionFacilities({
            loading: false,
            error: error?.response,
            data: [],
          })
        )
        console.log(error)
      })
  }

export const storeProvinceConsumptions = values => dispatch => {
  dispatch(
    provinceConsumptionAction.setStore({
      loading: true,
    })
  )
  axiosInstance
    .post("/v1/store-province-consumptions", values)
    .then(response => {
      if (response) {
        console.log(response?.data)
        dispatch(getProvinceConsumptions(response?.data?.data))
        dispatch(
          provinceConsumptionAction.setStore({
            loading: false,
          })
        )
      }
    })
    .catch(error => {
      dispatch(
        provinceConsumptionAction.setStore({
          loading: false,
          error: error?.response?.data,
        })
      )
      console.log(error?.response?.data)
    })
}

export const exportProvinceConsumptions = values => dispatch => {
  dispatch(
    provinceConsumptionAction.setExport({
      loading: true,
    })
  )
  exportAxiosInstance
    .post("/v1/export-province-consumptions", values)
    .then(response => {
      if (response) {
        const dataBlob = new Blob([response?.data], {
          type: "application/pdf,",
        })
        FileSaver.saveAs(
          dataBlob,
          `Consumption-${Math.random().toString()}.xlsx`
        )
        dispatch(
          provinceConsumptionAction.setExport({
            loading: false,
          })
        )
      }
    })
    .catch(error => {
      dispatch(
        provinceConsumptionAction.setExport({
          loading: false,
          error: error?.response?.data,
        })
      )
    })
}

export const updateProvinceConsumptions = values => dispatch => {
  dispatch(
    provinceConsumptionAction.setStore({
      loading: true,
      error: null,
    })
  )
  axiosInstance
    .post("/v1/update-province-consumptions", values)
    .then(response => {
      if (response) {
        dispatch(getProvinceConsumptions(values))
        dispatch(
          provinceConsumptionAction.setStore({
            loading: false,
            error: null,
          })
        )
      }
    })
    .catch(error => {
      dispatch(
        provinceConsumptionAction.setStore({
          loading: false,
          error: error?.response?.data,
        })
      )
      console.log(error)
    })
}
