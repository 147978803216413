import PaginationLinks from "components/Pagination"
import React, { useState } from "react"
import { Table } from "reactstrap"

const GeneralTables = ({
  headers = [],
  data,
  renderItem,
  pagination,
  loading,
  parentCheckbox,
  onParentCheckChange,
}) => {
  const [parentCheck, setParentCheck] = useState(false)

  const onParentCheckClick = () => {
    setParentCheck(!parentCheck)
    onParentCheckChange(!parentCheck)
  }
  return (
    <div className="table-responsive">
      <Table className="table mb-0">
        <thead>
          <tr>
            {headers.length > 0 &&
              headers.map((header, index) => (
                <th
                  key={index}
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  {parentCheckbox ? (
                    <React.Fragment>
                      {index === 0 && (
                        <div className="">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={`checkboxParent`}
                            checked={parentCheck}
                            onChange={onParentCheckClick}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`checkboxParent`}
                          >
                            &nbsp;{header}
                          </label>
                        </div>
                      )}
                      {index > 0 && header}
                    </React.Fragment>
                  ) : (
                    header
                  )}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {data?.length > 0 &&
            data.map((data, index) => renderItem(data, index))}
        </tbody>
      </Table>
      {data?.length === 0 && !loading && (
        <h5 style={{ textAlign: "center", padding: 20 }}>No Data Found</h5>
      )}

      {pagination?.meta && (
        <PaginationLinks
          meta={pagination?.meta}
          onPageClick={pagination?.onPageClick}
        />
      )}
    </div>
  )
}

export default GeneralTables
