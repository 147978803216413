import axiosInstance from "api/axiosInstance"
import { activityLogsAction } from "./activity-logs-slice"
import { batch } from "react-redux"

export const getActivityLogs =
  (
    url,
    pageNumber = 10,
    according = { order: "desc", name: "id" },
    search = null,
    advancedSearch = {}
  ) =>
  dispatch => {
    dispatch(activityLogsAction.setLoading(true))
    url = url || "/v1/get-activity-logs"
    axiosInstance
      .get(url, {
        params: {
          pageNumber: pageNumber,
          according: according,
          search: search,
          advancedSearch: advancedSearch,
        },
      })
      .then(response => {
        if (response) {
          batch(() => {
            dispatch(activityLogsAction.setError(null))
            dispatch(activityLogsAction.setData(response.data))
            dispatch(activityLogsAction.setLoading(false))
          })
        }
      })
      .catch(error => {
        dispatch(activityLogsAction.setLoading(false))
        console.log(error)
      })
  }
