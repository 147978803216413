import { useFormik } from "formik"

import * as Yup from "yup"
import React, { useEffect, useMemo, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Label,
  Button,
  Form,
  Input,
  FormFeedback,
  Row,
  Col,
  Table,
} from "reactstrap"
import CustomHeaderTitle from "components/Common/CustomHeaderTitle"
import CustomSelect from "components/Common/SelectBoxes/CustomSelect"
import { isEmpty, isObject } from "lodash"
import { useDispatch } from "react-redux"
import {
  getProviderFacilities,
  getProviderItems,
} from "store/provider-dashboard/provider-dashboard-action"
import Flatpickr from "react-flatpickr"
import moment from "moment"
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"
import { isArray } from "lodash"
import { getProviderOutReceipts } from "store/provider-out/provider-out-action"
import ReceiptsList from "./ReceiptsList"
import SubmitedList from "./SubmitedList"
import {
  getProvinceFacilities,
  getProvinceItems,
} from "store/province-dashboard/province-dashboard-action"

const ProvinceOutEditForm = ({
  onSubmit,
  loading,
  formMode,
  error,
  providers,
  categories,
  items,
  distribution,
  provinces,
  facilities,
}) => {
  const [item, setItem] = useState()

  const [submited, setSubmited] = useState([])
  const [newQuantity, setNewQuantity] = useState(0)
  const submissionDateRef = useRef(null)

  const dispatch = useDispatch()
  const distributeValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      provider_id: distribution ? distribution?.provider_id : "",
      item_category_id: distribution ? distribution?.item_category_id : "",
      item_id: distribution ? distribution?.item_id : "",
      province_id: distribution ? distribution?.province_id : "",
      facility_id: distribution ? distribution?.facility_id : "",
      province_in_id: distribution ? distribution?.province_in_id : "",
      out_quantity: distribution ? distribution?.out_quantity : "",
      distributor: distribution ? distribution?.distributor : "",
      date: distribution ? distribution?.date : "",
    },
    validationSchema: Yup.object().shape({
      date: Yup.string().required("This in quantity IP is required"),
    }),

    onSubmit: values => {
      // console.log(values)
      onSubmit(values)
    },
  })

  const resetAllForm = () => {
    distributeValidation.resetForm()
    setItem(null)
    submissionDateRef.current.flatpickr.clear()
  }

  const onProviderChangeHandler = value => {
    distributeValidation.setFieldValue("provider_id", value)
  }

  // const onIpChangeHandler = value => {
  //   distributeValidation.setFieldValue("ip_id", value)
  // }

  const onProvinceChangeHandler = value => {
    distributeValidation.setFieldValue("province_id", value)
    dispatch(getProvinceFacilities(value.value))
  }

  const onItemCategoryChangeHandler = value => {
    distributeValidation.setFieldValue("item_category_id", value)
    dispatch(getProviderItems(value.value))
    distributeValidation.setFieldValue("item_id", null)
    setItem(null)
  }

  const onItemChangeHandler = value => {
    distributeValidation.setFieldValue("item_id", value)
  }

  const onFacilityChangeHandler = value => {
    distributeValidation.setFieldValue("facility_id", value)
  }

  const filteredItems = useMemo(
    () =>
      items.map(item => ({
        value: item.id,
        label: `${item.unfp_item_name} ( ${item.item_name} )`,
      })),
    [items]
  )

  useMemo(
    () =>
      setItem(
        distribution ? items.find(it => it.id === distribution?.item_id) : null
      ),
    [items]
  )

  useEffect(() => {
    dispatch(getProvinceFacilities(distribution?.province_id))
    dispatch(getProvinceItems(distribution?.item_category_id))
  }, [])

  const onChangeOutQuantityHandler = e => {
    if (e.target.value < 0) {
      return
    }
    distributeValidation.setFieldValue("out_quantity", e.target.value)
    setNewQuantity(e.target.value)
  }

  const getTotalOutQuantity = () => {
    const total =
      distribution?.province_in?.in_qty +
      distribution?.out_quantity -
      newQuantity
    if (total >= 0) {
      return total
    } else {
      return -1
    }
  }

  return (
    <Card>
      <CardBody>
        <CustomHeaderTitle
          loading={loading}
          title={formMode === "Edit" ? "Edit Distribution" : "Add Distribution"}
          lgColumn={4}
          rowCount={false}
          orderingList={false}
          searchBox={false}
          rightBtn={true}
          rightBtnTitle={"Back"}
          rightBtnRoute={"/province-distributions"}
        />

        <Form
          onSubmit={e => {
            e.preventDefault()
            distributeValidation.handleSubmit()
            return false
          }}
        >
          <div className="mb-3">
            <Row>
              <Col>
                <Label className="form-label">Providers</Label>
                {providers && (
                  <CustomSelect
                    name="warehouse_id"
                    options={providers}
                    value={
                      isObject(distributeValidation.values.provider_id)
                        ? distributeValidation.values.provider_id
                        : providers.find(
                            prov =>
                              distributeValidation.values.provider_id ===
                              prov.value
                          ) || providers[0]
                    }
                    onChange={onProviderChangeHandler}
                  />
                )}
              </Col>
              <Col>
                <Label className="form-label">Provinces</Label>
                {provinces && (
                  <CustomSelect
                    name="province_id"
                    options={provinces}
                    value={
                      isObject(distributeValidation.values.province_id)
                        ? distributeValidation.values.province_id
                        : provinces.find(
                            prov =>
                              distributeValidation.values.province_id ===
                              prov.value
                          )
                    }
                    onChange={onProvinceChangeHandler}
                  />
                )}
              </Col>
            </Row>
          </div>

          {/* <div className="mb-3">
            <Row>
              <Col>
                <Label className="form-label">IPs</Label>
                {ips && (
                  <CustomSelect
                    name="warehouse_id"
                    options={ips}
                    value={
                      isObject(distributeValidation.values.ip_id)
                        ? distributeValidation.values.ip_id
                        : ips.find(
                            p => distributeValidation.values.ip_id === p.value
                          ) || ips[0]
                    }
                    onChange={onIpChangeHandler}
                  />
                )}
              </Col>
              <Col>
                <Label className="form-label">Distributor Name</Label>
                <Input
                  name="distributor"
                  placeholder=" Distributor Name"
                  type="text"
                  onChange={distributeValidation.handleChange}
                  onBlur={distributeValidation.handleBlur}
                  value={distributeValidation.values.distributor || ""}
                  invalid={
                    (distributeValidation.touched.distributor &&
                      distributeValidation.errors.distributor) ||
                    error?.distributor?.[0]
                      ? true
                      : false
                  }
                />
                {(distributeValidation.touched.distributor &&
                  distributeValidation.errors.distributor) ||
                error?.distributor?.[0] ? (
                  <FormFeedback type="invalid">
                    {distributeValidation.errors.distributor ||
                      error?.distributor?.[0]}
                  </FormFeedback>
                ) : null}
              </Col>
            </Row>
          </div> */}

          <div className="mb-3">
            <Row>
              {facilities && (
                <Col>
                  <div className="mb-3">
                    <Label className="form-label">Facilities</Label>
                    {facilities && (
                      <CustomSelect
                        name="facility_id"
                        options={facilities}
                        value={
                          isObject(distributeValidation.values.facility_id)
                            ? distributeValidation.values.facility_id
                            : facilities.find(
                                facil =>
                                  distributeValidation.values.facility_id ===
                                  facil.value
                              )
                        }
                        onChange={onFacilityChangeHandler}
                      />
                    )}
                  </div>
                </Col>
              )}

              {/* {!isEmpty(distributeValidation.values.distributor) && (
                <Col>
                  <Label className="form-label">Distributor Name</Label>
                  <Input
                    name="distributor"
                    placeholder=" Distributor Name"
                    type="text"
                    onChange={distributeValidation.handleChange}
                    onBlur={distributeValidation.handleBlur}
                    value={distributeValidation.values.distributor || ""}
                    invalid={
                      (distributeValidation.touched.distributor &&
                        distributeValidation.errors.distributor) ||
                      error?.distributor?.[0]
                        ? true
                        : false
                    }
                  />
                  {(distributeValidation.touched.distributor &&
                    distributeValidation.errors.distributor) ||
                  error?.distributor?.[0] ? (
                    <FormFeedback type="invalid">
                      {distributeValidation.errors.distributor ||
                        error?.distributor?.[0]}
                    </FormFeedback>
                  ) : null}
                </Col>
              )} */}
            </Row>
          </div>

          <div className="mb-3">
            <Label className="form-label">Submission Date</Label>
            <Flatpickr
              ref={submissionDateRef}
              className="form-control d-block"
              placeholder="dd M,yyyy"
              options={{
                altInput: true,
                altFormat: "Y-m-d",
                dateFormat: "Y-m-d",
                defaultDate: distributeValidation.values.date,
                maxDate: moment().format("YYYY-MM-DD"),
              }}
              onChange={value => {
                distributeValidation.setFieldValue(
                  "date",
                  moment(value[0]).format("YYYY-MM-DD")
                )
              }}
            />
            {(distributeValidation.touched.date &&
              distributeValidation.errors.date) ||
            error?.date?.[0] ? (
              <FormFeedback type="invalid">
                {distributeValidation.errors.date || error?.date?.[0]}
              </FormFeedback>
            ) : null}
          </div>

          <div className="mb-3">
            <Row>
              <Col>
                <Label className="form-label">Item Categories</Label>
                {categories && (
                  <CustomSelect
                    name="item_category_id"
                    options={categories}
                    value={
                      isObject(distributeValidation.values.item_category_id)
                        ? distributeValidation.values.item_category_id
                        : categories.filter(
                            cate =>
                              distributeValidation.values.item_category_id ===
                              cate.value
                          )
                    }
                    isDisabled={true}
                    onChange={onItemCategoryChangeHandler}
                  />
                )}
              </Col>
              {/* <Col>
                <Label className="form-label">Items</Label>
                {filteredItems && (
                  <CustomSelect
                    name="item_id"
                    options={filteredItems}
                    value={
                      isObject(distributeValidation.values.item_id)
                        ? distributeValidation.values.item_id
                        : filteredItems.find(
                            item =>
                              distributeValidation.values.item_id === item.value
                          )
                    }
                    onChange={onItemChangeHandler}
                  />
                )}
              </Col> */}
            </Row>
          </div>

          {distribution?.province_in && item && (
            <div className="mb-3">
              <Table>
                <thead>
                  <tr>
                    <th>UNFPA ITEM ID</th>
                    <th>IP-ITEM DESCRIPTION</th>
                    <th>UNFPA UOM</th>
                    <th>IP UNIT UOM</th>
                    <th>BATCH NUMBER</th>
                    <th>EXPIRE DATE</th>
                    <th>QTY</th>
                    <th>OUT</th>
                    <th>BALANCE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{item?.unfp_item_name}</td>
                    <td>{item?.ip_item_description}</td>
                    <td>
                      {item?.uom}{" "}
                      {item?.unfpa_uom_quantity > 1 && item?.unfpa_uom_quantity}
                    </td>
                    <td>
                      {item?.ip_unit_umo}{" "}
                      {item?.ip_uom_quantity > 1 && item?.ip_uom_quantity}
                    </td>
                    <td>{distribution?.province_in?.batch_number}</td>
                    <td>{distribution?.province_in?.expire_date}</td>
                    <td>{distribution?.province_in?.in_qty}</td>
                    <td>
                      <Input
                        type="number"
                        name="out_qty"
                        onWheel={e => e.target.blur()}
                        value={
                          newQuantity !== 0
                            ? newQuantity
                            : distributeValidation.values.out_quantity
                        }
                        onChange={onChangeOutQuantityHandler}
                      />
                    </td>
                    <td>
                      {getTotalOutQuantity() >= 0 ? (
                        getTotalOutQuantity()
                      ) : (
                        <span className="text-danger">
                          quantity is greater then receipt
                        </span>
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
              {error?.out_quantity && (
                <p className="text-danger">{error?.out_quantity}</p>
              )}
            </div>
          )}

          <div className="d-flex flex-wrap gap-2">
            <Button
              type="submit"
              color="primary"
              disabled={loading || getTotalOutQuantity() < 0}
            >
              {loading
                ? "Please Wait..."
                : formMode === "Add"
                ? "Save"
                : "Update"}
            </Button>
          </div>
        </Form>
        <br />
        {submited.length > 0 && <SubmitedList submited={submited} />}
      </CardBody>
    </Card>
  )
}

export default ProvinceOutEditForm
