import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import {
  exportProviderExpireReceipts,
  getExpireReceipts,
} from "store/provider-exipre-receipts/e-expire-receipts-action"
import ProviderExpireListComponent from "components/ProviderExpireReceipts/list"

const ProviderExpireReceiptList = () => {
  document.title = "Expire Receipts"

  const dispatch = useDispatch()
  const {
    data,
    error,
    loading,
    exportExpireReceipt: exportStatus,
  } = useSelector(state => ({
    data: state.providerExpireReceipts.data,
    error: state.providerExpireReceipts.error,
    loading: state.providerExpireReceipts.loading,
    exportExpireReceipt: state.providerExpireReceipts.exportExpireReceipt,
  }))
  const [search, setSearch] = useState(null)
  const [pageNumber, setPageNumber] = useState(10)
  const [according, setAccording] = useState({ order: "desc", name: "id" })
  const [advnacedSearch, setAdvancedSearch] = useState({})

  const onPageClick = link => {
    dispatch(
      getExpireReceipts(
        link.url,
        pageNumber,
        according,
        search,
        advnacedSearch,
        6
      )
    )
  }

  const onSearchHandler = value => {
    setSearch(value)
  }

  const onChangeRowNumber = value => {
    setPageNumber(value)
  }

  const onChangeOrdering = value => {
    if (value.order) {
      setAccording(prev => {
        return { ...prev, order: value.order }
      })
    }
    if (value.name) {
      setAccording(prev => {
        return { ...prev, name: value.name }
      })
    }
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(
        getExpireReceipts(
          undefined,
          undefined,
          undefined,
          search,
          advnacedSearch,
          6
        )
      )
    }, 1500)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  useEffect(() => {
    dispatch(
      getExpireReceipts(
        undefined,
        pageNumber,
        according,
        search,
        advnacedSearch,
        6
      )
    )
  }, [pageNumber, according])

  const onAdvancedSearchSubmit = data => {
    setAdvancedSearch(data)
    dispatch(
      getExpireReceipts(undefined, undefined, undefined, search, data, 6)
    )
  }

  const onExportBtnClick = () => {
    dispatch(
      exportProviderExpireReceipts(
        undefined,
        undefined,
        search,
        advnacedSearch,
        6
      )
    )
  }

  return (
    <React.Fragment>
      <Breadcrumbs
        title={"Provider"}
        breadcrumbItem={"Expire Receipts Based 6 Month"}
      />
      <ProviderExpireListComponent
        data={data}
        error={error}
        loading={loading}
        onPageClick={onPageClick}
        onSearch={onSearchHandler}
        onChangeRowNumber={onChangeRowNumber}
        onChangeOrdering={onChangeOrdering}
        onAdvancedSearchSubmit={onAdvancedSearchSubmit}
        onExportBtnClick={onExportBtnClick}
        exportStatus={exportStatus}
        based={6}
      />
    </React.Fragment>
  )
}

export default ProviderExpireReceiptList
