import React, { useState } from "react"
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
import { downloadURL } from "api/APP_URL"
import { Link } from "react-router-dom"

const ProviderOutDetailModal = ({ data, open, setOpen }) => {
  return (
    <Modal
      isOpen={open}
      role="dialog"
      autoFocus={true}
      centered={true}
      tabIndex="-1"
      toggle={() => {
        setOpen(!open)
      }}
      size="xl"
    >
      <div>
        <ModalHeader
          toggle={() => {
            setOpen(!open)
          }}
        >
          Summary
        </ModalHeader>
        <ModalBody>
          <div className="table-responsive">
            <div
              style={{
                borderBottom: "0.8px solid #9facc2",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Row>
                <Col lg={4}>
                  <div>
                    <h6 className="detailTitle">DISTRIBUTED QTY PER IP</h6>
                    <p className="detailValue">{data?.out_quantity} </p>
                  </div>
                </Col>
                <Col lg={4}>
                  <div>
                    <h6 className="detailTitle">DISTRIBUTED QTY PER UNFPA</h6>
                    <p className="detailValue">
                      {data?.unfpa_out_quantity.toFixed(2)}{" "}
                    </p>
                  </div>
                </Col>
                <Col lg={4}>
                  <div>
                    <h6 className="detailTitle">TOTLA VALUE PER UNFPA</h6>
                    <p className="detailValue">
                      {data?.unfpa_total_value.toFixed(2)}{" "}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
            <Row
              style={{
                justifyContent: "center",
                textAlign: "center",
                marginTop: 20,
              }}
            >
              <Col lg={6}>
                <div>
                  <h6 className="detailTitle">Project</h6>
                  <p className="detailValue">{data?.project?.name}</p>
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <h6 className="detailTitle">Warehouse</h6>
                  <p className="detailValue">{data?.warehouse?.name}</p>
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <h6 className="detailTitle">Provider</h6>
                  <p className="detailValue">{data?.provider?.title}</p>
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <h6 className="detailTitle">IP ( Distributor )</h6>
                  <p className="detailValue">{data?.ip?.name}</p>
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <h6 className="detailTitle">Facility</h6>
                  <p className="detailValue">
                    {data?.facility?.facility_name &&
                      data?.facility?.facility_name != "Others" &&
                      data?.facility?.facility_name}
                  </p>
                  <p className="detailValue">{data?.other_hfs}</p>
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <h6 className="detailTitle">Item Category</h6>
                  <p className="detailValue">{data?.item_category?.name}</p>
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <h6 className="detailTitle">Item</h6>
                  <p className="detailValue">{data?.item?.item_name}</p>
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <h6 className="detailTitle">UNFPA Item ID</h6>
                  <p className="detailValue">{data?.item?.unfp_item_name}</p>
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <h6 className="detailTitle">IP- Item description</h6>
                  <p className="detailValue">
                    {data?.item?.ip_item_description}
                  </p>
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <h6 className="detailTitle">UNFPA Unit of measure (UOM)</h6>
                  <p className="detailValue">
                    {data?.item.uom} &nbsp;
                    {data?.provider_in?.unit_uom_quantity_per_unfpa > 1 &&
                      data?.provider_in?.unit_uom_quantity_per_unfpa}
                  </p>
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <h6 className="detailTitle">IP Unit UOM</h6>
                  <p className="detailValue">
                    {data?.item.ip_unit_umo} &nbsp;
                    {data?.provider_in?.unit_umo_quantity_per_ip > 1 &&
                      data?.provider_in?.unit_umo_quantity_per_ip}
                  </p>
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <h6 className="detailTitle">Unit price (as per UNFPA)</h6>
                  <p className="detailValue">{data?.unit_price_per_unfpa}</p>
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <h6 className="detailTitle">Unit price (as per IP UOM)</h6>
                  <p className="detailValue">{data?.unit_price_per_ip}</p>
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <h6 className="detailTitle">Province</h6>
                  <p className="detailValue">{data?.province?.province}</p>
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <h6 className="detailTitle">Date</h6>
                  <p className="detailValue">{data?.date}</p>
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <h6 className="detailTitle">Created at</h6>
                  <p className="detailValue">{data?.created_at}</p>
                </div>
              </Col>
            </Row>

            {data?.document && (
              <div>
                {" "}
                <br />
                <a
                  href={`${downloadURL}/${data?.document}`}
                  className="btn btn-sm btn-info"
                  target="_blank"
                  rel="noreferrer"
                  download={true}
                >
                  Download
                </a>
              </div>
            )}
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default ProviderOutDetailModal
