import PropTypes from "prop-types"
import React, { useEffect } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"
import { Link, useNavigate, useParams } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { useDispatch, useSelector } from "react-redux"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.svg"
import logoLight from "../../assets/images/logos/log_bg_less.png"
import {
  checkResetPassword,
  login,
  resetPassword,
} from "store/auth/auth-actions"
import Preloader from "components/Common/Loader"
import { getAuthToken } from "utils/authUtil"
import PasswordInput from "components/Common/PasswordInput"
import { authAction } from "store/auth/auth-slice"

const ResetPasswordPage = props => {
  //meta title
  document.title = "eLMIS Reset Password"
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const { loading: checkPasswordLoading, data: checkPassword } = useSelector(
    state => state.auth.checkResetPassword
  )
  const { loading, data, error } = useSelector(
    state => state.auth.resetPassword
  )

  const token = getAuthToken()

  const params = useParams()

  useEffect(() => {
    dispatch(checkResetPassword(params.token))

    return () => {
      dispatch(authAction.resetCheckResetPassword())
      dispatch(authAction.resetResetPassword())
    }
  }, [])

  if (token) {
    navigate(-1)
  }

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      password: "",
      confirm: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Please Enter Your Password"),
      confirm: Yup.string().required("Please Enter Your Re-Type Password"),
    }),
    onSubmit: values => {
      values.token = params.token
      dispatch(resetPassword(values))(() => {
        navigate("/login?password=updated")
      })
    },
  })

  return (
    <React.Fragment>
      {!token ? (
        <React.Fragment>
          <div className="account-pages my-5 pt-sm-5">
            <Container>
              <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>
                  <Card className="overflow-hidden">
                    <div className="bg-primary bg-soft">
                      <Row>
                        <Col xs={7}>
                          <div className="text-primary p-4">
                            <h5 className="text-primary">eLMIS !</h5>
                            <p>Reset Your Password.</p>
                          </div>
                        </Col>
                        <Col
                          className="col-5 align-self-center"
                          style={{ textAlign: "right" }}
                        >
                          <img
                            src={logoLight}
                            alt=""
                            style={{ width: 100, marginRight: 10 }}
                            className="img-fluid"
                          />
                        </Col>
                      </Row>
                    </div>
                    <CardBody className="pt-0">
                      <div>
                        <Link to="/" className="logo-light-element">
                          <div className="avatar-md profile-user-wid mb-4">
                            <span className="avatar-title rounded-circle bg-light">
                              <img
                                src={logo}
                                alt=""
                                className="rounded-circle"
                                height="34"
                              />
                            </span>
                          </div>
                        </Link>
                      </div>
                      {checkPasswordLoading ? (
                        <p
                          style={{
                            padding: 10,
                            textAlign: "center",
                            fontSize: 15,
                          }}
                        >
                          Loading...
                        </p>
                      ) : (
                        <React.Fragment>
                          {checkPassword?.result ? (
                            <div className="p-2">
                              <Form
                                className="form-horizontal"
                                onSubmit={e => {
                                  e.preventDefault()
                                  validation.handleSubmit()
                                  return false
                                }}
                              >
                                {error?.error ? (
                                  <Alert color="danger">{error?.error}</Alert>
                                ) : null}

                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label className="form-label">
                                        Password
                                      </Label>
                                      <PasswordInput
                                        name="password"
                                        value={validation.values.password || ""}
                                        type="password"
                                        placeholder="Enter Password"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        invalid={
                                          (validation.touched.password &&
                                            validation.errors.password) ||
                                          error?.password?.[0]
                                            ? true
                                            : false
                                        }
                                      />
                                      {(validation.touched.password &&
                                        validation.errors.password) ||
                                      error?.password?.[0] ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.password ||
                                            error?.password?.[0]}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <Label className="form-label">
                                        Re-Type Password
                                      </Label>
                                      <PasswordInput
                                        name="confirm"
                                        value={validation.values.confirm || ""}
                                        type="password"
                                        placeholder="Re-type Password"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        invalid={
                                          (validation.touched.confirm &&
                                            validation.errors.confirm) ||
                                          error?.confirm?.[0]
                                            ? true
                                            : false
                                        }
                                      />
                                      {(validation.touched.confirm &&
                                        validation.errors.confirm) ||
                                      error?.confirm?.[0] ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.confirm ||
                                            error?.confirm?.[0]}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                </Row>

                                <div className="mt-3 d-grid">
                                  <button
                                    className="btn btn-primary btn-block"
                                    type="submit"
                                    disabled={loading}
                                  >
                                    {loading ? "Please wait..." : "Log In"}
                                  </button>
                                </div>
                              </Form>
                            </div>
                          ) : (
                            <p
                              style={{
                                padding: 10,
                                textAlign: "center",
                                fontSize: 15,
                              }}
                              className="text-danger"
                            >
                              {checkPassword?.message}
                            </p>
                          )}
                        </React.Fragment>
                      )}
                    </CardBody>
                  </Card>
                  <div className="mt-5 text-center">
                    <p>
                      © {new Date().getFullYear()} eLMIS by{" "}
                      <a href="https://www.aryanict.com/">
                        Aryan ICT Solutions
                      </a>
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </React.Fragment>
      ) : (
        <Preloader loading={true} />
      )}
    </React.Fragment>
  )
}

export default withRouter(ResetPasswordPage)

ResetPasswordPage.propTypes = {
  history: PropTypes.object,
}
