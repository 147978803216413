import axiosInstance from "api/axiosInstance"
import { ipsAction } from "./ips-slice"
import SAlert from "components/Alert"
import exportAxiosInstance from "api/exportAxiosInstance"
import FileSaver from "file-saver"

export const getIps =
  (
    url,
    pageNumber = 10,
    according = { order: "desc", name: "id" },
    search = null
  ) =>
  dispatch => {
    dispatch(ipsAction.setLoading(true))
    url = url || "/v1/ips"
    axiosInstance
      .get(url, {
        params: {
          pageNumber: pageNumber,
          according: according,
          search: search,
        },
      })
      .then(response => {
        if (response) {
          dispatch(ipsAction.setError(null))
          dispatch(ipsAction.setData(response.data))
          dispatch(ipsAction.setLoading(false))
        }
      })
      .catch(error => {
        dispatch(ipsAction.setLoading(false))
        console.log(error)
      })
  }

export const deleteIP = id => dispatch => {
  dispatch(ipsAction.setLoading(true))
  axiosInstance
    .delete(`/v1/ips/${id}`)
    .then(response => {
      dispatch(getIps())
      SAlert({ title: "Success", text: "IPs successfully deleted" })
      dispatch(ipsAction.setError(null))
      dispatch(ipsAction.setLoading(false))
    })
    .catch(error => {
      dispatch(ipsAction.setLoading(false))
      console.log(error)
    })
}

export const storeIP = (data, navigate) => dispatch => {
  dispatch(ipsAction.setLoading(true))
  axiosInstance
    .post("/v1/ips", data)
    .then(response => {
      navigate("/ips")
      SAlert({ title: "Success", text: "IPs successfully inserted" })
      dispatch(ipsAction.setError(null))
      dispatch(ipsAction.setLoading(false))
    })
    .catch(err => {
      dispatch(ipsAction.setLoading(false))
      dispatch(ipsAction.setError(err?.response?.data?.errors))
    })
}

export const updateIP = (id, data, navigate) => dispatch => {
  dispatch(ipsAction.setLoading(true))
  axiosInstance
    .put(`/v1/ips/${id}`, data)
    .then(respnose => {
      navigate("/ips")
      SAlert({ title: "Success", text: "IPs successfully updated" })
      dispatch(ipsAction.setError(null))
      dispatch(ipsAction.setLoading(false))
    })
    .catch(error => {
      dispatch(ipsAction.setLoading(false))
      dispatch(ipsAction.setError(error?.response?.data?.errors))
    })
}

export const getByIdIP = id => dispatch => {
  dispatch(ipsAction.setLoading(true))
  axiosInstance
    .get(`/v1/ips/${id}`)
    .then(respnose => {
      dispatch(ipsAction.setError(null))
      dispatch(ipsAction.setLoading(false))
      dispatch(ipsAction.setIP(respnose.data))
    })
    .catch(error => {
      dispatch(ipsAction.setLoading(false))
      console.log(error)
    })
}

export const exportIPs = search => dispatch => {
  dispatch(
    ipsAction.setExportStatus({
      loading: true,
      error: null,
    })
  )
  exportAxiosInstance
    .get("/v1/ips-export", {
      params: { search },
    })
    .then(response => {
      if (response) {
        const dataBlob = new Blob([response?.data], {
          type: "application/pdf,",
        })
        FileSaver.saveAs(dataBlob, `IPs-${Math.random().toString()}.xlsx`)
        dispatch(
          ipsAction.setExportStatus({
            loading: false,
            error: null,
          })
        )
      }
    })
    .catch(error => {
      dispatch(
        ipsAction.setExportStatus({
          loading: false,
          error: error?.response?.errors,
        })
      )
      console.log(error)
    })
}
