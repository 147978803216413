import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: [],
  loading: false,
  error: null,
  project: null,
  exportStatus: { loading: false, error: null },
}

const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    setError(state, action) {
      state.error = action.payload
    },
    setProject(state, action) {
      state.project = action.payload
    },
    setExportStatus(state, action) {
      state.exportStatus = {
        loading: action.payload.loading,
        error: action.payload.error,
      }
    },
    resetState(state) {
      state.data = []
      state.loading = false
      state.error = null
      state.project = null
      state.exportStatus = { loading: false, error: null }
    },
  },
})

export const projectAction = projectSlice.actions

export default projectSlice
