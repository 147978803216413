import React, { useEffect, useMemo, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import {
  exportMonitorConsumptionReports,
  getMonitorConsumptionReports,
} from "store/monitor-reports/monitor-report-action"
import {
  getMonitorIps,
  getMonitorItemCategories,
  getMonitorProjects,
} from "store/monitor-dashboard/monitor-dashboard-action"
import MonitorConsumptionReportListComponent from "components/MonitorReports/Consumption"

const MonitorConsumptionReportList = ({ from }) => {
  document.title = "Consumption Reports"

  const dispatch = useDispatch()
  const consumptions = useSelector(
    state => state.monitorReports.monitorConsumptionReport
  )
  const exportStatus = useSelector(
    state => state.monitorReports.monitorConsumptionExport
  )

  const [according, setAccording] = useState({ order: "desc", name: "date" })
  const [advnacedSearch, setAdvancedSearch] = useState({})
  const [pageNumber, setPageNumber] = useState(20)

  const onPageClick = link => {
    dispatch(
      getMonitorConsumptionReports(
        link.url,
        pageNumber,
        according,
        advnacedSearch
      )
    )
  }

  const onChangeRowNumber = value => {
    setPageNumber(value)
  }

  const onChangeOrdering = value => {
    if (value.order) {
      setAccording(prev => {
        return { ...prev, order: value.order }
      })
    }
    if (value.name) {
      setAccording(prev => {
        return { ...prev, name: value.name }
      })
    }
  }

  useEffect(() => {
    dispatch(
      getMonitorConsumptionReports(
        undefined,
        pageNumber,
        according,
        advnacedSearch
      )
    )
  }, [according, pageNumber])

  useEffect(() => {
    dispatch(getMonitorItemCategories())
    dispatch(getMonitorIps())
  }, [])

  const onSearchHandler = data => {
    setAdvancedSearch(data)
    dispatch(
      getMonitorConsumptionReports(undefined, pageNumber, undefined, data)
    )
  }

  const onExportBtnClick = () => {
    dispatch(exportMonitorConsumptionReports(according, advnacedSearch))
  }

  return (
    <React.Fragment>
      {from !== "Dashboard" && (
        <Breadcrumbs title={"Monitor"} breadcrumbItem={"Consumption Reports"} />
      )}
      {from === "Dashboard" && (
        <div>
          <span style={{ fontSize: 24, marginBottom: 20 }}>
            Consumption Report
          </span>
          <br />
        </div>
      )}

      <MonitorConsumptionReportListComponent
        data={consumptions?.data}
        error={consumptions?.error}
        loading={consumptions?.loading}
        onChangeOrdering={onChangeOrdering}
        onSearchHandler={onSearchHandler}
        onExportBtnClick={onExportBtnClick}
        exportStatus={exportStatus}
        from={from}
        onPageClick={onPageClick}
        onChangeRowNumber={onChangeRowNumber}
      />
    </React.Fragment>
  )
}

export default MonitorConsumptionReportList
