import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import { exportProvinces, getProvinces } from "store/province/province-action"
import ProvinceListComponent from "components/Province/list"

const ProvinceList = () => {
  const dispatch = useDispatch()
  const { data, error, loading, exportStatus } = useSelector(
    state => state.province
  )
  const [search, setSearch] = useState(null)
  const [pageNumber, setPageNumber] = useState(10)
  const [according, setAccording] = useState({ order: "desc", name: "id" })

  const onPageClick = link => {
    dispatch(getProvinces(link.url, pageNumber, according, search))
  }

  const onSearchHandler = value => {
    setSearch(value)
  }

  const onChangeRowNumber = value => {
    setPageNumber(value)
  }

  const onChangeOrdering = value => {
    if (value.order) {
      setAccording(prev => {
        return { ...prev, order: value.order }
      })
    }
    if (value.name) {
      setAccording(prev => {
        return { ...prev, name: value.name }
      })
    }
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(getProvinces(undefined, undefined, undefined, search))
    }, 1500)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  useEffect(() => {
    dispatch(getProvinces(undefined, pageNumber, according, search))
  }, [pageNumber, according])

  const onExportBtnClick = () => {
    dispatch(exportProvinces(search))
  }

  return (
    <React.Fragment>
      <Breadcrumbs title={"Admin"} breadcrumbItem={"Provinces"} />
      <ProvinceListComponent
        data={data}
        error={error}
        loading={loading}
        onPageClick={onPageClick}
        onSearch={onSearchHandler}
        onChangeRowNumber={onChangeRowNumber}
        onChangeOrdering={onChangeOrdering}
        onExportBtnClick={onExportBtnClick}
        exportStatus={exportStatus}
      />
    </React.Fragment>
  )
}

export default ProvinceList
