import ItemCategoryForm from "components/ItemCategory/form"
import WarehouseForm from "components/Warehouse/form"
import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { storeItemCategory } from "store/item-category/item-category-action"

const AddItemCategory = () => {
  document.title = "Add Item Category"

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { loading, error, data } = useSelector(state => state.itemCategory)

  const handleSubmit = values => {
    dispatch(storeItemCategory(values, navigate))
  }

  return (
    <ItemCategoryForm
      onSubmit={handleSubmit}
      loading={loading}
      error={error}
      formMode={"Add"}
    />
  )
}

export default AddItemCategory
