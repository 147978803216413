import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import DeleteModal from "components/Common/DeleteModal"
import {
  deleteManyProviderIn,
  deleteProviderIn,
  getProviderIns,
} from "store/provider-in/provider-in-action"
import ProviderInListComponent from "components/ProviderIn/list"
import SAlert from "components/Alert"
import {
  getProviderItemCategories,
  getProviderProjects,
} from "store/provider-dashboard/provider-dashboard-action"
import useSearch from "hooks/useSearch"
import ProviderInDetailModal from "components/ProviderIn/details"
import { getActivityLogs } from "store/activity-logs/activity-logs-action"
import ActivityLogsComponents from "components/ActivityLogs/ActivityLogs"

const ActivityLogsList = () => {
  document.title = "Activiy Logs"

  const dispatch = useDispatch()
  const { data, error, loading } = useSelector(state => state.activityLogs)
  const [search, setSearch] = useState(null)
  const [pageNumber, setPageNumber] = useState(10)
  const [according, setAccording] = useState({ order: "desc", name: "id" })
  const [advnacedSearch, setAdvancedSearch] = useState({})

  const onPageClick = link => {
    dispatch(
      getActivityLogs(link.url, pageNumber, according, search, advnacedSearch)
    )
  }

  const onSearchHandler = value => {
    setSearch(value)
  }

  const onChangeRowNumber = value => {
    setPageNumber(value)
  }

  const onChangeOrdering = value => {
    if (value.order) {
      setAccording(prev => {
        return { ...prev, order: value.order }
      })
    }
    if (value.name) {
      setAccording(prev => {
        return { ...prev, name: value.name }
      })
    }
  }

  useSearch(
    getActivityLogs(undefined, undefined, undefined, search, advnacedSearch),
    search
  )

  useEffect(() => {
    dispatch(
      getActivityLogs(undefined, pageNumber, according, search, advnacedSearch)
    )
  }, [pageNumber, according])

  const onAdvancedSearchSubmit = data => {
    setAdvancedSearch(data)
    dispatch(getActivityLogs(undefined, undefined, undefined, search, data))
  }
  return (
    <React.Fragment>
      <Breadcrumbs title={"Admin"} breadcrumbItem={"Activity Logs"} />
      <ActivityLogsComponents
        data={data}
        error={error}
        loading={loading}
        onPageClick={onPageClick}
        onSearch={onSearchHandler}
        onChangeRowNumber={onChangeRowNumber}
        onChangeOrdering={onChangeOrdering}
        onAdvancedSearchSubmit={onAdvancedSearchSubmit}
      />
    </React.Fragment>
  )
}

export default ActivityLogsList
