import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: [],
  role: null,
  error: null,
  loading: false,
  permissionChange: null,
}

const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload
    },
    setError(state, action) {
      state.error = action.payload
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    resetState(state) {
      state.data = []
      state.error = null
      state.loading = true
      state.role = null
      state.permissionChange = { loading: false, error: null, data: null }
    },
    setRole(state, action) {
      state.role = action.payload
    },
    setPermissionChange(state, action) {
      state.permissionChange = {
        loading: action.payload.loading,
        error: action.payload.error,
        data: action.payload.data,
      }
    },
  },
})

export const roleAction = roleSlice.actions

export default roleSlice
