import { createSlice } from "@reduxjs/toolkit"

import {
  layoutTypes,
  layoutModeTypes,
  layoutWidthTypes,
  topBarThemeTypes,
  leftBarThemeImageTypes,
  leftSidebarTypes,
  leftSideBarThemeTypes,
} from "../../constants/layout"

const initialState = {
  layoutType: layoutTypes.VERTICAL,
  layoutModeType: layoutModeTypes.LIGHT,
  layoutWidth: layoutWidthTypes.FLUID,
  leftSideBarTheme: leftSideBarThemeTypes.DARK,
  leftSideBarThemeImage: leftBarThemeImageTypes.NONE,
  leftSideBarType: leftSidebarTypes.DEFAULT,
  topbarTheme: topBarThemeTypes.LIGHT,
  isPreloader: false,
  showRightSidebar: false,
  isMobile: false,
  showSidebar: true,
  leftMenu: false,
}

const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    changeLayout(state, action) {
      state.layoutType = action.payload
    },
    changePreloader(state, action) {
      state.isPreloader = action.payload
    },
    changeLayoutMode(state, action) {
      state.layoutModeType = action.payload
    },
    changeLayoutWidth(state, action) {
      state.layoutWidth = action.payload
    },
    changeSideBarTheme(state, action) {
      state.leftSideBarTheme = action.payload
    },
    changeLeftSidebarThemeImage(state, action) {
      state.leftSideBarThemeImage = action.payload
    },
    changeSidebarType(state, action) {
      state.leftSideBarType = action.payload.sidebarType
    },
    changeTopBarTheme(state, action) {
      state.topbarTheme = action.payload
    },
    ShowRightSidebar(state, action) {
      state.showRightSidebar = action.payload
    },
    ShowSidebar(state, action) {
      state.showSidebar = action.payload
    },
    toggleLeftMenu(state, action) {
      state.leftMenu = action.payload
    },
  },
})

export const layoutAction = layoutSlice.actions

export default layoutSlice
