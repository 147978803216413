import MonitorStockOutComponent from "components/MonitorStockOut"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  exportMonitorStockOut,
  getMonitorStockOut,
} from "store/monitor-stock-out/monitor-stockout-action"

const MonitorStockOut = () => {
  document.title = "Stock Out"
  const dispatch = useDispatch()
  const [params, setParams] = useState({})
  const { data, error, loading, exportStatus } = useSelector(state => ({
    data: state.monitorStockout.data,
    error: state.monitorStockout.error,
    loading: state.monitorStockout.loading,
    exportStatus: state.monitorStockout.exportStatus,
  }))

  useEffect(() => {
    dispatch(getMonitorStockOut())
  }, [])

  const onAdvancedSearch = filters => {
    setParams(filters)
    dispatch(getMonitorStockOut(filters))
  }

  const onExportBtnClick = () => {
    dispatch(exportMonitorStockOut(params))
  }

  return (
    <MonitorStockOutComponent
      data={data}
      error={error}
      loading={loading}
      exportStatus={exportStatus}
      onAdvancedSearch={onAdvancedSearch}
      onExportBtnClick={onExportBtnClick}
    />
  )
}

export default MonitorStockOut
